var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.$router.back()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-expense" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6", md: "4" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "min-width": "auto",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    label: "Fecha",
                                                    "prepend-icon":
                                                      "mdi-calendar",
                                                    readonly: "",
                                                  },
                                                  model: {
                                                    value: _vm.form.issue_date,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "issue_date",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.issue_date",
                                                  },
                                                },
                                                "v-text-field",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.menu,
                                    callback: function ($$v) {
                                      _vm.menu = $$v
                                    },
                                    expression: "menu",
                                  },
                                },
                                [
                                  _c("v-date-picker", {
                                    on: {
                                      input: function ($event) {
                                        _vm.menu = false
                                      },
                                    },
                                    model: {
                                      value: _vm.form.issue_date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "issue_date", $$v)
                                      },
                                      expression: "form.issue_date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.funds,
                                  "prepend-icon": "mdi-bank-outline",
                                  "item-text": "title",
                                  "item-value": "id",
                                  loading: _vm.loading.fund,
                                  "error-messages": _vm.fundErrors,
                                  label: "Fondo",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.fund.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.fund.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.fund,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fund", $$v)
                                  },
                                  expression: "form.fund",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.expense_types,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "error-messages": _vm.expenseTypeErrors,
                                  label: "Tipo",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.expense_type.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.expense_type.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.expense_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "expense_type", $$v)
                                  },
                                  expression: "form.expense_type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.expense_states,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "error-messages": _vm.expenseStateErrors,
                                  label: "Estado",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.expense_state.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.expense_state.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.expense_state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "expense_state", $$v)
                                  },
                                  expression: "form.expense_state",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Monto",
                                  prefix: "$",
                                  "error-messages": _vm.amountErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.amount.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.amount.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "amount", $$v)
                                  },
                                  expression: "form.amount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Título",
                                  "error-messages": _vm.titleErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.description.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.description.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "2",
                                  solo: "",
                                  label: "Detalle...",
                                },
                                model: {
                                  value: _vm.form.detail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "detail", $$v)
                                  },
                                  expression: "form.detail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                      _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.formLoad,
                    loading: _vm.formLoad,
                    form: "form-expense",
                    color: "blue darken-1",
                    text: "",
                  },
                },
                [_vm._v(" Guardar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }