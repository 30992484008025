<template>
	<v-app-bar style="box-shadow: none; background-color: transparent" app>
		<v-app-bar-nav-icon style="display: none"></v-app-bar-nav-icon>

		<v-toolbar-title></v-toolbar-title>

		<!-- <v-text-field
            label="Buscar..."
            placeholder="Ingrese la búsqueda"
            solo
						class="mt-10"
          ></v-text-field>
-->
		<v-spacer></v-spacer>

		<v-btn icon>
			<v-icon>mdi-magnify</v-icon>
		</v-btn>

		<v-menu left bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>

			<v-list>
				<v-list-item
					v-for="item in items"
					:key="item.title"
					@click="action(item.action)"
				>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
	</v-app-bar>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	data: () => ({
		items: [
			{ title: "Mi Cuenta", action: "administration/account" },
			{ title: "Mi Empresa", action: "administration/enterprise" },
			{ title: "Mis Sucursales", action: "administration/branch" },
			{ title: "Cerrar Sesión", action: "logout" },
		],
	}),
	computed: {
		...mapGetters("Auth", ["getEnterpriseAlias"]),
	},
	methods: {
		action(value) {
			if (value != "logout") this.$router.push("/" + value);
			else this.logout();
		},
		logout: async function () {
			this.loading = true;
			await this.$store.dispatch("Auth/logout", {
				session: this.$session,
			});
			this.$router.push("/login");
			this.loading = false;
		},
	},
};
</script>
