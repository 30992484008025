<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="blue">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form ref="form" id="form-users" @submit.prevent="save">
						<v-container>
							<v-row>
								<v-col cols="4">
									<v-text-field
										required
										v-model="user.name"
										:disabled="isDisabled"
										label="Usuario"
										:error-messages="userErrors"
										@input="$v.user.name.$touch()"
										@blur="$v.user.name.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="4">
									<v-combobox
										:items="roles"
										item-text="name"
										item-value="id"
										v-model="user.role"
										label="Rol"
										:error-messages="roleErrors"
										@change="$v.user.role.$touch()"
										@blur="$v.user.role.$touch()"
									></v-combobox>
								</v-col>
							</v-row>
							<v-row>
								<v-col lg="4">
									<v-text-field
										required
										v-model="user.entity.first_name"
										label="Nombres"
										:error-messages="nameErrors"
										@input="$v.user.entity.first_name.$touch()"
										@blur="$v.user.entity.first_name.$touch()"
									></v-text-field>
								</v-col>
								<v-col lg="4">
									<v-text-field
										required
										v-model="user.entity.last_name"
										label="Apellidos"
										:error-messages="surnameErrors"
										@input="$v.user.entity.last_name.$touch()"
										@blur="$v.user.entity.last_name.$touch()"
									></v-text-field>
								</v-col>
								<v-col lg="4">
									<v-text-field
										required
										v-model="user.entity.document"
										label="Documento"
										prefix="DNI:"
										:error-messages="documentErrors"
										:counter="8"
										@input="$v.user.entity.document.$touch()"
										@blur="$v.user.entity.document.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12"><h4>Datos de contacto</h4> </v-col>
								<v-col cols="5">
									<v-text-field
										label="Correo Electrónico"
										v-model="user.contact.email"
										:disabled="isDisabled"
										hint="Se utilizará para validar el usuario"
										persistent-hint
										:error-messages="emailErrors"
										@input="$v.user.contact.email.$touch()"
										@blur="$v.user.contact.email.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="3">
									<v-text-field
										v-model="user.contact.phone"
										label="Teléfono / Celular"
										:error-messages="phoneErrors"
										@input="$v.user.contact.phone.$touch()"
										@blur="$v.user.contact.phone.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
							<!--<Location :addressable="user.address" ref="location" :v="$v" />-->
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="loading"
						:loading="loading"
						form="form-users"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { mapActions } from "vuex";
import {
	required,
	integer,
	email,
	alphaNum,
	minLength,
} from "vuelidate/lib/validators";
import Toast from "@/components/toast";
import Location from "@/components/location";

export default {
	components: { Toast, Location },
	validations: {
		user: {
			entity: {
				first_name: { required },
				last_name: { required },
				document: { required, minLength: minLength(8) },
			},
			name: { required, minLength: minLength(5), alphaNum },
			contact: {
				email: { required, email },
				phone: { required, integer },
			},
			role: { required },
		},
		/*address: {
			province: { required },
			city: { required },
			street: { required },
			number: { required, integer },
		},*/
	},
	data: () => ({
		dialog: true,
		loading: false,
		disabled: false,
		user: {
			entity: {
				first_name: "",
				last_name: "",
				document: "",
			},
			id: null,
			name: "",
			contact: {
				email: "",
				phone: "",
			},
			role: {
				id: null,
				name: null,
			},
			role_id: null,
			address: null,
		}
	}),
	created: async function () {
		if (this.$store.state.Role.roles.length == 0)
			await this.$store.dispatch("Role/get");

		//if (this.$store.state.Location.location.provinces.length == 0)
		//	await this.$store.dispatch("Location/getLocations")

		this.user.role = this.getFirstRole

		if (this.$route.params.id) {
			let data = Object.assign({}, this.getUser(this.$route.params.id));
			this.user.entity = data.entity;
			this.user.name = data.username;
			this.user.id = data.id;
			this.user.contact.phone = this.getPhone(data.id);
			this.user.contact.email = this.getEmail(data.id);
			//this.user.address = data.address;
			this.user.role = this.getRole(data.role.id);
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		...mapState("User", {
			users: (state) => state.users,
		}),

		...mapState('Role', ['roles']),
		...mapGetters('Role', ['getRole', 'getFirstRole']),

		errors: {
			get() {
				return this.$store.state.User.errors;
			},
			set(value) {
				this.$store.commit("User/SET_ERRORS", value);
			},
		},

		isDisabled() {
			return Boolean(this.user.id);
		},

		...mapGetters("User", [
			"getUser",
			"getPhone",
			"getEmail"
		]),

		title() {
			return this.$route.params.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},

		nameErrors() {
			const errors = [];
			if (!this.$v.user.entity.first_name.$dirty) return errors;
			!this.$v.user.entity.first_name.required &&
				errors.push("El nombre es requerido");
			return errors;
		},
		roleErrors() {
			const errors = [];
			if (!this.$v.user.role.$dirty) return errors;
			!this.$v.user.role.required && errors.push("El rol es requerido");
			return errors;
		},
		surnameErrors() {
			const errors = [];
			if (!this.$v.user.entity.last_name.$dirty) return errors;
			!this.$v.user.entity.last_name.required &&
				errors.push("El apellido es requerido");
			return errors;
		},
		documentErrors() {
			const errors = [];
			if (!this.$v.user.entity.document.$dirty) return errors;
			!this.$v.user.entity.document.required &&
				errors.push("El documento es requerido");
			!this.$v.user.entity.document.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			return errors;
		},
		userErrors() {
			const errors = [];
			if (!this.$v.user.name.$dirty) return errors;
			!this.$v.user.name.required && errors.push("El usuario es requerido");
			!this.$v.user.name.alphaNum &&
				errors.push("Solo debe contener números y letras");
			!this.$v.user.name.minLength &&
				errors.push("Debe tener mínimo 5 caracteres");
			return errors;
		},
		emailErrors() {
			const errors = [];
			if (!this.$v.user.contact.email.$dirty) return errors;
			!this.$v.user.contact.email.required &&
				errors.push("El correo es requerido");
			!this.$v.user.contact.email.email &&
				errors.push("El formato es incorrecto");
			return errors;
		},
		phoneErrors() {
			const errors = [];
			if (!this.$v.user.contact.phone.$dirty) return errors;
			!this.$v.user.contact.phone.required &&
				errors.push("El telefono es requerido");
			!this.$v.user.contact.phone.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
	},
	methods: {
		...mapActions("User", ["store"]),

		close() {
			this.$nextTick(() => {
				//this.user = Object.assign({}, null);
				// this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.loading = true;
		},

		successSave: async function () {
			this.$store.dispatch("User/get");
			this.$store.dispatch("Auth/get");

			if(this.$store.state.errors.status == 200)
				this.$router.back();
		},

		completeSave: async function () {
			this.loading = false;
		},

		save: function () {
			/*this.user.address = {
				street: this.$refs.location.address.street,
				number: this.$refs.location.address.number,
				location_id: this.$refs.location.address.city.id,
			};*/
			this.user.role_id = this.user.role.id;
			this.$v.$touch();
			if (!this.$v.$invalid) {
				this.store({
					id: this.user.id,
					account: false,
					data: {
						entity: {
							first_name: this.user.entity.first_name,
							last_name: this.user.entity.last_name,
							document: this.user.entity.document,
						},
						name: this.user.name,
						/*address: {
							street: this.user.address.street,
							number: this.user.address.number,
							location_id: this.user.address.location_id,
						},*/
						contact: {
							email: this.user.contact.email,
							phone: this.user.contact.phone,
						},
						role_id: this.user.role_id,
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
			}
		},
	},
};
</script>
