<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="900px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar fixed>
					<v-toolbar-title>Editar Empresa</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-enterprises"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="enterprise.entity.alias"
										required
										label="Alias"
										:error-messages="aliasErrors"
										@input="$v.enterprise.entity.alias.$touch()"
										@blur="$v.enterprise.entity.alias.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="enterprise.entity.document"
										required
										label="Documento"
										prefix="CUIT: "
										:error-messages="documentErrors"
										@input="$v.enterprise.entity.document.$touch()"
										@blur="$v.enterprise.entity.document.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-select
										v-model="enterprise.entity.vat_condition_ref"
										:items="dictionary.vatCondition"
										item-text="name"
										item-value="id"
										label="Condición IVA"
										:error-messages="vatConditionErrors"
										@change="$v.enterprise.entity.vat_condition_ref.$touch()"
										@blur="$v.enterprise.entity.vat_condition_ref.$touch()"
									></v-select>
								</v-col>
							</v-row>
							<v-divider class="ma-1" />
							<div class="text-h6 mt-3 mb-2">Contacto</div>
							<v-row>
								<v-col cols="5">
									<v-text-field
										label="Correo Electrónico"
										v-model="enterprise.contact.email"
										:error-messages="emailErrors"
										@input="$v.enterprise.contact.email.$touch()"
										@blur="$v.enterprise.contact.email.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="3">
									<v-text-field
										v-model="enterprise.contact.phone"
										label="Teléfono / Celular"
										:error-messages="phoneErrors"
										@input="$v.enterprise.contact.phone.$touch()"
										@blur="$v.enterprise.contact.phone.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-divider class="ma-1" />
							<Location
								:addressable="getAddressEnterprise"
								ref="location"
								:v="$v"
							/>
						</v-container>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-enterprises"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { required, integer, email, minLength } from "vuelidate/lib/validators";
import Toast from "@/components/toast";
import Location from "@/components/location";

export default {
	components: { Toast, Location },
	validations: {
		enterprise: {
			entity: {
				vat_condition_ref: { required },
				alias: { required },
				document: { required, integer, minLength: minLength(11) },
			},
			contact: {
				phone: { required, integer },
				email: { required, email },
			},
		},
		address: {
			province: { required },
			city: { required },
			street: { required },
			number: { required, integer },
		},
	},
	data: () => ({
		dialog: true,
		formValid: false,
		formLoad: false,
		enterprise: {
			entity: {
				document: null,
				vat_condition_ref: null,
				alias: null,
			},
			contact: {
				phone: null,
				email: null,
			},
			address: {
				street: null,
				number: null,
				city: {
					id: null,
				},
			},
		},
	}),
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.Enterprise.errors;
			},
			set(value) {
				this.$store.commit("Enterprise/SET_ERRORS", value);
			},
		},

		...mapState("List", ["dictionary"]),
		...mapState("Auth", ["user"]),
		...mapGetters("Auth", [
			"getPhoneEnterprise",
			"getEmailEnterprise",
			"getAddressEnterprise",
		]),

		emailErrors() {
			const errors = [];
			if (!this.$v.enterprise.contact.email.$dirty) return errors;
			!this.$v.enterprise.contact.email.required &&
				errors.push("El correo es requerido");
			!this.$v.enterprise.contact.email.email &&
				errors.push("El formato es incorrecto");
			return errors;
		},
		phoneErrors() {
			const errors = [];
			if (!this.$v.enterprise.contact.phone.$dirty) return errors;
			!this.$v.enterprise.contact.phone.required &&
				errors.push("El telefono es requerido");
			!this.$v.enterprise.contact.phone.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		aliasErrors() {
			const errors = [];
			if (!this.$v.enterprise.entity.alias.$dirty) return errors;
			!this.$v.enterprise.entity.alias.required &&
				errors.push("El alias es requerido");
			return errors;
		},
		documentErrors() {
			const errors = [];
			if (!this.$v.enterprise.entity.document.$dirty) return errors;
			!this.$v.enterprise.entity.document.required &&
				errors.push("El documento es requerido");
			!this.$v.enterprise.entity.document.minLength &&
				errors.push("Deber tener un minimo de 11 números");
			!this.$v.enterprise.entity.document.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		vatConditionErrors() {
			const errors = [];
			if (!this.$v.enterprise.entity.vat_condition_ref.$dirty) return errors;
			!this.$v.enterprise.entity.vat_condition_ref.required &&
				errors.push("La condición IVA es requerido");
			return errors;
		},
	},
	created() {
		let entity = Object.assign({}, this.user.enterprise.entity);
		this.enterprise.entity.document = entity.document;
		this.enterprise.entity.vat_condition_ref = entity.vat_condition_ref;
		this.enterprise.entity.alias = entity.alias;

		this.enterprise.contact.phone = this.getPhoneEnterprise;
		this.enterprise.contact.email = this.getEmailEnterprise;
	},
	methods: {
		...mapActions("Enterprise", ["edit"]),
		...mapActions("Auth", ["get"]),

		close() {
			this.$nextTick(() => {
				this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
			this.tableLoad = true;
		},

		successSave: async function () {
			//if (!this.enterprise.id > 0) this.$refs.form.reset();
			this.get();
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.enterprise.address = this.$refs.location.address;
			this.$v.$touch();
			console.log(this.$v);
			if (!this.$v.$invalid) {
				this.edit({
					data: {
						entity: this.enterprise.entity,
						contact: this.enterprise.contact,
						address: {
							street: this.enterprise.address.street,
							number: this.enterprise.address.number,
							location_id: this.enterprise.address.city.id,
						},
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
			}
		},
	},
};
</script>
