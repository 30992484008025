import axios from "axios";
import http from "../../utils/http";

export default {
	namespaced: true,
	state: {
		sales: [],
		response: null
	},
	mutations: {
		ADD_SALE(state, data) {
			state.sales = state.sales.filter((prop) => prop.id != data.id);
			state.sales.push(data);
		},
		SET_SALES(state, data) {
			state.sales = data;
		},
		SET_RESPONSE(state, data) {
			state.response = data;
		}
	},
	actions: {
		get: async function ({ commit }, params) {
			return await axios.get("sale" + http.formatQueryParams(params.filters)).then((response) => {
				commit("SET_SALES", response.data);
			}).catch(() => {});
		},
		find: async function ({ commit }, id) {
			return await axios.get("sale/" + id).then((response) => {
				commit("ADD_SALE", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios.post("sale", params.data)
						.then((response) => {
							commit('SET_ERRORS', response, { root: true })
							params.success();
							commit('SET_ERRORS', null, { root: true })
						}) 
						.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			params.before();
			if (params.id) {
				await dispatch("update", {
					id: params.id,
					data: params.data,
					success: () => {
						params.success();
					}
				});
			} else {
				await dispatch("create", {
					data: params.data,
					success: () => {
						params.success();
					}
				});
			}
			params.complete();
		},
		update: async function ({ commit }, params) {
			return axios
				.put("sale/" + params.id, params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("sale/" + id)
				.then(() => {
				})
				.catch(() => {
				});
		}
	},
	getters: {
		getSales: (state) => {
			if (state.sales) return state.sales;
			else return [];
		},
		getSale: (state) => (saleId) => {
			if (state.sales)
				return state.sales.filter((prop) => prop.id == saleId)[0];
			else return null;
		},
		getTotalPendingToCharge: (state) => (saleId) => {
			if (state.sales){
				let sale = state.sales.filter((prop) => prop.id == saleId)[0];
				return sale.total_amount - sale.charges.reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.total_amount), 0);
			}else return null;
		},
	},
};
