<template>
  <v-stepper-content step="1">
    <v-container>
      <v-row>
        <DateTimeCustom 
          label="Fecha de la compra" 
          v-model="form.issue_date" 
          v-on:date-changed="newDateChanged" 
          :v="validator"
        />
        <v-col cols="4">
          <Deposit 
            @value="getDeposit"
            :validator="validator"
          >
          </Deposit>
        </v-col>
        <v-col cols="4">
          <Provider
            v-model="form.provider"
            :validator="validator"
          >
          </Provider>
        </v-col>
       
        <v-col cols="8">
          <v-textarea
            rows="2"
            solo
            v-model="form.comment"
            label="Detalle..."
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      color="primary"
      :disabled="!datosPrincipalesValidator"
      @click="confirmarDatosPrincipales()"
    >
      Continuar
    </v-btn>
    <v-btn
      @click="$router.go(-1)"
      text>
      Cancelar
    </v-btn>
  </v-stepper-content>
</template>
<script>

import Provider from '@/components/list/providers-list';
import Deposit from '@/components/list/deposits-list';
import DateTimeCustom from "@/components/datetime-custom-1";

export default {

  components: { Provider, Deposit, DateTimeCustom },

  props: ['validator', 'step', 'originData'],

  data: () => {
    return {
      form: {
        issue_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				comment: null,
        provider: {},
        deposit: {},
      },
      datosPrincipalesValidator: false
    }
  },

  created: async function() {
    this.setFormDataFrom(this.originData);
    this.setValidForm();
  },

  watch: {
    originData(newValue) {
      this.setFormDataFrom(newValue);
    },
  },

  updated(){
    this.setValidForm();
  },
  
  methods: {

    setFormDataFrom(data) {
      this.form.issue_date = data.issue_date;
      this.form.deposit = data.deposit;
      this.form.provider = data.provider;
      this.form.comment = data.comment;
    },

    newDateChanged(date){
			this.form.issue_date = date;
      this.setValidForm();
      this.$emit('main-info', this.form);
		},

    getDeposit(deposit) {
      this.form.deposit = deposit;
      this.setValidForm();
      this.$emit('main-info', this.form);
    },

    setValidForm() {
      this.datosPrincipalesValidator = this.form.deposit.id && this.form.provider.id && this.form.issue_date
    },

    confirmarDatosPrincipales(){
      this.$emit('main-info', this.form);
      this.$emit('main-info-confirmed', this.form);
		},

  }

};

</script>