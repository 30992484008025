<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="closeDialog"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							closeDialog();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-6">
				<div id="data" v-if="sale">
					<h4 class="text-h4">Venta # {{ sale.id }}</h4>
					<v-chip class="mt-3" :color="getColorForState(sale.sale_state)">{{ getDescriptionForState(sale.sale_state) }}</v-chip>
					<v-divider class="ma-4"></v-divider>
					<v-spacer></v-spacer>
					<p class="text-body-1 mt-3">Venta en la fecha <strong>{{ sale.issue_date }}</strong> a <strong>Consumidor Final</strong> vendido por <strong>{{ getSeller }}</strong>.</p>
					<p class="text-body-2"><strong>Comentario: </strong> {{ sale.comment ?? 'Sin datos' }}</p>
					<v-divider class="ma-3"></v-divider>
					<p class="text-body-1">Se cargaron <strong>{{ totalProductosCargados }} productos</strong> con un total de <strong class="blue--text">{{ formatCurrency(totalPreciosCargados) }}</strong></p>
					<p class="text-body-2">
						Del cual se pagó un total de <strong>{{ formatCurrency(totalCharge) }}</strong>
						quedando pendiente a pagar <strong>{{ formatCurrency(totalPreciosCargados - totalCharge) }}</strong>
					</p>
					<p>Descuento en efectivo aplicado: <strong class="success--text text-body-1">{{ formatCurrency(sale.discount_amount) }}</strong> </p>
					<v-divider class="ma-4"></v-divider>
					<v-spacer></v-spacer>
					<h5 class="text-h5">Listado de Productos</h5>
					<v-list-item two-line v-for="item in sale.carts" :key="item.id">
						<v-list-item-content>
							<v-list-item-title class="text-body-1 font-weight-medium text-capitalize ma-0">
								<span class="text-body-1 font-weight-bold text-capitalize ma-0">#{{ item.product.code }}</span>
								{{ getCategory(item.product.category_id)?.name }} 
								<span class="text-body-2 text-capitalize ma-0">{{ getProduct(item.product.id)?.full_name }}</span>
							</v-list-item-title>
							<v-list-item-subtitle> {{ item.count }} por {{ formatCurrency(item.unit_price * item.count) }} en total.</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</div>
				<div v-else id="no-data">
					La venta no existe
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						closeDialog();
					"
				>
					Salir
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		dialogOut: {
			default: true
		},
		single: {
			default: false
		},
		saleOut: {
			default: null
		}
	},
	data: () => {
		return {
			title: 'Detalle de la venta'
		}
	},
	created: async function(){

		if(this.$route.params.id){
			if(this.$store.state.Sale.sales.length == 0)
				await this.$store.dispatch('Sale/find', this.$route.params.id);

			if(this.$store.state.User.users.length == 0)
				await this.$store.dispatch('User/get');
		}
	},
	watch: {
		dialogOut: async function(){
			if(this.$store.state.User.users.length == 0)
				await this.$store.dispatch('User/get');

			if(this.$store.state.Product.products.length == 0)
				await this.$store.dispatch('Product/get');
		}
	},
	computed: {
		...mapGetters('Sale', ['getSale']),
		...mapGetters('User', ['getUser']),
		...mapGetters('Product', ['getProduct']),
		...mapGetters('Category', ['getCategory']),

		dialog: {
      get() {
        return this.dialogOut;
      },
      set: async function(newValue) {
        this.$emit('update:dialog', newValue);
      },
    },

		sale: {
      get() {
        return this.saleOut ?? this.getSale(this.$route.params.id);
      },
      set(newValue) {
        this.$emit('update:sale', newValue);
      },
    },

		totalProductosCargados(){
			let count = 0;
			if(this.sale)
				this.sale.carts.forEach(element => {
					count += parseInt(element.count);
				});

			return count;
		},

		totalPreciosCargados(){
			let count = 0;
			if(this.sale)
				this.sale.carts.forEach(element => {
					count += parseInt(element.amount);
				});

			return count - this.sale.discount_amount;
		},

		getSeller(){
			if(this.sale)
				return this.getUser(this.sale.seller.id)?.entity?.full_name
			return null;
		},

		totalCharge(){
			let total = 0;

			if(this.sale)
				this.sale.charges.forEach(element => {
						total += parseFloat(element.total_amount);
				});

			return total;
		}
	},
	methods: {
		getColorForState(state) {
			if (state == 'PENDING_CHARGE') return "orange";
			if (state == 'PARTIAL_CHARGE') return "purple";
			else return "green";
		},

		closeDialog(){
			if(!this.single)
				this.$router.back()
		},

		getDescriptionForState(state) {
			if (state == 'PENDING_CHARGE') return "Pendiente de Cobro";
			if (state == 'PARTIAL_CHARGE') return "Cobro Parcial";
			else return "Completado";
		},
	}
}

</script>
