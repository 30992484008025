import TreasuryHome from "@/views/app/treasury/treasury-home.vue";
import TreasuryData from "@/views/app/treasury/treasury-data.vue";

import MyFundHome from "@/views/treasury/my_fund/my-fund-home.vue";
import MyFundData from "@/views/treasury/my_fund/my-fund-data.vue";
import MyFundForm from "@/views/treasury/my_fund/my-fund-form.vue";
import MyFundTransfer from "@/views/treasury/my_fund/my-fund-transfer.vue";
import MyFundAdjust from "@/views/treasury/my_fund/my-fund-adjust.vue";

import ProviderFundHome from "@/views/treasury/provider_fund/provider-fund-home.vue";
import ProviderFundData from "@/views/treasury/provider_fund/provider-fund-data.vue";
import ProviderFundForm from "@/views/treasury/provider_fund/provider-fund-form.vue";

import CustomerFundHome from "@/views/treasury/customer_fund/customer-fund-home.vue";
import CustomerFundData from "@/views/treasury/customer_fund/customer-fund-data.vue";
import CustomerFundForm from "@/views/treasury/customer_fund/customer-fund-form.vue";

import UserFundHome from "@/views/treasury/user_fund/user-fund-home.vue";
import UserFundData from "@/views/treasury/user_fund/user-fund-data.vue";
import UserFundForm from "@/views/treasury/user_fund/user-fund-form.vue";

import OtherFundHome from "@/views/treasury/other_fund/other-fund-home.vue";
import OtherFundData from "@/views/treasury/other_fund/other-fund-data.vue";
import OtherFundForm from "@/views/treasury/other_fund/other-fund-form.vue";

import ExpenseHome from "@/views/treasury/expense/expense-home.vue";
import ExpenseData from "@/views/treasury/expense/expense-data.vue";
import ExpenseForm from "@/views/treasury/expense/expense-form.vue";

import SurchargeHome from "@/views/treasury/surcharge/surcharge-home.vue";
import SurchargeData from "@/views/treasury/surcharge/surcharge-data.vue";
import SurchargeForm from "@/views/treasury/surcharge/surcharge-form.vue";

import SurchargeTypeHome from "@/views/treasury/surcharge_type/surcharge-type-home.vue";
import SurchargeTypeData from "@/views/treasury/surcharge_type/surcharge-type-data.vue";
import SurchargeTypeForm from "@/views/treasury/surcharge_type/surcharge-type-form.vue";

import PaymentMethodHome from "@/views/treasury/payment_method/payment-method-home.vue";
import PaymentMethodData from "@/views/treasury/payment_method/payment-method-data.vue";
import PaymentMethodForm from "@/views/treasury/payment_method/payment-method-form.vue";

const STR_STORE = "store";

export default {
	path: "/treasury",
	name: "",
	component: TreasuryHome,
	meta: { requiresAuth: true, access: 'LEC.TRE.FUN' },
	children: [
		{
			path: "",
			name: "TreasuryData",
			component: TreasuryData,
			meta: { access: 'LEC.TRE.FUN' }
		},
		{
			path: "fund/me",
			component: MyFundHome,
			meta: { access: 'LEC.TRE.FUN' },
			children: [
				{
					path: "",
					name: "MyFundData",
					component: MyFundData,
					meta: { access: 'LEC.TRE.FUN' }
				},
				{
					path: "transfer/:id",
					name: "MyFundTransferData",
					component: MyFundTransfer,
					meta: { access: 'ESC.TRE.FUN' }
				},
				{
					path: "adjust/:id",
					name: "MyFundAdjust",
					component: MyFundAdjust,
					meta: { access: 'ESC.TRE.FUN' }
				},
				{
					name: "MyFundCreate",
					path: STR_STORE,
					component: MyFundForm,
					meta: { access: 'ESC.TRE.FUN' }
				},
				{
					name: "MyFundUpdate",
					path: STR_STORE + "/:id",
					component: MyFundForm,
					meta: { access: 'ESC.TRE.FUN' }
				},
			],
		},
		{
			path: "fund/provider",
			component: ProviderFundHome,
			children: [
				{
					path: "",
					name: "ProviderFundData",
					component: ProviderFundData,
				},
				{
					name: "ProviderFundCreate",
					path: STR_STORE,
					component: ProviderFundForm,
				},
				{
					name: "ProviderFundUpdate",
					path: STR_STORE + "/:id",
					component: ProviderFundForm,
				},
			],
		},
		{
			path: "fund/customer",
			component: CustomerFundHome,
			children: [
				{
					path: "",
					name: "CustomerFundData",
					component: CustomerFundData,
				},
				{
					name: "CustomerFundCreate",
					path: STR_STORE,
					component: CustomerFundForm,
				},
				{
					name: "CustomerFundUpdate",
					path: STR_STORE + "/:id",
					component: CustomerFundForm,
				},
			],
		},
		{
			path: "fund/user",
			component: UserFundHome,
			children: [
				{
					path: "",
					name: "UserFundData",
					component: UserFundData,
				},
				{
					name: "UserFundCreate",
					path: STR_STORE,
					component: UserFundForm,
				},
				{
					name: "UserFundUpdate",
					path: STR_STORE + "/:id",
					component: UserFundForm,
				},
			],
		},
		{
			path: "fund/other",
			component: OtherFundHome,
			children: [
				{
					path: "",
					name: "OtherFundData",
					component: OtherFundData,
				},
				{
					name: "OtherFundCreate",
					path: STR_STORE,
					component: OtherFundForm,
				},
				{
					name: "OtherFundUpdate",
					path: STR_STORE + "/:id",
					component: OtherFundForm,
				},
			],
		},
		{
			path: "expense",
			component: ExpenseHome,
			meta: { access: 'LEC.TRE.GAS' },
			children: [
				{
					path: "",
					name: "ExpenseData",
					component: ExpenseData,
					meta: { access: 'LEC.TRE.GAS' },
				},
				{
					name: "ExpenseCreate",
					path: STR_STORE,
					component: ExpenseForm,
					meta: { access: 'ESC.TRE.GAS' },
				},
				{
					name: "ExpenseUpdate",
					path: STR_STORE + "/:id",
					component: ExpenseForm,
					meta: { access: 'ESC.TRE.GAS' },
				},
			],
		},
		{
			path: "surcharge",
			component: SurchargeHome,
			meta: { access: 'LEC.TRE.REC' },
			children: [
				{
					path: "",
					name: "SurchargeData",
					component: SurchargeData,
					meta: { access: 'LEC.TRE.REC' },
				},
				{
					name: "SurchargeCreate",
					path: STR_STORE,
					component: SurchargeForm,
					meta: { access: 'ESC.TRE.REC' },
				},
				{
					name: "SurchargeUpdate",
					path: STR_STORE + "/:id",
					component: SurchargeForm,
					meta: { access: 'ESC.TRE.REC' },
				},
			],
		},
		{
			path: "surcharge-type",
			component: SurchargeTypeHome,
			meta: { access: 'LEC.TRE.TRC' },
			children: [
				{
					path: "",
					name: "SurchargeTypeData",
					component: SurchargeTypeData,
					meta: { access: 'LEC.TRE.TRC' },
				},
				{
					name: "SurchargeTypeCreate",
					path: STR_STORE,
					component: SurchargeTypeForm,
					meta: { access: 'ESC.TRE.TRC' },
				},
				{
					name: "SurchargeTypeUpdate",
					path: STR_STORE + "/:id",
					component: SurchargeTypeForm,
					meta: { access: 'ESC.TRE.TRC' },
				},
			],
		},
		{
			path: "payment-method",
			component: PaymentMethodHome,
			meta: { access: 'LEC.TRE.PME' },
			children: [
				{
					path: "",
					name: "PaymentMethodData",
					component: PaymentMethodData,
					meta: { access: 'LEC.TRE.PME' },
				},
				{
					name: "PaymentMethodCreate",
					path: STR_STORE,
					component: PaymentMethodForm,
					meta: { access: 'ESC.TRE.PME' },
				},
				{
					name: "PaymentMethodUpdate",
					path: STR_STORE + "/:id",
					component: PaymentMethodForm,
					meta: { access: 'ESC.TRE.PME' },
				}
			]
		}
	],
};
