var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-content",
    { attrs: { step: "1" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c("DateTimeCustom", {
                attrs: { label: "Fecha de la compra", v: _vm.validator },
                on: { "date-changed": _vm.newDateChanged },
                model: {
                  value: _vm.form.issue_date,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "issue_date", $$v)
                  },
                  expression: "form.issue_date",
                },
              }),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("Deposit", {
                    attrs: { validator: _vm.validator },
                    on: { value: _vm.getDeposit },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("Provider", {
                    attrs: { validator: _vm.validator },
                    model: {
                      value: _vm.form.provider,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "provider", $$v)
                      },
                      expression: "form.provider",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c("v-textarea", {
                    attrs: { rows: "2", solo: "", label: "Detalle..." },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { color: "primary", disabled: !_vm.datosPrincipalesValidator },
          on: {
            click: function ($event) {
              return _vm.confirmarDatosPrincipales()
            },
          },
        },
        [_vm._v(" Continuar ")]
      ),
      _c(
        "v-btn",
        {
          attrs: { text: "" },
          on: {
            click: function ($event) {
              return _vm.$router.go(-1)
            },
          },
        },
        [_vm._v(" Cancelar ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }