var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "text-h5 blue--text" }, [
                _vm._v(
                  "A " +
                    _vm._s(_vm.action) +
                    " " +
                    _vm._s(_vm.formatCurrency(_vm.totalCharge - _vm.discount)) +
                    " "
                ),
              ]),
              _c("v-checkbox", {
                attrs: { label: "Cobrar todo en efectivo" },
                on: { change: _vm.setAllCash },
                model: {
                  value: _vm.allCash,
                  callback: function ($$v) {
                    _vm.allCash = $$v
                  },
                  expression: "allCash",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-3", attrs: { cols: "12", lg: "4" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.paymentMethods,
                  outlined: "",
                  "item-text": "name",
                  "item-value": "id",
                  loading: _vm.loading.paymentMethod,
                  "error-messages": _vm.paymentMethodErrors,
                  label: "Método de Pago",
                },
                on: {
                  change: function ($event) {
                    return _vm.$v.charge.paymentMethod.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.charge.paymentMethod.$touch()
                  },
                },
                model: {
                  value: _vm.charge.paymentMethod,
                  callback: function ($$v) {
                    _vm.$set(_vm.charge, "paymentMethod", $$v)
                  },
                  expression: "charge.paymentMethod",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-3", attrs: { cols: "12", lg: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  prefix: "$",
                  "error-messages": _vm.amountErrors,
                  label: "Monto",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.charge.amount.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.charge.amount.$touch()
                  },
                },
                model: {
                  value: _vm.charge.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.charge, "amount", $$v)
                  },
                  expression: "charge.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2 mt-4",
                  attrs: { fab: "", small: "", dark: "", color: "primary" },
                  on: { click: _vm.addCharge },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v(" mdi-plus ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-container",
            _vm._l(_vm.charges, function (item) {
              return _c(
                "v-row",
                {
                  key: item.name,
                  staticClass: "text-center mb-2 rounded-sm elevation-1",
                },
                [
                  _c("v-col", { attrs: { cols: "4", lg: "3" } }, [
                    _c("p", { staticClass: "text-h6 ma-0" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "4", lg: "3" } }, [
                    _c("p", { staticClass: "text-h6 ma-0" }, [
                      _vm._v(_vm._s(_vm.formatCurrency(item.amount))),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mt-0 text-center",
                      attrs: { cols: "2", lg: "3" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            fab: "",
                            dark: "",
                            dense: "",
                            "x-small": "",
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCharge(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-delete "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm.pendiente == 0
            ? _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "success",
                      attrs: { rounded: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.applyDiscount()
                        },
                      },
                    },
                    [_vm._v("Aplicar descuentos")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
            _c("p", { staticClass: "text-h5" }, [
              _vm._v(
                "Total " +
                  _vm._s(_vm.formatCurrency(_vm.total - _vm.discount)) +
                  " "
              ),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
            _c("p", { staticClass: "text-h5" }, [
              _vm._v(
                "Pendiente " + _vm._s(_vm.formatCurrency(_vm.pendiente)) + " "
              ),
            ]),
          ]),
          _vm.discount > 0
            ? _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
                _c("p", { staticClass: "text-h5 success--text" }, [
                  _vm._v(
                    "Descuento " +
                      _vm._s(_vm.formatCurrency(_vm.discount)) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.discount == 0 && _vm.showDiscount
            ? _c("v-col", { attrs: { cols: "12", lg: "4" } }, [
                _c("p", { staticClass: "text-h6 success--text" }, [
                  _vm._v("Sin descuentos"),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }