<template>
	<v-container>
		<v-row>
			<v-col cols="12" lg="12">
				<h6 class="text-h5">{{ title }}</h6>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" lg="3">
				<v-select
					:items="typesList"
					item-text="name"
					:hint="detailType"
					return-object
					item-value="code"
					v-model="form.type"
					:label="labelType"
				>
			</v-select>
			</v-col>
			<v-col>
				<v-btn
					fab
					small
					class="mt-4"
					color="primary"
					@click="addItem"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-card width="100%" class="pt-3 pl-3" :color="$vuetify.theme.dark ? 'grey darken-4' : 'blue lighten-5'">
					<v-container>
						<v-row v-for="item in items" :key="item.key">
							<v-col cols="12" lg="3">
								<p v-if="isNumber(item.code)" class="text-body-1">{{  item.name }}</p>
								<v-text-field
									v-else
									dense
									outlined
									autofocus
									@focus="selectAllSinceEvent"
									v-model="item.name"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3">
								<v-select
									:items="rate_types"
									dense
									outlined
									item-text="name"
									item-value="id"
									v-model="item.rate_type"
									label="Tipo de Variación"
									@change="changeRateOrType(item)"
								></v-select>
							</v-col>
							<v-col cols="12" lg="2">
								<v-text-field
									:prefix="item.rate_type == 'FIXED' ? 'Fijo' : '%'"
									v-model="item.rate"
									required
									dense
									outlined
									label="Tasa"
									type="number"
									@input="changeRateOrType(item)"
									@blur="changeRateOrType(item)"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="2">
								<h5 class="text-h5">+ {{ formatCurrency(item.amount_to_apply) }}</h5>
							</v-col>
							<v-col cols="12" lg="2">
								<v-btn
									fab
									x-small
									dark
									color="danger"
									@click="deleteItem(item)"
									>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-row v-if="items.length == 0">
							<v-col cols="12">No hay {{ title.toLowerCase() }} cargados.</v-col>
						</v-row>
					</v-container>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

	export default {
		props: {
			title: {
				default: ''
			},
			type: {
				default: 'surcharge'
			},
			amount: {
				default: 0
			},
			types: {
				default: null
			}
		},
		data: () => {
			return {
				rate_types: [
					{id: 'FIXED', name: 'Fijo'},
					{id: 'PERCENTAGE', name: 'Porcentual'}
				],
				form: {
					type: null,
					rate_type: 'FIXED'
				},
				items: []
			}
		},
		created: async function (){
			if(this.type == 'surcharge'){
				if(this.$store.state.SurchargeType.surchargeTypes.length == 0)
					await this.$store.dispatch('SurchargeType/get');

				if(this.types){
					this.items = this.getSurchargeTypesFormated(this.types);
					this.$emit('amount-changed', this.items)
				}
			}
		},
		watch: {
			types(){
				if(this.types){
					this.items = this.getSurchargeTypesFormated(this.types);
					this.$emit('amount-changed', this.items)
				}
			},
			amount(){
				this.items.forEach(element => {
					element.amount_to_apply = this.getAmountToApply(element)
				});
			}
		},
		computed: {
			...mapState('SurchargeType', ['surchargeTypes']),
			...mapGetters('SurchargeType', ['getSurchargeType']),

			typesList(){
				let surchargeTypes = Array.from(this.surchargeTypes);
				surchargeTypes.unshift({
					id: null,
					code: this.surchargeTypes.length,
					name: 'Nuevo',
					rate_type: 'FIXED',
					rate: 0
				})
				return surchargeTypes;
			},

			labelType(){
				return 'Tipo de recargo'
			},

			detailType(){
				let value = new String(this.form.type?.rate).toString();
				let type = this.form.type?.rate_type == 'FIXED' ? 'Fijo' : '%';
				return value + ' ' + type;
			}
		},
		methods: {
			getSurchargeTypesFormated(surcharges){
				let key = 0;
				let items = [];
				surcharges.forEach(element => {
					let surchargeType = Object.assign({}, this.getSurchargeType(element.surcharge_type_id));
					surchargeType.key = key;
					surchargeType.amount_to_apply = element.amount;
					items.push(Object.assign({}, surchargeType));
					++key;
				});
				return items
			},

			addItem(){
				if(!this.form.type)
					return this.sweetAlertCustom('No seleccionó el tipo de recargo', 'warning')
				let type = Object.assign({}, this.form.type);
				type.key = this.items.length;
				type.amount_to_apply = this.getAmountToApply(type);
				this.items.push(type);
				this.$emit('amount-changed', this.items)
			},

			deleteItem(item){
				let items = this.items.filter((prop) => prop.key != item.key);
				this.items = [].concat(items);
				this.$emit('amount-changed', this.items)
			},

			changeRateOrType(item){
				item.amount_to_apply = this.getAmountToApply(item);
				this.$emit('amount-changed', this.items)
			},

			getAmountToApply(item){
				if(item.rate_type == 'FIXED')
					return parseFloat(item.rate);
				else
					return parseFloat(this.amount / 100 * item.rate);
			},
		}
	}

</script>
