<template>
	<div>
		<Title title="Listas de Precio"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					v-if="hasAccess('ESC.INV.LPR')"
					fab
					dark
					color="primary"
					to="/inventory/price-list/store"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="4">
				<v-text-field
					v-model="search.name"
					label="Buscar lista de precio..."
					required
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="priceLists"
					:search="search.name"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.rate_value_type="{ item }">
						{{ rateTypeDescription(item.rate_value_type) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn
							class="ml-2"
							fab
							v-if="hasAccess('ESC.INV.LPR')"
							x-small
							@click="redirectWithParam('PriceListUpdate', { id: item.id })"
						>
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.INV.LPR')" class="ml-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
	</div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => {
		return {
			search: {
				name: "",
			},
			tab: null,
			loading: false,
			totalAudits: 0,
			options: {},
			total: 0,
			headers: [
				{ text: "Nombre", value: "name" },
				{ text: "Tipo de Tasa", value: "rate_value_type" },
				{ text: "Tasa", value: "rate_value" },
				{ text: "Detalle", value: "detail" },
				{ text: "Acciones", value: "actions" },
			],
			dialogDelete: false,
			deleteLoad: false,
		};
	},
	created: async function () {
		this.loading = true;
		if(this.$store.state.PriceList.priceLists.length == 0)
			await this.$store.dispatch('PriceList/get');
		this.loading = false;
	},
	computed: {
		...mapState("PriceList", ["priceLists"]),
		...mapGetters("Auth", ['hasAccess'])

	},
	methods: {

		...mapActions("PriceList", ['delete']),

		rateTypeDescription(name){
			return name == 'FIXED' ? 'Fijo' : 'Porcentual';
		},

		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},

		deledit(item) {
			this.editedIndex = this.priceLists.indexOf(item);
			this.form = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "PriceListUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.loading = true;
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.form.id);
			this.closeDelete();
			await this.$store.dispatch('PriceList/get');
			this.formLoad = false;
			this.deleteLoad = false;
			this.loading = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	},
};
</script>
