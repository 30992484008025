<template>
	<div>
		<v-container class="mt-3 mb-10 flex-column">
			<v-row>
				<div class="text-h3 ma-4">Auditoria</div>
			</v-row>
			<v-row class="justify-center">
				<v-data-table
					dense
					:headers="headers"
					:items="audits"
					height="700"
					:fixed-header="true"
					:options.sync="options"
					:server-items-length="total"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
				></v-data-table>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapState } from "vuex";

export default {
	data: () => ({
		name: "Auditoria",
		loading: true,
		totalAudits: 0,
		options: {},
		headers: [
			{ text: "Usuario", value: "user.name" },
			{ text: "Sucursal", value: "branch.name" },
			{ text: "Módulo", value: "module" },
			{ text: "Detalle", value: "detail" },
			{ text: "Fecha y Hora", value: "created_at" },
		],
	}),

	created: async function () {
		this.loading = false;
	},

	computed: {
		...mapState({
			audits: (state) => state.Audit.audits,
		}),

		total: {
			get() {
				return this.$store.state.Audit.total;
			},
			set: async function (value) {
				this.$store.commit("Audit/SET_TOTAL", value);
			},
		},
	},
	watch: {
		options: {
			handler: async function () {
				this.loading = true;
				await this.$store.dispatch("Audit/get", {
					page: this.options.page,
					rowsPerPage: this.options.itemsPerPage,
				});
				this.loading = false;
			},
			deep: true,
		},
	},
};
</script>
