var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      staticStyle: { "box-shadow": "none", "background-color": "transparent" },
      attrs: { app: "" },
    },
    [
      _c("v-app-bar-nav-icon", { staticStyle: { display: "none" } }),
      _c("v-toolbar-title"),
      _c("v-spacer"),
      _c(
        "v-btn",
        { attrs: { icon: "" } },
        [_c("v-icon", [_vm._v("mdi-magnify")])],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: { left: "", bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { icon: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-list-item",
                {
                  key: item.title,
                  on: {
                    click: function ($event) {
                      return _vm.action(item.action)
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }