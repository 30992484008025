<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<!--<v-tabs color="primary" v-model="tab" background-color="transparent">
					<v-tab v-if="hasAccess('LEC.COM.COM')" to="/purchasing/purchase">Compras</v-tab>
					<v-tab v-if="hasAccess('LEC.COM.PRV')" to="/purchasing/provider">Proveedor</v-tab>
				</v-tabs>-->
				<router-view></router-view>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import CardForm from "@/components/card-form-custom-1.vue";
import { mapGetters, mapState } from "vuex";

export default {
	components: { CardForm},
	data: () => {
		return {
			search: {
				name: "",
			},
			tab: null,
			loading: true,
			options: {},
			total: 0
		};
	},
	created: async function () {
		if(this.$store.state.Provider.providers.length == 0)
			this.$store.dispatch('Provider/get');
	},
	computed: {

		...mapState("Provider", ["providers"]),
		...mapGetters("Auth", ['hasAccess'])

	},
	methods: {
		redirect(route) {
			this.$router.push({ name: route });
		},
	},
};
</script>
