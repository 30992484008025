import axios from "axios";

export default {
	namespaced: true,
	state: {
		accesses: [],
	},
	mutations: {
		SET_ACCESSES(state, data) {
			state.accesses = data;
		},
	},
	actions: {
		get: async function ({ commit }) {
			return axios.get("access").then((response) => {
				commit("SET_ACCESSES", response.data);
			}).catch(() => {});
		},
	},
};
