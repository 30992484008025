<template>
	<div>
		<Title title="Categorias"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					v-if="hasAccess('ESC.INV.CAT')"
					fab
					dark
					color="primary"
					to="/inventory/category/store"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
				<v-btn
						class="mx-2"
						rounded
						x-large
						dark
						color="primary"
						:to="{ name: 'CategoryAttributeData' }"
						v-if="this.hasAccess('LEC.INV.ATR')"
					>
						<v-icon class="mr-2" dark> mdi-shape-outline </v-icon>
						 Attributos
					</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="4">
				<v-text-field
					v-model="search.name"
					label="Buscar categoria..."
					required
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="categories"
					height="700"
					:fixed-header="true"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.actions="{ item }">
						<v-btn
							class="ml-2"
							fab
							v-if="hasAccess('ESC.INV.CAT')"
							x-small
							@click="redirectWithParam('CategoryUpdate', { id: item.id })"
						>
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.INV.CAT')" class="ml-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => {
		return {
			search: {
				name: "",
			},
			loading: true,
			total: 0,
			headers: [
				{ text: "Nombre", value: "name" },
				{ text: "Descripción", value: "description" },
				{ text: "Acciones", value: "actions" }
			],
			dialogDelete: false,
			deleteLoad: false,
		};
	},
	created: async function(){
		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');
		this.loading = false;
	},
	computed: {
		...mapGetters('Auth', ['hasAccess']),
		...mapState('Category', ['categories'])
	},
	methods: {
		...mapActions('Category', ['delete']),

		deledit(item) {
			this.editedIndex = this.categories.indexOf(item);
			this.form = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "CategoryUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.loading = true;
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.form.id);
			this.closeDelete();
			await this.$store.dispatch('Category/get');
			this.formLoad = false;
			this.deleteLoad = false;
			this.loading = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	}
};

</script>
