var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-4" },
    [
      _c("DialogDelete", {
        attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
        on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
      }),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "text-h4" }, [_vm._v("Tipos de Recargo")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.hasAccess("ESC.TRE.TRC")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        fab: "",
                        color: "primary",
                        to: "/treasury/surcharge-type/store",
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.surchargeTypes,
                  search: _vm.search.name,
                  "footer-props": {
                    itemsPerPageOptions: [5, 10, 50, 100],
                    itemsPerPageText: "Filas por página:",
                  },
                  loading: _vm.tableLoad,
                  "sort-by": "created_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.rate",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(item.rate) +
                            " " +
                            _vm._s(item.rate_type == "FIXED" ? "Fijo" : "%") +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _vm.hasAccess("ESC.TRE.TRC")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ma-2",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectWithParam(
                                      "SurchargeTypeUpdate",
                                      { id: item.id }
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                              1
                            )
                          : _vm._e(),
                        _vm.hasAccess("ESC.TRE.TRC")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ma-2",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-delete ")])],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }