import axios from "axios";

export default {
	namespaced: true,
	state: {
		priceList: {
			name: null,
			detail: null,
			branch_id: null,
			deposit_type_ref: null,
		},
		priceLists: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_PRICE_LIST(state, data) {
			state.priceList = data;
		},
		SET_PRICE_LISTS(state, data) {
			state.priceLists  = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("product/price-list").then((response) => {
				commit("SET_PRICE_LISTS", response.data.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("product/price-list", params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("product/price-list/" + params.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("product/price-list/" + id)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch(() => {});
		},
	},
	getters: {
		getPriceList: (state) => (priceListId) => {
			if (state.priceLists)
				return state.priceLists.filter((prop) => prop.id == priceListId)[0];
			else return "";
		},
		getFirstPriceList: (state) => {
			if (state.priceLists) 
				return state.priceLists[0];
			else return [];
		},
		getPriceLists: (state) => {
			if (state.priceLists) return state.priceLists;
			else return [];
		},
	},
};
