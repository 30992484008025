import SalingHome from "@/views/app/saling/saling-home.vue";
import SalingData from "@/views/app/saling/saling-data.vue";

import SaleHome from "@/views/saling/sale/sale-home.vue";
import SaleData from "@/views/saling/sale/sale-data.vue";
import SaleForm from "@/views/saling/sale/sale-form.vue";
import SaleDetail from "@/views/saling/sale/sale-detail.vue";

import CustomerHome from "@/views/saling/customer/customer-home.vue";
import CustomerData from "@/views/saling/customer/customer-data.vue";
import CustomerForm from "@/views/saling/customer/customer-form.vue";

import ChargeHome from "@/views/saling/charge/charge-home.vue";
import ChargeData from "@/views/saling/charge/charge-data.vue";
import ChargeForm from "@/views/saling/charge/charge-form.vue";

const STR_STORE = "store";

export default {
	path: "/saling",
	name: "",
	component: SalingHome,
	meta: { requiresAuth: true },
	children: [
		{
			path: "",
			name: "SalingData",
			component: SalingData,
			meta: { access: 'LEC.VEN.VEN' }
		},
		{
			path: 'sale',
			component: SaleHome,
			meta: { access: 'LEC.VEN.VEN' },
			children: [
				{
					path: "",
					name: "SaleData",
					component: SaleData,
					meta: {access: 'LEC.VEN.VEN'}
				},
				{
					name: "SaleCreate",
					path: STR_STORE,
					component: SaleForm,
					meta: {access: 'ESC.VEN.VEN'}
				},
				{
					name: "SaleUpdate",
					path: STR_STORE + "/:id",
					component: SaleForm,
					meta: {access: 'ESC.VEN.VEN'}
				},
				{
					name: "SaleDetail",
					path: "detail/:id",
					component: SaleDetail,
					meta: {access: 'LEC.VEN.VEN'}
				},
			]
		},
		{
			path: "customer",
			component: CustomerHome,
			meta: {access: 'LEC.VEN.CLI'},
			children: [
				{
					path: "",
					name: "CustomerData",
					component: CustomerData,
					meta: {access: 'LEC.VEN.CLI'}
				},
				{
					name: "CustomerCreate",
					path: STR_STORE,
					component: CustomerForm,
					meta: {access: 'ESC.VEN.CLI'}
				},
				{
					name: "CustomerUpdate",
					path: STR_STORE + "/:id",
					component: CustomerForm,
					meta: {access: 'ESC.VEN.CLI'}
				},
			],
		},
		{
			path: "charge",
			component: ChargeHome,
			meta: { access: 'LEC.VEN.COB' },
			children: [
				{
					path: "",
					name: "ChargeData",
					component: ChargeData,
					meta: {access: 'LEC.VEN.COB'}
				},
				{
					name: "ChargeCreate",
					path: STR_STORE,
					component: ChargeForm,
					meta: { access: 'ESC.VEN.COB' }
				},
				{
					name: "ChargeCreateTo",
					path: STR_STORE + '/:type/:id',
					component: ChargeForm,
					meta: { access: 'ESC.VEN.COB' }
				},
				{
					name: "ChargeUpdate",
					path: STR_STORE + "/:id",
					component: ChargeForm,
					meta: { access: 'ESC.VEN.COB' }
				},
			],
		}
	],
};
