import axios from "axios";

export default {
	namespaced: true,
	state: {
		enterprise: {
			entity: {
				alias: "",
				document_type: {
					code: "",
				},
			},
			sale_points: [],
			branch: [],
		},
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_ENTERPRISE(state, data) {
			state.enterprise = data;
			state.branch = data.branch[0];
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("enterprise").then((response) => {
				commit("SET_ENTERPRISE", response.data);
			}).catch(() => {});
		},
		edit: async function ({ commit }, params) {
			params.before();
			await axios
				.put("enterprise", params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
			params.complete();
		},
	},
	getters: {
		getEnterpriseAlias: (state) => {
			return state.enterprise.entity.alias;
		},
		getCodeOfDocumentType: (state) => {
			return state.enterprise.entity.document_type.code;
		},
		getCodeOfVatCondition: (state) => {
			return state.enterprise.entity.vat_condition_type.code;
		},
		getBranchs: (state) => {
			return state.enterprise.branch;
		},
		getFirstBranch: (state) => {
			return state.enterprise.branch[0];
		},
	},
};
