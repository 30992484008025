<template>
	<div>
		<Title title="Proveedores"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
			<v-row>
				<v-col>
					<v-btn
						class="mx-2"
						fab
						dark
						v-if="hasAccess('ESC.COM.PRV')"
						color="primary"
						to="/purchasing/provider/store"
					>
						<v-icon dark> mdi-plus </v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row>
			<v-col cols="12" md="4">
				<v-text-field
					v-model="search.name"
					label="Buscar proveedor..."
					required
				></v-text-field>
			</v-col>
			</v-row>
			<DialogDelete
				:value="dialogDelete"
				:progress="deleteLoad"
				@close="closeDelete"
				@confirm="deleteItemConfirm"
			></DialogDelete>
			<v-row>
				<v-col>
					<v-data-table
					:headers="headers"
					:items="providers"
					:search="search.name"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="tableLoad"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.balance="{ item }">
						<div :class="{'red--text': item.entity.fund.balancet < 0, 'green--text': item.entity.fund.balance > 0}" >
							{{ formatCurrency(item.entity.fund.balance) }}
						</div>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('ESC.COM.PRV')" class="ml-2" fab x-small @click=" redirectWithParam('ProviderUpdate', { id: item.id })">
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.COM.PRV')" class="ml-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
					<template v-slot:no-data></template>
				</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => ({
		name: "Proveedor",
		search: {
			name: "",
		},
		dialogDelete: false,
		deleteLoad: false,
		tableLoad: false,
		headers: [
			{ text: "Proveedor", value: "alias" },
			{ text: "Balance de Fondo", value: "balance" },
			{ text: "Acciones", value: "actions", sortable: false },
		],
		editedIndex: -1
	}),

	computed: {
		...mapState("Provider", ["providers"]),
		...mapGetters("Auth", ['hasAccess'])
	},

	watch: {
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created: async function () {
		this.tableLoad = true;
		if(this.$store.state.Provider.providers.length == 0)
			await this.$store.dispatch('Provider/get');
		this.tableLoad = false;
	},

	methods: {
		...mapActions("Provider", ["store", "delete"]),

		deledit(item) {
			this.editedIndex = this.providers.indexOf(item);
			this.provider = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "ProviderUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			this.delete(this.provider);
			await this.$store.dispatch('Provider/get');
			this.closeDelete()
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete(){
			this.dialogDelete = false;
		},

		redirect(to) {
			this.$router.push({ name: to });
		},
	},
};
</script>
