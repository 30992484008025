<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-container class="mt-3 mb-10 flex-column">
			<v-row class="justify-center">
				<v-data-table
					:headers="headers"
					:items="roles"
					sort-by="name"
					:loading="tableLoad"
					class="elevation-1"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title>{{ name }}</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
							<v-spacer></v-spacer>
							<v-btn color="primary" @click="redirect('RoleCreate')" small fab>
								<v-icon dark> mdi-plus </v-icon>
							</v-btn>
							<DialogDelete
								:value="dialogDelete"
								:progress="deleteLoad"
								@close="closeDelete"
								@confirm="deleteItemConfirm"
							></DialogDelete>
						</v-toolbar>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('ESC.ADM.ROL')" class="ml-2" fab x-small @click=" redirectWithParam('RoleUpdate', { id: item.id })">
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.ADM.ROL')" class="ml-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
					<template v-slot:no-data></template>
				</v-data-table>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import Toast from "@/components/toast";

export default {
	components: { DialogDelete, Toast },
	data: () => ({
		name: "Roles",
		dialogDelete: false,
		deleteLoad: false,
		tableLoad: false,
		headers: [
			{ text: "Descripción", value: "name" },
			{ text: "Acciones", value: "actions", sortable: false },
		],
		editedIndex: -1,
		rol: {},
	}),

	computed: {
		...mapState("Role", ["roles"]),
		...mapGetters('Auth', ['hasAccess']),

		errors: {
			get() {
				return this.$store.state.Role.errors;
			},
			set(value) {
				this.$store.commit("Role/SET_ERRORS", value);
			},
		},
	},

	watch: {
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created: async function() {
		this.tableLoad = true;
		if(this.$store.state.Role.roles.length == 0)
			await this.$store.dispatch('Role/get');
		this.tableLoad = false;
	},

	methods: {
		...mapActions("Role", ["store", "delete"]),

		deledit(item) {
			this.editedIndex = this.roles.indexOf(item);
			this.rol = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "RoleUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			this.delete(this.rol);
			this.closeDelete();
			this.$store.dispatch('Role/get');
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},

		redirect(to) {
			this.$router.push({ name: to });
		},
	},
};
</script>
