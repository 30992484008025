<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-sucursales"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="branch.name"
										required
										label="Descripción"
										:error-messages="nameErrors"
										@change="$v.branch.name.$touch()"
										@blur="$v.branch.name.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-select
										:items="salePoints"
										item-text="name"
										item-value="id"
										v-model="salePoint"
										label="Punto de Venta"
										:error-messages="salePointErrors"
										@change="$v.salePoint.$touch()"
										@blur="$v.salePoint.$touch()"
									></v-select>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-radio-group v-model="branch.head">
										<v-radio label="Principal" :value="1"></v-radio>
										<v-radio label="Secundario" :value="0"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<Location :addressable="getAddress" ref="location" :v="$v" />
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-sucursales"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { required, integer } from "vuelidate/lib/validators";
import Toast from "@/components/toast";
import Location from "@/components/location";

export default {
	components: { Toast, Location },
	validations: {
		branch: {
			name: { required },
		},
		salePoint: { required },
		address: {
			province: { required },
			city: { required },
			street: { required },
			number: { required, integer },
		},
	},
	data: () => {
		return {
			title: "Sucursal",
			dialog: true,
			formValid: false,
			formLoad: false,
			branch: {
				id: null,
				sale_point_id: "",
				name: "",
				head: 0,
				address: {
					street: null,
					number: null,
					city: {
						id: null,
					},
				},
			},
			salePoint: null,
			salePoints: null,
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.Branch.errors;
			},
			set(value) {
				this.$store.commit("Branch/SET_ERRORS", value);
			},
		},

		getAddress() {
			if (this.$route.params.id) {
				let branch = this.getBranch(this.$route.params.id);
				if (branch) return branch.addresses[0];
				else return null;
			} else return null;
		},

		...mapState("Branch", ["branches"]),
		...mapGetters("Branch", ["getBranch"]),
		...mapGetters("Auth", ["getSalePoints"]),

		nameErrors() {
			const errors = [];
			if (!this.$v.branch.name.$dirty) return errors;
			!this.$v.branch.name.required && errors.push("El nombre es requerido");
			return errors;
		},
		salePointErrors() {
			const errors = [];
			if (!this.$v.salePoint.$dirty) return errors;
			!this.$v.salePoint.required &&
				errors.push("El punto de venta es requerido");
			return errors;
		},
	},
	created: async function () {
		if (this.$route.params.id)
			this.branch = Object.assign({}, this.getBranch(this.$route.params.id));
		this.salePoints = this.getSalePoints;
		this.salePoint = this.branch.sale_point.id;
	},
	methods: {
		...mapActions("Branch", ["store"]),

		close() {
			this.$nextTick(() => {
				//this.branch = Object.assign({}, null);
				//this.branch = this.getBranch(this.$route.params.id);
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
		},

		successSave: async function () {
			await this.$store.dispatch("Branch/get");
			this.$router.push({ name: "BranchData" });
			//if (!this.branch.id > 0) {
			//this.$refs.form.reset();
			//}
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.branch.sale_point_id = this.salePoint;
			this.branch.address = this.$refs.location.address;
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					data: {
						id: this.branch.id,
						name: this.branch.name,
						head: this.branch.head,
						sale_point_id: this.branch.sale_point_id,
						address: {
							street: this.branch.address.street,
							number: this.branch.address.number,
							location_id: this.branch.address.city.id,
						},
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
