var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-stepper-header",
    [
      _c(
        "v-stepper-step",
        {
          attrs: {
            complete: _vm.step > 1,
            step: "1",
            rules: [() => !_vm.validator.$invalid],
          },
        },
        [_vm._v(" Datos Principales ")]
      ),
      _c("v-divider"),
      _c("v-stepper-step", { attrs: { complete: _vm.step > 2, step: "2" } }, [
        _vm._v(" Listado de Productos "),
      ]),
      _c("v-divider"),
      _c("v-stepper-step", { attrs: { complete: _vm.step > 3, step: "3" } }, [
        _vm._v(" Recargos y Descuentos "),
      ]),
      !this.$route.params.id ? _c("v-divider") : _vm._e(),
      !this.$route.params.id
        ? _c(
            "v-stepper-step",
            { attrs: { complete: _vm.step > 4, step: "4" } },
            [_vm._v(" Proceso de Pago ")]
          )
        : _vm._e(),
      _c("v-divider"),
      _c(
        "v-stepper-step",
        { attrs: { step: !this.$route.params.id ? 5 : 4 } },
        [_vm._v(" Vista General ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }