var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    [
      _c("v-subheader", [_vm._v("Fondos")]),
      _c(
        "v-list-item-group",
        {
          attrs: { color: "primary" },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        _vm._l(_vm.funds, function (fund) {
          return _c(
            "v-list-item",
            {
              key: fund.id,
              on: {
                click: function ($event) {
                  return _vm.selectFund(fund)
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [_c("v-icon", [_vm._v(" " + _vm._s(_vm.getIcon(fund)) + " ")])],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", [_vm._v(_vm._s(fund.title))]),
                  _c("v-list-item-subtitle", [
                    _vm._v(_vm._s(_vm.formatCurrency(fund.balance)) + " "),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "text-caption mt-2",
                      class: { "orange--text": fund.movements_sum_amount },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          class: { "orange--text": fund.movements_sum_amount },
                          staticStyle: { "margin-top": "-2px" },
                          attrs: { "x-small": "" },
                        },
                        [_vm._v("mdi-clock-outline")]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatCurrency(fund.movements_sum_amount)
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item-action",
                [
                  _vm.hasAccess("ESC.TRE.FUN")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectWithParam(
                                "MyFundTransferData",
                                { id: fund.id }
                              )
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey lighten-1" } }, [
                            _vm._v("mdi-send"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasAccess("ESC.TRE.FUN")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.redirectWithParam("MyFundAdjust", {
                                id: fund.id,
                              })
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "grey lighten-1" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }