<template>
	<div>
		<Title title="Gastos" ></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
			<DialogDelete
				:value="dialogDelete"
				:progress="deleteLoad"
				@close="closeDelete"
				@confirm="deleteItemConfirm"
			></DialogDelete>
			<v-row>
				<v-col>
					<v-btn
						class="mx-2"
						fab
						color="primary"
						to="/treasury/expense/store"
						v-if="hasAccess('ESC.TRE.GAS')"
						><v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-data-table
						:headers="headers"
						:items="expenses"
						:search="search.name"
						:footer-props="{
							itemsPerPageOptions: [5, 10, 50, 100],
							itemsPerPageText: 'Filas por página:',
						}"
						:loading="tableLoad"
						sort-by="created_at"
						class="elevation-1"
					>
						<template v-slot:item.expense_type=" { item }">
								{{ capitalizeFirstLetter(item.expense_type.toLowerCase()) }}
						</template>
						<template v-slot:item.amount=" { item }">
								{{ formatCurrency(item.amount) }}
						</template>
						<template v-slot:item.detail=" { item }">
								{{ item.detail ?? 'Sin detalle' }}
						</template>
						<template v-slot:item.actions="{ item }">
							<!--<v-btn v-if="hasAccess('ESC.TRE.GAS')" class="ma-2" fab x-small @click="redirectWithParam('ExpenseUpdate', { id: item.id })">
								<v-icon> mdi-pencil </v-icon>
							</v-btn>
							-->
							<v-btn v-if="hasAccess('ESC.TRE.GAS')" class="ma-2" fab x-small @click="deleteItem(item)">
								<v-icon> mdi-delete </v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => {
		return {
			name: "Gastos",
			search: {
				name: "",
			},
			dialogDelete: false,
			deleteLoad: false,
			tableLoad: false,
			headers: [
				{ text: "Titulo", value: "description" },
				{ text: "Categoria", value: "expense_type" },
				{ text: "Detalle", value: "detail" },
				{ text: "Fondo", value: "fund.title" },
				{ text: "Monto", value: "amount" },
				{ text: "Creado por", value: "user.name" },
				{ text: "Fecha del Gasto", value: "issue_date" },
				{ text: "Acciones", value: "actions", sortable: false },
			],
			editedIndex: -1,
			expense: null
		}
	},
	created: async function(){
		this.tableLoad = true;
		if(this.$store.state.Expense.expenses.length == 0)
			await this.$store.dispatch('Expense/get');
		this.tableLoad = false;
	},
	computed: {
		...mapState('Expense', ['expenses']),
		...mapGetters('Auth', ['hasAccess'])
	},
	methods: {
		...mapActions("Expense", ["store", "delete"]),

		deledit(item) {
			this.editedIndex = this.expenses.indexOf(item);
			this.expense = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "ExpenseUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			this.delete(this.expense);
			await this.$store.dispatch('Expense/get');
			this.$store.dispatch("Fund/get");
			this.$store.dispatch("DailyBox/get");
			this.closeDelete()
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete(){
			this.dialogDelete = false;
		},

		redirect(to) {
			this.$router.push({ name: to });
		},
	}
}

</script>
