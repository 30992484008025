<template>
  <v-container>
		<v-row>
			<v-col cols="12" lg="9" md="9" sm="12">
				<v-combobox
						:items="getProductsToLoad"
						:prepend-icon="withAddProductAction && hasAccess('ESC.INV.PRD') ? 'mdi-package-variant-closed' : ''"
						@click:prepend="this.openDialogProduct"
						clearable
						filled
						:filter="customFilter"
						item-text="full_name"
						item-value="id"
						:hint="'Usando ' + priceList.name"
      			persistent-hint
						:loading="loading.product"
						v-model="cart.product"
						label="Productos"
						@change="changeProduct()"
					>
						<template v-if="onlyProductsWithStock" v-slot:append-outer>
							<v-chip v-if="cart.product?.id" style="margin-top: -5px;" :color="getStock <= getMinStock ? 'error' : 'success'">
								{{ getStock }}
							</v-chip>
						</template>
						<template v-slot:item="{ item }">
							<v-list-item>
								<div class="pa-2">
								<p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ item.name }}</p>
								<p class="text-body-2 font-weight-light text-capitalize ma-0">{{ item.attributes_name }}</p>
							</div>
							</v-list-item>
							<v-list-item-action class="float-right" v-if="onlyProductsWithStock">
								<v-chip :color="item.stock.count <= getMinStock ? 'error' : 'success'">
									{{ item.stock.count }}
								</v-chip>
							</v-list-item-action>
							<v-list-item-action class="float-right" v-if="withEditProductAction && hasAccess('ESC.INV.PRD')">
								<v-btn fab small @click="openDialogProduct(item.id)">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</v-list-item-action>
						</template>
				</v-combobox>
			</v-col>
			<v-col cols="6" lg="2" md="2" sm="4">
				<v-text-field
					v-model="cart.price"
					clearable
					filled
					label="Precio"
				></v-text-field>
				<h5 v-if="showCashDiscount && cart.price > 0" class="text-body-1 ma-0 pa-0 d-inline"> <v-icon class="success--text">mdi-cash</v-icon> <p class="success--text d-inline">{{ formatCurrency(getPriceWithCashDiscount()) }}</p></h5>
			</v-col>
			<v-col cols="2" lg="1" md="1" sm="2">
				<v-btn
					class="mx-2"
					fab
					small
					dark
					color="accent"
					@click="
						addProduct()
						$emit('product-alter', productsCharged)"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col><v-divider></v-divider></v-col>
		</v-row>
    <v-data-iterator
      :items="productsCharged"
			no-data-text="No se encontraron datos, aún..."
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
			class="mb-5"
    >
      <template v-slot:header v-if="withHeadersFilter">
					<v-container>
						<v-row>
							<v-col cols="12" lg="4">
								<v-text-field
									v-model="search"
									flat
									outlined
									dense
									elevation="1"
									hide-details
									prepend-inner-icon="mdi-magnify"
									label="Buscar"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="4">
							<v-select
									v-model="sortBy"
									flat
									elevation="1"
									outlined
									hide-details
									:items="custom"
									item-value="id"
									item-text="desc"
									dense
									prepend-inner-icon="mdi-magnify"
									label="Ordenar por..."
								></v-select>
							</v-col>
							<v-col cols="12" lg="4">
								<v-btn-toggle
									v-model="sortDesc"
									small
									mandatory
								>
									<v-btn
										large
										depressed
										color="secondary"
										:value="false"
									>
										<v-icon color="white">mdi-arrow-up</v-icon>
									</v-btn>
									<v-btn
										large
										depressed
										color="secondary"
										:value="true"
									>
										<v-icon color="white">mdi-arrow-down</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-container>
      </template>

      <template v-slot:default="props" >
				<v-container class="pt-6">
				<div class="mb-4 mt-4">
					<v-row v-for="item in props.items" :key="item.product_id">
						<v-col cols="8" lg="5" class="pb-0">
							<div class="pa-2">
								<p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ item.attributes_name }}</p>
								<p class="text-body-2 font-weight-light text-capitalize ma-0">{{ item.name }}</p>
							</div>
						</v-col>
						<v-col cols="4" lg="2" class="pb-0 mt-0">
							<v-text-field
									prefix="$"
									outlined
									dense
									@input="
												editProduct(item)
												$emit('product-alter', productsCharged)"
									v-model="item.unit_price"
								></v-text-field>
						</v-col>
						<v-col cols="4" lg="1" class="pb-0 mt-0">
							<v-text-field
									outlined
									dense
									@input="editProduct(item)
													$emit('product-alter', productsCharged)"
									v-model="item.count"
								></v-text-field>
						</v-col>
						<v-col cols="5" lg="3" class="pb-0 mt-0 text-center">
							<p class="text-h5 mt-1">{{ formatCurrency(item.unit_price * (parseInt(item.count) ? item.count : 0))  }}</p>
						</v-col>
						<v-col cols="3" lg="1" class="pb-0 mt-0 text-center">
							<v-btn
									class="mx-2"
									fab
									dark
									dense
									x-small
									color="accent"
									@click="deleteProduct(item.product_id)
													$emit('product-alter', productsCharged)"
								>
									<v-icon dark> mdi-delete </v-icon>
								</v-btn>
						</v-col>
					</v-row>
				</div>
				</v-container>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-4"
          align="center"
          justify="center"
        >
          <span class="grey--text">Cantidad por página</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class="mr-4
            grey--text"
          >
            Página {{ page }} de {{ numberOfPages }}
          </span>
          <v-btn
            fab
            dark
						x-small
            color="secondary"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            color="secondary"
            class="ml-1 mr-3"
						x-small
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>

import { mapGetters, mapState } from 'vuex';
import ProductForm from '@/views/inventory/product/product-form-single.vue';

export default {
	props: {
		carts: {
			default: null
		},
		withAddProductAction: {
			default: true
		},
		withEditProductAction: {
			default: false
		},
		onlyProductsWithStock:{
			default: false
		},
		withHeadersFilter:{
			default: true
		},
		showCashDiscount: {
			default: false
		},
		priceList: {
			default: null
		}
	},
	components: { ProductForm },
	data () {
		return {
			itemsPerPageArray: [7, 15, 30],
			dialogProduct: false,
			productIdDialog: null,
			search: '',
			filter: {},
			sortDesc: false,
			page: 1,
			itemsPerPage: 7,
			loading: {
				product: true
			},
			sortBy: 'name',
			keys: [
				'name',
				'unit_price'
			],
			custom: [
				{
					id: 'name',
					desc: 'Nombre'
				},
				{
					id: 'unit_price',
					desc: 'Precio'
				}
			],
			headers: [
				'Producto',
				'Precio',
				'Cantidad',
				'Total'
			],
			cart: {
				product: {
					id: null,
					name: null,
					full_name: null
				},
				count: 1,
				price: null,
			},
			stock: [],
			priceListToUse: null,
			productsCharged: [],
		}
	},
	created: async function(){
		if(this.$store.state.Product.products.length == 0)
			await this.$store.dispatch('Product/get');

		await this.$store.dispatch('Product/getStock');

		this.formatProductStock();
		this.loading.product = false;

		if(this.$store.state.PriceList.priceLists.length == 0)
			await this.$store.dispatch('PriceList/get');
		
		if(this.$store.state.Fund.funds.length == 0)
			await this.$store.dispatch('Fund/get');

		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');

		if(this.priceList)
			this.priceListToUse = Object.assign({}, this.getPriceList(this.priceList.id))
		if(this.carts){
			this.productsCharged = this.getProductFromPurchase(this.carts)
		}

	},
	computed: {
		...mapGetters('Product', ['getProductsWithStock', 'getProduct']),
		...mapGetters('Category',['getCategory']),
		...mapGetters('PriceList', ['getPriceList']),
		...mapGetters('Fund', ['getMainCashFund']),
		...mapGetters('PaymentMethod', ['getPaymentMethodByFundId']),
		...mapGetters('Auth', ['hasAccess']),
		...mapGetters({
			getStoreStock: 'Product/getStock'
		}),

		...mapState('Product', ['products']),

		getProductsToLoad(){
			if(this.onlyProductsWithStock)
				return this.getProductsWithStock;
			return this.products;
		},

		getStock(){
			if(this.cart.product.id)
				return this.cart.product.stock.count;
			return 0;
		},

		getMinStock(){
			if(this.cart.product?.id)
				return this.cart.product.min_stock;
			return 0;
		},

		numberOfPages () {
			return Math.ceil(this.productsCharged.length / this.itemsPerPage)
		},

		filteredKeys () {
			return this.keys.filter(key => key !== 'Name')
		}

	},
	methods: {

		customFilter(item, queryText, itemText) {
			const searchText = queryText.toLowerCase();
			const searchWords = searchText.split(" ");
			const itemValues = [item.name, this.getCategory(item.id)?.name ?? '', item.attributes_name];
			return searchWords.every(word => {
				return itemValues.some(value => value.toLowerCase().includes(word));
			});
		},

		nextPage () {
			if (this.page + 1 <= this.numberOfPages) this.page += 1
		},
		formerPage () {
			if (this.page - 1 >= 1) this.page -= 1
		},
		updateItemsPerPage (number) {
			this.itemsPerPage = number
		},

		formatProductStock(){
			this.stock = [];
			this.getStoreStock.forEach(element => {
				let product = this.getProduct(element.product_id)
				this.stock.push({
					attributes_name: element.name,
					name: product.name,
					stock: {
						count: element.count
					}
				})
			});
		},

		getProductFromPurchase(products){
			let data = [];
			products.forEach(element => {
					data.push({
						id: this.cart.id,
						product_id: element.product.id,
						product: element.product,
						attributes_name: this.getProduct(element.product.id).attributes_name,
						full_name: this.getProduct(element.product.id).full_name,
						name: element.product.name,
						unit_cost: element.unit_cost,
						unit_price: element.unit_price,
						count: element.count,
						total_price: element.amount,
						taxes_discount: element.taxes_discount,
						taxes_amount: element.discount_amount
					})
			});
			return data;
		},

		getPriceWithCashDiscount(){
			let cashFund = this.getMainCashFund;
			let paymentMethod = this.getPaymentMethodByFundId(cashFund.id);

			if(!paymentMethod.discount_value > 0){
				return this.cart.price;
			}

			if(paymentMethod.discount_rate == 'FIXED')
				return this.roundUpToMultiple(parseFloat(this.cart.price) - paymentMethod.discount_value, paymentMethod.discount_round);
			else
				return this.roundUpToMultiple(parseFloat(this.cart.price) - (this.cart.price / 100 * paymentMethod.discount_value), paymentMethod.discount_round);
		},

		deleteProduct(id){
			let productosFiltrados = this.productsCharged.filter((prop) => prop.product_id != id);
			this.productsCharged = [].concat(productosFiltrados);
		},

		editProduct(item){

			let productos = [].concat(this.productsCharged)

			const indice = productos.findIndex((elemento, indice) => {
				if (elemento.product_id == item.product_id) {
					return true;
				}
			});

			productos[indice].unit_price = item.unit_price;
			productos[indice].count = item.count;

			this.productsCharged = [].concat(productos);
		},

		addProduct(){

			this.cart.count = 1;

			if(!this.cart.product)
				return this.sweetAlertCustom('Debe seleccionar un producto', 'warning');

			if(!this.cart.product.id)
				return this.sweetAlertCustom('Debe seleccionar un producto', 'warning');

			if(this.productsCharged.filter((prop) => prop.product.id == this.cart.product.id).length > 0){
				const index = this.productsCharged.findIndex((elemento, indice) => {
					if (elemento.product.id == this.cart.product.id) {
						return true;
					}
				});
				this.productsCharged[index].count = parseInt(this.productsCharged[index].count) + 1;
				return;
			}

			if(this.cart.price == 0)
				return this.sweetAlertCustom('El precio no puede ser 0', 'warning');

			if(this.cart.count == 0)
				return this.sweetAlertCustom('La cantidad no puede ser 0', 'warning');

			let productAdding = {
					id: this.cart.id,
					product_id: this.cart.product.id,
					product: this.cart.product,
					name: this.cart.product.name,
					attributes_name: this.cart.product.attributes_name,
					full_name: this.getProduct(this.cart.product.id).full_name,
					unit_cost: this.cart.product.cost_price,
					unit_price: this.cart.price,
					count: this.cart.count,
					total_price: this.cart.price * this.cart.count,
					taxes_discount: 0,
					taxes_amount: 0
			};


			this.productsCharged.push(
				Object.assign({}, productAdding)
			)
		},

		changeProduct(){
			if(this.cart.product){
				if(!this.priceList)
					return this.cart.price = this.cart.product.cost_price;
				this.cart.price = this.priceList.products.filter((prop) => prop.product_id == this.cart.product.id)[0].price;
			}
		},

		resetListModel(){
			this.cart.product = {
				id: null,
				name: null,
				full_name: null
			};
		},

		openDialogProduct(id = null){
			if(id)
				this.productIdDialog = id;
			this.dialogProduct = true;
		},

		updateProductIdDialog(value){
			this.productIdDialog = value;
		},

		updateDialogProduct: async function(value){
			this.dialogProduct = value;
			if(!this.dialogProduct){
				this.productIdDialog = null;
				this.resetListModel();
				this.loading.product = true;
				await this.$store.dispatch('Product/get');
				this.loading.product = false;
			}
		}
	},
}
</script>
