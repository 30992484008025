<template>
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-payment-to"
						@submit.prevent="save"
						v-model="formValid"
					>
					<v-container>
						<div class="row">
							<v-col>
								<ChargeList action="pagar" :total-charge="total" v-on:charges-updated="chargesUpdated" />
							</v-col>
						</div>
					</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-payment-to"
						color="blue darken-1"
						text
					>
						Pagar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
</template>
<script>

import ChargeList from "@/components/saling/charges-list";
import { mapActions, mapGetters } from 'vuex';

export default {
	components: { ChargeList },
	data: () => {
		return {
			title: 'Pago',
			formLoad: false,
			formValid: false,
			dialog: true,
			total: 0,
			operation: 0
		}
	},
	created: async function() {
		if(this.$store.state.Purchase.purchases.length == 0)
			await this.$store.dispatch('Purchase/get');

		if(this.$route.params.type){
			if(this.$route.params.type == 'purchase'){
				this.total = this.getTotalPendingToPay(this.$route.params.id);
			}
		}
	},
	computed: {
		...mapGetters('Purchase', ['getTotalPendingToPay'])
	},
	methods: {

		...mapActions('Payment', ['payPurchase']),

		beforeSave(){
			this.formLoad = true;
		},

		successSave(){
			if(this.$store.state.errors.status == 200){
				this.$store.dispatch('Purchase/get');
				this.$store.dispatch('Provider/get');
				this.$store.dispatch('DailyBox/get');
				this.$store.dispatch('Fund/get');
				this.$router.back();
			}
		},

		save: async function(){
			if(this.operation.total <= 0)
				return this.sweetAlertCustom('El monto a pagar debe ser mayor a 0', 'warning');
			if(this.$route.params.type == 'purchase')
				await this.payPurchase({
					id: this.$route.params.id,
					data: this.operation,
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					}
				})
			this.formLoad = false;
		},
		chargesUpdated(charge){
			this.operation = charge;
		}
	}
}
</script>
