var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: _vm.persistent,
        "max-width": "500px",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.progress
            ? _c("v-progress-linear", {
                staticClass: "mb-0",
                attrs: { indeterminate: "", color: "primary" },
              })
            : _vm._e(),
          _c("v-card-title", { staticClass: "text-h6" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("v-card-text", [_vm._v(" ñlasdñlkasdñl ")]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: { click: _vm.handleClickClose },
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    disabled: _vm.progress,
                    loading: _vm.progress,
                    text: "",
                  },
                  on: { click: _vm.handleClickConfirm },
                },
                [_vm._v("OK")]
              ),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }