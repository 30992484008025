import axios from "axios";

export default {
	namespaced: true,
	state: {
		surchargeTypes: []
	},
	mutations: {
		SET_SURCHARGE_TYPES(state, data) {
			state.surchargeTypes = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("surcharge/type").then((response) => {
				commit("SET_SURCHARGE_TYPES", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("surcharge/type", params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("surcharge/type/" + params.id, params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("surcharge/type/" + params.id)
				.then(() => {})
				.catch(() => {});
		},
	},
	getters: {
		getSurchargeType: (state) => (surchargeTypeId) => {
			if (state.surchargeTypes.length > 0)
				return state.surchargeTypes.filter((prop) => prop.id == surchargeTypeId)[0];
			else return "";
		},
		getFirstSurchargeType: (state) => {
			if (state.surchargeTypes.length > 0)
				return state.surchargeTypes[0];
			else return "";
		},
	},
};
