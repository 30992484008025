import axios from "axios";

export default {
	namespaced: true,
	state: {
		salePoint: {},
		salePoints: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_SALE_POINT(state, data) {
			state.salePoint = data;
		},
		SET_SALE_POINTS(state, data) {
			state.salePoints = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
			/*for (const property in data) {
				let value = data[property];
				if (Array.isArray(value)) {
					value.forEach((element) => {
						state.errors.push(element);
					});
				} else state.errors.push(value);
			}
			setTimeout(function () {
				state.errors = null;
			}, 3000);*/
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("sale-point").then((response) => {
				commit("SET_SALE_POINTS", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, data) {
			commit("SET_SALE_POINT", data.salePoint);
			return axios
				.post("sale-point", data.salePoint)
				.then((res) => {
					data.success();
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				console.log(params.salePoint);
				if (params.salePoint.id > -1) {
					await dispatch("update", {
						salePoint: params.salePoint,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						salePoint: params.salePoint,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, data) {
			let salePoint = data.salePoint;
			commit("SET_SALE_POINT", salePoint);
			return axios
				.put("sale-point/" + salePoint.id, salePoint)
				.then((res) => {
					data.success();
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		delete: async function ({ commit }, data) {
			return axios
				.delete("sale-point/" + data.id)
				.then((res) => {
					data.success();
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					console.log(error.response.data);
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
	},
};
