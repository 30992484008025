import Vue from "vue";
import Vuex from "vuex";
import VueSession from "vue-session";

//ADMIN
import Auth from "@/class/auth/Auth.js";
import User from "@/class/admin/User.js";
import Enterprise from "@/class/admin/Enterprise.js";
import Branch from "@/class/admin/Branch.js";
import SalePoint from "@/class/admin/SalePoint.js";
import Access from "@/class/admin/Access.js";
import Role from "@/class/admin/Role.js";
import Location from "@/class/admin/Location.js";

//INVENTORY
import Category from "@/class/inventory/Category.js";
import CategoryAttribute from "@/class/inventory/CategoryAttribute.js";
import Deposit from "@/class/inventory/Deposit.js";
import Product from "@/class/inventory/Product.js";
import MovementInventory from "@/class/inventory/MovementInventory.js";
import PriceList from "@/class/inventory/PriceList.js";

//TREASURY
import Fund from "@/class/treasury/Fund.js";
import FundMovement from "@/class/treasury/FundMovement.js";
import Expense from "@/class/treasury/Expense.js";
import Surcharge from "@/class/treasury/Surcharge.js";
import SurchargeType from "@/class/treasury/SurchargeType.js";
import PaymentMethod from "@/class/treasury/PaymentMethod.js";

//PURCHASING
import Provider from "@/class/purchasing/Provider.js";
import Purchase from "@/class/purchasing/Purchase.js";
import Payment from "@/class/purchasing/Payment.js";

//DAILY BOX
import DailyBox from "@/class/daily_box/DailyBox.js";
import MovementDailyBox from "@/class/daily_box/Movement.js";

//SALING
import Customer from "@/class/saling/Customer.js";
import Sale from "@/class/saling/Sale.js";
import Charge from "@/class/saling/Charge.js";

//DASHBOARD
import Dashboard from "@/class/dashboard/Dashboard.js";

import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_RUTA_API;

Vue.use(VueSession, {
	persist: false,
});
Vue.use(Vuex);

export default new Vuex.Store({
	state: () => ({
		dictionary: {},
		errors: false,
		auth: true,
		loadingApp: true
	}),
	mutations: {
		SET_ERRORS(state, data) {
			state.errors = data;
		},
	},
	actions: {
		init: async function ({ dispatch }) {
			//if(!this.state.Auth.user?.id)
			//	await dispatch("Auth/get");
			await dispatch("Location/getLocations");
		},
	},
	getters: {},
	modules: {
		Auth,
		User,
		Enterprise,
		Access,
		Role,
		Branch,
		SalePoint,
		Location,
		Category,
		CategoryAttribute,
		Deposit,
		Product,
		MovementInventory,
		PriceList,
		Provider,
		Purchase,
		DailyBox,
		MovementDailyBox,
		Sale,
		Customer,
		Fund,
		FundMovement,
		Surcharge,
		SurchargeType,
		Expense,
		PaymentMethod,
		Payment,
		Charge,
		Dashboard
	},
});
