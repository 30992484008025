var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dialog-delete", {
        attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
        on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
      }),
      _c(
        "v-container",
        { staticClass: "mt-3 mb-10 flex-column" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "text-h3" }, [_vm._v("Mi Empresa")]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "2" } },
                    [
                      _c("v-card-title", [_vm._v(_vm._s(_vm.alias))]),
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.documentType) +
                            ": " +
                            _vm._s(_vm.document) +
                            " "
                        ),
                        _c("br"),
                        _vm._v(
                          " Condición IVA: " + _vm._s(_vm.vatCondition) + " "
                        ),
                        _c("br"),
                      ]),
                      _c("v-card-actions", { staticClass: "ma-3" }),
                    ],
                    1
                  ),
                ],
                1
              ),
              false
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { elevation: "2" } },
                        [
                          _c("v-card-title", [_vm._v("Suscripción")]),
                          _c("v-card-text", [
                            _vm._v(
                              " Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias sit exercitationem eligendi nemo illo ex, optio aliquam, dignissimos hic officiis, nulla eius error. Voluptatem fuga aliquid non maxime, quos nulla. "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "2" } },
                    [
                      _c("v-card-title", [_vm._v("Puntos de Venta")]),
                      _vm.hasAccess("LEC.ADM.PUN")
                        ? _c(
                            "v-card-text",
                            _vm._l(_vm.salePoints, function (salePoint) {
                              return _c(
                                "v-list",
                                { key: salePoint.id },
                                [
                                  _vm.hasAccess("ESC.ADM.PUN")
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            left: "",
                                            fab: "",
                                            "x-small": "",
                                            to: `sale-point/store/${salePoint.id}`,
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-pencil "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(salePoint.name) + " "),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _c("v-card-actions"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }