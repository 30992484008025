<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-container class="mt-5">
			<v-row justify="center">
				<v-col cols="12" sm="10" md="12" lg="12">
					<h3>{{ name }}</h3>
					<hr class="mt-4 mb-4" />
					<v-stepper v-model="e1">
						<v-stepper-header>
							<v-stepper-step :complete="e1 > 1" step="1">
								Tus Datos
							</v-stepper-step>
							<v-divider></v-divider>
							<v-stepper-step :complete="e1 > 2" step="2">
								Tu Empresa
							</v-stepper-step>
							<v-divider></v-divider>
							<v-stepper-step :complete="e1 > 3" step="3">
								Usuario
							</v-stepper-step>
							<v-divider></v-divider>
							<v-stepper-step step="4"> Confirmar Datos </v-stepper-step>
						</v-stepper-header>
						<v-stepper-items>
							<v-form @submit.prevent="submit">
								<v-stepper-content step="1">
									<v-container fluid>
										<v-row>
											<v-col lg="4">
												<v-text-field
													required
													v-model="user.entity.name"
													label="Nombres"
													:error-messages="nameErrors"
													@input="$v.user.entity.name.$touch()"
													@blur="$v.user.entity.name.$touch()"
												></v-text-field>
											</v-col>
											<v-col lg="4">
												<v-text-field
													required
													v-model="user.entity.surname"
													label="Apellidos"
													:error-messages="surnameErrors"
													@input="$v.user.entity.surname.$touch()"
													@blur="$v.user.entity.surname.$touch()"
												></v-text-field>
											</v-col>
											<v-col lg="4">
												<v-text-field
													required
													v-model="user.entity.document"
													label="Documento"
													:error-messages="documentErrors"
													:counter="8"
													@input="$v.user.entity.document.$touch()"
													@blur="$v.user.entity.document.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12">
												<h4>Datos de Dirección</h4>
											</v-col>
											<v-col lg="3">
												<v-select
													:items="provinces"
													item-text="description"
													item-value="id"
													v-model="province"
													label="Provincia"
													:error-messages="provinceErrors"
													@change="
														$v.province.$touch();
														changeCities();
													"
													@blur="$v.province.$touch()"
												></v-select>
											</v-col>
											<v-col lg="4">
												<v-combobox
													:items="cities"
													item-text="description"
													item-value="id"
													v-model="user.address.location_id"
													label="Localidad"
													:error-messages="location_idUserErrors"
													@change="$v.user.address.location_id.$touch()"
													@blur="$v.user.address.location_id.$touch()"
												></v-combobox>
											</v-col>
											<v-col lg="3">
												<v-text-field
													v-model="user.address.street"
													label="Calle"
													:error-messages="streetUserErrors"
													@input="$v.user.address.street.$touch()"
													@blur="$v.user.address.street.$touch()"
												></v-text-field>
											</v-col>
											<v-col lg="2">
												<v-text-field
													v-model="user.address.number"
													label="Número"
													:error-messages="numberUserErrors"
													@input="$v.user.address.number.$touch()"
													@blur="$v.user.address.number.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12"><h4>Datos de contacto</h4> </v-col>
											<v-col cols="5">
												<v-text-field
													label="Correo Electrónico"
													v-model="user.contact.email"
													:error-messages="emailUserErrors"
													@input="$v.user.contact.email.$touch()"
													@blur="$v.user.contact.email.$touch()"
												></v-text-field>
											</v-col>
											<v-col cols="3">
												<v-text-field
													v-model="user.contact.phone"
													label="Teléfono / Celular"
													:error-messages="phoneUserErrors"
													@input="$v.user.contact.phone.$touch()"
													@blur="$v.user.contact.phone.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
									</v-container>
									<v-btn color="primary" @click="next(2)"> Continuar </v-btn>
									<v-btn class="ml-2" text @click="clear"> Limpiar </v-btn>
									<v-btn text to="Login"> Cancelar </v-btn>
								</v-stepper-content>
								<v-stepper-content step="2">
									<v-container fluid>
										<v-row>
											<v-col lg="4">
												<v-text-field
													label="Empresa"
													v-model="enterprise.entity.alias"
													hint="Ingresa el nombre de su Empresa/Marca"
													:error-messages="aliasErrors"
													@input="$v.enterprise.entity.alias.$touch()"
													@blur="$v.enterprise.entity.alias.$touch()"
												></v-text-field>
											</v-col>
											<v-col lg="4">
												<v-text-field
													v-model="enterprise.entity.document"
													label="CUIT"
													:counter="11"
													:error-messages="documentEnterpriseErrors"
													@input="$v.enterprise.entity.document.$touch()"
													@blur="$v.enterprise.entity.document.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12">
												<h4>Datos de Dirección</h4>
												<small>Definirá la sucursal principal</small>
											</v-col>
											<v-col lg="3">
												<v-select
													:items="provinces"
													item-text="description"
													item-value="id"
													v-model="province"
													label="Provincia"
													:error-messages="provinceErrors"
													@change="
														$v.province.$touch();
														changeCities();
													"
													@blur="$v.province.$touch()"
												></v-select>
											</v-col>
											<v-col lg="4">
												<v-combobox
													:items="cities"
													item-text="description"
													item-value="id"
													v-model="enterprise.address.location_id"
													label="Localidad"
													:error-messages="location_idEnterpriseErrors"
													@change="$v.enterprise.address.location_id.$touch()"
													@blur="$v.enterprise.address.location_id.$touch()"
												></v-combobox>
											</v-col>
											<v-col lg="3">
												<v-text-field
													v-model="enterprise.address.street"
													label="Calle"
													:error-messages="streetEnterpriseErrors"
													@input="$v.enterprise.address.street.$touch()"
													@blur="$v.enterprise.address.street.$touch()"
												></v-text-field>
											</v-col>
											<v-col lg="2">
												<v-text-field
													v-model="enterprise.address.number"
													label="Número"
													:error-messages="numberEnterpriseErrors"
													@input="$v.enterprise.address.number.$touch()"
													@blur="$v.enterprise.address.number.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12"><h4>Datos de contacto</h4> </v-col>
											<v-col cols="5">
												<v-text-field
													label="Correo Electrónico"
													v-model="enterprise.contact.email"
													:error-messages="emailEnterpriseErrors"
													@input="$v.enterprise.contact.email.$touch()"
													@blur="$v.enterprise.contact.email.$touch()"
												></v-text-field>
											</v-col>
											<v-col cols="3">
												<v-text-field
													v-model="enterprise.contact.phone"
													label="Teléfono / Celular"
													:error-messages="phoneEnterpriseErrors"
													@input="$v.enterprise.contact.phone.$touch()"
													@blur="$v.enterprise.contact.phone.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
									</v-container>
									<v-btn color="primary" @click="next(3)"> Continuar </v-btn>
									<v-btn class="ml-2" text @click="clear"> Limpiar </v-btn>
									<v-btn @click="e1 = 1" text> Atras </v-btn>
								</v-stepper-content>
								<v-stepper-content step="3">
									<v-container fluid>
										<v-row>
											<v-col cols="4">
												<v-text-field
													required
													v-model="user.name"
													label="Usuario"
													:error-messages="userErrors"
													@input="$v.user.name.$touch()"
													@blur="$v.user.name.$touch()"
												></v-text-field>
											</v-col>
											<v-col cols="4">
												<v-text-field
													required
													type="password"
													v-model="user.password"
													label="Contraseña"
													:error-messages="passwordErrors"
													@input="$v.user.password.$touch()"
													@blur="$v.user.password.$touch()"
												></v-text-field>
											</v-col>
											<v-col cols="4">
												<v-text-field
													required
													v-model="user.confirmated"
													type="password"
													label="Confirmación de Contraseña"
													:error-messages="confirmatePasswordErrors"
													@input="$v.user.confirmated.$touch()"
													@blur="$v.user.confirmated.$touch()"
												></v-text-field>
											</v-col>
										</v-row>
									</v-container>
									<v-btn color="primary" @click="next(4)"> Continuar </v-btn>
									<v-btn text @click="e1 = 1"> Volver </v-btn>
								</v-stepper-content>
								<v-stepper-content step="4">
									<v-container>
										<v-row>
											<v-col cols="12">
												<h1>¿Está todo bien?</h1>
												<h3 class="mt-2">Verifique sus datos</h3>
											</v-col>
										</v-row>
										<v-row>
											<v-col cols="12">
												<v-list-item two-line>
													<v-list-item-content>
														<v-list-item-title
															>Datos de Usuario</v-list-item-title
														>
														<v-list-item-subtitle>
															<ul>
																<li>Usuario: {{ user.name }}</li>
																<li>Correo Electrónico: {{ user.email }}</li>
																<li>
																	Nombre y Apellido:
																	{{
																		user.entity.name + " " + user.entity.surname
																	}}
																</li>
																<li>DNI: {{ user.entity.document }}</li>
															</ul>
														</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
												<v-list-item two-line>
													<v-list-item-content>
														<v-list-item-title
															>Datos de enterprise</v-list-item-title
														>
														<v-list-item-subtitle>
															<ul>
																<li>{{ enterprise.entity.alias }}</li>
																<li>CUIT: {{ enterprise.entity.document }}</li>
															</ul>
														</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
												<v-list-item two-line>
													<v-list-item-content>
														<v-list-item-title
															>Datos de Dirección y contact</v-list-item-title
														>
														<v-list-item-subtitle>
															<ul>
																<li>
																	Dirección:
																	{{
																		enterprise.address.street +
																		" " +
																		enterprise.address.number
																	}}
																</li>
																<li>
																	Teléfono:
																	{{ enterprise.contact.phone }}
																</li>
																<li>
																	Correo Electrónico:
																	{{ enterprise.contact.email }}
																</li>
															</ul>
														</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
											</v-col>
										</v-row>
									</v-container>
									<v-btn color="primary" type="submit"> Registrar </v-btn>
								</v-stepper-content>
							</v-form>
						</v-stepper-items>
					</v-stepper>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
	required,
	minLength,
	email,
	alphaNum,
	sameAs,
	integer,
} from "vuelidate/lib/validators";
import Toast from "../../components/toast.vue";

export default {
	components: { Toast },
	validations: {
		user: {
			entity: {
				name: { required },
				surname: { required },
				document: { required, minLength: minLength(8) },
			},
			name: { required, minLength: minLength(5), alphaNum },
			password: { required, minLength: minLength(8) },
			confirmated: { required, sameAsPassword: sameAs("password") },
			address: {
				street: { required },
				number: { required, integer },
				location_id: { required },
			},
			contact: {
				email: { required, email },
				phone: { required, integer },
			},
		},
		enterprise: {
			entity: {
				alias: { required },
				document: { required, integer, minLength: minLength(11) },
			},
			address: {
				street: { required },
				number: { required, integer },
				location_id: { required },
			},
			contact: {
				email: { required, email },
				phone: { required, integer },
			},
		},
		province: { required },
	},
	data: () => ({
		user: {
			entity: {
				name: "Osvaldo",
				surname: "Vallone",
				document: "39720155",
			},
			name: "osvaldo",
			password: "12345678",
			confirmated: "12345678",
			address: {
				street: "Saavedra",
				number: "4935",
				location_id: "",
			},
			contact: {
				email: "osvaldovallone@outlook.com",
				phone: "3704825012",
			},
		},
		enterprise: {
			entity: {
				alias: "Lencerp",
				document: "20397201555",
			},
			address: {
				street: "Saavedra",
				number: "4935",
				location_id: "",
			},
			contact: {
				email: "osvaldovallone@lencersoftware.com",
				phone: "3704825012",
			},
		},
		province: "",
		provinces: [],
		cities: [],
		name: "Registro",
		e1: 1,
	}),
	created() {
		this.provinces = this.getProvinces;
		this.province = this.getFirstProvince.id;
		this.cities = this.getCities(this.province);
		this.enterprise.address.location_id = this.cities[0];
		this.user.address.location_id = this.cities[0];
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.Auth.errors;
			},
			set(value) {
				this.$store.commit("Auth/SET_ERRORS", value);
			},
		},

		...mapGetters("Location", [
			"getProvinces",
			"getCities",
			"getFirstProvince",
		]),

		nameErrors() {
			const errors = [];
			if (!this.$v.user.entity.name.$dirty) return errors;
			!this.$v.user.entity.name.required &&
				errors.push("El nombre es requerido");
			return errors;
		},
		surnameErrors() {
			const errors = [];
			if (!this.$v.user.entity.surname.$dirty) return errors;
			!this.$v.user.entity.surname.required &&
				errors.push("El apellido es requerido");
			return errors;
		},
		documentErrors() {
			const errors = [];
			if (!this.$v.user.entity.document.$dirty) return errors;
			!this.$v.user.entity.document.required &&
				errors.push("El documento es requerido");
			!this.$v.user.entity.document.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			return errors;
		},
		userErrors() {
			const errors = [];
			if (!this.$v.user.name.$dirty) return errors;
			!this.$v.user.name.required && errors.push("El usuario es requerido");
			!this.$v.user.name.alphaNum &&
				errors.push("Solo debe contener números y letras");
			!this.$v.user.name.minLength &&
				errors.push("Debe tener mínimo 5 caracteres");
			return errors;
		},
		emailErrors() {
			const errors = [];
			if (!this.$v.user.email.$dirty) return errors;
			!this.$v.user.email.required && errors.push("El correo es requerido");
			!this.$v.user.email.email && errors.push("El formato es incorrecto");
			return errors;
		},
		passwordErrors() {
			const errors = [];
			if (!this.$v.user.password.$dirty) return errors;
			!this.$v.user.password.required &&
				errors.push("La contraseña es requerida");
			!this.$v.user.password.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			return errors;
		},
		confirmatePasswordErrors() {
			const errors = [];
			if (!this.$v.user.confirmated.$dirty) return errors;
			!this.$v.user.confirmated.required &&
				errors.push("Debe confirmar la contraseña");
			!this.$v.user.confirmated.sameAsPassword &&
				errors.push("Las contraseñas no coinciden");
			return errors;
		},
		aliasErrors() {
			const errors = [];
			if (!this.$v.enterprise.entity.alias.$dirty) return errors;
			!this.$v.enterprise.entity.alias.required &&
				errors.push("El alias es requerido");
			return errors;
		},
		documentEnterpriseErrors() {
			const errors = [];
			if (!this.$v.enterprise.entity.document.$dirty) return errors;
			!this.$v.enterprise.entity.document.required &&
				errors.push("El documento es requerido");
			!this.$v.enterprise.entity.document.minLength &&
				errors.push("Deber tener un minimo de 11 números");
			!this.$v.enterprise.entity.document.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		provinceErrors() {
			const errors = [];
			if (!this.$v.province.$dirty) return errors;
			!this.$v.province.required && errors.push("La provincia es requerida");
			return errors;
		},
		location_idEnterpriseErrors() {
			const errors = [];
			if (!this.$v.enterprise.address.location_id.$dirty) return errors;
			!this.$v.enterprise.address.location_id.required &&
				errors.push("La localidad es requerida");
			return errors;
		},
		streetEnterpriseErrors() {
			const errors = [];
			if (!this.$v.enterprise.address.street.$dirty) return errors;
			!this.$v.enterprise.address.street.required &&
				errors.push("La calle es requerida");
			return errors;
		},
		numberEnterpriseErrors() {
			const errors = [];
			if (!this.$v.enterprise.address.number.$dirty) return errors;
			!this.$v.enterprise.address.number.required &&
				errors.push("El número es requerido");
			!this.$v.enterprise.address.number.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		emailEnterpriseErrors() {
			const errors = [];
			if (!this.$v.enterprise.contact.email.$dirty) return errors;
			!this.$v.enterprise.contact.email.required &&
				errors.push("El correo es requerido");
			!this.$v.enterprise.contact.email.email &&
				errors.push("El formato es incorrecto");
			return errors;
		},
		phoneEnterpriseErrors() {
			const errors = [];
			if (!this.$v.enterprise.contact.phone.$dirty) return errors;
			!this.$v.enterprise.contact.phone.required &&
				errors.push("El telefono es requerido");
			!this.$v.enterprise.contact.phone.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		location_idUserErrors() {
			const errors = [];
			if (!this.$v.user.address.location_id.$dirty) return errors;
			!this.$v.user.address.location_id.required &&
				errors.push("La localidad es requerida");
			return errors;
		},
		streetUserErrors() {
			const errors = [];
			if (!this.$v.user.address.street.$dirty) return errors;
			!this.$v.user.address.street.required &&
				errors.push("La calle es requerida");
			return errors;
		},
		numberUserErrors() {
			const errors = [];
			if (!this.$v.user.address.number.$dirty) return errors;
			!this.$v.user.address.number.required &&
				errors.push("El número es requerido");
			!this.$v.user.address.number.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		emailUserErrors() {
			const errors = [];
			if (!this.$v.user.contact.email.$dirty) return errors;
			!this.$v.user.contact.email.required &&
				errors.push("El correo es requerido");
			!this.$v.user.contact.email.email &&
				errors.push("El formato es incorrecto");
			return errors;
		},
		phoneUserErrors() {
			const errors = [];
			if (!this.$v.user.contact.phone.$dirty) return errors;
			!this.$v.user.contact.phone.required &&
				errors.push("El telefono es requerido");
			!this.$v.user.contact.phone.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
	},
	methods: {
		...mapActions("Auth", ["register"]),

		changeCities() {
			this.cities = this.getCities(this.province);
			this.enterprise.address.location_id = this.cities[0];
			this.user.address.location_id = this.cities[0];
		},

		next(step) {
			if (step == 2) {
				!this.$v.user.$touch();
				if (
					!this.$v.user.entity.$invalid &&
					!this.$v.user.address.$invalid &&
					!this.$v.user.contact.$invalid
				)
					this.e1 = step;
			} else if (step == 3) {
				!this.$v.enterprise.$touch();
				if (!this.$v.enterprise.$invalid) this.e1 = step;
			} else if (step == 4) {
				!this.$v.user.$touch();
				if (!this.$v.user.$invalid) this.e1 = step;
			}
		},

		clear() {
			this.$v.$reset();
			this.user.entity.name = "";
		},

		beforeSave() {
			//this.formLoad = true;
		},

		successSave: async function () {
			await this.$store.dispatch("Auth/login", {
				credentials: {
					name: this.user.name,
					password: this.user.password,
				},
				session: this.$session,
			});
			await this.$store.dispatch("Auth/get");
			await this.$store.dispatch("Auth/sendEmailForVerifyAccount");
			this.$router.push("/");
		},

		error: async function () {
			this.changeCities();
			this.e1 = 1;
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		submit: async function () {
			this.enterprise.address.location_id =
				this.enterprise.address.location_id.id;
			this.user.address.location_id = this.user.address.location_id.id;
			await this.register({
				enterprise: this.enterprise,
				user: this.user,
				success: () => {
					this.successSave();
				},
				error: () => {
					this.error();
				},
			});
		},
	},
};
</script>
