<template>
	<v-dialog
		hide-overlay
		width="400"
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="700px"
	>
		<v-card>
			<v-card-title class="text-h5 grey lighten-2">
				{{ title }}
			</v-card-title>

			<v-card-text class="pa-2 pl-5">
				{{ subtitle }}
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="openCloseBox()">
					{{ textButton }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	data() {
		return {
			dialog: true,
			boxId: 0,
			openClose: "open",
		};
	},
	computed: {
		title() {
			if (this.boxId > 0 && this.openClose == "open") return "Cerrar Caja";
			if (this.boxId > 0 && this.openClose == "close") return "Reabrir Caja";
			return "Abrir Nueva Caja";
		},
		subtitle() {
			if (this.boxId > 0 && this.openClose == "open")
				return "Se cerrará la caja para el dia de hoy";
			if (this.boxId > 0 && this.openClose == "close")
				return "Se intentará abrir nuevamente esta si la fecha de la caja es la fecha actual";
			return "Se intentará crear una nueva caja para hoy si no existe";
		},
		textButton() {
			if (this.boxId > 0 && this.openClose == "open") return "Cerrar";
			if (this.boxId > 0 && this.openClose == "close") return "Reabrir";
			return "Abrir";
		},
	},
	created() {},
	methods: {
		openCloseBox() {
			this.$swal({
				position: "top-end",
				icon: "success",
				title: "Your work has been saved",
				showConfirmButton: false,
				timer: 1500,
			});
		},
	},
};
</script>
