<template>
  <v-container>
		<v-dialog
      v-model="storeProductDialog"
      persistent
      max-width="600px"
    >
			<v-card>
        <v-card-title>
          <span class="text-h5">Nuevo Producto</span>
        </v-card-title>
        <v-card-text>
						<ProductForm
							:product="productToAdd"
							@close-form="storeProductDialog = false"
							@add-product="productAdded"
						>
						</ProductForm>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
      v-model="listProductsDialog"
      max-width="600px"
    >
			<v-card v-if="!withButtonToAddProductCount">
        <v-card-title>
          <span class="text-h5">Listado de Productos Cargados</span>
        </v-card-title>
        <v-card-text>
					<h3 v-if="productsCharged.filter(element => element.count > 0).length == 0" class="text-h6 ma-6">No hay productos cargados.</h3>
					<v-list-item v-else two-line class="elevation-1 ma-2" v-for="item in productsCharged.filter(element => element.count > 0)" :key="item.id">
						<v-list-item-content>
							<v-list-item-title>{{ getCategory(item.category.id)?.name }} {{ item.attributes_name }} {{ item.name }}</v-list-item-title>
							<v-list-item-subtitle>{{ getAttributesCountNamedByItem(item) }}</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<span class="text-h5 green--text">
								{{ formatCurrency(item.unit_price * item.count) }}
							</span>
							<span class="text-subtitle-1">
								<span class="blue-grey--text">{{ formatCurrency(item.unit_price) }}</span> x <strong>{{ item.count }}</strong>
							</span>
						</v-list-item-action>
					</v-list-item>
					<v-divider class="ma-4"></v-divider>
					<span class="text-subtitle-1">Cantidad Total: <strong class="blue-grey--text mb-2 text-h6">{{ getTotalCountProductCharged }}</strong></span><br>
					<span class="text-subtitle-1">Monto Total: <strong class="green--text text-h6">{{ formatCurrency(getTotalAmountProductCharged) }}</strong></span>
				</v-card-text>
				<v-card-actions>
					<v-btn
            color="blue darken-1"
            text
            @click="listProductsDialog = false"
          >
            Cerrar
          </v-btn>
				</v-card-actions>
			</v-card>
			<v-card v-else>
        <v-card-title>
          <span class="text-h5">Listado de productos a ajustar</span>
        </v-card-title>
        <v-card-text>
					<div>
						<span class="text-h6">Productos a agregar</span>
						<h3 v-if="getProductsChargedToAddStock.length == 0" class="text-h6 ma-6">No hay productos cargados.</h3>
						<v-list-item v-else two-line class="elevation-1 ma-2" v-for="item in getProductsChargedToAddStock" :key="item.id">
							<v-list-item-content>
								<v-list-item-title>{{ getCategory(item.category.id)?.name }} {{ item.attributes_name }} {{ item.name }}</v-list-item-title>
								<v-list-item-subtitle>{{ getAttributesCountNamedByItem(item) }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<span class="text-h4 green--text">
									<strong>{{ getAttributesCountTotal(item) }}</strong>
								</span>
							</v-list-item-action>
						</v-list-item>
						<span class="text-subtitle-1">Cantidad Total: <strong class="green--text mb-2 text-h6">{{ getTotalCountProductChargedToAddStock }}</strong></span><br>
						<v-divider class="ma-4"></v-divider>
					</div>
					<div>
						<span class="text-h6">Productos a descontar</span>
						<h3 v-if="getProductsChargedToSubStock.length == 0" class="text-h6 ma-6">No hay productos cargados.</h3>
						<v-list-item v-else two-line class="elevation-1 ma-2" v-for="item in getProductsChargedToSubStock" :key="item.id">
							<v-list-item-content>
								<v-list-item-title>{{ getCategory(item.category.id)?.name }} {{ item.attributes_name }} {{ item.name }}</v-list-item-title>
								<v-list-item-subtitle>{{ getAttributesCountNamedByItem(item, false) }}</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<span class="text-h4 red--text">
									<strong>{{ getAttributesCountTotal(item, false) }}</strong>
								</span>
							</v-list-item-action>
						</v-list-item>
						<span class="text-subtitle-1">Cantidad Total: <strong class="red--text mb-2 text-h6">{{ getTotalCountProductChargedToSubStock }}</strong></span><br>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn
            color="blue darken-1"
            text
            @click="listProductsDialog = false"
          >
            Cerrar
          </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <v-data-iterator
      :items="productsCharged"
			no-data-text="No se encontraron datos, aún..."
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
			class="mb-5"
    >
      <template v-slot:header>
					<v-container>
						<v-row v-if="!provider">
							<v-col cols="12" lg="9" md="9" sm="12">
								<v-combobox
									:items="getProductsToLoad"
									clearable
									filled
									dense
									:filter="productToLoadFilter"
									:item-text="productItemText"
									item-value="id"
									persistent-hint
									:loading="loading.product"
									v-model="cart.product"
									label="Productos"
								>
									<template v-slot:append-outer>
										<v-chip v-if="cart.product?.id" style="margin-top: -5px;" :color="getStock <= getMinStock ? 'error' : 'success'">
											{{ getStock }}
										</v-chip>
									</template>
									<template v-slot:item="{ item }">
										<v-list-item>
											<div class="pa-2">
											<p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ getCategory(item.category_id)?.name }} {{ item.name }} {{ item.attributes_name }}</p>
											<!-- <p class="text-body-2 font-weight-light text-capitalize ma-0">{{ item.attributes_name }}</p> -->
										</div>
										</v-list-item>
										<v-list-item-action class="float-right">
											<v-chip :color="item.stock.count <= getMinStock ? 'error' : 'success'">
												{{ item.stock.count }}
											</v-chip>
										</v-list-item-action>
									</template>
								</v-combobox>
							</v-col>
							<v-col cols="6" lg="2" md="2" sm="4">
								<v-text-field
									v-if="withPrices"
									v-model="cart.price"
									clearable
									filled
									dense
									label="Precio"
								></v-text-field>
							</v-col>
							<v-col cols="2" lg="1" md="1" sm="2">
								<v-btn
									class="mx-2 mt-3"
									fab
									small
									dark
									color="primary"
									@click="addProduct()"
								>
									<v-icon dark> mdi-plus </v-icon>
								</v-btn>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" lg="4">
								<v-text-field
									v-model="search"
									flat
									outlined
									dense
									elevation="1"
									hide-details
									prepend-inner-icon="mdi-magnify"
									label="Buscar"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="4">
							<v-select
									v-model="sortBy"
									flat
									elevation="1"
									outlined
									hide-details
									:items="custom"
									item-value="id"
									item-text="desc"
									dense
									prepend-inner-icon="mdi-magnify"
									label="Ordenar por..."
								></v-select>
							</v-col>
							<v-col cols="12" lg="4">
								<v-btn-toggle
									v-model="sortDesc"
									small
									mandatory
								>
									<v-btn
										depressed
										small
										color="secondary"
										:value="false"
									>
										<v-icon color="white">mdi-arrow-up</v-icon>
									</v-btn>
									<v-btn
										small
										depressed
										color="secondary"
										:value="true"
									>
										<v-icon color="white">mdi-arrow-down</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-container>
					<v-container>
						<v-btn
						v-if="withNewProductAction"
						color="primary"
						text
						@click="openModalNewProduct()"
					>
						<v-icon  class="mr-1">
							mdi-plus
						</v-icon>
						Nuevo Producto
					</v-btn>
					<v-btn
						color="primary"
						class="ml-3"
						text
						@click="listProductsDialog = true"
					>
						<v-icon class="mr-1">
							mdi-table
						</v-icon>
						Ver Detalle
					</v-btn>
					</v-container>
      </template>

      <template v-slot:default="props" >
				<v-container class="pt-6">
					<div class="mb-4 mt-1">
						<v-row v-for="item in props.items" :key="item.id">
							<v-container fluid class="rounded-sm mb-6" elevation-2>
								<v-row class="rounded-t-lg"  justify="space-between">
									<v-col cols="6" lg="7" md="7" sm="9" class="pa-0 ma-0">
										<div class="pa-2">
											<v-chip
												color="primary"
												x-small
												v-if="item.new"
											>
												<v-icon x-small mr-2>mdi-star</v-icon> Nuevo
											</v-chip>
											<p class="text-body-2 text-capitalize ma-0">{{ getCategory(item.category.id)?.name }} {{ item.attributes_name }} {{ item.name }}</p>
										</div>
									</v-col>
									<v-col v-if="item.attribute_detail[0].attributes.length == 0" cols="2" lg="2" md="2" sm="3" class="pa-0 ma-0 text-center">
										<v-text-field
												outlined
												value="0"
												@input="editProduct(item)"
												v-model="item.attribute_detail[0].count"
												hide-details="auto"
												class="mr-2 mt-3"
												dense
												:color="getCountColor(item.attribute_detail[0].count)"
												:suffix="withButtonToAddProductCount ? item.attribute_detail[0].current.toString() : formatCurrency(item.unit_price * (parseInt(item.attribute_detail[0].count) ? item.attribute_detail[0].count : 0))"
												:append-outer-icon="withButtonToAddProductCount ? 'mdi-plus-circle-outline' : ''"
												:prepend-icon="withButtonToAddProductCount && item.attribute_detail[0].current > 0 ? 'mdi-minus-circle-outline' : ''"
												@click:append-outer="addProductStock(item.attribute_detail[0], item)"
												@click:prepend="subProductStock(item.attribute_detail[0], item)"
											></v-text-field>
									</v-col>
									<v-col cols="4" lg="3" md="3" sm="3" class="pa-0 ma-0 mt-2">
										<v-text-field
												v-if="withPrices"
												class="text-h6 mr-2"
												prefix="$"
												hide-details="auto"
												dense
												@input="editProduct(item)"
												v-model="item.unit_price"
											>
											<template v-slot:append>
												<v-chip
													v-if="getTotalCountByItem(item.id) > 0"
													medium
													class="pa-0 pl-2 pr-2 mb-1 mt-0"
													color="success"
												>
													Total: {{ formatCurrency(getTotalAmountByProduct(item))  }}
												</v-chip>
												<v-chip
													v-else
													medium
													class="pa-0 pl-2 pr-2 mb-1 mt-0"
												>
													Total: {{ formatCurrency(getTotalAmountByProduct(item))  }}
												</v-chip>
											</template>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" class="pa-0 ma-0 mt-4">
										<v-container fluid class="pa-0 ma-0">
											<v-row v-if="item.attribute_detail[0].attributes.length > 0">
												<v-col class="pa-0 ma-0 mt-2" cols="12" lg="12" v-for="stock in item.attribute_detail" :key="stock.id">
													<v-container>
														<v-row>
															<v-col cols="2" lg="1" md="1" sm="2" class="pa-0 ma-0 text-center">
																<v-btn
																	x-small
																	color="red"
																	elevation="0"
																	dark
																	fab
																	@click="deleteStock(item.id, stock.id)"
																>
																	<v-icon x-small>
																		mdi-delete
																	</v-icon>
																</v-btn>
															</v-col>
															<v-col cols="10" lg="3" md="4" sm="6" class="pa-0 ma-0" v-for="attrNon in stock.attributes" :key="attrNon.id">
																<v-select
																	v-if="getCategoryAttribute(attrNon.id)?.values.length > 0"
																	:items="getCategoryAttribute(attrNon.id)?.values ?? []"
																	item-text="value"
																	item-value="id"
																	hide-details="auto"
																	v-model="attrNon.value"
																	return-object
																	:label="attrNon.name"
																	outlined
																	dense
																	@change="editProduct(item)"
																></v-select>
																<v-text-field
																	v-else
																	outlined
																	:label="attrNon.name"
																	@input="editProduct(item)"
																	v-model="attrNon.value"
																	hide-details="auto"
																	dense
																></v-text-field>
															</v-col>
															<v-col cols="6" lg="2" md="2" sm="3" class="pa-0 ma-0 text-center">
																<v-text-field
																		outlined
																		value="0"
																		@input="editProduct(item)"
																		v-model="stock.count"
																		hide-details="auto"
																		:color="getCountColor(stock.count)"
																		:class="{'ml-2': withButtonToAddProductCount}"
																		dense
																		:suffix="withButtonToAddProductCount ? stock.current.toString() : formatCurrency(item.unit_price * (parseInt(stock.count) ? stock.count : 0))"
																		:append-outer-icon="withButtonToAddProductCount ? 'mdi-plus-circle-outline' : ''"
																		:prepend-icon="withButtonToAddProductCount && stock.current > 0 ? 'mdi-minus-circle-outline' : ''"
																		@click:append-outer="addProductStock(stock, item)"
																		@click:prepend="subProductStock(stock, item)"
																	></v-text-field>
															</v-col>
														</v-row>
													</v-container>
												</v-col>
											</v-row>
											<v-row>
												<v-col class="pa-0 ma-0 text-center mt-3" cols="12" lg="12">
													<v-btn
														x-small
														color="accent"
														class="py-2"
														dark
														rounded
														v-if="item.attribute_detail[0].attributes.length > 0"
														@click="addStock(item)"
													>
														<v-icon x-small>
															mdi-plus
														</v-icon>
														Agregar
													</v-btn>
													<v-btn
														v-if="withDuplicateAction"
														x-small
														color="accent"
														class="py-2 ml-2"
														dark
														rounded
														@click="duplicateProduct(item)"
													>
														<v-icon x-small>
															mdi-content-duplicate
														</v-icon>
														Duplicar
													</v-btn>
													<v-btn
														v-if="! item.product_id || withButtonToDiscardProducts"
														x-small
														color="danger"
														class="py-2 ml-2"
														dark
														rounded
														@click="discardProduct(item.id)"
													>
														<v-icon x-small>
															mdi-delete
														</v-icon>
														Descartar
													</v-btn>
												</v-col>
											</v-row>
										</v-container>
									</v-col>
								</v-row>
							</v-container>
						</v-row>
					</div>
				</v-container>
      </template>

      <template v-slot:footer>
        <v-row
          class="mt-4"
          align="center"
          justify="center"
        >
          <span class="grey--text">Cantidad por página</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="secondary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class="mr-4 grey--text"
          >
            Página {{ page }} de {{ numberOfPages }}
          </span>
          <v-btn
            fab
						color="secondary"
						outlined
						x-small
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
						color="secondary"
						outlined
            class="ml-1 mr-3"
						x-small
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>

import { mapGetters, mapState } from 'vuex';
import ProductForm from '@/components/inventory/store-product';

export default {
	props: {
		carts: {
			default: null
		},
		provider: {
			default: null
		},
		withPrices: {
			default: true
		},
		withNewProductAction: {
			default: true
		},
		withDuplicateAction: {
			default: true
		},
		withStock: {
			default: true
		},
		enableProductCountZero: {
			default: false
		},
		withProductCountZero: {
			default: false
		},
		withButtonToAddProductCount: {
			default: false
		},
		withButtonToDiscardProducts: {
			default: false
		}
	},
	components: { ProductForm },
	data () {
		return {
			itemsPerPageArray: [10, 20, 50],
			search: '',
			filter: {},
			sortDesc: false,
			page: 1,
			itemsPerPage: 10,
			loading: {
				product: true
			},
			storeProductDialog: false,
			listProductsDialog: false,
			sortBy: 'name',
			custom: [
				{
					id: 'name',
					desc: 'Nombre'
				},
				{
					id: 'unit_price',
					desc: 'Precio'
				},
				{
					id: 'count',
					desc: 'Cantidad'
				}
			],
			headers: [
				'Producto',
				'Precio',
				'Cantidad',
				'Total'
			],
			product: {},
			productToAdd: {
				description: "",
        category: null,
        attributesValues: [],
        attributesName: ''
			},
			productsCharged: [],
			cart: {
				product: {
					id: null,
					name: null,
					full_name: null
				},
				count: 1,
				price: null,
			},
		}
	},
	created: async function(){

		if(this.$store.state.Product.products.length == 0)
			await this.$store.dispatch('Product/get');

		this.loading.product = false;

		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');

		if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');

		if(this.provider){
			this.productsCharged = this.getProductsByProvider(this.provider);
			this.formatProductsCharged();
		}

		if(this.carts) {
			this.formatProductsFromPurchase();
		}

	},
	watch: {
		provider(){
			this.productsCharged = this.getProductsByProvider(this.provider);
			this.formatProductsCharged();
		},
		carts(){
			this.formatProductsFromPurchase();
		}
	},
	computed: {
		...mapGetters('Product', ['getProduct', 'getProducts', 'getProductsByProvider', 'getProductsWithStock', 'getProductStock']),
		...mapGetters('Category', ['getCategory']),
		...mapGetters('CategoryAttribute', ['getCategoryAttribute']),
		...mapGetters({
			getStoreStock: 'Product/getStock'
		}),

		...mapState('Product', ['products']),

		numberOfPages () {
			return Math.ceil(this.productsCharged.length / this.itemsPerPage)
		},

		getTotalCountProductCharged() {
			let count = 0;

			this.productsCharged.forEach(element => {
				count += parseInt(element.count)
			});

			return count;
		},

		getTotalAmountProductCharged() {
			let totalAmount = 0;

			this.productsCharged.forEach(element => {
				totalAmount += parseInt(element.unit_price * element.count)
			});

			return totalAmount;
		},

		getProductsToLoad(){
			if(this.withStock)
      	return this.getProductsWithStock
			return this.getProducts;
    },

		getStock(){
      if(this.cart.product?.id)
				return this.cart.product.stock.count;
			return 0;
    },

    getMinStock(){
			if(this.cart.product?.id)
				return this.cart.product.stock.min;
			return 0;
		},

		getProductsChargedToAddStock(){
			let products = [];

			this.productsCharged.filter(element => {

				let isThereAnProduct = false;
				element.attribute_detail.forEach(element2 => {
					if(element2.count > 0){
						isThereAnProduct = true;
					}
				})

				if(isThereAnProduct)
					products.push(element);
			});
			return products;
		},

		getProductsChargedToSubStock(){
			let products = [];

			this.productsCharged.filter(element => {

				let isThereAnProduct = false;
				element.attribute_detail.forEach(element2 => {
					if(element2.count < 0){
						isThereAnProduct = true;
					}
				})

				if(isThereAnProduct)
					products.push(element);
			});
			return products;
		},

		getTotalCountProductChargedToAddStock(){
			let count = 0;

			this.getProductsChargedToAddStock.forEach(element => {
				element.attribute_detail.forEach(element2 => {
					if(element2.count > 0){
						count += element2.count;
					}
				})
			});

			return count;
		},

		getTotalCountProductChargedToSubStock(){
			let count = 0;

			this.getProductsChargedToSubStock.forEach(element => {
				element.attribute_detail.forEach(element2 => {
					if(element2.count < 0){
						count += Math.abs(element2.count);
					}
				})
			});

			return count;
		}

	},
	methods: {

		getCountColor(count){
			if(count > 0){
				return 'green'
			}else if(count < 0){
				return 'red'
			}
			return '';
		},

		productItemText(item){
      if(!item?.id){
        return ''
      }
      let categoryName = this.getCategory(item.category_id)?.name;
      return `${categoryName} ${item.full_name}`
    },

		productToLoadFilter(item, queryText, itemText) {
			const searchText = queryText.toLowerCase();
			const searchWords = searchText.split(" ");
			const itemValues = [item.name, this.getCategory(item.id)?.name ?? '', item.attributes_name ?? ''];
			return searchWords.every(word => {
				return itemValues.some(value => value.toLowerCase().includes(word));
			});
		},

		formatProductsFromPurchase(){
			this.carts.forEach(element => {

				let indice = this.productsCharged.findIndex(item => item.product_id == element.product_id);

				if(indice < 0){
					return;
				}

				this.productsCharged[indice].attribute_detail = [];

				element.attribute_detail.forEach(element2 => {

					let attributes = [];

					let attributesValues = element2.attribute_values;

						attributesValues.forEach(element3 => {
							attributes.push({
								id: element3.id,
								name: element3.name,
								value: element3.value
							})
						})

						this.productsCharged[indice].attribute_detail.push({
							id: element2.id,
							attributes: attributes,
							count: element2.count
						});

				});


				this.editProduct(this.productsCharged[indice])

			});
		},

		productAdded(product) {
			this.productsCharged.push(this.newProductCustom(this.productsCharged.length + 1, product, true));
			this.storeProductDialog = false;
			this.sweetAlertCustom('Producto agregado', 'info')
		},

		openModalNewProduct(){
			let productToAdd = {
				description: '',
				category: null,
				attributesValues: [],
				attributesName: ''
      }
			this.productToAdd = productToAdd;
			this.storeProductDialog = true
		},

		duplicateProduct(item){
			let productToAdd = {
				description: item.name,
				category: item.category,
				attributesValues: item.attribute_values,
				attributesName: item.attributes_name
			};
			this.productToAdd = productToAdd;
			this.storeProductDialog = true
		},

		newProductCustom(id, product, isNew = false){
			let category = this.getCategory(product.category_id);
			let attributesNonDiscriminatory =  category.attributes.filter(element => element.non_discriminatory);
			let costPrice = product.cost_price ?? 0;
			let count = product.count ?? 0;
			return {
				id: id,
				product_id: product.id,
				attribute_detail: this.getAttributeDetails(attributesNonDiscriminatory),
				attribute_values: product.attribute_values ?? this.getAttributeValues(product),
				attributes_non_discriminatory: attributesNonDiscriminatory,
				attributes_name: product.attributes_name,
				category: category,
				category_name: category.name,
				attributes: category.attributes,
				name: product.name,
				count: count,
				taxes_amount: 0,
				total_price: costPrice * count,
				unit_price: costPrice,
				unit_cost: costPrice,
				new: isNew
			}
		},

		getAttributeValues(product){
			let attributesValues = [];
			product.attributes.forEach(element => {
				attributesValues.push({
					id: element.id,
					name: this.getCategoryAttribute(element.id).name,
					value: element.value
				})
			});
			return attributesValues;
		},

		getAttributeDetails(attributesNonDiscriminatory){
			return [
					{
						id: 1,
						attributes: this.formatAttributesNonDiscriminatory(attributesNonDiscriminatory),
						current: 0,
						count: 0
					}
			]
		},

		addStock(product){
			this.productsCharged.filter(element => element.id == product.id)[0]?.attribute_detail.push({
				id: product.attribute_detail[product.attribute_detail.length - 1].id + 1,
				attributes: this.formatAttributesNonDiscriminatory(product.attributes_non_discriminatory),
				current: 0,
				count: 0
			})
		},

		deleteStock(id, stockId){
			if(this.productsCharged.filter(element => element.id == id)[0].attribute_detail.length < 2)
				return;
			this.productsCharged.filter(element => element.id == id)[0].attribute_detail = [].concat(
				this.productsCharged.filter(element => element.id == id)[0].attribute_detail.filter(element => element.id != stockId)
			)
		},

		discardProduct(itemId) {
			this.productsCharged = [].concat(this.productsCharged.filter(element => element.id != itemId))
			this.sweetAlertCustom('Producto descartado', 'info')
		},

		formatAttributesNonDiscriminatory(attributesNonDiscriminatory){
			let attributes = [];
			attributesNonDiscriminatory.forEach(element => {
				attributes.push({
					id: element.id,
					name: element.name,
					value: element.values.length > 0 ? element.values[0] : ''
				})
			});
			return attributes;
		},

		formatProductsCharged(){
			let products = [];
			let count = 1;
			this.productsCharged.forEach(element => {
				products.push(this.newProductCustom(count, element));
				++count;
			})
			this.productsCharged = [].concat(products);
		},

		nextPage () {
			if (this.page + 1 <= this.numberOfPages) this.page += 1
		},

		formerPage () {
			if (this.page - 1 >= 1) this.page -= 1
		},

		updateItemsPerPage (number) {
			this.itemsPerPage = number
		},

		getTotalCountByItem(id){
			let totalCount = 0;
			this.productsCharged?.filter(element => element.id == id)?.[0].attribute_detail?.forEach(function(element){
				totalCount += parseInt(element.count);
			})
			if(!totalCount)
				return 0;
			return totalCount ?? 0;
		},

		getTotalAmountByProduct(product){
			let totalCount = 0;
			this.productsCharged?.filter(element => element.id == product.id)?.[0].attribute_detail.forEach(function(element){
				totalCount += parseInt(element.count) * product.unit_price;
			})
			if(!totalCount)
				return 0;
			return totalCount ?? 0;
		},

		editProduct(item){

			let productos = [].concat(this.productsCharged)

			const indice = productos.findIndex((elemento, indice) => {
				if (elemento.id == item.id) {
					return true;
				}
			});

			productos[indice].unit_price = item.unit_price;
			productos[indice].count = this.getTotalCountByItem(item.id);

			this.productsCharged = [].concat(productos);

			this.$emit('product-alter', this.productsCharged.filter(element => (element.count > 0 || this.enableProductCountZero) && element.unit_price > 0))
		},

		getAttributesCountNamedByItem(item, onlyPositiveCount = true){

			let names = '';

			item.attribute_detail.forEach(element2 => {

				let attributesName = '';

				element2.attributes.forEach(element3 => {
					if(typeof element3.value === 'object')
						attributesName += element3.value.value + '';
					else
						attributesName += element3.value + '';
				});

				if(attributesName == ''){
					attributesName = 'En total'
				}
				if(onlyPositiveCount == element2.count > 0 && element2.count != 0){
					names += attributesName + ': ' + Math.abs(element2.count) + ', ';
				}

			});

			return names.slice(0, -2);

		},

		getAttributesCountTotal(item, onlyPositiveCount = true){
			let count = 0;
			item.attribute_detail.forEach(element2 => {
				if(onlyPositiveCount == element2.count > 0 && element2.count != 0){
					count += Math.abs(element2.count)
				}
			});
			return count;
		},

		addProductStock(stock, item){
			stock.count = parseInt(stock.count) + 1;
			this.editProduct(item)
		},

		subProductStock(stock, item){
			if(stock.count + stock.current <= 0){
				return;
			}
			stock.count = parseInt(stock.count) - 1;
			this.editProduct(item)
		},

		addProduct(){

			this.cart.count = 1;

			if(!this.cart.product)
				return this.sweetAlertCustom('Debe seleccionar un producto', 'warning');

			if(!this.cart.product.id)
				return this.sweetAlertCustom('Debe seleccionar un producto', 'warning');

			if(this.cart.price == 0)
				return this.sweetAlertCustom('El precio no puede ser 0', 'warning');

			if(this.cart.count == 0)
				return this.sweetAlertCustom('La cantidad no puede ser 0', 'warning');

			if(this.productsCharged.filter(element => element.product_id == this.cart.product.id).length > 0){
				this.productsCharged = this.productsCharged.filter(element => element.product_id != this.cart.product.id);
			}

			const maxId = this.productsCharged.reduce((max, objeto) => (objeto.id > max ? objeto.id : max), 0);

			let productAdding = this.newProductCustom(maxId ? maxId + 1 : 1, this.cart.product)

			this.addDetailFormatted(productAdding);

			this.productsCharged.push(Object.assign({}, productAdding))

			this.$emit('product-alter', this.productsCharged)
		},

		addDetailFormatted(productAdding){

			let productStock = this.getProductStock(this.cart.product.id)

			if(productStock.length == 0){
				productAdding.attribute_detail[0].current = 0;
				return;
			}

			productAdding.attribute_detail = [].concat([]);

			let attributeValues = [];

			productStock.forEach(element => {

				attributeValues = [];

				element.attribute_values.forEach(element2 => {
					attributeValues.push({
						id: element2.id,
						name: this.getCategoryAttribute(element2.id).name,
						value: element2.value
					})
				});

				productAdding.attribute_detail.push({
					id: element.id,
					attributes: attributeValues,
					count: this.withProductCountZero ? 0 : element.count,
					current: element.count
				})

			});


		},

		countStockForDetail(productAdding){

			let count = 0;

			productAdding.attribute_detail.forEach(element => {
				count += parseFloat(element.count)
			});

			return count;

		}
	},
}
</script>
