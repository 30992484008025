export default {

	formatQueryParams(data){
		let query = '?'
		for (const key in data) {
			query += key + "=" + data[key] + '&';
		}
		return query.substring(0, query.length - 1);
	}

}
