var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _vm.loading
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "12", lg: "12" } },
                [
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticClass: "ma-5",
                        attrs: {
                          size: 70,
                          width: 7,
                          color: "teal",
                          indeterminate: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            _vm._l(_vm.attributes, function (item) {
              return _c(
                "v-col",
                { key: item.id, attrs: { cols: "12" } },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "elevation-1" },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-checkbox", {
                            on: { change: _vm.changeAttributeValue },
                            model: {
                              value: item.selected,
                              callback: function ($$v) {
                                _vm.$set(item, "selected", $$v)
                              },
                              expression: "item.selected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.getCategoryAttribute(item.id)?.name)
                            ),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                _vm.attributeNamesToStrings(
                                  _vm.getCategoryAttribute(item.id)?.values
                                )
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _vm._v(" No discriminar stock "),
                          _c("v-checkbox", {
                            on: { change: _vm.changeAttributeValue },
                            model: {
                              value: item.non_discriminatory,
                              callback: function ($$v) {
                                _vm.$set(item, "non_discriminatory", $$v)
                              },
                              expression: "item.non_discriminatory",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }