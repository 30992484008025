import axios from "axios";

export default {
	namespaced: true,
	state: {
		deposit: {
			id: null,
			name: null,
			detail: null,
			branch_id: null,
			deposit_type: null,
		},
		deposits: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_DEPOSIT(state, data) {
			state.deposit = data;
		},
		SET_DEPOSITS(state, data) {
			state.deposits = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("product/deposit").then((response) => {
				commit("SET_DEPOSITS", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("product/deposit", params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("product/deposit/" + params.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors ?? error.response.data,
						type: "error",
					});
				});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("product/deposit/" + id)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors ?? error.response.data,
						type: "error",
					});
				});
		},
	},
	getters: {
		getDeposit: (state) => (depositId) => {
			if (state.deposits)
				return state.deposits.filter((prop) => prop.id == depositId)[0];
			else return "";
		},
		getFirstDeposit: (state) => {
			if (state.deposits) return state.deposits[0];
			else return [];
		},
		getDeposits: (state) => {
			if (state.deposits) return state.deposits;
			else return [];
		},
	},
};
