<template>
	<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-surcharge-type"
						@submit.prevent="save"
						v-model="formValid"
					>
					<v-container>
						<v-row>
							<v-col cols="12" lg="6">
								<v-text-field
									v-model="form.name"
									required
									label="Nombre"
									:error-messages="nameErrors"
									@change="$v.form.name.$touch()"
									@blur="$v.form.name.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3">
								<v-select
									:items="rate_types"
									item-text="name"
									item-value="id"
									v-model="form.rate_type"
									label="Tipo de Variación"
									:error-messages="rateTypeErrors"
									@change="$v.form.rate_type.$touch();"
									@blur="$v.form.rate_type.$touch()"
								></v-select>
							</v-col>
							<v-col cols="12" lg="3">
								<v-text-field
									v-model="form.rate"
									required
									label="Tasa"
									:error-messages="rateErrors"
									@change="$v.form.rate.$touch()"
									@blur="$v.form.rate.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-textarea
									rows="2"
									solo
									v-model="form.detail"
									label="Detalle..."
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-surcharge-type"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
	</v-dialog>
</template>
<script>

import { required, integer } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
	validations: {
		form: {
				rate: { required, integer},
				rate_type: { required },
				name: { required },
			}
	},
	data: () => {
		return {
			title: "Tipo de Recargo",
			dialog: true,
			formValid: false,
			formLoad: false,
			menu: false,
			rate_types: [
				{id: 'FIXED', name: 'Fijo'},
				{id: 'PERCENTAGE', name: 'Porcentual'}
			],
			loading: {
				fund: true
			},
			form: {
				id: null,
				rate: 0,
				rate_type: null,
				name: null,
				detail: null
			}
		}
	},
	created: async function(){
		if(this.$route.params.id){
			if(this.$store.state.SurchargeType.surchargeTypes.length == 0)
				await this.$store.dispatch('SurchargeType/get');
			this.form = Object.assign({}, this.getSurchargeType(this.$route.params.id))
		}else
			this.form.rate_type = this.rate_types[0].id;
	},
	computed:{

		...mapGetters('SurchargeType', ['getSurchargeType']),

		rateTypeErrors(){
			const errors = [];
			if (!this.$v.form.rate_type.$dirty) return errors;
			!this.$v.form.rate_type.required &&
				errors.push("El tipo de tasa es requerido");
			return errors;
		},

		rateErrors(){
			const errors = [];
			if (!this.$v.form.rate.$dirty) return errors;
			!this.$v.form.rate.required &&
				errors.push("La tasa es requerida");
			!this.$v.form.rate.required &&
				errors.push("La tasa debe ser un número");
			return errors;
		},

		nameErrors(){
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
			!this.$v.form.name.required &&
				errors.push("El nombre es requerido");
			return errors;
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {

		...mapActions('SurchargeType', ['store']),

		close() {
			this.$nextTick(() => {
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
		},

		successSave: async function () {
			if(this.$store.state.errors?.status == 200){
				this.$store.dispatch("SurchargeType/get");
				this.$router.back();
			}
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						rate_type: this.form.rate_type,
						rate: this.form.rate,
						name: this.form.name,
						detail: this.form.detail
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	}
}
</script>

