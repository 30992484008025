var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-5" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "5", md: "4", lg: "4" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "ma-4", attrs: { elevation: "2" } },
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " Correo Electrónico Verificado. Puede cerrar esta ventana. "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }