import axios from "axios";

export default {
	namespaced: true,
	state: {
		fund: {
			entity_id: null,
			user_id_created_by: null,
			fund_type: null,
			currency: null,
			debt_limit: null,
			main: null,
			code: null,
			title: null,
			detail: null,
			balance: null,
			fund_state: null
		},
		funds: []
	},
	mutations: {
		SET_FUND(state, data) {
			state.fund = data;
		},
		SET_FUNDS(state, data) {
			state.funds = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("fund").then((response) => {
				commit("SET_FUNDS", response.data);
			}).catch(() => {});
		},
		transfer: async function ({ commit }, params) {
			params.before();
			return await axios.patch("fund/transfer/" + params.data.path.source + '/' + params.data.path.target, params.data.body)
			.then((response) => {
				commit("SET_ERRORS", response, { root: true });
				params.success();
				commit("SET_ERRORS", null, { root: true });
			}).catch(() => {});
		},
		adjust: async function ({ commit }, params) {
			params.before();
			return await axios.patch("fund/adjust/" + params.data.path.fund, params.data.body)
			.then((response) => {
				commit("SET_ERRORS", response, { root: true });
				params.success();
				commit("SET_ERRORS", null, { root: true });
			}).catch(() => {});
		}
	},
	getters: {
		getFund: (state) => (fundId) => {
			if (state.funds)
				return state.funds.filter((prop) => prop.id == fundId)[0];
			else return "";
		},
		getFundsWithout: (state) => (fundId) => {
			if (state.funds)
				return state.funds.filter((prop) => prop.id != fundId);
			else return "";
		},
		getFirstFund: (state) => {
			if (state.funds)
				return state.funds[0];
			else return "";
		},
		getMainCashFund: (state) => {
			if (state.funds)
				return state.funds.filter((prop) => prop.fund_type == 'CF' && prop.main)[0];
			else return "";
		}
	},
};
