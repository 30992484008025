<template>
	<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-payment-method"
						@submit.prevent="save"
						v-model="formValid"
					>
					<v-container>
						<v-row>
							<v-col cols="12" lg="4">
								<v-combobox
										:items="funds"
										prepend-icon="mdi-bank-outline"
										item-text="title"
										item-value="id"
										:loading="loading.fund"
										v-model="form.fund"
										:error-messages="fundErrors"
										@change="$v.form.fund.$touch()"
										@blur="$v.form.fund.$touch()"
										label="Fondo Asociado"
									>
									</v-combobox>
							</v-col>
							<v-col cols="12" lg="8">
								<v-text-field
									v-model="form.name"
									required
									label="Nombre"
									:error-messages="nameErrors"
									@change="$v.form.name.$touch()"
									@blur="$v.form.name.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3">
								<v-text-field
									v-model="form.settlement_rate"
									hint="En 0 la liquidación es inmediata"
									suffix="dias"
									required
									label="Liquidación en Dias"
									:error-messages="settlementRateErrors"
									@change="$v.form.settlement_rate.$touch()"
									@blur="$v.form.settlement_rate.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3">
								<v-text-field
									v-model="form.discount.value"
									hint="Se aplica para todas las ventas"
									:suffix="form.discount.type == 'FIXED' ? 'Fijo' : '%'"
									required
									label="Valor de Descuento"
									:error-messages="discountValueErrors"
									@change="$v.form.discount.value.$touch()"
									@blur="$v.form.discount.value.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="3">
								<v-select
										:items="form.discount.types"
										v-model="form.discount.type"
										hint="Se aplica para todas las ventas"
										:suffix="form.discount.type == 'FIXED' ? 'Fijo' : '%'"
										item-text="value"
										item-value="id"
								></v-select>
							</v-col>
							<v-col cols="12" lg="3">
								<v-text-field
									v-model="form.discount.round"
									required
									label="Redondeo aplicado"
									:error-messages="discountRoundErrors"
									@change="$v.form.discount.round.$touch()"
									@blur="$v.form.discount.round.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-textarea
									rows="2"
									solo
									v-model="form.detail"
									label="Detalle..."
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-payment-method"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
	</v-dialog>
</template>
<script>

import { required, integer } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
	validations: {
		form: {
				settlement_rate: { required, integer },
				name: { required },
				fund: { required },
				discount: {
					value: { integer },
					round: { integer }
				}
			}
	},
	data: () => {
		return {
			title: "Método de Pago",
			dialog: true,
			formValid: false,
			formLoad: false,
			loading: {
				fund: true
			},
			form: {
				id: null,
				settlement_rate: 0,
				name: null,
				detail: null,
				fund: {
					id: null,
					title: null
				},
				discount: {
					value: 0,
					types: [
						{id: 'PERCENTAGE', value: 'Porcentual'},
						{id: 'FIXED', value: 'Fijo'},
					],
					type: 'PERCENTAGE',
					round: 0
				}
			}
		}
	},
	created: async function(){
		if(this.$store.state.Fund.funds.length == 0)
			await this.$store.dispatch('Fund/get');
		if(this.$store.state.PaymentMethod.paymentMethods.length == 0)
			await this.$store.dispatch('PaymentMethod/get');
		if(this.$route.params.id){
			this.dto(this.getPaymentMethod(this.$route.params.id))
		}else
			this.form.fund = this.getFirstFund;
		this.loading.fund = false;
	},
	computed:{
		...mapState('Fund', ['funds']),
		...mapGetters('Fund', ['getFirstFund']),
		...mapGetters('PaymentMethod', ['getPaymentMethod']),

		fundErrors(){
			const errors = [];
			if (!this.$v.form.fund.$dirty) return errors;
			!this.$v.form.fund.required &&
				errors.push("El fondo es requerido");
			return errors;
		},
		settlementRateErrors(){
			const errors = [];
			if (!this.$v.form.settlement_rate.$dirty) return errors;
			!this.$v.form.settlement_rate.required &&
				errors.push("La liquidación en dias es requerido");
			!this.$v.form.settlement_rate.required &&
				errors.push("La liquidación en dias debe ser un número");
			return errors;
		},
		nameErrors(){
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
			!this.$v.form.name.required &&
				errors.push("El nombre es requerido");
			return errors;
		},
		discountValueErrors(){
			const errors = [];
			if (!this.$v.form.discount.value.$dirty) return errors;
			!this.$v.form.discount.value.integer &&
				errors.push("El valor de descuento debe ser un número");
			return errors;
		},
		discountRoundErrors(){
			const errors = [];
			if (!this.$v.form.discount.round.$dirty) return errors;
			!this.$v.form.discount.round.integer &&
				errors.push("El valor de redondeo debe ser un número");
			return errors;
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {

		...mapActions('PaymentMethod', ['store']),

		dto(form){
			this.form.id = form.id;
			this.form.name = form.name;
			this.form.settlement_rate = form.settlement_rate;
			this.form.detail = form.detail;
			this.form.fund.id = form.fund.id;
			this.form.fund.title = form.fund.title;
			this.form.discount.value = form.discount_value;
			this.form.discount.type = form.discount_type;
			this.form.discount.round = form.discount_round;
		},

		close() {
			this.$nextTick(() => {
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
		},

		successSave: async function () {
			if(this.$store.state.errors?.status == 200){
				this.$store.dispatch("PaymentMethod/get");
				this.$router.back();
			}
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						settlement_rate: this.form.settlement_rate,
						name: this.form.name,
						detail: this.form.detail,
						fund_id: this.form.fund.id,
						discount_value: this.form.discount.value,
						discount_type: this.form.discount.type,
						discount_round: this.form.discount.round
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	}
}
</script>

