import InventoryHome from "@/views/app/inventory/inventory-home.vue";
import InventoryData from "@/views/app/inventory/inventory-data.vue";

import ProductHome from "@/views/inventory/product/product-home.vue";
import ProductData from "@/views/inventory/product/product-data.vue";
import ProductForm from "@/views/inventory/product/product-form.vue";
import ProductStockForm from "@/views/inventory/product/stock/stock-form.vue";

import CategoryHome from "@/views/inventory/category/category-home.vue";
import CategoryData from "@/views/inventory/category/category-data.vue";
import CategoryForm from "@/views/inventory/category/category-form.vue";

import CategoryAttributeHome from "@/views/inventory/attribute/category-attribute-home.vue";
import CategoryAttributeData from "@/views/inventory/attribute/category-attribute-data.vue";
import CategoryAttributeForm from "@/views/inventory/attribute/category-attribute-form.vue";

import MovementHome from "@/views/inventory/movement/movement-home.vue";
import MovementData from "@/views/inventory/movement/movement-data.vue";
import MovementForm from "@/views/inventory/movement/movement-form.vue";

import DepositHome from "@/views/inventory/deposit/deposit-home.vue";
import DepositData from "@/views/inventory/deposit/deposit-data.vue";
import DepositForm from "@/views/inventory/deposit/deposit-form.vue";

import PriceListHome from "@/views/inventory/price_list/price-list-home.vue";
import PriceListData from "@/views/inventory/price_list/price-list-data.vue";
import PriceListForm from "@/views/inventory/price_list/price-list-form.vue";

const STR_STORE = "store";

export default {
	path: "/inventory",
	name: "",
	component: InventoryHome,
	meta: { requiresAuth: true, access: 'LEC.INV.PRD' },
	children: [
		{
			path: "",
			name: "InventoryData",
			component: InventoryData,
			meta: { access: 'LEC.INV.PRD' },
		},
		{
			path: "product",
			component: ProductHome,
			meta: { access: 'LEC.INV.PRD' },
			children: [
				{
					path: "",
					name: "ProductData",
					component: ProductData,
					meta: { access: 'LEC.INV.PRD' },
				},
				{
					name: "ProductCreate",
					path: STR_STORE,
					component: ProductForm,
					meta: { access: 'ESC.INV.PRD' },
				},
				{
					name: "ProductUpdate",
					path: STR_STORE + "/:id",
					component: ProductForm,
					meta: { access: 'ESC.INV.PRD' },
				},
				{
					name: "ProductStockUpdate",
					path: "stock",
					component: ProductStockForm,
					meta: { access: 'ESC.INV.PRD' },
				},
			],
		},
		{
			path: "movement",
			component: MovementHome,
			meta: { access: 'LEC.INV.MOV' },
			children: [
				{
					path: "",
					name: "MovementData",
					component: MovementData,
					meta: { access: 'LEC.INV.MOV' },
				},
				{
					name: "MovementCreate",
					path: STR_STORE,
					component: MovementForm,
					meta: { access: 'ESC.INV.MOV' },
				},
				{
					name: "MovementUpdate",
					path: STR_STORE + "/:id",
					component: MovementForm,
					meta: { access: 'ESC.INV.MOV' },
				},
			],
		},
		{
			path: "deposit",
			component: DepositHome,
			meta: { access: 'LEC.INV.DEP' },
			children: [
				{
					path: "",
					name: "DepositData",
					component: DepositData,
					meta: { access: 'LEC.INV.DEP' },
				},
				{
					name: "DepositCreate",
					path: STR_STORE,
					component: DepositForm,
					meta: { access: 'ESC.INV.DEP' },
				},
				{
					name: "DepositUpdate",
					path: STR_STORE + "/:id",
					component: DepositForm,
					meta: { access: 'ESC.INV.DEP' },
				},
			],
		},
		{
			path: "price-list",
			component: PriceListHome,
			meta: { access: 'LEC.INV.LPR' },
			children: [
				{
					path: "",
					name: "PriceListData",
					component: PriceListData,
					meta: { access: 'LEC.INV.LPR' },
				},
				{
					name: "PriceListCreate",
					path: STR_STORE,
					component: PriceListForm,
					meta: { access: 'ESC.INV.LPR' },
				},
				{
					name: "PriceListUpdate",
					path: STR_STORE + "/:id",
					component: PriceListForm,
					meta: { access: 'ESC.INV.LPR' },
				},
			],
		},
		{
			path: "category",
			component: CategoryHome,
			meta: { access: 'LEC.INV.CAT' },
			children: [
				{
					path: "",
					name: "CategoryData",
					component: CategoryData,
					meta: { access: 'LEC.INV.CAT' },
				},
				{
					name: "CategoryCreate",
					path: STR_STORE,
					component: CategoryForm,
					meta: { access: 'ESC.INV.CAT' },
				},
				{
					name: "CategoryUpdate",
					path: STR_STORE + "/:id",
					component: CategoryForm,
					meta: { access: 'ESC.INV.CAT' },
				},
			],
		},
		{
			path: "attribute",
			component: CategoryAttributeHome,
			meta: { access: 'LEC.INV.ATR' },
			children: [
				{
					path: "",
					name: "CategoryAttributeData",
					component: CategoryAttributeData,
					meta: { access: 'LEC.INV.ATR' },
				},
				{
					name: "CategoryAttributeCreate",
					path: STR_STORE,
					component: CategoryAttributeForm,
					meta: { access: 'ESC.INV.ATR' },
				},
				{
					name: "CategoryAttributeUpdate",
					path: STR_STORE + "/:id",
					component: CategoryAttributeForm,
					meta: { access: 'ESC.INV.ATR' },
				},
			],
		},
	],
};
