<template>
	<router-view style="background-color: transparent"></router-view>
</template>
<script>

export default {

}

</script>
