import { render, staticRenderFns } from "./purchase-data.vue?vue&type=template&id=135ab0ef&"
import script from "./purchase-data.vue?vue&type=script&lang=js&"
export * from "./purchase-data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\osval\\vue_projects\\spa.lencerp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('135ab0ef')) {
      api.createRecord('135ab0ef', component.options)
    } else {
      api.reload('135ab0ef', component.options)
    }
    module.hot.accept("./purchase-data.vue?vue&type=template&id=135ab0ef&", function () {
      api.rerender('135ab0ef', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/purchasing/purchase/purchase-data.vue"
export default component.exports