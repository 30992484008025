var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("dialog-delete", {
        attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
        on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: {
                    fab: "",
                    dark: "",
                    color: "indigo",
                    to: "/inventory/family/store",
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v(" mdi-plus ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c("v-treeview", {
                attrs: {
                  rounded: "",
                  hoverable: "",
                  activatable: "",
                  active: _vm.active,
                  open: _vm.open,
                  "return-object": true,
                  "open-all": true,
                  items: _vm.families,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.active = $event
                  },
                  "update:open": function ($event) {
                    _vm.open = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _vm.selected
                ? _c(
                    "v-card",
                    { attrs: { rounded: "lg" } },
                    [
                      _c("v-card-title", [
                        _c("h5", [_vm._v(_vm._s(_vm.selected.name))]),
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("span", [_vm._v(_vm._s(_vm.selected.detail))]),
                          _vm.selected.sale
                            ? _c("v-chip", { staticClass: "ml-2" }, [
                                _vm._v("Venta"),
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.getMetric(_vm.selected.metric_ref.code)
                              )
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                fab: "",
                                dark: "",
                                small: "",
                                color: "indigo",
                                to:
                                  "/inventory/family/store/" + _vm.selected.id,
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v(" mdi-pencil "),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                fab: "",
                                small: "",
                                dark: "",
                                color: "indigo",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(_vm.selected.id)
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v(" mdi-delete "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }