var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600px" },
          model: {
            value: _vm.storeProductDialog,
            callback: function ($$v) {
              _vm.storeProductDialog = $$v
            },
            expression: "storeProductDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "text-h5" }, [
                  _vm._v("Nuevo Producto"),
                ]),
              ]),
              _c(
                "v-card-text",
                [
                  _c("ProductForm", {
                    attrs: { product: _vm.productToAdd },
                    on: {
                      "close-form": function ($event) {
                        _vm.storeProductDialog = false
                      },
                      "add-product": _vm.productAdded,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.listProductsDialog,
            callback: function ($$v) {
              _vm.listProductsDialog = $$v
            },
            expression: "listProductsDialog",
          },
        },
        [
          !_vm.withButtonToAddProductCount
            ? _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "text-h5" }, [
                      _vm._v("Listado de Productos Cargados"),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm.productsCharged.filter((element) => element.count > 0)
                        .length == 0
                        ? _c("h3", { staticClass: "text-h6 ma-6" }, [
                            _vm._v("No hay productos cargados."),
                          ])
                        : _vm._l(
                            _vm.productsCharged.filter(
                              (element) => element.count > 0
                            ),
                            function (item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.id,
                                  staticClass: "elevation-1 ma-2",
                                  attrs: { "two-line": "" },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCategory(item.category.id)
                                              ?.name
                                          ) +
                                            " " +
                                            _vm._s(item.attributes_name) +
                                            " " +
                                            _vm._s(item.name)
                                        ),
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getAttributesCountNamedByItem(
                                              item
                                            )
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-action", [
                                    _c(
                                      "span",
                                      { staticClass: "text-h5 green--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatCurrency(
                                                item.unit_price * item.count
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-subtitle-1" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "blue-grey--text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatCurrency(
                                                  item.unit_price
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" x "),
                                        _c("strong", [
                                          _vm._v(_vm._s(item.count)),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                      _c("v-divider", { staticClass: "ma-4" }),
                      _c("span", { staticClass: "text-subtitle-1" }, [
                        _vm._v("Cantidad Total: "),
                        _c(
                          "strong",
                          { staticClass: "blue-grey--text mb-2 text-h6" },
                          [_vm._v(_vm._s(_vm.getTotalCountProductCharged))]
                        ),
                      ]),
                      _c("br"),
                      _c("span", { staticClass: "text-subtitle-1" }, [
                        _vm._v("Monto Total: "),
                        _c("strong", { staticClass: "green--text text-h6" }, [
                          _vm._v(
                            _vm._s(
                              _vm.formatCurrency(
                                _vm.getTotalAmountProductCharged
                              )
                            )
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.listProductsDialog = false
                            },
                          },
                        },
                        [_vm._v(" Cerrar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _c("span", { staticClass: "text-h5" }, [
                      _vm._v("Listado de productos a ajustar"),
                    ]),
                  ]),
                  _c("v-card-text", [
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "text-h6" }, [
                          _vm._v("Productos a agregar"),
                        ]),
                        _vm.getProductsChargedToAddStock.length == 0
                          ? _c("h3", { staticClass: "text-h6 ma-6" }, [
                              _vm._v("No hay productos cargados."),
                            ])
                          : _vm._l(
                              _vm.getProductsChargedToAddStock,
                              function (item) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: item.id,
                                    staticClass: "elevation-1 ma-2",
                                    attrs: { "two-line": "" },
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCategory(item.category.id)
                                                ?.name
                                            ) +
                                              " " +
                                              _vm._s(item.attributes_name) +
                                              " " +
                                              _vm._s(item.name)
                                          ),
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAttributesCountNamedByItem(
                                                item
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-action", [
                                      _c(
                                        "span",
                                        { staticClass: "text-h4 green--text" },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getAttributesCountTotal(
                                                  item
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                        _c("span", { staticClass: "text-subtitle-1" }, [
                          _vm._v("Cantidad Total: "),
                          _c(
                            "strong",
                            { staticClass: "green--text mb-2 text-h6" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getTotalCountProductChargedToAddStock
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c("br"),
                        _c("v-divider", { staticClass: "ma-4" }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c("span", { staticClass: "text-h6" }, [
                          _vm._v("Productos a descontar"),
                        ]),
                        _vm.getProductsChargedToSubStock.length == 0
                          ? _c("h3", { staticClass: "text-h6 ma-6" }, [
                              _vm._v("No hay productos cargados."),
                            ])
                          : _vm._l(
                              _vm.getProductsChargedToSubStock,
                              function (item) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: item.id,
                                    staticClass: "elevation-1 ma-2",
                                    attrs: { "two-line": "" },
                                  },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCategory(item.category.id)
                                                ?.name
                                            ) +
                                              " " +
                                              _vm._s(item.attributes_name) +
                                              " " +
                                              _vm._s(item.name)
                                          ),
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getAttributesCountNamedByItem(
                                                item,
                                                false
                                              )
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-action", [
                                      _c(
                                        "span",
                                        { staticClass: "text-h4 red--text" },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getAttributesCountTotal(
                                                  item,
                                                  false
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                        _c("span", { staticClass: "text-subtitle-1" }, [
                          _vm._v("Cantidad Total: "),
                          _c(
                            "strong",
                            { staticClass: "red--text mb-2 text-h6" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.getTotalCountProductChargedToSubStock
                                )
                              ),
                            ]
                          ),
                        ]),
                        _c("br"),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "blue darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.listProductsDialog = false
                            },
                          },
                        },
                        [_vm._v(" Cerrar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("v-data-iterator", {
        staticClass: "mb-5",
        attrs: {
          items: _vm.productsCharged,
          "no-data-text": "No se encontraron datos, aún...",
          "items-per-page": _vm.itemsPerPage,
          page: _vm.page,
          search: _vm.search,
          "sort-by": _vm.sortBy.toLowerCase(),
          "sort-desc": _vm.sortDesc,
          "hide-default-footer": "",
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:page": function ($event) {
            _vm.page = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "v-container",
                  [
                    !_vm.provider
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                attrs: {
                                  cols: "12",
                                  lg: "9",
                                  md: "9",
                                  sm: "12",
                                },
                              },
                              [
                                _c("v-combobox", {
                                  attrs: {
                                    items: _vm.getProductsToLoad,
                                    clearable: "",
                                    filled: "",
                                    dense: "",
                                    filter: _vm.productToLoadFilter,
                                    "item-text": _vm.productItemText,
                                    "item-value": "id",
                                    "persistent-hint": "",
                                    loading: _vm.loading.product,
                                    label: "Productos",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "append-outer",
                                        fn: function () {
                                          return [
                                            _vm.cart.product?.id
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    staticStyle: {
                                                      "margin-top": "-5px",
                                                    },
                                                    attrs: {
                                                      color:
                                                        _vm.getStock <=
                                                        _vm.getMinStock
                                                          ? "error"
                                                          : "success",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.getStock) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "item",
                                        fn: function ({ item }) {
                                          return [
                                            _c("v-list-item", [
                                              _c(
                                                "div",
                                                { staticClass: "pa-2" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-body-1 font-weight-medium text-capitalize ma-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getCategory(
                                                            item.category_id
                                                          )?.name
                                                        ) +
                                                          " " +
                                                          _vm._s(item.name) +
                                                          " " +
                                                          _vm._s(
                                                            item.attributes_name
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "v-list-item-action",
                                              { staticClass: "float-right" },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      color:
                                                        item.stock.count <=
                                                        _vm.getMinStock
                                                          ? "error"
                                                          : "success",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.stock.count
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1148977168
                                  ),
                                  model: {
                                    value: _vm.cart.product,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cart, "product", $$v)
                                    },
                                    expression: "cart.product",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                attrs: { cols: "6", lg: "2", md: "2", sm: "4" },
                              },
                              [
                                _vm.withPrices
                                  ? _c("v-text-field", {
                                      attrs: {
                                        clearable: "",
                                        filled: "",
                                        dense: "",
                                        label: "Precio",
                                      },
                                      model: {
                                        value: _vm.cart.price,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.cart, "price", $$v)
                                        },
                                        expression: "cart.price",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                attrs: { cols: "2", lg: "1", md: "1", sm: "2" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2 mt-3",
                                    attrs: {
                                      fab: "",
                                      small: "",
                                      dark: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addProduct()
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(" mdi-plus "),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "4" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                flat: "",
                                outlined: "",
                                dense: "",
                                elevation: "1",
                                "hide-details": "",
                                "prepend-inner-icon": "mdi-magnify",
                                label: "Buscar",
                              },
                              model: {
                                value: _vm.search,
                                callback: function ($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "4" } },
                          [
                            _c("v-select", {
                              attrs: {
                                flat: "",
                                elevation: "1",
                                outlined: "",
                                "hide-details": "",
                                items: _vm.custom,
                                "item-value": "id",
                                "item-text": "desc",
                                dense: "",
                                "prepend-inner-icon": "mdi-magnify",
                                label: "Ordenar por...",
                              },
                              model: {
                                value: _vm.sortBy,
                                callback: function ($$v) {
                                  _vm.sortBy = $$v
                                },
                                expression: "sortBy",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", lg: "4" } },
                          [
                            _c(
                              "v-btn-toggle",
                              {
                                attrs: { small: "", mandatory: "" },
                                model: {
                                  value: _vm.sortDesc,
                                  callback: function ($$v) {
                                    _vm.sortDesc = $$v
                                  },
                                  expression: "sortDesc",
                                },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      depressed: "",
                                      small: "",
                                      color: "secondary",
                                      value: false,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("mdi-arrow-up")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      depressed: "",
                                      color: "secondary",
                                      value: true,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("mdi-arrow-down")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-container",
                  [
                    _vm.withNewProductAction
                      ? _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.openModalNewProduct()
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "mr-1" }, [
                              _vm._v(" mdi-plus "),
                            ]),
                            _vm._v(" Nuevo Producto "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-3",
                        attrs: { color: "primary", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.listProductsDialog = true
                          },
                        },
                      },
                      [
                        _c("v-icon", { staticClass: "mr-1" }, [
                          _vm._v(" mdi-table "),
                        ]),
                        _vm._v(" Ver Detalle "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function (props) {
              return [
                _c("v-container", { staticClass: "pt-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-4 mt-1" },
                    _vm._l(props.items, function (item) {
                      return _c(
                        "v-row",
                        { key: item.id },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "rounded-sm mb-6",
                              attrs: { fluid: "", "elevation-2": "" },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "rounded-t-lg",
                                  attrs: { justify: "space-between" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0",
                                      attrs: {
                                        cols: "6",
                                        lg: "7",
                                        md: "7",
                                        sm: "9",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "pa-2" },
                                        [
                                          item.new
                                            ? _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    "x-small": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        "mr-2": "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-star")]
                                                  ),
                                                  _vm._v(" Nuevo "),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-body-2 text-capitalize ma-0",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getCategory(
                                                    item.category.id
                                                  )?.name
                                                ) +
                                                  " " +
                                                  _vm._s(item.attributes_name) +
                                                  " " +
                                                  _vm._s(item.name)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  item.attribute_detail[0].attributes.length ==
                                  0
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0 ma-0 text-center",
                                          attrs: {
                                            cols: "2",
                                            lg: "2",
                                            md: "2",
                                            sm: "3",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mr-2 mt-3",
                                            attrs: {
                                              outlined: "",
                                              value: "0",
                                              "hide-details": "auto",
                                              dense: "",
                                              color: _vm.getCountColor(
                                                item.attribute_detail[0].count
                                              ),
                                              suffix:
                                                _vm.withButtonToAddProductCount
                                                  ? item.attribute_detail[0].current.toString()
                                                  : _vm.formatCurrency(
                                                      item.unit_price *
                                                        (parseInt(
                                                          item
                                                            .attribute_detail[0]
                                                            .count
                                                        )
                                                          ? item
                                                              .attribute_detail[0]
                                                              .count
                                                          : 0)
                                                    ),
                                              "append-outer-icon":
                                                _vm.withButtonToAddProductCount
                                                  ? "mdi-plus-circle-outline"
                                                  : "",
                                              "prepend-icon":
                                                _vm.withButtonToAddProductCount &&
                                                item.attribute_detail[0]
                                                  .current > 0
                                                  ? "mdi-minus-circle-outline"
                                                  : "",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.editProduct(item)
                                              },
                                              "click:append-outer": function (
                                                $event
                                              ) {
                                                return _vm.addProductStock(
                                                  item.attribute_detail[0],
                                                  item
                                                )
                                              },
                                              "click:prepend": function (
                                                $event
                                              ) {
                                                return _vm.subProductStock(
                                                  item.attribute_detail[0],
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                item.attribute_detail[0].count,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item.attribute_detail[0],
                                                  "count",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.attribute_detail[0].count",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0 mt-2",
                                      attrs: {
                                        cols: "4",
                                        lg: "3",
                                        md: "3",
                                        sm: "3",
                                      },
                                    },
                                    [
                                      _vm.withPrices
                                        ? _c("v-text-field", {
                                            staticClass: "text-h6 mr-2",
                                            attrs: {
                                              prefix: "$",
                                              "hide-details": "auto",
                                              dense: "",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.editProduct(item)
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _vm.getTotalCountByItem(
                                                        item.id
                                                      ) > 0
                                                        ? _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "pa-0 pl-2 pr-2 mb-1 mt-0",
                                                              attrs: {
                                                                medium: "",
                                                                color:
                                                                  "success",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Total: " +
                                                                  _vm._s(
                                                                    _vm.formatCurrency(
                                                                      _vm.getTotalAmountByProduct(
                                                                        item
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-chip",
                                                            {
                                                              staticClass:
                                                                "pa-0 pl-2 pr-2 mb-1 mt-0",
                                                              attrs: {
                                                                medium: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Total: " +
                                                                  _vm._s(
                                                                    _vm.formatCurrency(
                                                                      _vm.getTotalAmountByProduct(
                                                                        item
                                                                      )
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.unit_price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "unit_price",
                                                  $$v
                                                )
                                              },
                                              expression: "item.unit_price",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0 mt-4",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        {
                                          staticClass: "pa-0 ma-0",
                                          attrs: { fluid: "" },
                                        },
                                        [
                                          item.attribute_detail[0].attributes
                                            .length > 0
                                            ? _c(
                                                "v-row",
                                                _vm._l(
                                                  item.attribute_detail,
                                                  function (stock) {
                                                    return _c(
                                                      "v-col",
                                                      {
                                                        key: stock.id,
                                                        staticClass:
                                                          "pa-0 ma-0 mt-2",
                                                        attrs: {
                                                          cols: "12",
                                                          lg: "12",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-container",
                                                          [
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0 text-center",
                                                                    attrs: {
                                                                      cols: "2",
                                                                      lg: "1",
                                                                      md: "1",
                                                                      sm: "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          "x-small":
                                                                            "",
                                                                          color:
                                                                            "red",
                                                                          elevation:
                                                                            "0",
                                                                          dark: "",
                                                                          fab: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteStock(
                                                                                item.id,
                                                                                stock.id
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "x-small":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " mdi-delete "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._l(
                                                                  stock.attributes,
                                                                  function (
                                                                    attrNon
                                                                  ) {
                                                                    return _c(
                                                                      "v-col",
                                                                      {
                                                                        key: attrNon.id,
                                                                        staticClass:
                                                                          "pa-0 ma-0",
                                                                        attrs: {
                                                                          cols: "10",
                                                                          lg: "3",
                                                                          md: "4",
                                                                          sm: "6",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.getCategoryAttribute(
                                                                          attrNon.id
                                                                        )
                                                                          ?.values
                                                                          .length >
                                                                        0
                                                                          ? _c(
                                                                              "v-select",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    items:
                                                                                      _vm.getCategoryAttribute(
                                                                                        attrNon.id
                                                                                      )
                                                                                        ?.values ??
                                                                                      [],
                                                                                    "item-text":
                                                                                      "value",
                                                                                    "item-value":
                                                                                      "id",
                                                                                    "hide-details":
                                                                                      "auto",
                                                                                    "return-object":
                                                                                      "",
                                                                                    label:
                                                                                      attrNon.name,
                                                                                    outlined:
                                                                                      "",
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editProduct(
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      attrNon.value,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          attrNon,
                                                                                          "value",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "attrNon.value",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "v-text-field",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    outlined:
                                                                                      "",
                                                                                    label:
                                                                                      attrNon.name,
                                                                                    "hide-details":
                                                                                      "auto",
                                                                                    dense:
                                                                                      "",
                                                                                  },
                                                                                on: {
                                                                                  input:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editProduct(
                                                                                        item
                                                                                      )
                                                                                    },
                                                                                },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      attrNon.value,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          attrNon,
                                                                                          "value",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "attrNon.value",
                                                                                  },
                                                                              }
                                                                            ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0 text-center",
                                                                    attrs: {
                                                                      cols: "6",
                                                                      lg: "2",
                                                                      md: "2",
                                                                      sm: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-text-field",
                                                                      {
                                                                        class: {
                                                                          "ml-2":
                                                                            _vm.withButtonToAddProductCount,
                                                                        },
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          value:
                                                                            "0",
                                                                          "hide-details":
                                                                            "auto",
                                                                          color:
                                                                            _vm.getCountColor(
                                                                              stock.count
                                                                            ),
                                                                          dense:
                                                                            "",
                                                                          suffix:
                                                                            _vm.withButtonToAddProductCount
                                                                              ? stock.current.toString()
                                                                              : _vm.formatCurrency(
                                                                                  item.unit_price *
                                                                                    (parseInt(
                                                                                      stock.count
                                                                                    )
                                                                                      ? stock.count
                                                                                      : 0)
                                                                                ),
                                                                          "append-outer-icon":
                                                                            _vm.withButtonToAddProductCount
                                                                              ? "mdi-plus-circle-outline"
                                                                              : "",
                                                                          "prepend-icon":
                                                                            _vm.withButtonToAddProductCount &&
                                                                            stock.current >
                                                                              0
                                                                              ? "mdi-minus-circle-outline"
                                                                              : "",
                                                                        },
                                                                        on: {
                                                                          input:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.editProduct(
                                                                                item
                                                                              )
                                                                            },
                                                                          "click:append-outer":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.addProductStock(
                                                                                stock,
                                                                                item
                                                                              )
                                                                            },
                                                                          "click:prepend":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.subProductStock(
                                                                                stock,
                                                                                item
                                                                              )
                                                                            },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            stock.count,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                stock,
                                                                                "count",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "stock.count",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 ma-0 text-center mt-3",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "12",
                                                  },
                                                },
                                                [
                                                  item.attribute_detail[0]
                                                    .attributes.length > 0
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass: "py-2",
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "accent",
                                                            dark: "",
                                                            rounded: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.addStock(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-plus "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" Agregar "),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.withDuplicateAction
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "py-2 ml-2",
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "accent",
                                                            dark: "",
                                                            rounded: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.duplicateProduct(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-content-duplicate "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" Duplicar "),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  !item.product_id ||
                                                  _vm.withButtonToDiscardProducts
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "py-2 ml-2",
                                                          attrs: {
                                                            "x-small": "",
                                                            color: "danger",
                                                            dark: "",
                                                            rounded: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.discardProduct(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                "x-small": "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-delete "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" Descartar "),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ]),
              ]
            },
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "v-row",
                  {
                    staticClass: "mt-4",
                    attrs: { align: "center", justify: "center" },
                  },
                  [
                    _c("span", { staticClass: "grey--text" }, [
                      _vm._v("Cantidad por página"),
                    ]),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-2",
                                        attrs: {
                                          dark: "",
                                          text: "",
                                          color: "secondary",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.itemsPerPage) + " "
                                    ),
                                    _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          _vm._l(
                            _vm.itemsPerPageArray,
                            function (number, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateItemsPerPage(number)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(number)),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c("span", { staticClass: "mr-4 grey--text" }, [
                      _vm._v(
                        " Página " +
                          _vm._s(_vm.page) +
                          " de " +
                          _vm._s(_vm.numberOfPages) +
                          " "
                      ),
                    ]),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-1",
                        attrs: {
                          fab: "",
                          color: "secondary",
                          outlined: "",
                          "x-small": "",
                        },
                        on: { click: _vm.formerPage },
                      },
                      [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-1 mr-3",
                        attrs: {
                          fab: "",
                          color: "secondary",
                          outlined: "",
                          "x-small": "",
                        },
                        on: { click: _vm.nextPage },
                      },
                      [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }