<template>
	<div>
		<Title title="Compras"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="dialogDelete = false"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<dialog-confirmed
			:value="depositPurchase.dialog"
			:progress="depositPurchase.load"
			title="¿Desea depositar los productos de esta compra?"
			@close="depositPurchase.dialog = false"
			@confirm="depositPurchaseConfirm"
		></dialog-confirmed>
		<dialog-payment
			:value="payment.dialog"
			:progress="payment.load"
			@close="payment.dialog = false"
			@confirm="paymentConfirm"
		></dialog-payment>
		<PurchaseDetail
			:dialogOut="detail.dialog"
			@update:dialog="this.updateDialogDetail"
			@update:purchase="this.updatePurchaseDetail"
			:single="true"
			:purchaseOut="detail.purchase"
		></PurchaseDetail>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					fab
					dark
					color="primary"
					to="/purchasing/purchase/store"
					v-if="hasAccess('ESC.COM.COM')"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="getPurchases"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
					no-data-text="No hay compras..."
				>
					<template v-slot:top>
						<v-container fluid>
							<v-row>
								<v-col
									cols="12"
									sm="6"
									md="4"
								>
									<v-menu
										ref="menu"
										v-model="menu"
										:close-on-content-click="false"
										:return-value.sync="filter.date"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												v-model="filterDateText"
												label="Rango de fechas"
												prepend-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
											></v-text-field>
										</template>
										<v-date-picker
											v-model="filter.date"
											no-title
											scrollable
											range
										>
											<v-spacer></v-spacer>
											<v-btn
												text
												color="primary"
												@click="menu = false"
											>
												Cancel
											</v-btn>
											<v-btn
												text
												color="primary"
												@click="$refs.menu.save(filter.date)"
											>
												OK
											</v-btn>
										</v-date-picker>
									</v-menu>
								</v-col>
								<v-col cols="12" lg="4">
									<v-select
										:items="purchaseStates"
										label="Estado"
										item-text="text"
										item-value="value"
										v-model="filter.state"
										outlined
									>
									<template v-slot:item="{ item }">
										<v-chip dark :color="getColorForState(item.value)">
											{{ getDescriptionForState(item.value) }}
										</v-chip>
									</template>
									<template v-slot:selection="{ item }">
										<v-chip dark :color="getColorForState(item.value)">
											{{ getDescriptionForState(item.value) }}
										</v-chip>
									</template>
								</v-select>
								</v-col>
								<v-col cols="12" lg="4">
									<v-select
										:items="getProvidersFilter"
										label="Proveedor"
										item-text="text"
										item-value="value"
										v-model="filter.provider"
										outlined
									>
									</v-select>
								</v-col>
							</v-row>
						</v-container>
					</template>
					<template v-slot:item.purchase_state="{ item }">
						<v-chip dark :color="getColorForState(item.purchase_state)">
							{{ getDescriptionForState(item.purchase_state) }}
						</v-chip>
					</template>
					<template v-slot:item.payment_state="{ item }">
						<v-chip dark :color="getColorForState(item.payment_state)">
							{{ getDescriptionForState(item.payment_state) }}
						</v-chip>
					</template>
					<template v-slot:item.pending="{ item }">
						<div :class="{'red--text': getTotalPendingToPay(item) > 0}">
							{{ formatCurrency(getTotalPendingToPay(item)) }}
						</div>
					</template>
					<template v-slot:item.total_amount="{ item }">
						{{ formatCurrency(item.total_amount) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('LEC.COM.COM')" title="Ver Detalle" class="ma-2" fab x-small @click="viewPurchase(item.id)">
							<v-icon> mdi-eye </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.COM.COM') && showUpdateButton(item)" class="ma-2" fab x-small @click="redirectWithParam('PurchaseUpdate', { id: item.id })">
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.COM.COM')" class="ma-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.INV.STK')" title="Depositar" class="ma-2" fab x-small @click="depositPurchaseItem(item)">
							<v-icon> mdi-cube-outline </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.COM.PAG')" title="Pagar" class="ma-2" fab x-small @click="redirectWithParam('PaymentCreateTo', { id: item.id, type: 'purchase' })">
							<v-icon> mdi-cash-sync </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		</v-container>
	</div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import DialogConfirmed from "@/components/dialog-confirmed.vue";
import DialogPayment from "@/components/purchasing/dialog-payment.vue";
import Title from "@/components/title-1.vue";
import PurchaseDetail from "./purchase-detail.vue";

export default {
	components: {DialogDelete, DialogConfirmed, DialogPayment, Title, PurchaseDetail },
	data: () => {
		return {
			filter: {
				date: [
					null,
					null
				],
				state: null,
				provider: null
			},
			tab: null,
			loading: false,
			totalAudits: 0,
			dialogProduct: true,
			options: {},
      menu: false,
			purchaseStates: [
				{
					value: null,
					text: 'Todos'
				},
				{
					value: 'CREATED',
					text: 'Depósito Pendiente'
				},
				{
					value: 'PENDING_PAYMENT',
					text: 'Pendiente de Pago'
				},
				{
					value: 'PARTIAL_PAYMENT',
					text: 'Pago Parcial'
				},
				{
					value: 'COMPLETED',
					text: 'Completado'
				}
			],
			total: 0,
			detail: {
				dialog: false,
				purchase: null
			},
			headers: [
				{ text: "Proveedor", value: "provider.alias" },
				{ text: "Monto Total", value: "total_amount" },
				{ text: "Pendiente a Pagar", value: "pending" },
				{ text: "Estado de Compra", value: "purchase_state" },
				{ text: "Estado de Pago", value: "payment_state" },
				{ text: "Fecha de Compra", value: "issue_date_custom" },
				{ text: "Acciones", value: "actions" },
			],
			dialogDelete: false,
			deleteLoad: false,
			depositPurchase: {
				load: false,
				dialog: false
			},
			payment: {
				load: false,
				dialog: false
			}
		};
	},
	created: async function () {
		this.filter.date = [
			this.$moment((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)).subtract(14, 'days').format('YYYY-MM-DD'),
			(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
		];

		this.loading = true;
		if(this.$store.state.Purchase.purchases.length == 0)
			await this.$store.dispatch('Purchase/get');

		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');

		if(this.$store.state.Provider.providers.length == 0)
			await this.$store.dispatch('Provider/get');

		if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');
		this.loading = false;
	},
	computed: {
		...mapState("Purchase", ["purchases"]),
		...mapState("Provider", ['providers']),
		...mapGetters('Auth', ['hasAccess']),
		...mapGetters('Purchase', ['getPurchaseDetail']),

		filterDateText(){
			return this.filter.date.join(' ~ ')
		},

		getProvidersFilter() {
			let providers = [];

			this.providers.forEach(element => {
				providers.push({
					value: element.id,
					text: element.alias
				})
			});

			providers = providers.sort((a, b) => a.text.localeCompare(b.text));

			providers.unshift({
				value: null,
				text: 'Todos'
			})

			return providers;
		},

		getPurchases() {

			if(!this.filter.date && !this.filter.state){
				return this.purchases;
			}

			let purchases = [].concat(this.purchases);

			if(this.filter.state){
				purchases = purchases.filter(element => element.purchase_state == this.filter.state);
			}

			if(this.filter.date){
				purchases = purchases.filter(element => {
					let fechaDesde = this.$moment(this.filter.date[0]).subtract(1, 'days');
					let fechaHasta = this.$moment(this.filter.date[1]).add(1, 'days');
					return this.$moment(element.issue_date, 'YYYY-MM-DD').isBetween(fechaDesde, fechaHasta);
				})
			}

			if(this.filter.provider){
				purchases = purchases.filter(element => element.provider_id == this.filter.provider);
			}

			return purchases;
		},
	},
	methods: {

		...mapActions("Purchase", ['delete']),

		...mapActions("Purchase", {
			depositPurchaseAction: "depositPurchase"
		}),

		customFilter(value, search, item){
			return search != null && item.purchase_state == search
		},

		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},

		getTotalPendingToPay(purchase){
			return purchase.total_amount - purchase.payments.reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.total_amount), 0);
		},

		showUpdateButton(item){
			return item.purchase_state == 'CREATED' && item.payment_state == 'PENDING_PAYMENT';
		},

		viewPurchase: async function(id){
			await this.$store.dispatch('Purchase/find', id)
			this.detail.dialog = true;
			this.detail.purchase = Object.assign({}, this.getPurchaseDetail);
		},

		updateDialogDetail(value){
			this.detail.dialog = value;
		},

		newDateChanged(date){
			this.search = date;
		},

		updatePurchaseDetail(value){
			this.detail.purchase = value;
		},

		getColorForState(state) {
			if (state == 'CREATED') return "cyan";
			if (state == 'PENDING_PAYMENT') return "orange";
			if (state == 'PARTIAL_PAYMENT') return "purple";
			if (state == 'COMPLETED') return "green";
			else return "grey";
		},

		getDescriptionForState(state) {
			if (state == 'CREATED') return "Depósito Pendiente";
			if (state == 'PENDING_PAYMENT') return "Pendiente de Pago";
			if (state == 'PARTIAL_PAYMENT') return "Pago Parcial";
			if (state == 'COMPLETED') return "Completado";
			else return "Todos";
		},

		deledit(item) {
			this.editedIndex = this.purchases.indexOf(item);
			this.form = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "RoleUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		depositPurchaseItem(item){
			this.editedIndex = this.purchases.indexOf(item);
			this.form = Object.assign({}, item);
			this.depositPurchase.dialog = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.form.id);
			this.$store.dispatch('Purchase/get');
			this.$store.dispatch('Product/get');
			this.$store.dispatch('Provider/get');
			this.close(this.dialogDelete);
			this.formLoad = false;
			this.deleteLoad = false;
			this.dialogDelete = false;
		},

		depositPurchaseConfirm: async function () {
			this.depositPurchase.load = true;
			this.formLoad = true;
			await this.depositPurchaseAction(this.form);
			await this.$store.dispatch('Purchase/get');
			this.$store.dispatch('Product/get');
			this.$store.dispatch('PriceList/get');
			this.close(this.dialogProduct);
			this.formLoad = false;
			this.depositPurchase.load = false;
			this.depositPurchase.dialog = false;
		},

		paymentConfirm: async function(){
			this.payment.load = true;
			this.formLoad = true;
			await this.depositPurchaseAction(this.form);
			await this.$store.dispatch('Purchase/get');
			this.$store.dispatch('Product/get');
			this.close(this.dialogDelete);
			this.formLoad = false;
			this.payment.load = false;
		},

		close(dialog) {
			dialog = false;
			return dialog;
		},
	},
};
</script>
