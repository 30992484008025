var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Ventas" } }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c(
            "dialog-delete",
            {
              attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
              on: {
                close: function ($event) {
                  _vm.dialogDelete = false
                },
                confirm: _vm.deleteItemConfirm,
              },
            },
            [
              _c("h5", { staticClass: "text-subtitle-1" }, [
                _vm._v("¿Que pasa al eliminar esta venta?"),
              ]),
              _c("br"),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Si la venta fue cobrada, se reversarán los cobros realizados y se depositarán en los fondos correspondientes a los métodos de pagos utilizados."
                  ),
                ]),
                _c("li", [
                  _vm._v(
                    "Se generará el aumento de stock de cada producto en la venta."
                  ),
                ]),
              ]),
            ]
          ),
          _c("SaleDetail", {
            attrs: {
              dialogOut: _vm.detail.dialog,
              single: true,
              saleOut: _vm.detail.sale,
            },
            on: {
              "update:dialog": this.updateDialogDetail,
              "update:sale": this.updateSaleDetail,
            },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.hasAccess("ESC.VEN.VEN")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            fab: "",
                            dark: "",
                            color: "primary",
                            to: "/saling/sale/store",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-plus "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "dialog",
                      attrs: {
                        "return-value": _vm.filters.dates,
                        persistent: "",
                        width: "290px",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.filters, "dates", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.filters, "dates", $event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: "Rango de fechas",
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dateRangeText,
                                        callback: function ($$v) {
                                          _vm.dateRangeText = $$v
                                        },
                                        expression: "dateRangeText",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.modal,
                        callback: function ($$v) {
                          _vm.modal = $$v
                        },
                        expression: "modal",
                      },
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            range: "",
                            "selected-items-text": "2 seleccionados",
                            locale: "es-ar",
                          },
                          model: {
                            value: _vm.filters.dates,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "dates", $$v)
                            },
                            expression: "filters.dates",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.modal = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.$refs.dialog.save(_vm.filters.dates)
                                  _vm.actualizarVista()
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Buscar venta...", required: "" },
                    model: {
                      value: _vm.search.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.search, "name", $$v)
                      },
                      expression: "search.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                [
                  _c("CardInfoNumber", {
                    attrs: {
                      title: "Montos Totales",
                      number: _vm.getTotalAmountChargedForFund,
                      "text-color": "blue",
                      "number-size": "6",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                [
                  _c("CardInfoNumber", {
                    attrs: {
                      title: "Total de Ventas",
                      number: _vm.formatCurrency(_vm.totalAmountPeriod),
                      "text-color": "green",
                      "number-size": "4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                [
                  _c("CardInfoNumber", {
                    attrs: {
                      title: "Cantidad de Ventas",
                      number: _vm.getTotalCountSale,
                      "text-color": "green",
                      "number-size": "4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                [
                  _c("CardInfoNumber", {
                    attrs: {
                      title: "Pendiente a Cobrar",
                      number: _vm.formatCurrency(_vm.getTotalAmountPending),
                      "text-color": "purple",
                      "number-size": "4",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [_c("v-divider")], 1),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("h4", [_vm._v("Vista")]),
              ]),
              _c(
                "v-col",
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-5 mr-2 my-1",
                      attrs: {
                        color: _vm.viewActive.sale ? "primary" : "secondary",
                        medium: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.viewActive.sale = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-view-grid-outline"),
                      ]),
                      _vm._v(" Por venta"),
                    ],
                    1
                  ),
                  _c(
                    "v-chip",
                    {
                      staticClass: "ml-2 mr-2 my-1",
                      attrs: {
                        color: !_vm.viewActive.sale ? "primary" : "secondary",
                        medium: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.viewActive.sale = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-view-grid-outline"),
                      ]),
                      _vm._v(" Por producto"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.viewActive.sale
                ? _c(
                    "v-col",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.sales,
                          search: _vm.search.name,
                          "no-data-text": "No hay ventas...",
                          "loading-text": "Cargando ventas...",
                          "footer-props": {
                            itemsPerPageOptions: [5, 10, 50, 100],
                            itemsPerPageText: "Filas por página:",
                          },
                          loading: _vm.loading,
                          "sort-by": "created_at",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.id",
                              fn: function ({ item }) {
                                return [_vm._v(" #" + _vm._s(item.id) + " ")]
                              },
                            },
                            {
                              key: "item.sale_state",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: _vm.getColorForState(
                                          item.sale_state
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDescriptionForState(
                                              item.sale_state
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.charge_state",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: _vm.getColorForState(
                                          item.charge_state
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDescriptionForState(
                                              item.charge_state
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.pending",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "red--text":
                                          _vm.getTotalPendingToCharge(item) > 0,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.getTotalPendingToCharge(item)
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.seller",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getSeller(item.seller)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.payment_methods",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getPaymentMethodsDetail(
                                          item.payment_methods ?? null
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.total_amount",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "text-subtitle-1 primary--text",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatCurrency(item.total_amount)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.actions",
                              fn: function ({ item }) {
                                return [
                                  _vm.hasAccess("LEC.VEN.VEN")
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            title: "Ver Detalle",
                                            fab: "",
                                            "x-small": "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.viewSale(item.id)
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v(" mdi-eye ")])],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.hasAccess("ESC.VEN.VEN")
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { fab: "", "x-small": "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteItem(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-delete "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.hasAccess("ESC.VEN.COB")
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            title: "Cobrar",
                                            fab: "",
                                            "x-small": "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.redirectWithParam(
                                                "ChargeCreateTo",
                                                { id: item.id, type: "sale" }
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(" mdi-cash-sync "),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2092027669
                        ),
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headersToProductsBySale,
                          items: _vm.productsBySale,
                          search: _vm.search.name,
                          "no-data-text": "No hay ventas...",
                          "loading-text": "Cargando ventas...",
                          "footer-props": {
                            itemsPerPageOptions: [5, 10, 50, 100],
                            itemsPerPageText: "Filas por página:",
                          },
                          loading: _vm.loading,
                          "sort-by": "created_at",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item.total_amount",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "text-subtitle-1 primary--text",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatCurrency(item.total_amount)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item.count",
                            fn: function ({ item }) {
                              return [
                                _c("h4", { staticClass: "text-subtitle-1" }, [
                                  _vm._v(_vm._s(item.count)),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "item.price",
                            fn: function ({ item }) {
                              return [
                                _c("h4", { staticClass: "text-subtitle-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.formatCurrency(item.price))
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }