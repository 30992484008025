<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-users" @submit.prevent="save">
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-text-field
									required
									v-model="form.name"
									label="Nombre"
									:error-messages="nameErrors"
									@change="$v.form.name.$touch()"
									@blur="$v.form.name.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="4">
								<v-select
									:items="depositTypes"
									item-text="name"
									item-value="id"
									v-model="form.deposit_type"
									label="Tipo de Depósito"
									:error-messages="depositTypeErrors"
									@change="$v.form.deposit_type.$touch()"
									@blur="$v.form.deposit_type.$touch()"
								></v-select>
							</v-col>
							<v-col cols="4">
								<v-select
									:items="branchs"
									item-text="name"
									item-value="id"
									v-model="form.branch_id"
									label="Sucursal"
									:error-messages="branchErrors"
									@change="$v.form.branch_id.$touch()"
									@blur="$v.form.branch_id.$touch()"
								></v-select>
							</v-col>
							<v-col cols="8">
								<v-textarea
									solo
									v-model="form.detail"
									label="Detalle..."
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						$router.back();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-users"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
	validations: {
		form: {
			deposit_type: { required },
			name: { required },
			branch_id: { required },
		},
	},
	data: () => {
		return {
			active: [],
			open: [],
			dialog: true,
			dialogParent: false,
			loading: false,
			disabled: false,
			form: {
				id: null,
				name: "",
				detail: "",
				branch_id: null,
				deposit_type: 'COMMERCE',
			},
			depositTypes: [
				{id: 'COMMERCE', name: 'Comercial'},
				{id: 'PERSONAL', name: 'Personal'},
			],
			branchs: [],
		};
	},
	beforeCreate: async function () {
		if (this.$store.state.Branch.branches.length == 0)
			await this.$store.dispatch("Branch/get");
	},
	created: async function () {
		if (this.$route.params.id){
			if (this.$store.state.Deposit.deposits.length == 0)
				await this.$store.dispatch("Deposit/get");
			this.form = Object.assign({}, this.getDeposit(this.$route.params.id));
		}
		this.branchs = this.getBranchs;
	},
	computed: {
		depositTypeErrors() {
			const errors = [];
			if (!this.$v.form.deposit_type.$dirty) return errors;
			!this.$v.form.deposit_type.required &&
				errors.push("El tipo de depósito es requerido");
			return errors;
		},

		branchErrors() {
			const errors = [];
			if (!this.$v.form.branch_id.$dirty) return errors;
			!this.$v.form.branch_id.required &&
				errors.push("La sucursal es requerida");
			return errors;
		},

		nameErrors() {
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
			!this.$v.form.name.required && errors.push("El nombre es requerido");
			return errors;
		},

		title() {
			return this.$route.params.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},

		...mapGetters("Branch", ["getBranchs"]),

		...mapGetters("Deposit", ["getDeposit"]),
	},
	methods: {
		...mapActions("Deposit", ["store"]),

		beforeSave() {},
		successSave() {
			this.$store.dispatch("Deposit/get")
		},
		completeSave() {},
		save() {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						name: this.form.name,
						detail: this.form.detail,
						branch_id: this.form.branch_id,
						deposit_type: this.form.deposit_type,
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
