<template>
	<v-card elevation="4" :min-height="minHeight">
		<v-card-title>
			{{ title }}
		</v-card-title>
		<v-card-text>
			<div v-if="Array.isArray(number)" width="100%" class="text-left">
				<div v-for="(item, index) in number" :key="index">
					<p class="d-inline">{{ item.title }} </p>
					<p class="d-inline" :class="getClass">{{ item.value }}</p>
				</div>
			</div>
			<div v-else width="100%" class="text-center">
				<p :class="getClass"> {{ number }}</p>
			</div>
		</v-card-text>
	</v-card>
</template>
<script>

	export default {
		props: {
			title: {
				default: '',
			},
			number: {
				default: '',
			},
			numberSize: {
				default: '2'
			},
			textColor: {
				default: 'blue-grey'
			},
			minHeight:{
				default: 100
			}
		},
		computed: {
			getSize(){
				return "text-h" + this.numberSize;
			},
			getTextColor(){
				return this.textColor + '--text'
			},
			getClass(){
				return this.getSize + ' ' + this.getTextColor;
			}
		}
	};

</script>
