var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "9", md: "9", sm: "12" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.getProductsToLoad,
                  clearable: "",
                  filled: "",
                  dense: "",
                  filter: _vm.productToLoadFilter,
                  "item-text": _vm.productItemText,
                  "item-value": "id",
                  hint: "Usando " + _vm.priceList.name,
                  "persistent-hint": "",
                  loading: _vm.loading.product,
                  label: "Productos",
                },
                on: {
                  change: function ($event) {
                    return _vm.changeProduct()
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "append-outer",
                    fn: function () {
                      return [
                        _vm.cart.product?.id
                          ? _c(
                              "v-chip",
                              {
                                staticStyle: { "margin-top": "-5px" },
                                attrs: {
                                  color:
                                    _vm.getStock <= _vm.getMinStock
                                      ? "error"
                                      : "success",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.getStock) + " ")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c("v-list-item", [
                          _c("div", { staticClass: "pa-2" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-body-1 font-weight-medium text-capitalize ma-0",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.getCategory(item.category_id)?.name
                                  ) +
                                    " " +
                                    _vm._s(item.name) +
                                    " " +
                                    _vm._s(item.attributes_name)
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _c(
                          "v-list-item-action",
                          { staticClass: "float-right" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  color:
                                    item.stock.count <= _vm.getMinStock
                                      ? "error"
                                      : "success",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.stock.count) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.cart.product,
                  callback: function ($$v) {
                    _vm.$set(_vm.cart, "product", $$v)
                  },
                  expression: "cart.product",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6", lg: "2", md: "2", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  clearable: "",
                  filled: "",
                  dense: "",
                  label: "Precio",
                },
                model: {
                  value: _vm.cart.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.cart, "price", $$v)
                  },
                  expression: "cart.price",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", lg: "1", md: "1", sm: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2 mt-3",
                  attrs: { fab: "", small: "", dark: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addProduct()
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v(" mdi-plus ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-iterator", {
                attrs: {
                  items: _vm.productsCharged,
                  "no-data-text": "No se encontraron datos, aún...",
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "v-container",
                          _vm._l(props.items, function (item) {
                            return _c(
                              "v-row",
                              { key: item.id, staticClass: "pb-2" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 d-inline",
                                    attrs: { cols: "4", lg: "5" },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-body-2 font-weight-light text-capitalize ma-0",
                                      },
                                      [_vm._v("#" + _vm._s(item.code) + " ")]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-body-1 font-weight-medium text-capitalize ma-0",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCategory(item.category_id)
                                              ?.name
                                          ) +
                                            " " +
                                            _vm._s(item.name) +
                                            " " +
                                            _vm._s(item.attributes_name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm.getStocksByProduct(item).length > 0
                                      ? _c("v-select", {
                                          attrs: {
                                            items: _vm.getStocksByProduct(item),
                                            "item-text": "name",
                                            value:
                                              _vm.getStocksByProduct(item)[0],
                                            dense: "",
                                            "return-object": "",
                                            "item-value": "id",
                                          },
                                          model: {
                                            value: item.stock,
                                            callback: function ($$v) {
                                              _vm.$set(item, "stock", $$v)
                                            },
                                            expression: "item.stock",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 mt-0",
                                    attrs: { cols: "4", lg: "2" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        prefix: "$",
                                        outlined: "",
                                        "hide-details": "",
                                        dense: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.editProduct(item)
                                        },
                                      },
                                      model: {
                                        value: item.unit_price,
                                        callback: function ($$v) {
                                          _vm.$set(item, "unit_price", $$v)
                                        },
                                        expression: "item.unit_price",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 mt-0",
                                    attrs: { cols: "4", lg: "1" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.editProduct(item)
                                        },
                                      },
                                      model: {
                                        value: item.count,
                                        callback: function ($$v) {
                                          _vm.$set(item, "count", $$v)
                                        },
                                        expression: "item.count",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 mt-0 text-center",
                                    attrs: { cols: "5", lg: "3" },
                                  },
                                  [
                                    _c("p", { staticClass: "text-h5 mt-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatCurrency(
                                            item.unit_price *
                                              (parseInt(item.count)
                                                ? item.count
                                                : 0)
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pb-0 mt-0 text-center",
                                    attrs: { cols: "3", lg: "1" },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          fab: "",
                                          dark: "",
                                          dense: "",
                                          "x-small": "",
                                          color: "red",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteProduct(item.id)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { dark: "" } }, [
                                          _vm._v(" mdi-delete "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("v-col", [_c("v-divider")], 1),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }