var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [_c("v-col", [_c("h2", [_vm._v("Usuarios")])])],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                fab: "",
                                dark: "",
                                color: "indigo",
                                to: "/administration/user/store",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v(" mdi-plus "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              counter: 10,
                              label: "Buscar usuario...",
                              required: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          [
                            _c("v-data-table", {
                              staticClass: "elevation-1",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.users,
                                "items-per-page": 5,
                              },
                            }),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "col-12" },
                        [
                          _c(
                            "v-card",
                            { attrs: { elevation: "4", outlined: "" } },
                            [
                              _c("v-card-title", [_vm._v(" Roles ")]),
                              _c(
                                "v-card-text",
                                { staticClass: "pa-4" },
                                [
                                  _vm._l(this.roles, function (role) {
                                    return _c(
                                      "v-list-item",
                                      { key: role.id },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(role.name)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-center" },
                                    [
                                      _c("v-pagination", {
                                        attrs: { length: 1, circle: "" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        fab: "",
                                        "x-small": "",
                                        text: "",
                                        to: "administration/role/store",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-plus")])],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        fab: "",
                                        "x-small": "",
                                        text: "",
                                        to: "administration/role",
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-view-headline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        fab: "",
                                        "x-small": "",
                                        text: "",
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-help")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }