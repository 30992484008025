import { render, staticRenderFns } from "./customer-data.vue?vue&type=template&id=bc44c46e&"
import script from "./customer-data.vue?vue&type=script&lang=js&"
export * from "./customer-data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\osval\\vue_projects\\spa.lencerp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bc44c46e')) {
      api.createRecord('bc44c46e', component.options)
    } else {
      api.reload('bc44c46e', component.options)
    }
    module.hot.accept("./customer-data.vue?vue&type=template&id=bc44c46e&", function () {
      api.rerender('bc44c46e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/saling/customer/customer-data.vue"
export default component.exports