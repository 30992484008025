<template>
	<div>
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-sucursales"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col>
									<p class="text-body-1">Si no envia el monto de apertura, se abrira con el monto de cierre de la caja anterior.</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col lg="3" md="4">
									<v-text-field
										v-model="form.amount_current"
										required
										label="Monto de apertura"
										prefix="$"
										:error-messages="amountCurrentErrors"
										@change="$v.form.amount_current.$touch()"
										@blur="$v.form.amount_current.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-sucursales"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

import { integer } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';

export default {
	validations: {
		form: {
			amount_current: { integer }
		}
	},
	data: () => {
		return {
			title: "Caja Diaria",
			dialog: true,
			formValid: false,
			formLoad: false,
			form: {
				id: null,
				amount_current: null
			}
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},

	created: async function () {
		if(this.$store.state.DailyBox.daily_boxes.length == 0)
			await this.$store.dispatch('DailyBox/get')
		if (this.$route.params.id){
			this.form = Object.assign({}, this.getDailyBox(this.$route.params.id));
		}
	},
	computed: {
		...mapGetters('DailyBox', ['getDailyBox']),

		amountCurrentErrors() {
			const errors = [];
			if (!this.$v.form.amount_current.$dirty) return errors;
			!this.$v.form.amount_current.integer &&
				errors.push("El monto de apertura debe ser un número");
			return errors;
		},
	},
	methods: {

		...mapActions('DailyBox', ['store']),

		close() {
			this.$nextTick(() => {
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
		},

		successSave: async function () {
			await this.$store.dispatch("DailyBox/get");
			this.$store.dispatch('Fund/get');
			this.$router.back();
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						amount_current: this.form.amount_current
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
