<template>
	<v-container>
		<v-row>
			<v-col cols="4">
				<v-text-field
					required
					v-model="value"
					label="Valor"
				></v-text-field>
			</v-col>
			<v-col>
				<v-btn
					class="mx-2"
					fab
					dark
					color="primary"
					@click="addValue"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" lg="12">
				<v-chip
					class="ma-2"
					v-for="item in values" :key="item"
					close
					@click:close="deleteItem(item)">
					{{ item }}
				</v-chip>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>

	export default {
		props: {
			inValues: {
				default: []
			}
		},
		data: () => {
			return {
				value: null,
				values: []
			}
		},
		created: async function (){
			this.values = this.inValues;
		},
		methods: {
			addValue(){
				if(!this.value)
					return;
				if(this.values.includes(this.value.trim()))
					return;
				this.values.push(this.value.trim());
				this.$emit('list-updated', this.values)
			},
			deleteItem(value){
				let values = this.values.filter(element => element != value);
				this.values = [].concat(values);
				this.$emit('list-updated', this.values)
			}
		}
	};

</script>
