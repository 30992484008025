import axios from "axios";

export default {
	namespaced: true,
	state: {
		surcharges: [],
		isChanged: false
	},
	mutations: {
		SET_SURCHARGES(state, data) {
			state.surcharges = data;
		},
		SET_IS_CHANGED(state, data){
			state.isChanged = data;
		}
	},
	actions: {
		setDataChanged({ commit }){
			commit('SET_IS_CHANGED', true);
		},
		get: async function ({ state, commit }) {
			if(state.surcharges.length == 0 || state.isChanged){
				commit('SET_IS_CHANGED', false);
				return await axios.get("surcharge").then((response) => {
					commit("SET_SURCHARGES", response.data);
				}).catch(() => {});
			}
		},
		create: async function ({ commit }, params) {
			return axios
				.post("surcharge", params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("surcharge/" + params.id, params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("surcharge/" + params.id)
				.then(() => {})
				.catch(() => {});
		},
	},
	getters: {
		getSurcharge: (state) => (surchargeId) => {
			if (state.surcharges)
				return state.surcharges.filter((prop) => prop.id == surchargeId)[0];
			else return "";
		},
		getFirstSurcharge: (state) => {
			if (state.surcharges)
				return state.surcharges[0];
			else return "";
		},
	},
};
