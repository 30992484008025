<template>
  	<v-container>
      <v-row>
        <v-col cols="6">
          <v-combobox
            :items="list.categories"
            item-text="name"
            item-value="id"
            outlined
            @change="loadAttributeValues();addProduct()"
            hide-details
            auto-select-first
            v-model="form.category"
            label="Categoria"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <v-row><v-divider class="ma-2"></v-divider></v-row>
      <v-row>
        <v-col cols="4" v-for="(item, index) in form.attributesValues" :key="index">
          <div v-if="getCategoryAttribute(item.id)?.values.length > 0">
            <v-select
              v-model.number="item.value"
              :items="getCategoryAttribute(item.id)?.values ?? []"
              item-text="value"
              item-value="id"
              @change="addProduct()"
              outlined
              required
              hide-details
              dense
              :label="item.name"
            ></v-select>
          </div>
          <div v-else>
            <v-text-field
              v-model="item.value"
              :label="item.name"
              outlined
              @input="addProduct()"
              dense
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            required
            outlined
            dense
            @input="addProduct()"
            v-model="form.description"
            label="Descripción"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="elevation-1">
          <h4 class="text-subtitle-2">Vista previa</h4>
          <h6 class="text-body-1 mt-1">{{ form.category.name }} {{ fullName }} </h6>
        </v-col>
      </v-row>
      <v-row v-if="withAddOption" class="mt-5">
        <v-btn
            color="blue darken-1"
            text
            @click="closeForm()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="loadProduct()"
          >
            Agregar
          </v-btn>
      </v-row>
    </v-container>
</template>
<script>

import { mapGetters } from "vuex";

export default {
  props: {
    product: {
      default: null
    },
    withAddOption: {
      default: true
    },
    withSingleForm: {
      default: true
    }
  },
  data () {
		return {
      form: {
        description: "",
        category: {},
        attributesValues: [],
        attributesName: ''
      },
      list: {
        categories: [],
      }
    }
	},
  created: async function() {

    if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');

    if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');

    if(this.product) {
      this.form = this.product
    }

    if(!this.form.category) {
      this.setInitialValues();
    }
  },

  beforeUpdate(){
    this.formatAttributeValues()
  },

  watch: {
    product(newValue){
      if(!newValue.category)
        return this.setInitialValues();
      this.form = newValue;
      this.formatAttributeValues();
    }
  },

  computed: {

    ...mapGetters('Category', ['getCategories', 'getCategory', 'getFirstCategory']),
		...mapGetters('CategoryAttribute', ['getCategoryAttribute']),

    attributesName() {
      let values = [];

      this.form.attributesValues.forEach(element => {
        if(this.getCategoryAttribute(element.id)?.values.length)
          values.push(this.getCategoryAttribute(element.id)?.values.filter(item => item.id == element.value)[0]?.value)
        else
          values.push(element.value)
      });

      values.sort();

      return values.join(' ');
    },

    fullName() {
      return this.attributesName + ' ' + this.form.description;
    }

  },

  methods: {

    setInitialValues(){
      this.list.categories = this.getCategories;

      this.form.category = this.getFirstCategory;

      this.loadAttributeValues();
    },

    formatAttributeValues(){
      let attributeValues = [].concat(this.form.attributesValues);
      this.form.attributesValues = [];
      attributeValues.forEach(element => {
        this.form.attributesValues.push({
          id: element.id,
          name: this.getCategoryAttribute(element.id).name,
          value: element.value
        })
      });
    },

    loadAttributeValues() {
      this.form.attributesValues = [];
			let attributesValues = this.getCategory(this.form.category.id).attributes;
			attributesValues.forEach(element => {
        if(element.non_discriminatory) {
          return;
        }

        this.form.attributesValues.push({
          id: element.id,
          name: element.name,
          value: element.values.length > 0 ? element.values[0].id : '',
        })
			});
    },

    cleanForm(){
      this.form = {
        description: "",
        category: null,
        attributesValues: [],
        attributesName: ''
      }
      this.setInitialValues();
    },

    closeForm(){
      this.$emit('close-form')
      this.cleanForm();
    },

    addProduct(){
      if(this.withSingleForm){
        return;
      }

      this.$emit('add-product', {
        id: null,
        category_id: this.form.category.id,
        attributes_name: this.attributesName,
        name: this.form.description,
        attribute_values: this.form.attributesValues
      });
    },

    loadProduct() {
      this.form.attributesName = this.attributesName;
      this.$emit('add-product', {
        id: null,
        category_id: this.form.category.id,
        attributes_name: this.attributesName,
        name: this.form.description,
        attribute_values: this.form.attributesValues
      });
      this.cleanForm();
      this.$emit('close-form');
    }

  }
}

</script>
