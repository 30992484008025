var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-4" },
    [
      _c("dialog-delete", {
        attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
        on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
      }),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h4", { staticClass: "text-h4" }, [_vm._v("Atributos")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.hasAccess("ESC.INV.ATR")
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        fab: "",
                        dark: "",
                        color: "primary",
                        to: "/inventory/attribute/store",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(" mdi-plus "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Buscar atributo...", required: "" },
                model: {
                  value: _vm.search.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.search, "name", $$v)
                  },
                  expression: "search.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.categoryAttributes,
                  search: _vm.search.name,
                  height: "700",
                  "fixed-header": true,
                  "footer-props": {
                    itemsPerPageOptions: [5, 10, 50, 100],
                    itemsPerPageText: "Filas por página:",
                  },
                  loading: _vm.loading,
                  "sort-by": "created_at",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.values",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.attributeNamesToStrings(item.values)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _vm.hasAccess("ESC.INV.ATR")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectWithParam(
                                      "CategoryAttributeUpdate",
                                      { id: item.id }
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                              1
                            )
                          : _vm._e(),
                        _vm.hasAccess("ESC.INV.ATR")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-delete ")])],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }