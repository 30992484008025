import axios from "axios";

export default {
	namespaced: true,
	state: {
		roles: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_ROLES(state, data) {
			state.roles = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return axios.get("role").then((response) => {
				commit("SET_ROLES", response.data);
			}).catch(() => {});
		},
		find: async function ({ commit }, data) {
			return axios.get("role/" + data.id).then((response) => {
				commit("SET_ROLES", response.data);
			}).catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			params.before();
			if (params.id) {
				await dispatch("update", {
					id: params.id,
					data: params.data,
					success: () => {
						params.success();
					},
				});
			} else {
				await dispatch("create", {
					data: params.data,
					success: () => {
						params.success();
					},
				});
			}
			params.complete();
		},
		create: async function ({ commit }, params) {
			return axios
				.post("role", params.data)
				.then((res) => {
					//commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					/*commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});*/
				});
		},
		update: async function ({ commit }, params) {
			return axios
				.put("role/" + params.id, params.data)
				.then((res) => {
					//commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					/*commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});*/
				});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("role/" + params.id)
				.then((res) => {
					//commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
						/*if (error.response)
						commit("SET_ERRORS", {
							value: error.response.data.errors,
							type: "error",
						});*/
				});
		},
	},
	getters: {
		getRole: (state) => (roleId) => {
			if (state.roles) {
				return state.roles.filter((prop) => prop.id == roleId)[0];
			} else null;
		},
		getFirstRole: (state) => {
			if (state.roles) {
				return state.roles[0];
			} else null;
		},
		getAccessKey: (state) => (roleId) => {
			if (state.roles) {
				let role = state.roles.filter((prop) => prop.id == roleId)[0];
				let access = [];
				for (const key in role.access) access.push(role.access[key].id);
				return access;
			} else null;
		},
	},
};
