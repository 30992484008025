<template>
	<div>
		<Title title="Ventas" ></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg" >
			<dialog-delete
				:value="dialogDelete"
				:progress="deleteLoad"
				@close="dialogDelete = false"
				@confirm="deleteItemConfirm"
			>
				<h5 class="text-subtitle-1">¿Que pasa al eliminar esta venta?</h5>
				<br>
				<ul>
					<li>Si la venta fue cobrada, se reversarán los cobros realizados y se depositarán en los fondos correspondientes a los métodos de pagos utilizados.</li>
					<li>Se generará el aumento de stock de cada producto en la venta.</li>
				</ul>
			</dialog-delete>
			<SaleDetail
				:dialogOut="detail.dialog"
				@update:dialog="this.updateDialogDetail"
				@update:sale="this.updateSaleDetail"
				:single="true"
				:saleOut="detail.sale"
			></SaleDetail>
			<v-row>
				<v-col>
					<v-btn
						class="mx-2"
						v-if="hasAccess('ESC.VEN.VEN')"
						fab
						dark
						color="primary"
						to="/saling/sale/store"
					>
						<v-icon dark> mdi-plus </v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="4">
					<v-dialog
							ref="dialog"
							v-model="modal"
							:return-value.sync="filters.dates"
							persistent
							width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="dateRangeText"
									label="Rango de fechas"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="filters.dates"
								range
								selected-items-text="2 seleccionados"
								locale="es-ar"
							>
								<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="modal = false"
								>
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.dialog.save(filters.dates)
											actualizarVista()"
								>
									OK
								</v-btn>
							</v-date-picker>
						</v-dialog>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						v-model="search.name"
						label="Buscar venta..."
						required
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						title="Montos Totales"
						:number="getTotalAmountChargedForFund"
						text-color="blue"
						number-size="6"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						title="Total de Ventas"
						:number="formatCurrency(totalAmountPeriod)"
						text-color="green"
						number-size="4"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						title="Cantidad de Ventas"
						:number="getTotalCountSale"
						text-color="green"
						number-size="4"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						title="Pendiente a Cobrar"
						:number="formatCurrency(getTotalAmountPending)"
						text-color="purple"
						number-size="4"
					></CardInfoNumber>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="12">
					<h4>Vista</h4>
				</v-col>
				<v-col>
					<v-chip :color="viewActive.sale ? 'primary' : 'secondary' " @click="viewActive.sale = true" medium class="ml-5 mr-2 my-1"><v-icon class="mr-2">mdi-view-grid-outline</v-icon> Por venta</v-chip>
					<v-chip :color="!viewActive.sale ? 'primary' : 'secondary' " @click="viewActive.sale = false" medium class="ml-2 mr-2 my-1"><v-icon class="mr-2">mdi-view-grid-outline</v-icon> Por producto</v-chip>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="viewActive.sale">
					<v-data-table
						:headers="headers"
						:items="sales"
						:search="search.name"
						no-data-text="No hay ventas..."
						loading-text="Cargando ventas..."
						:footer-props="{
							itemsPerPageOptions: [5, 10, 50, 100],
							itemsPerPageText: 'Filas por página:',
						}"
						:loading="loading"
						sort-by="created_at"
						class="elevation-1"
					>
						<template v-slot:item.id="{ item }">
							#{{ item.id }}
						</template>
						<template v-slot:item.sale_state="{ item }">
							<v-chip dark :color="getColorForState(item.sale_state)">
								{{ getDescriptionForState(item.sale_state) }}
							</v-chip>
						</template>
						<template v-slot:item.charge_state="{ item }">
							<v-chip dark :color="getColorForState(item.charge_state)">
								{{ getDescriptionForState(item.charge_state) }}
							</v-chip>
						</template>
						<template v-slot:item.pending="{ item }">
							<div :class="{'red--text': getTotalPendingToCharge(item) > 0}">
								{{ formatCurrency(getTotalPendingToCharge(item)) }}
							</div>
						</template>
						<template v-slot:item.seller="{ item }">
							{{ getSeller(item.seller) }}
						</template>
						<template v-slot:item.payment_methods="{ item }">
							{{ getPaymentMethodsDetail(item.payment_methods ?? null) }}
						</template>
						<template v-slot:item.total_amount="{ item }">
							<h4 class="text-subtitle-1 primary--text" >{{ formatCurrency(item.total_amount) }}</h4>
						</template>
						<template v-slot:item.actions="{ item }">
							<v-btn v-if="hasAccess('LEC.VEN.VEN')" title="Ver Detalle" class="ma-2" fab x-small @click="viewSale(item.id)">
								<v-icon> mdi-eye </v-icon>
							</v-btn>
							<v-btn v-if="hasAccess('ESC.VEN.VEN')" class="ma-2" fab x-small @click="deleteItem(item)">
								<v-icon> mdi-delete </v-icon>
							</v-btn>
							<v-btn v-if="hasAccess('ESC.VEN.COB')" title="Cobrar" class="ma-2" fab x-small @click="redirectWithParam('ChargeCreateTo', { id: item.id, type: 'sale' })">
								<v-icon> mdi-cash-sync </v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
				<v-col v-else>
					<v-data-table
						:headers="headersToProductsBySale"
						:items="productsBySale"
						:search="search.name"
						no-data-text="No hay ventas..."
						loading-text="Cargando ventas..."
						:footer-props="{
							itemsPerPageOptions: [5, 10, 50, 100],
							itemsPerPageText: 'Filas por página:',
						}"
						:loading="loading"
						sort-by="created_at"
						class="elevation-1"
					>
					<template v-slot:item.total_amount="{ item }">
							<h4 class="text-subtitle-1 primary--text" >{{ formatCurrency(item.total_amount) }}</h4>
						</template>
						<template v-slot:item.count="{ item }">
							<h4 class="text-subtitle-1" >{{ item.count }}</h4>
						</template>
						<template v-slot:item.price="{ item }">
							<h4 class="text-subtitle-1" >{{ formatCurrency(item.price) }}</h4>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import CardInfoNumber from "@/components/card-info-number.vue";
import SaleDetail from "./sale-detail.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, CardInfoNumber, SaleDetail, Title },
	data: () => {
		return {
			search: {
				name: "",
			},
			filters: {
				dates: [
					(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
					(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
				]
			},
			detail: {
				dialog: false,
				sale: null
			},
			viewActive: {
				sale: true
			},
			modal: false,
			tab: null,
			loading: true,
			totalAudits: 0,
			options: {},
			total: 0,
			headers: [
				{ text: "Venta", value: "id" },
				{ text: "Vendedor", value: "seller" },
				{ text: "Monto Total", value: "total_amount" },
				{ text: "Métodos de Pago", value: "payment_methods" },
				{ text: "Pendiente a Cobrar", value: "pending" },
				{ text: "Estado de Venta", value: "sale_state" },
				{ text: "Estado de Cobro", value: "charge_state" },
				{ text: "Fecha de Venta", value: "issue_date" },
				{ text: "Acciones", value: "actions" },
			],
			headersToProductsBySale: [
				{ text: "Venta", value: "sale_id" },
				{ text: "Producto", value: "product_name" },
				{ text: "Precio Unitario", value: "price" },
				{ text: "Cantidad", value: "count" },
				{ text: "Monto Total", value: "total_amount" },
				{ text: "Fecha de Venta", value: "issue_date" }
			],
			dialogDelete: false,
			deleteLoad: false,
			totalAmountPeriod: 0,
			payment: {
				load: false,
				dialog: false
			}
		};
	},
	created: async function () {
		if(this.$store.state.Product.products.length == 0)
			this.$store.dispatch('Product/get');

		if(this.$store.state.Category.categories.length == 0)
			this.$store.dispatch('Category/get');

		if(this.$store.state.PaymentMethod.paymentMethods.length == 0)
			this.$store.dispatch('PaymentMethod/get');

		this.actualizarVista();
	},
	computed: {
		...mapState("Sale", ["sales"]),
		...mapGetters('Auth', ['hasAccess']),
		...mapGetters('Fund', ['getFund']),
		...mapGetters('Product', ['getProduct']),
		...mapGetters('Category', ['getCategory']),
		...mapGetters('PaymentMethod', ['getPaymentMethod']),

		dateRangeText () {
			return this.filters.dates.join(' ~ ')
		},

		getTotalCountSale(){
			return this.sales.length;
		},

		productsBySale(){

			let products = [];

			if(this.sales.length == 0)
				return [];

			this.sales.forEach(sale => {

				sale.carts.forEach(cart => {

					let category = this.getCategory(cart.product.category_id)?.name ?? '';
					let productName = this.getProduct(cart.product.id)?.full_name ?? cart.product.name;
					let attributeDetailNames = '';

					if(cart.attribute_detail.length > 0) {

						cart.attribute_detail[0].attribute_values.forEach(element => {
							attributeDetailNames += (element.value?.value ?? element.value) + ' ';
						});

					}

					products.push({
						sale_id: '#' + cart.cartable_id,
						product_name: category + ' ' + productName + ' ' + attributeDetailNames,
						price: cart.unit_price,
						count: cart.count,
						total_amount: cart.amount,
						issue_date: sale.issue_date
					})

				});

			});

			return products;
		},

		getTotalAmount(){
			let total = 0;
			this.sales.forEach(element => {
					total += parseFloat(element.total_amount)
			});
			return total;
		},

		getTotalAmountPending(){
			let total = 0;
			this.sales.forEach(element => {
					total += parseFloat(element.total_amount - element.charges.reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.total_amount), 0))
			});
			return total;
		},

		getTotalAmountChargedForFund(){
			let chargesDetails = [];

			if(this.sales.length == 0)
				return [{title: 'No hay montos aún...'}]

			this.sales.forEach(element => {
				element.charges.forEach(element2 => {
					element2.details.forEach(element3 => {
						chargesDetails.push(element3);
					});
				});
			});

			if(chargesDetails.length == 0)
				return [{title: 'No hay montos aún...'}]

			const chargesDetailsGrouped = chargesDetails.reduce((acc, curr) => {
				//const fundType = this.getFund(curr.fund_id).title;
				const fundType = curr.payment_method?.name ?? this.getFund(curr.fund_id).title;
				if (!acc[fundType]) {
					acc[fundType] = [];
				}
				acc[fundType].push(curr);
				return acc;
			}, {})

			let itemsToShow = [];

			//Esto es por tipo de fondo
			/*for (const key in chargesDetailsGrouped) {
				let amount = chargesDetailsGrouped[key].reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.amount), 0);
				itemsToShow.push(this.getFundTypeDescription(key) + ' ' + this.formatCurrency(amount));
			}*/

			this.totalAmountPeriod = 0;

			for (const key in chargesDetailsGrouped) {
				let amount = chargesDetailsGrouped[key].reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.amount), 0);
				this.totalAmountPeriod += parseFloat(amount);
				itemsToShow.push({
					title: key,
					value: this.formatCurrency(amount)
				});
			}

			return itemsToShow;
		}
	},
	methods: {

		actualizarVista: async function(){
			this.loading = true;
			if(this.$store.state.Fund.funds.length == 0)
				await this.$store.dispatch('Fund/get');
			await this.$store.dispatch('Sale/get', {
				filters: {
					from: this.filters.dates[0],
					to: this.filters.dates[1]
				}
			})
			this.loading = false;
		},

		...mapActions("Sale", ['delete']),

		getPaymentMethodsDetail(paymentMethods){
			let details = [];

			if(!paymentMethods)
				return 'No disponible';

			if(paymentMethods.length == 0)
				return 'No disponible';

			paymentMethods.forEach(element => {
					details.push(this.getPaymentMethod(element.id).name)
			});

			return details.join(', ');
		},

		getTotalPendingToCharge(sale){
			return sale.total_amount - sale.charges.reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.total_amount), 0);
		},

		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},

		getColorForState(state) {
			if (state == 'PENDING_CHARGE') return "orange";
			if (state == 'PARTIAL_CHARGE') return "purple";
			else return "green";
		},

		getDescriptionForState(state) {
			if (state == 'PENDING_CHARGE') return "Pendiente de Cobro";
			if (state == 'PARTIAL_CHARGE') return "Cobro Parcial";
			else return "Completado";
		},

		getSeller(seller){
			if(!seller)
				return '';
			if(seller.entity.entity_type == 'PEOPLE')
				return seller.entity.who.first_name + ' ' + seller.entity.who.surname;
			return seller.entity.who.alias;
		},

		deledit(item) {
			this.editedIndex = this.sales.indexOf(item);
			this.form = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "SaleUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.form.id);
			await this.actualizarVista();
			await this.$store.dispatch('Fund/get');
			await this.$store.dispatch('DailyBox/get');
			await this.$store.dispatch('Product/get');
			this.close(this.dialogDelete);
			this.formLoad = false;
			this.deleteLoad = false;
			this.dialogDelete = false;
		},

		updateDialogDetail(value){
			this.detail.dialog = value;
		},

		updateSaleDetail(value){
			this.detail.sale = value;
		},

		rowClicked(event){
			const row = this.sales[event.currentTarget.rowIndex - 1];
			this.detail.dialog = true;
			this.detail.sale = Object.assign({}, row);
		},

		viewSale(id){
			const row = this.sales.filter(prop => prop.id == id)[0];
			this.detail.dialog = true;
			this.detail.sale = Object.assign({}, row);
		},

		close(dialog) {
			dialog = false;
			return dialog;
		},
	},
};
</script>
