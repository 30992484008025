import axios from "axios";

export default {
	namespaced: true,
	state: {
		categoryAttributes: []
	},
	mutations: {
		SET_CATEGORY_ATTRIBUTES(state, data) {
			state.categoryAttributes = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("product/category/attribute").then((response) => {
				commit("SET_CATEGORY_ATTRIBUTES", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("product/category/attribute", params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("product/category/attribute/" + params.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("product/category/attribute/" + id)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
	},
	getters: {
		getCategoryAttribute: (state) => (categoryAttributeId) => {
			if (state.categoryAttributes)
				return state.categoryAttributes.filter((prop) => prop.id == categoryAttributeId)[0];
			else return "";
		},
	},
};
