var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.$router.back()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-payment-method" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.funds,
                                  "prepend-icon": "mdi-bank-outline",
                                  "item-text": "title",
                                  "item-value": "id",
                                  loading: _vm.loading.fund,
                                  "error-messages": _vm.fundErrors,
                                  label: "Fondo Asociado",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.fund.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.fund.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.fund,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fund", $$v)
                                  },
                                  expression: "form.fund",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "8" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Nombre",
                                  "error-messages": _vm.nameErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.name.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  hint: "En 0 la liquidación es inmediata",
                                  suffix: "dias",
                                  required: "",
                                  label: "Liquidación en Dias",
                                  "error-messages": _vm.settlementRateErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.settlement_rate.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.settlement_rate.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.settlement_rate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "settlement_rate", $$v)
                                  },
                                  expression: "form.settlement_rate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  hint: "Se aplica para todas las ventas",
                                  suffix:
                                    _vm.form.discount.type == "FIXED"
                                      ? "Fijo"
                                      : "%",
                                  required: "",
                                  label: "Valor de Descuento",
                                  "error-messages": _vm.discountValueErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.discount.value.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.discount.value.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.discount.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.discount, "value", $$v)
                                  },
                                  expression: "form.discount.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.form.discount.types,
                                  hint: "Se aplica para todas las ventas",
                                  suffix:
                                    _vm.form.discount.type == "FIXED"
                                      ? "Fijo"
                                      : "%",
                                  "item-text": "value",
                                  "item-value": "id",
                                },
                                model: {
                                  value: _vm.form.discount.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.discount, "type", $$v)
                                  },
                                  expression: "form.discount.type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Redondeo aplicado",
                                  "error-messages": _vm.discountRoundErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.discount.round.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.discount.round.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.discount.round,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.discount, "round", $$v)
                                  },
                                  expression: "form.discount.round",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "2",
                                  solo: "",
                                  label: "Detalle...",
                                },
                                model: {
                                  value: _vm.form.detail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "detail", $$v)
                                  },
                                  expression: "form.detail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                      _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.formLoad,
                    loading: _vm.formLoad,
                    form: "form-payment-method",
                    color: "blue darken-1",
                    text: "",
                  },
                },
                [_vm._v(" Guardar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }