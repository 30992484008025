var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "9", md: "9", sm: "12" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.getProductsToLoad,
                  "prepend-icon":
                    _vm.withAddProductAction && _vm.hasAccess("ESC.INV.PRD")
                      ? "mdi-package-variant-closed"
                      : "",
                  clearable: "",
                  filled: "",
                  filter: _vm.customFilter,
                  "item-text": "full_name",
                  "item-value": "id",
                  hint: "Usando " + _vm.priceList.name,
                  "persistent-hint": "",
                  loading: _vm.loading.product,
                  label: "Productos",
                },
                on: {
                  "click:prepend": this.openDialogProduct,
                  change: function ($event) {
                    return _vm.changeProduct()
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm.onlyProductsWithStock
                      ? {
                          key: "append-outer",
                          fn: function () {
                            return [
                              _vm.cart.product?.id
                                ? _c(
                                    "v-chip",
                                    {
                                      staticStyle: { "margin-top": "-5px" },
                                      attrs: {
                                        color:
                                          _vm.getStock <= _vm.getMinStock
                                            ? "error"
                                            : "success",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(_vm.getStock) + " ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          _c("v-list-item", [
                            _c("div", { staticClass: "pa-2" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-body-1 font-weight-medium text-capitalize ma-0",
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-body-2 font-weight-light text-capitalize ma-0",
                                },
                                [_vm._v(_vm._s(item.attributes_name))]
                              ),
                            ]),
                          ]),
                          _vm.onlyProductsWithStock
                            ? _c(
                                "v-list-item-action",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: {
                                        color:
                                          item.stock.count <= _vm.getMinStock
                                            ? "error"
                                            : "success",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.stock.count) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.withEditProductAction &&
                          _vm.hasAccess("ESC.INV.PRD")
                            ? _c(
                                "v-list-item-action",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { fab: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openDialogProduct(item.id)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.cart.product,
                  callback: function ($$v) {
                    _vm.$set(_vm.cart, "product", $$v)
                  },
                  expression: "cart.product",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6", lg: "2", md: "2", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { clearable: "", filled: "", label: "Precio" },
                model: {
                  value: _vm.cart.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.cart, "price", $$v)
                  },
                  expression: "cart.price",
                },
              }),
              _vm.showCashDiscount && _vm.cart.price > 0
                ? _c(
                    "h5",
                    { staticClass: "text-body-1 ma-0 pa-0 d-inline" },
                    [
                      _c("v-icon", { staticClass: "success--text" }, [
                        _vm._v("mdi-cash"),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "success--text d-inline" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatCurrency(_vm.getPriceWithCashDiscount())
                          )
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2", lg: "1", md: "1", sm: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { fab: "", small: "", dark: "", color: "accent" },
                  on: {
                    click: function ($event) {
                      _vm.addProduct()
                      _vm.$emit("product-alter", _vm.productsCharged)
                    },
                  },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v(" mdi-plus ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [_c("v-col", [_c("v-divider")], 1)], 1),
      _c("v-data-iterator", {
        staticClass: "mb-5",
        attrs: {
          items: _vm.productsCharged,
          "no-data-text": "No se encontraron datos, aún...",
          "items-per-page": _vm.itemsPerPage,
          page: _vm.page,
          search: _vm.search,
          "sort-by": _vm.sortBy.toLowerCase(),
          "sort-desc": _vm.sortDesc,
          "hide-default-footer": "",
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:page": function ($event) {
            _vm.page = $event
          },
        },
        scopedSlots: _vm._u(
          [
            _vm.withHeadersFilter
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      flat: "",
                                      outlined: "",
                                      dense: "",
                                      elevation: "1",
                                      "hide-details": "",
                                      "prepend-inner-icon": "mdi-magnify",
                                      label: "Buscar",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      flat: "",
                                      elevation: "1",
                                      outlined: "",
                                      "hide-details": "",
                                      items: _vm.custom,
                                      "item-value": "id",
                                      "item-text": "desc",
                                      dense: "",
                                      "prepend-inner-icon": "mdi-magnify",
                                      label: "Ordenar por...",
                                    },
                                    model: {
                                      value: _vm.sortBy,
                                      callback: function ($$v) {
                                        _vm.sortBy = $$v
                                      },
                                      expression: "sortBy",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", lg: "4" } },
                                [
                                  _c(
                                    "v-btn-toggle",
                                    {
                                      attrs: { small: "", mandatory: "" },
                                      model: {
                                        value: _vm.sortDesc,
                                        callback: function ($$v) {
                                          _vm.sortDesc = $$v
                                        },
                                        expression: "sortDesc",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            large: "",
                                            depressed: "",
                                            color: "secondary",
                                            value: false,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("mdi-arrow-up")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            large: "",
                                            depressed: "",
                                            color: "secondary",
                                            value: true,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("mdi-arrow-down")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "default",
              fn: function (props) {
                return [
                  _c("v-container", { staticClass: "pt-6" }, [
                    _c(
                      "div",
                      { staticClass: "mb-4 mt-4" },
                      _vm._l(props.items, function (item) {
                        return _c(
                          "v-row",
                          { key: item.product_id },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pb-0",
                                attrs: { cols: "8", lg: "5" },
                              },
                              [
                                _c("div", { staticClass: "pa-2" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-body-1 font-weight-medium text-capitalize ma-0",
                                    },
                                    [_vm._v(_vm._s(item.attributes_name))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-body-2 font-weight-light text-capitalize ma-0",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pb-0 mt-0",
                                attrs: { cols: "4", lg: "2" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    prefix: "$",
                                    outlined: "",
                                    dense: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.editProduct(item)
                                      _vm.$emit(
                                        "product-alter",
                                        _vm.productsCharged
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.unit_price,
                                    callback: function ($$v) {
                                      _vm.$set(item, "unit_price", $$v)
                                    },
                                    expression: "item.unit_price",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pb-0 mt-0",
                                attrs: { cols: "4", lg: "1" },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: { outlined: "", dense: "" },
                                  on: {
                                    input: function ($event) {
                                      _vm.editProduct(item)
                                      _vm.$emit(
                                        "product-alter",
                                        _vm.productsCharged
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.count,
                                    callback: function ($$v) {
                                      _vm.$set(item, "count", $$v)
                                    },
                                    expression: "item.count",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pb-0 mt-0 text-center",
                                attrs: { cols: "5", lg: "3" },
                              },
                              [
                                _c("p", { staticClass: "text-h5 mt-1" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(
                                        item.unit_price *
                                          (parseInt(item.count)
                                            ? item.count
                                            : 0)
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "pb-0 mt-0 text-center",
                                attrs: { cols: "3", lg: "1" },
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      fab: "",
                                      dark: "",
                                      dense: "",
                                      "x-small": "",
                                      color: "accent",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteProduct(item.product_id)
                                        _vm.$emit(
                                          "product-alter",
                                          _vm.productsCharged
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v(" mdi-delete "),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]),
                ]
              },
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-4",
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c("span", { staticClass: "grey--text" }, [
                        _vm._v("Cantidad por página"),
                      ]),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            dark: "",
                                            text: "",
                                            color: "primary",
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.itemsPerPage) + " "
                                      ),
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-down"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(
                              _vm.itemsPerPageArray,
                              function (number, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateItemsPerPage(number)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(_vm._s(number)),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _c("span", { staticClass: "mr-4 grey--text" }, [
                        _vm._v(
                          " Página " +
                            _vm._s(_vm.page) +
                            " de " +
                            _vm._s(_vm.numberOfPages) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            fab: "",
                            dark: "",
                            "x-small": "",
                            color: "secondary",
                          },
                          on: { click: _vm.formerPage },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-1 mr-3",
                          attrs: {
                            fab: "",
                            dark: "",
                            color: "secondary",
                            "x-small": "",
                          },
                          on: { click: _vm.nextPage },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }