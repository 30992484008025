var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "600px", "text-align": "center" } },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            margin: "0 auto",
            "margin-top": "100px",
            "border-radius": "30px",
          },
          attrs: {
            "elevation-11": "",
            width: "350px",
            elevation: "2",
            loading: _vm.loading,
          },
        },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticStyle: { width: "100%", "text-align": "center" } },
              [
                _c("v-img", {
                  staticStyle: { "border-radius": "255px", margin: "0 auto" },
                  attrs: {
                    src: require("./../../assets/logo.png"),
                    width: "100",
                  },
                }),
                _c("v-divider", { staticClass: "ma-4" }),
                _c("h3", [_vm._v("Inicio de Sesión")]),
              ],
              1
            ),
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "ma-3",
                  attrs: { method: "POST" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.login.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: { counter: 80, label: "Usuario", required: "" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Contraseña",
                      required: "",
                      type: "password",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _c("v-divider", { staticClass: "mt-3 mb-3" }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        color: "primary",
                        rounded: "",
                        type: "submit",
                        disabled: _vm.loading,
                      },
                    },
                    [_vm._v(" Iniciar Sesión ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }