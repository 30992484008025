var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Toast", {
        attrs: { type: _vm.errors.type },
        model: {
          value: _vm.errors.value,
          callback: function ($$v) {
            _vm.$set(_vm.errors, "value", $$v)
          },
          expression: "errors.value",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
            "max-width": "700px",
            fullscreen: _vm.$vuetify.breakpoint.xs,
          },
          on: {
            input: function ($event) {
              return _vm.$router.back()
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", fixed: "", color: "blue" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = !_vm.dialog
                              _vm.$router.back()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-2" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { id: "form-users" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.save.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      disabled: _vm.isDisabled,
                                      label: "Usuario",
                                      "error-messages": _vm.userErrors,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.user.name.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "name", $$v)
                                      },
                                      expression: "user.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      items: _vm.roles,
                                      "item-text": "name",
                                      "item-value": "id",
                                      label: "Rol",
                                      "error-messages": _vm.roleErrors,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$v.user.role.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.role.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.role,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "role", $$v)
                                      },
                                      expression: "user.role",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { lg: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Nombres",
                                      "error-messages": _vm.nameErrors,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.user.entity.first_name.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.entity.first_name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.entity.first_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.user.entity,
                                          "first_name",
                                          $$v
                                        )
                                      },
                                      expression: "user.entity.first_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Apellidos",
                                      "error-messages": _vm.surnameErrors,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.user.entity.last_name.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.entity.last_name.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.entity.last_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.user.entity,
                                          "last_name",
                                          $$v
                                        )
                                      },
                                      expression: "user.entity.last_name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { lg: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Documento",
                                      prefix: "DNI:",
                                      "error-messages": _vm.documentErrors,
                                      counter: 8,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.user.entity.document.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.entity.document.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.entity.document,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.user.entity,
                                          "document",
                                          $$v
                                        )
                                      },
                                      expression: "user.entity.document",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _c("h4", [_vm._v("Datos de contacto")]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "5" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Correo Electrónico",
                                      disabled: _vm.isDisabled,
                                      hint: "Se utilizará para validar el usuario",
                                      "persistent-hint": "",
                                      "error-messages": _vm.emailErrors,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.user.contact.email.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.contact.email.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.contact.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user.contact, "email", $$v)
                                      },
                                      expression: "user.contact.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Teléfono / Celular",
                                      "error-messages": _vm.phoneErrors,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.user.contact.phone.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.user.contact.phone.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.user.contact.phone,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user.contact, "phone", $$v)
                                      },
                                      expression: "user.contact.phone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.$router.back()
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        disabled: _vm.loading,
                        loading: _vm.loading,
                        form: "form-users",
                        color: "blue darken-1",
                        text: "",
                      },
                    },
                    [_vm._v(" Guardar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }