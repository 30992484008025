<template>
	<v-container></v-container>
</template>
<script>
import CardForm from "@/components/card-form-custom-1.vue";
import ProductData from "@/views/inventory/product/product-data.vue";
import MovementData from "@/views/inventory/movement/movement-data.vue";
import FamilyData from "@/views/inventory/family/family-data.vue";

export default {
	components: { CardForm, ProductData, MovementData, FamilyData },
	data: () => {
		return {

		};
	}
};
</script>
