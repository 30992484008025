<template>
	<!-- App.vue -->

	<v-app>
		<v-container v-if="$store.state.loadingApp" fluid class="text-center mt-10">
			<v-progress-circular
				:size="100"
				:width="5"
				color="purple"
				indeterminate
				class="mb-5 mt-10"
			></v-progress-circular>
			<h5 class="text-h5">Cargando la app, se paciente...</h5>
		</v-container>
		<v-navigation-drawer app
			class="overflow-y-hidden"
			v-model="drawer"
			:mini-variant.sync="mini"
			expand-on-hover
			permanent
			v-if="this.$session.exists() && this.user?.email_verified_at != null">
			<v-list-item class="px-2">
				<v-list-item-avatar>
					<!-- <v-icon>mdi-menu</v-icon> -->
					<v-img src="../src/assets/logo.png"></v-img>
				</v-list-item-avatar>
				<v-list-item-title>{{ this.getUserFullName }}</v-list-item-title>
				<!--<v-btn
					icon
					@click.stop="mini = !mini"
				>
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
				-->
			</v-list-item>
			<v-divider></v-divider>

			<v-list dense rounded v-for="header in itemsWithChildren(items)" :key="header.title">
				<div v-if="hasChildren(header.children)">
					<span v-if="!mini" class="text-subtitle-2 font-weight-medium mt-2 mx-2 grey--text text--darken-1 pl-4 py-0" style="border-left: 3px solid #757575; border-radius: 3px;">{{ header.title }}</span>
					<v-list-item
						v-for="item in getSubItemsFiltered(header.children)"
						:key="item.title"
						link
						:class="$vuetify.theme.dark ? getBgColorBlackTheme : item.bgColor"
						class="mb-1 mt-1"
						@click="redirect(item.route)"
					>
						<v-list-item-icon>
							<v-icon :class="item.textColor" style="margin-left: -8px;">{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title class="text-capitalize text-body-2">{{ item.title.toLowerCase() }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</div>
			</v-list>
		</v-navigation-drawer>
		<v-card class="overflow-hidden">
			<v-app-bar
				v-if="this.$session.exists() && this.user?.email_verified_at != null"
				app
				absolute
				dense
				height="57"
				color="primary"
				class="rounded-0"
				dark
				elevation="3"
				elevate-on-scroll
				scroll-target="#scrolling-techniques-7"
			>
				<v-toolbar-title>{{ this.getEnterpriseAlias }}</v-toolbar-title>

				<v-spacer></v-spacer>
				<div v-if="closingSession">
					<v-progress-circular
					:size="30"
					:width="5"
					color="white"
					indeterminate
					class="mb-5 mt-5 mr-3"
				></v-progress-circular>
				Cerrando sesión..
				</div>
				<!--<v-text-field
					class="mt-8"
					style="width: 250px;"
					solo
					v-model="search"
					label="Fecha"
				></v-text-field>
				-->

				<v-btn icon @click="cambiarTema()">
					<v-icon>{{ iconTheme }}</v-icon>
				</v-btn>
				<v-menu left bottom>
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon v-bind="attrs" v-on="on">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					v-for="item in optionsHeaderFiltered"
					:key="item.title"
					@click="action(item.action)"
				>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>
			</v-app-bar>
			<v-sheet
			id="scrolling-techniques-7"
			class="overflow-y-auto"
			max-height="900"
		>
		<v-main >
			<!-- Provides the application the proper gutter -->
			<v-container fluid >
				<!-- If using vue-router -->
				<router-view></router-view>
			</v-container>
		</v-main>
			</v-sheet>
		</v-card>
  	<!-- Sizes your content based upon application components -->
		<!-- MOBILE -->
		<v-footer
			app
			v-if="this.$vuetify.breakpoint.smAndDown && this.$session.exists() && this.user?.email_verified_at != null "
			v-bind="localAttrs"
			:padless="true"
			>
		<v-card
			flat
			tile
			width="100%"
			class="text-center"
		>
			<v-card-text
			elevation-5>
			<v-btn
				v-for="item in itemsMobile"
				:key="item.icon"
				class="mx-4"
				:class="item.bgColor"
				@click="redirect(item.route)"
				icon
			>
				<v-icon :class="item.textColor" size="24px">
				{{ item.icon }}
				</v-icon>
			</v-btn>
			</v-card-text>
		</v-card>
		</v-footer>
		<!-- PC -->
		<v-footer height="30 pa-0" padless app v-if="this.$vuetify.breakpoint.mdAndUp">
			<v-col
				class="text-center pa-0"
				cols="12"
			>
				<span class="text-caption">Desarrollado por Osvaldo Vallone - {{ new Date().getFullYear() }}</span>
			</v-col>
		</v-footer>
	</v-app>
</template>

<script>
import Header from "./components/header.vue";
import Navigation from "./components/navigation.vue";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import nav from './services/navigation';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_RUTA_API;

export default {
	components: { Navigation, Header },
	name: "App",
	data: function () {
		return {
			drawer: true,
			mini: true,
			search: null,
			group: null,
			selectedItem: null,
			closingSession: false,
			variant: 'fixed',
			iconTheme: 'mdi-brightness-4',
			optionsHeader: [
				{ title: "Mi Cuenta", action: "administration/account", view: true },
				{ title: "Mi Empresa", action: "administration/enterprise", view: false },
				{ title: "Mis Sucursales", action: "administration/branch", view: false },
				{ title: "Cerrar Sesión", action: "logout", view: true },
			],
			items: nav
		};
	},

	updated(){
		this.$store.state.loadingApp = false;
		this.optionsHeader[1].view = this.hasAccess('LEC.ADM.EMP');
		this.optionsHeader[2].view = this.hasAccess('LEC.ADM.SUC');
	},

	created(){
		this.$store.dispatch('init');

		var self = this;
		axios.interceptors.response.use(
			function(response) {
				if(response.config.url.includes('auth/user'))
					return response;
				if(response.config.method != 'get' && response.status == 200)
					self.sweetAlertCustom(response.data, 'success');
				return response;
			},
			function(error) {

				if(error.response.config.url.includes('auth/user'))
					return response;

				//Errores de logica de negocio
				if(error.response.status == 400){
					self.sweetAlertCustom(error.response.data, 'warning')
					self.$store.commit('SET_ERRORS', error.response, { root: true });
				}

				//No esta autenticado, require nuevo login
				if (error.response.status == 401) {
					self.sweetAlertCustom('Su sesión expiró, vuelva a autenticarse', 'warning')
					self.$session.destroy();
					self.$store.commit('Auth/SET_USER', null)
					self.$router.push("/login");
				}

				//No tiene permiso, intenta acceder a un recurso ajeno
				if (error.response.status == 403) {
					self.sweetAlertCustom('No posee autorización para hacerlo', 'warning')
				}

				//El token ha vencido, posiblemente..
				if (error.response.status == 419) {
					self.sweetAlertCustom('Ha pasado tiempo, inicia sesión nuevamente', 'warning')
				}

				//Error de validacion de datos de entrada
				if (error.response.status == 422) {
					let errors = error.response.data.errors;
					self.sweetAlertCustom(errors[Object.keys(errors)[0]][0], 'warning')
				}

			}
		);
	},
	computed: {
		...mapState("Auth", ["user"]),

		getBgColorBlackTheme(){
			return 'grey darken-3';
		},

		isValidUser(){
			return this.$session.exists() && this.user?.email_verified_at != null;
		},

		optionsHeaderFiltered(){
			return this.optionsHeader.filter((prop) => prop.view)
		},

		...mapGetters("Auth", [
			"getUserFullName",
			"getEnterpriseAlias",
			"getBranchs",
			"getFirstBranch",
			"hasAccess"
		]),

		itemsMobile(){
			return this.itemsWithPermissions.filter(prop => prop.onlyPC == false)
		},

		itemsPc(){
			return this.itemsWithPermissions.filter(prop => prop.onlyMobile == false)
		},

		localAttrs () {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      },

			itemsWithPermissions(){
				return this.items.filter( prop => this.hasPermission(prop.permission))
			},
	},
	methods: {

		getSubItemsFiltered(items){
			return items.filter(prop => this.hasPermission(prop.permission))
		},

		hasChildren(items){
			return this.getSubItemsFiltered(items).length > 0;
		},

		itemsWithChildren(items){
			return items.filter(element => {
				return this.hasChildren(element.children)
			});
		},

		cambiarTema(){
			if(!this.$vuetify.theme.dark)
			 this.iconTheme = 'mdi-brightness-6'
			else
			this.iconTheme = 'mdi-brightness-4'
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
		},

		redirect(route) {
			this.$router.push({ name: route });
		},
		hasPermission(code){
			if(code === true)
				return true;
			return this.hasAccess(code);
		},
		action(value) {
			if (value != "logout") this.$router.push("/" + value);
			else this.logout();
		},
		logout: async function () {
			this.closingSession = true;
			this.loading = true;
			await this.$store.dispatch("Auth/logout", {
				session: this.$session,
			});
			this.closingSession = false;
			this.$router.push("/login");
			this.loading = false;
		},
	},
};
</script>
