import axios from "axios";

const EMAIL_TYPE = 16;
const PHONE_TYPE = 15;

export default {
	namespaced: true,
	state: {
		user: {
			id: null,
			people: {
				first_name: "",
				second_name: "",
				surname: "",
				entity: {
					document_type: "",
				},
			},
			enterprise: {
				alias: "",
				entity: {
					document_type: "",
				},
				branches: [],
				sale_points: [],
				contacts: [],
				addresses: [],
			},
			addresses: [
				{
					id: null,
					location_id: null,
				},
			],
			access: [],
			roles: [
				{
					id: null,
					name: null,
					access: []
				}
			]
		},
		validation: null,
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_USER(state, data) {
			state.user = data;
		},
		SET_VALIDATION(state, data) {
			state.validation = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			await axios
				.get("auth/user")
				.then((res) => {
					commit("SET_USER", res.data);
				})
				.catch(() => {
					//commit("SET_VALIDATION", error.response.data.errors);
					commit("SET_USER", null);
				});
		},
		login: async function ({ commit }, data) {
			commit("SET_ERRORS", null, { root: true });
			await axios.get("sanctum/csrf-cookie");
			await axios
				.post("login", data.credentials)
				.then((res) => {
					data.session.start();
					data.session.set("user", res.data);
					commit("SET_USER", res.data);
				})
				.catch(() => {});
		},
		logout: async function ({ commit }, data) {
			await axios.post("logout")
				.then(() => {})
				.catch(() => {});
			data.session.destroy();
			commit("SET_USER", null);
		},
		register: async function ({ commit }, params) {
			await axios
				.post("/register", params)
				.then((res) => {
					params.success();
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					params.error();
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		sendEmailForVerifyAccount: async function ({ commit }) {
			await axios
				.post("/email/verification-notification")
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		verifyEmail: async function ({ commit }, userId) {
			await axios
				.post("/email/verify/" + userId)
				.then((res) => {
					commit("SET_ERRORS", { value: res, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		changePassword: async function ({ commit }, params) {
			await axios
				.post("/password", params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
					params.error();
				});
		},
	},
	getters: {
		//Usuario
		getUsuarioId: (state) => {
			return state.user.id;
		},
		getUserFullName: (state) => {
			if (state.user)
				return state.user.people.first_name + " " + state.user.people.surname;
			else return "";
		},
		getCodeOfDocumentType: (state) => {
			if (state.user) return state.user.entity.document_type.code;
			else return "";
		},
		getCodeOfVatCondition: (state) => {
			if (state.user) return state.user.entity.vat_condition_type.code;
			else return "";
		},
		getEmail: (state) => {
			if (state.user)
				return state.user.contacts.filter(
					(prop) => prop.contact_type_ref == EMAIL_TYPE
				)[0].description;
			else return "";
		},
		getPhone: (state) => {
			if (state.user)
				return state.user.contacts.filter(
					(prop) => prop.contact_type_ref == PHONE_TYPE
				)[0].description;
			else return "";
		},
		//Empresa
		getEnterpriseId: (state) => {
			if (state.user.enterprise) return state.user.enterprise.id;
			else return "";
		},
		getEnterpriseAlias: (state) => {
			if (state.user.enterprise) return state.user.enterprise.alias;
			else return "";
		},
		getDocumentEnterprise: (state) => {
			if (state.user) return state.user.enterprise.entity.document;
			else return "";
		},
		getDocumentTypeEnterprise: (state) => {
			if (state.user.enterprise)
				return state.user.enterprise.entity.document_type;
			else return "";
		},
		getVatConditionEnterprise: (state) => {
			if (state.user.enterprise)
				return state.user.enterprise.entity.vat_condition;
			else return "";
		},
		getPhoneEnterprise: (state) => {
			if (state.user)
				return state.user.enterprise.contacts.filter(
					(prop) => prop.contact_type_ref == PHONE_TYPE
				)[0].description;
			else return "";
		},
		getEmailEnterprise: (state) => {
			if (state.user)
				return state.user.enterprise.contacts.filter(
					(prop) => prop.contact_type_ref == EMAIL_TYPE
				)[0].description;
			else return "";
		},
		getAddressEnterprise: (state) => {
			if (state.user) return state.user.enterprise.addresses[0];
			else return "";
		},
		getFullAddressEnterprise: (state) => {
			if (state.user) {
				let address =
					state.user.enterprise.addresses[0].street +
					" " +
					state.user.enterprise.addresses[0].number;
				let city = state.user.enterprise.addresses[0].location.description;
				return city + ", " + address;
			} else return "";
		},
		getCodeOfVatConditionEnterprise: (state) => {
			if (state.user)
				return state.user.enterprise.entity.vat_condition_type.code;
			else return "";
		},
		getBranchs: (state) => {
			if (state.user) return state.user.enterprise.branches;
			else return "";
		},
		getFirstBranch: (state) => {
			if (state.user) return state.user.enterprise.branches[0];
			else return "";
		},
		getSalePointForId: (state) => (id) => {
			if (state.user)
				return state.user.enterprise.sale_points.filter(
					(prop) => prop.id == id
				)[0];
			else return "";
		},
		getSalePoints: (state) => {
			if (state.user) return state.user.enterprise.sale_points;
			else return "";
		},
		getCity: (state) => {
			if (state.user) return state.user.addresses[0].location_id;
			else return "";
		},
		getProvince: (state) => {
			if (state.user) return state.user.addresses[0].location.parent;
			else return "";
		},
		hasAccess: (state) => (code) => {
			if(code === true)
				return true;
			if(state.user)
				return state.user.roles[0].access.filter(
					(prop) => prop.code == code
				).length > 0
			return false;
		}
	},
};
