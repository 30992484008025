import axios from "axios";
import http from "../../utils/http";

export default {
	namespaced: true,
	state: {
		fund_movement: {
			entity_id: null,
			user_id_created_by: null,
			fund_type: null,
			currency: null,
			debt_limit: null,
			main: null,
			code: null,
			title: null,
			detail: null,
			balance: null,
			fund_state: null
		},
		fund_movements: []
	},
	mutations: {
		SET_FUND_MOVEMENT(state, data) {
			state.fund_movement = data;
		},
		SET_FUND_MOVEMENTS(state, data) {
			state.fund_movements = data;
		}
	},
	actions: {
		get: async function ({ commit }, params) {
			return await axios.get("fund/movement" + http.formatQueryParams(params.filters)).then((response) => {
				commit("SET_FUND_MOVEMENTS", response.data);
			}).catch(() => {});
		}
	},
	getters: {
		getFundMovement: (state) => (fundMovementId) => {
			if (state.fund_movements)
				return state.fund_movements.filter((prop) => prop.id == fundMovementId)[0];
			else return "";
		}
	},
};
