<template>
  <v-combobox
    :items="providers"
    item-text="alias"
    item-value="id"
    auto-select-first
    v-model="form.provider"
    label="Proveedor"
    :loading="loading"
    :error-messages="providerErrors"
    @input="$emit('input', $event)"
    @change="validator.form.provider.$touch();"
    @blur="validator.form.provider.$touch();"
  >
  </v-combobox>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {

  props: ['validator', 'value'],

  data: () => {
    return {
      form: {
        provider: {
          id: null,
          alias: null
        }
      },
      providers: [],
      loading: false
    }
  },

  created: async function () {
   
    this.loadItems();

    this.setValue();
   
  },

  watch: {
    value(newValue){
      this.form.provider = newValue;
    }
  },

  computed: {

    ...mapGetters('Provider', ['getProviders', 'getFirstProvider']),

    providerErrors() {
			const errors = [];
			if (!this.validator.form.provider.$dirty) return errors;
			!this.validator.form.provider.required &&
				errors.push("El proveedor es requerido");
			return errors;
		},

  },
  methods: {
    ...mapActions('Provider', ['get']),

    setValue(){
      if(!this.value) {
        this.form.provider = this.getFirstProvider;
      }else{
        this.form.provider = this.value
      }
    },

    loadItems: async function(){
      this.loading = true;

      if(this.$store.state.Provider.providers.length == 0)
        await this.get();

      this.providers = this.getProviders;

      this.loading = false;
    }
  }

};

</script>