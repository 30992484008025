<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			scrollable
			v-model="dialog"
			:loading="loading"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="blue">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-progress-linear :active="loading" indeterminate></v-progress-linear>
				<v-card-text style="min-height: 500px" class="pa-2">
					<v-form
						:disabled="loading"
						ref="form"
						id="form-punto-venta"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="role.name"
										required
										label="Descripción"
										:error-messages="nameErrors"
										@change="$v.role.name.$touch()"
										@blur="$v.role.name.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<h2>Accesos</h2>
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="12"
									lg="6"
									v-for="(access, index) in accesses"
									:key="access.id"
								>
									<v-card :color="getColor(index)" dark>
										<v-card-title>{{ index }}</v-card-title>
										<v-card-actions>
											<v-checkbox
												v-for="permission in access"
												v-model="accessing"
												:key="permission.id"
												:value="permission.id"
												:label="getPermission(permission.code)"
												class="mr-4 ml-2"
											></v-checkbox>
										</v-card-actions>
									</v-card>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-punto-venta"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Toast from "@/components/toast";
import { required } from "vuelidate/lib/validators";

export default {
	components: { Toast },
	validations: {
		role: {
			name: { required },
		},
	},
	data() {
		return {
			dialog: true,
			role: {
				id: null,
				name: "",
			},
			accessing: [],
			formValid: false,
			formLoad: false,
			loading: false,
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.Role.errors;
			},
			set(value) {
				this.$store.commit("Role/SET_ERRORS", value);
			},
		},

		...mapState("Access", ["accesses"]),

		...mapGetters("Role", ["getRole", "getAccessKey"]),

		nameErrors() {
			const errors = [];
			if (!this.$v.role.name.$dirty) return errors;
			!this.$v.role.name.required && errors.push("El nombre es requerido");
			return errors;
		},

		title() {
			return this.role.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},
	},
	created: async function () {
		this.loading = true;
		if(this.$store.state.Access.accesses.length == 0)
			this.$store.dispatch('Access/get')
		if(this.$store.state.Role.roles.length == 0)
			await this.$store.dispatch('Role/get')
		if (this.$route.params.id) {
			this.role = Object.assign({}, this.getRole(this.$route.params.id));
			this.accessing = this.getAccessKey(this.role.id);
		}
		this.loading = false;
	},
	methods: {

		...mapActions("Role", ["store"]),

		getPermission(code) {
			if (code.substr(0, 3) == "ESC") return "Escritura";
			else return "Lectura";
		},

		getColor(name) {
			let adminAccess = [
				"Usuario",
				"Rol",
				"Sucursal",
				"Punto de Venta",
				"Empresa",
				"Auditoria",
			];
			if (adminAccess.includes(name)) return "blue darken-2";
			else return "deep-purple accent-2";
		},

		close() {
			this.$nextTick(() => {
				this.role = Object.assign({}, null);
				this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
			this.tableLoad = true;
		},

		successSave: async function () {
			this.$store.dispatch('Auth/get');
			this.$store.dispatch('Role/get');
			if (!this.role.id > 0)
				this.$refs.form.reset();
			this.$router.go(-1);
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					id: this.role.id,
					data: {
						name: this.role.name,
						accesses: this.accessing,
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
