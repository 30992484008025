import axios from "axios";

export default {
	namespaced: true,
	state: {
		daily_box: {
			user_id: null,
			branch_id: null,
			date_opening: null,
			date_closing: null,
			balance_opening: null,
			balance: null,
		},
		daily_boxes: []
	},
	mutations: {
		SET_DAILY_BOX(state, data) {
			state.daily_box = data;
		},
		SET_DAILY_BOXES(state, data) {
			state.daily_boxes = data;
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("daily-box").then((response) => {
				commit("SET_DAILY_BOXES", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("daily-box", params.data)
				.then((res) => {
					params.success(res);
				})
				.catch(() => {
				});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("daily-box/" + params.id, params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {
				});
		},
		openClose: async function ({ commit }, id) {
			return axios
				.patch("daily-box/open-close/" + id)
				.then(() => {
				})
				.catch(() => {
				});
		},
	},
	getters: {
		getDailyBoxes: (state) => {
			if (state.daily_boxes) return state.daily_boxes;
			else return [];
		},
		getDailyBox: (state) => (dailyBoxId) => {
			if (state.daily_boxes)
				return state.daily_boxes.filter((prop) => prop.id == dailyBoxId)[0];
			else return "";
		},
	},
};
