<template>
	<div>
		<v-container class="mt-5">
			<v-row justify="center">
				<v-col cols="12" sm="5" md="4" lg="4">
					<v-card elevation="2" class="ma-4">
						<v-card-text>
							Correo Electrónico Verificado. Puede cerrar esta ventana.
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions } from "vuex";

export default {
	data: () => {
		return {
			user: null,
		};
	},
	created: async function () {},
	methods: {
		...mapActions("Auth", ["verifyEmail"]),
		cerrar() {
			alert();
			window.close();
		},
	},
};
</script>
