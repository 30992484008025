<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		persistent
		max-width="900px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pt-2">
				<v-form ref="form" id="form-price-list" @submit.prevent="save">
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-text-field
									required
									v-model="form.name"
									label="Nombre"
									:error-messages="nameErrors"
									@change="$v.form.name.$touch()"
									@blur="$v.form.name.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="3">
								<v-select
									:items="rateValueTypes"
									item-text="name"
									item-value="id"
									v-model="form.rate_value_type"
									label="Tipo de Variación"
									:error-messages="rateTypesErrors"
									@change="$v.form.rate_value_type.$touch();
													calculatedPrices()"
									@blur="$v.form.rate_value_type.$touch()"
								></v-select>
							</v-col>
							<v-col cols="3">
								<v-text-field
									required
									type="number"
									v-model="form.rate_value"
									label="Tasa/Valor de Variación"
									:error-messages="rateValueErrors"
									@change="$v.form.rate_value.$touch()
													;calculatedPrices()"
									@input="calculatedPrices()"
									@blur="$v.form.rate_value.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="2">
								<v-btn
									dark
									color="info"
									@click="calculatedPrices"
								>
									Aplicar
								</v-btn>
							</v-col>
							<v-col cols="8">
								<v-textarea
									solo
									rows="2"
									v-model="form.detail"
									label="Detalle..."
								></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<!-- <v-alert
								border="top"
								colored-border
								type="info"
								elevation="2"
								class="text-body-2"
							>
							Al momento de vender se utiliza el <strong>precio actual</strong> y al guardar,
							el <strong>precio calculado</strong> pasará a ser el nuevo precio de venta.
							</v-alert> -->
							<p class="text-h6">Productos</p>
							<v-divider class="ma-4"></v-divider>
							<v-data-iterator
								:items="form.products"
								no-data-text="No hay productos cargados, aún..."
								item-key="id"
								:search="search"
								:sort-by="['cost_price', 'attributes_name']"
								sort-desc
								>
								<template v-slot:header>
									<v-container>
										<v-row>
											<v-col cols="12" lg="7">
												<v-text-field
													class="mb-7"
													v-model="search"
													outlined
													dense
													elevation="0"
													hide-details
													prepend-inner-icon="mdi-magnify"
													label="Buscar"
												></v-text-field>
											</v-col>
										</v-row>
									</v-container>
								</template>
								<template v-slot:default="{ items }">
									<v-row v-for="item in items" :key="item.product_id">
											<v-col cols="6">
												<div class="text-body-1 ml-3" :class="{'orange--text': item.current_price != item.price}">
													<p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ item.attributes_name }}</p>
													<p class="text-body-2 font-weight-light text-capitalize ma-0">{{ item.name }}</p>
												</div>
												<v-badge v-if="item.current_price != item.price" color="orange ml-3">
													<span slot="badge">editado</span>
												</v-badge>
											</v-col>
											<v-col cols="2">
												<v-text-field
													prefix="$"
													v-model="item.cost_price"
													readonly
													disabled
													dense
													label="Costo"
												></v-text-field>
											</v-col>
											<v-col cols="2">
												<v-text-field
													prefix="$"
													v-model="item.current_price"
													readonly
													disabled
													dense
													label="Precio Actual"
												></v-text-field>
											</v-col>
											<v-col cols="2">
												<v-text-field
													prefix="$"
													dense
													v-model="item.price"
													label="Nuevo Precio"
												></v-text-field>
											</v-col>
										</v-row>
								</template>
							</v-data-iterator>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						$router.back();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-price-list"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { required, decimal } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
	validations: {
		form: {
			name: { required },
			rate_value_type: { required },
			rate_value: { required, decimal },
		},
	},
	data: () => {
		return {
			active: [],
			open: [],
			dialog: true,
			dialogParent: false,
			loading: false,
			disabled: false,
			rateValueTypes: [
				{ id: "FIXED", name: "Fijo" },
				{ id: "PERCENTAGE", name: "Porcentual" },
			],
			form: {
				id: null,
				name: null,
				detail: null,
				rate_value_type: 'FIXED',
				rate_value: 0,
				products: [],
			},
			prices: [],
			search: null
		};
	},
	created: async function () {
		if(this.$store.state.Product.products.length == 0)
			await this.$store.dispatch('Product/get');
		if (this.$route.params.id){
			if(this.$store.state.PriceList.priceLists.length == 0)
				await this.$store.dispatch('PriceList/get');
			this.form = Object.assign({}, this.getPriceList(this.$route.params.id));
		}else{
			this.form.products = this.getProducts;
		}
		this.setProductsFormated();
		if(!this.$route.params.id)
			this.calculatedPrices();
	},
	computed: {
		title() {
			return this.$route.params.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},

		...mapGetters("PriceList", ["getPriceList"]),
		...mapGetters("Product", ["getProducts", "getProduct"]),

		nameErrors() {
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
			!this.$v.form.name.required && errors.push("El nombre es requerido");
			return errors;
		},

		rateTypesErrors() {
			const errors = [];
			if (!this.$v.form.rate_value_type.$dirty) return errors;
			!this.$v.form.rate_value_type.required &&
				errors.push("El tipo de variación es requerido");
			return errors;
		},

		rateValueErrors() {
			const errors = [];
			if (!this.$v.form.rate_value.$dirty) return errors;
			!this.$v.form.rate_value.required &&
				errors.push("La tasa/valor de variación es requerido");
			!this.$v.form.rate_value.decimal &&
				errors.push("La tasa/valor de variación debe ser un decimal válido");
			return errors;
		}
	},
	methods: {

		...mapActions('PriceList', ['store']),

		setProductsFormated(){
			let products = [];
			this.form.products.forEach(element => {
				products.push({
					id: element.id,
					attributes_name: !this.$route.params.id ? element.attributes_name : this.getProduct(element.id).attributes_name,
					name: !this.$route.params.id ? element.name : this.getProduct(element.id).name,
					cost_price: element.cost_price,
					current_price: element.current_price ?? element.price ?? element.cost_price,
					price: element.price ?? element.cost_price
				});
			});
			this.form.products = [].concat(products);
		},

		calculatedPrices(){
			this.form.products.forEach(element => {
				if(this.form.rate_value_type == 'FIXED'){
					element.price = parseFloat(element.cost_price) + parseFloat(this.form.rate_value);
				}else{
					element.price = parseFloat(element.cost_price / 100 * this.form.rate_value) + parseFloat(element.cost_price)
				}
			});
		},

		beforeSave() {
			this.loading = true
		},

		successSave() {
			if(this.$store.state.errors.status == 200){
				this.$store.dispatch('PriceList/get');
				this.$router.back();
			}
		},
		completeSave() {
			this.loading = false;
		},
		save() {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						name: this.form.name,
						detail: this.form.detail,
						rate_value: this.form.rate_value,
						rate_value_type: this.form.rate_value_type,
						products: this.form.products
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
