<template>
		<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="500px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-card-title dark fixed color="primary">
				Ajustar saldo
			</v-card-title>
			<v-card-text>
				<h5 class="text-h5">{{ fund.title }}</h5>
				<p class="text-content-1">Saldo actual: <strong>{{ formatCurrency(fund.balance) }}</strong></p>
				<v-form
						ref="form"
						id="form-adjust-fund"
						@submit.prevent="save"
						v-model="formValid"
					>
					<v-container>
						<v-row>
							<v-col cols="12" lg="4">
								<v-text-field
									v-model="fund.amount"
									required
									label="Nuevo Monto"
									prefix="$"
									:error-messages="amountErrors"
									@change="$v.fund.amount.$touch()"
									@blur="$v.fund.amount.$touch()"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
				<p>Se generará un nuevo movimiento.</p>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
				</v-btn>
				<v-btn
					color="primary"
					type="submit"
					text
					:disabled="formLoad"
					:loading="formLoad"
					form="form-adjust-fund"
				>
					<v-icon>mdi-pencil</v-icon>  Ajustar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>

import { required, integer } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
	validations:{
		fund: {
			amount: { required, integer }
		}
	},
	data: () => {
		return {
			formValid: false,
			formLoad: false,
			dialog: true,
			fund: {
				title: '',
				amount: 0
			}
		}
	},
	created: async function(){
		if(this.$store.state.Fund.funds.length == 0)
			await this.$store.dispatch('Fund/get');

		this.fund = this.getFund(this.$route.params.id);

	},
	computed: {
		...mapGetters('Fund', ['getFund']),

		amountErrors() {
				const errors = [];
				if (!this.$v.fund.amount.$dirty) return errors;
				!this.$v.fund.amount.required &&
					errors.push("El monto es requerido");
				!this.$v.fund.amount.integer &&
					errors.push("El monto debe ser numérico");
				return errors;
			},
	},
	methods: {
		...mapActions('Fund', ['adjust']),

			beforeSave() {
				this.formLoad = true;
			},

			successSave: async function () {
				if(this.$store.state.errors.status == 200){
					this.$store.dispatch('Fund/get');
					this.$router.back();
				}
			},

			save: async function () {
				this.$v.$touch();
				if (!this.$v.$invalid)
					await this.adjust({
						validate: !this.$v.$invalid,
						data: {
							path: {
								fund: this.fund.id
							},
							body: {
								amount: this.fund.amount
							}
						},
						before: () => {
							this.beforeSave();
						},
						success: () => {
							this.successSave();
						}
					});
				this.formLoad = false;
			},
	}
}
</script>
