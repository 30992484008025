<template>
  <v-toolbar dark fixed color="primary">
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn
        icon
        dark
        @click="changeScreen()"
      >
        <v-icon>{{ config.fullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
      </v-btn>
      <v-btn
        icon
        dark
        @click="
          dialog = !dialog;
          $router.back();
        "
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
export default {
  props: ['title'],
  data: () => {
    return {
      config: {
				fullscreen: false,
			},
      dialog: true
    }
  },

 
  methods: {

    closeDialog() {
      this.$emit('close-dialog');
    },

    changeScreen(){
      this.config.fullscreen = !this.config.fullscreen
      this.$emit('config-update', this.config);
    },

  }

};
</script>