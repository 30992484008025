import axios from "axios";

export default {
	namespaced: true,
	state: {
		provider: {
			alias: null,
			comment: null,
			document: null,
			vat_condition: null
		},
		providers: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_PROVIDER(state, data) {
			state.provider = data;
		},
		SET_PROVIDERS(state, data) {
			state.providers = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("provider").then((response) => {
				commit("SET_PROVIDERS", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("provider", params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("provider/" + params.id, params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("provider/" + params.id)
				.then(() => {})
				.catch(() => {});
		},
	},
	getters: {
		getProviders: (state) => {
			if (state.providers) return state.providers;
			else return [];
		},
		getFirstProvider: (state) => {
			if (state.providers) return state.providers[0];
			else return [];
		},
		getProvider: (state) => (providerId) => {
			if (state.providers)
				return state.providers.filter((prop) => prop.id == providerId)[0];
			else return "";
		},
	},
};
