<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form
					ref="form"
					id="form-payment-to"
					@submit.prevent="save"
					v-model="formValid"
				>
				<v-container>
					<div class="row">
						<v-col>
							<PaymentMethodList v-if="$route.params.type == 'sale'" :total-charge="total" v-on:charges-updated="chargesUpdated" />
							<ChargeList v-else :total-charge="total" v-on:charges-updated="chargesUpdated" />
						</v-col>
					</div>
				</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						$router.back();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="formLoad"
					:loading="formLoad"
					form="form-payment-to"
					color="blue darken-1"
					text
				>
					Cobrar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>

import ChargeList from "@/components/saling/charges-list";
import PaymentMethodList from "@/components/saling/payment-methods-list";
import { mapActions, mapGetters } from 'vuex';

export default {
components: { ChargeList, PaymentMethodList },
data: () => {
	return {
		title: 'Cobro',
		formLoad: false,
		formValid: false,
		dialog: true,
		total: 0,
		operation: 0
	}
},
created: async function() {

	if(this.$route.params.type){
		if(this.$route.params.type == 'sale'){
			await this.$store.dispatch('Sale/find', this.$route.params.id);
			this.total = this.getTotalPendingToCharge(this.$route.params.id);
		}
	}
},
computed: {
	...mapGetters('Sale', ['getTotalPendingToCharge'])
},
methods: {

	...mapActions('Charge', ['chargeSale']),

	beforeSave(){
		this.formLoad = true;
	},

	successSave(){
		if(this.$store.state.errors.status == 200){
			this.$store.dispatch('DailyBox/get');
			this.$store.dispatch('Fund/get');
			this.$router.back();
		}
	},

	/**
	 * Limpia los descuentos si es que no se aplicó
	 */
		setChargesToSend(){
		this.operation.discount = this.operation.chargeDiscount ? this.operation.discount : 0;
		if(this.operation.discount == 0){
			for (let i = 0; i < this.operation.details.length; i++) {
				this.operation.details[i].discount = 0;
			}
		}
	},

	save: async function(){
		if(this.operation.total <= 0)
			return this.sweetAlertCustom('El monto a cobrar debe ser mayor a 0', 'warning');
		this.setChargesToSend();
		if(this.$route.params.type == 'sale')
			await this.chargeSale({
				id: this.$route.params.id,
				data: this.operation,
				before: () => {
					this.beforeSave();
				},
				success: () => {
					this.successSave();
				}
			})
		this.formLoad = false;
	},
	chargesUpdated(charge){
		this.operation = charge;
	}
}
}
</script>
