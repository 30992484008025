<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="900px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar fixed>
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-users"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										required
										v-model="form.entity.name"
										label="Nombre"
										:error-messages="nameErrors"
										@input="$v.form.entity.name.$touch()"
										@blur="$v.form.entity.name.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										required
										v-model="form.entity.surname"
										label="Apellido"
										:error-messages="surnameErrors"
										@input="$v.form.entity.surname.$touch()"
										@blur="$v.form.entity.surname.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="form.entity.document"
										:error-messages="documentErrors"
										:counter="8"
										@input="$v.form.entity.document.$touch()"
										@blur="$v.form.entity.document.$touch()"
										required
										label="Documento"
									></v-text-field>
								</v-col>
							</v-row>
							<v-divider class="ma-2" />
							<v-row>
								<v-col cols="4">
									<v-text-field
										v-model="form.contact.phone"
										label="Teléfono"
										:error-messages="phoneErrors"
										@input="$v.form.contact.phone.$touch()"
										@blur="$v.form.contact.phone.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-divider class="ma-2" />
							<v-row>
								<v-col lg="3">
									<v-select
										:items="provinces"
										item-text="description"
										item-value="id"
										v-model="province"
										label="Provincia"
										:error-messages="provinceErrors"
										@change="
											$v.province.$touch();
											changeCities();
										"
										@blur="$v.province.$touch()"
									></v-select>
								</v-col>
								<v-col lg="4">
									<v-combobox
										:items="cities"
										item-text="description"
										item-value="id"
										v-model="form.address.location"
										label="Localidad"
										:error-messages="locationErrors"
										@change="$v.form.address.location.$touch()"
										@blur="$v.form.address.location.$touch()"
									></v-combobox>
								</v-col>
								<v-col>
									<v-text-field
										required
										v-model="form.address.street"
										label="Calle"
										:error-messages="streetErrors"
										@input="$v.form.address.street.$touch()"
										@blur="$v.form.address.street.$touch()"
									></v-text-field>
								</v-col>
								<v-col>
									<v-text-field
										required
										v-model="form.address.number"
										label="Altura"
										:error-messages="numberErrors"
										@input="$v.form.address.number.$touch()"
										@blur="$v.form.address.number.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-users"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Toast from "@/components/toast";
import { required, minLength, integer } from "vuelidate/lib/validators";

export default {
	components: { Toast },
	validations: {
		form: {
			entity: {
				name: { required },
				surname: { required },
				document: { required, minLength: minLength(8) },
			},
			contact: {
				phone: { required, integer },
			},
			address: {
				street: { required },
				number: { required, integer },
				location: { required },
				location_id: { required, integer },
			},
		},
		province: { required, integer },
	},
	data: () => ({
		dialog: true,
		formValid: false,
		formLoad: false,
		form: {
			id: null,
			entity: {
				name: "",
				surname: "",
				document: "",
			},
			contact: {
				phone: "",
			},
			address: {
				street: "",
				number: "",
				location: [],
				location_id: "",
			},
		},
		province: null,
		provinces: null,
		cities: null,
	}),
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.User.errors;
			},
			set(value) {
				this.$store.commit("User/SET_ERRORS", value);
			},
		},

		...mapState("List", ["dictionary"]),
		...mapState("Auth", ["user"]),
		...mapGetters("Auth", ["getPhone", "getCity", "getProvince"]),
		...mapGetters("Location", ["getProvinces", "getCities"]),
		...mapGetters("Location", {
			getCityForLocation: "getCity",
			getProvinceForLocation: "getProvince",
		}),

		title() {
			return "Editar Cuenta";
		},

		nameErrors() {
			const errors = [];
			if (!this.$v.form.entity.name.$dirty) return errors;
			!this.$v.form.entity.name.required &&
				errors.push("El nombre es requerido");
			return errors;
		},
		surnameErrors() {
			const errors = [];
			if (!this.$v.form.entity.surname.$dirty) return errors;
			!this.$v.form.entity.surname.required &&
				errors.push("El apellido es requerido");
			return errors;
		},
		documentErrors() {
			const errors = [];
			if (!this.$v.form.entity.document.$dirty) return errors;
			!this.$v.form.entity.document.required &&
				errors.push("El documento es requerido");
			!this.$v.form.entity.document.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			return errors;
		},
		streetErrors() {
			const errors = [];
			if (!this.$v.form.address.street.$dirty) return errors;
			!this.$v.form.address.street.required &&
				errors.push("La calle es requerida");
			return errors;
		},
		numberErrors() {
			const errors = [];
			if (!this.$v.form.address.number.$dirty) return errors;
			!this.$v.form.address.number.required &&
				errors.push("El número es requerido");
			!this.$v.form.address.number.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		phoneErrors() {
			const errors = [];
			if (!this.$v.form.contact.phone.$dirty) return errors;
			!this.$v.form.contact.phone.required &&
				errors.push("El telefono es requerido");
			!this.$v.form.contact.phone.integer &&
				errors.push("Debe ingresar un número");
			return errors;
		},
		provinceErrors() {
			const errors = [];
			if (!this.$v.province.$dirty) return errors;
			!this.$v.province.required && errors.push("La provincia es requerida");
			return errors;
		},
		locationErrors() {
			const errors = [];
			if (!this.$v.form.address.location.$dirty) return errors;
			!this.$v.form.address.location.required &&
				errors.push("La localidad es requerida");
			return errors;
		},
	},
	created() {
		this.init();
	},
	methods: {
		...mapActions("User", ["store"]),
		...mapActions("Auth", ["get"]),

		changeCities() {
			this.cities = this.getCities(this.province);
			this.form.address.location = this.getCityForLocation(this.cities[0]);
		},

		init() {
			this.form.id = this.user.id;
			this.form.entity.name = this.user.entity.name;
			this.form.entity.surname = this.user.entity.surname;
			this.form.entity.document = this.user.entity.document;
			this.form.contact.phone = this.getPhone;
			this.form.address.street = this.user.addresses[0].street;
			this.form.address.number = this.user.addresses[0].number;
			this.form.address.location_id = this.user.addresses[0].location_id;

			this.provinces = this.getProvinces;
			this.province = this.getProvince;
			this.cities = this.getCities(this.getProvince);
			this.form.address.location = this.getCityForLocation(this.getCity);
		},

		close() {
			this.$nextTick(() => {
				//this.user = Object.assign({}, null);
				this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
			this.tableLoad = true;
		},

		successSave: async function () {
			if (!this.user.id > 0) this.$refs.form.reset();
			await this.get();
		},

		completeSave: async function () {
			this.formLoad = false;
			this.form.address.location = this.getCityForLocation(this.getCity);
		},

		save: function () {
			this.form.address.location_id = this.form.address.location.id;
			this.$v.form.$touch();
			if (!this.$v.form.$invalid)
				this.store({
					id: this.form.id,
					data: this.form,
					account: true,
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
