var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "max-width": "1100px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: {
        input: function ($event) {
          return _vm.closeDialog()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-stock-adjustment" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "ml-3 mt-2" },
                        [
                          _c("DateTimeCustom", {
                            attrs: { label: "Fecha del ajuste", v: _vm.$v },
                            on: { "date-changed": _vm.getIssueDate },
                            model: {
                              value: _vm.form.issue_date,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "issue_date", $$v)
                              },
                              expression: "form.issue_date",
                            },
                          }),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "4" } },
                            [
                              _c("Deposit", {
                                attrs: { validator: _vm.$v },
                                on: { value: _vm.getDeposit },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Motivo",
                                  items: _vm.getStockAdjustmentReasons,
                                  "item-value": "id",
                                  "item-text": "name",
                                  "error-messages": _vm.reasonErrors,
                                  "return-object": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.reason.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.reason.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.reason,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "reason", $$v)
                                  },
                                  expression: "form.reason",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "2",
                                  solo: "",
                                  label: "Detalle...",
                                },
                                model: {
                                  value: _vm.form.comment,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "comment", $$v)
                                  },
                                  expression: "form.comment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "12" } },
                            [
                              _c("ProductListByProvider", {
                                attrs: {
                                  "with-prices": false,
                                  "with-new-product-action": false,
                                  "with-duplicate-action": false,
                                  "with-stock": false,
                                  "with-product-count-zero": true,
                                  "with-button-to-add-product-count": true,
                                  "with-button-to-discard-products": true,
                                  "enable-product-count-zero": true,
                                },
                                on: { "product-alter": _vm.getProductsCharged },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.loading,
                    loading: _vm.loading,
                    form: "form-stock-adjustment",
                    color: "blue darken-1",
                    text: "",
                  },
                },
                [_vm._v(" Guardar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }