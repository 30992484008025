import axios from "axios";

export default {
	namespaced: true,
	state: {
		categories: []
	},
	mutations: {
		SET_CATEGORIES(state, data) {
			state.categories = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("product/category").then((response) => {
				commit("SET_CATEGORIES", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("product/category", params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("product/category/" + params.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("product/category/" + id)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
	},
	getters: {
		getCategory: (state) => (categoryId) => {
			if (state.categories)
				return state.categories.filter((prop) => prop.id == categoryId)[0];
			else return "";
		},
		getFirstCategory: (state) => {
			if (state.categories) 
				return state.categories[0];
			else return [];
		},
		getCategories: (state) => {
			if (state.categories) return state.categories;
			else return [];
		},
		getAttributes: (state) => (categoryId) => {
			if (state.categories) 
				return state.categories.filter(element => element.id == categoryId)[0]?.attributes;
			else return [];
		}
	},
};
