<template>
	<v-dialog
		hide-overlay
		persistent
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialogg"
		max-width="1100px"
		:fullscreen="config.fullscreen"
	>
		<v-card>
			<Toolbar 
				:title="title"
				@config-update="updateConfig"
				@close-dialog="closeDialog"	
			></Toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-purchase" @submit.prevent="save">
					<v-stepper v-model="e6">
						<StepperHeader
							:validator="$v"
							:step="e6"
						>
						</StepperHeader>

						<v-stepper-items>
							
							<StepperMainInfo
								:validator="$v"
								:step="e6"
								:origin-data="form"
								@main-info="getMainInfo"
								@main-info-confirmed="confirmeMainInfo"
							>
							</StepperMainInfo>

							<v-stepper-content step="2">
								<!--<v-switch
									v-model="providerView"
									disabled
									small
									label="Modo de vista por proveedor"
								></v-switch>-->
								<ProductList
									v-if="!providerView"
									:withEditProductAction="true"
									v-on:product-alter="productAlter"
									:carts="this.form.carts" />
								<ProductListByProvider
									v-else
									:provider="form.provider.id"
									@product-alter="productAlter"
									:carts="this.form.carts" />
								<v-btn
									color="primary"
									:disabled="!hasProductsAdds"
									@click="e6 = 3"
								>
									Continuar
								</v-btn>
								<v-btn
									@click="e6 = 1"
									text>
									Atras
								</v-btn>
							</v-stepper-content>

							<v-stepper-content step="3">
								<SurchargeList
									class="mb-4"
									title="Recargos"
									v-on:amount-changed="surchargesAmountChanged"
									:types="this.form.surcharges"
									:amount="totalPreciosCargadosSinFormato"
								></SurchargeList>
								<v-divider></v-divider>
								<v-container>
									<v-row>
										<v-col cols="12" lg="4">
											<p class="text-subtitle-2">Monto Neto</p>
											<p class="text-h5">{{ totalPreciosCargados }}</p>
										</v-col>
										<v-col cols="12" lg="4">
											<p class="text-subtitle-2">Monto Aplicado</p>
											<p class="text-h5"> {{ formatCurrency(totalSurchargesCharged) }}</p>
										</v-col>
										<v-col cols="12" lg="4" class="bg-blue">
											<p class="text-subtitle-2">Monto Total</p>
											<p class="text-h5 blue--text">{{ formatCurrency(totalPreciosCargadosSinFormato + totalSurchargesCharged) }}</p>
										</v-col>
									</v-row>
								</v-container>
								<v-btn
									color="primary"
									@click="e6 = 4"
								>
									Continuar
								</v-btn>
								<v-btn
									@click="e6 = 2"
									text>
									Atras
								</v-btn>
							</v-stepper-content>

							<v-stepper-content v-if="!this.$route.params.id"  step="4">
								<ChargeList action="pagar" :total-charge="totalMontoSale" v-on:charges-updated="chargesUpdated" />
								<v-btn
									color="primary"
									@click="e6 = 5"
								>
									Continuar
								</v-btn>
								<v-btn
									@click="e6 = 3"
									text>
									Atras
								</v-btn>
							</v-stepper-content>

							<v-stepper-content :step="!this.$route.params.id ? 5 : 4">
								<v-card
									class="mb-12 pa-3"
									min-height="200px"
									tile
								>
									<h5 class="text-h5">Detalle de la compra</h5>
									<v-spacer></v-spacer>
									<p class="text-body-1 mt-3"> Compra en la fecha <strong>{{ form.issue_date }}</strong> a <strong>{{ form.provider?.alias }}</strong> que será depositada en <strong>{{ form.deposit?.name }}</strong></p>
									<p class="text-body-2"><strong>Comentario: </strong> {{ form.comment ?? 'Sin datos' }}</p>
									<v-divider class="ma-3"></v-divider>
									<p class="text-body-1">Se cargaron <strong>{{ totalProductosCargados }} productos</strong> con un total de <strong class="blue--text">{{ totalPreciosCargados }}</strong></p>
									<p class="text-body-1">Se aplica un recargo total de <strong class="blue--text">{{ formatCurrency(totalSurchargesCharged) }}</strong></p>
									<p v-if="!this.$route.params.id" class="text-body-2">
										Del cual se pagará un total de <strong>{{ formatCurrency(totalToPay) }}</strong>
										quedando pendiente a pagar <strong>{{ formatCurrency(totalMontoSale - totalToPay) }}</strong>
									</p>
								</v-card>
								<v-btn
									type="submit"
									color="primary"
									:loading="loadingPurchase"
									:disbaled="loadingPurchase"
									form="form-purchase"
								>
									Guardar
								</v-btn>
								<v-btn
									@click="!$route.params.id ? e6 = 4 : e6 = 3" text>
									Atras
								</v-btn>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import DateTimeCustom from "@/components/datetime-custom-1";
import ProductList from "@/components/purchasing/product-list";
import ProductListByProvider from "@/components/purchasing/product-list-by-provider-v2";
import ChargeList from "@/components/saling/charges-list";
import SurchargeList from "@/components/treasury/surcharge-discount-list";
import Toolbar from "@/components/toolbar"
import StepperHeader from "./components/stepper-header-form"
import StepperMainInfo from "./components/stepper-main-info"

export default {
	components: { DateTimeCustom, ProductList , ChargeList, ProductListByProvider, SurchargeList, Toolbar, StepperHeader, StepperMainInfo },
	validations: {
		form: {
			issue_date: { required },
			provider: { required },
			deposit: { required },
		},
	},
	data: () => {
		return {
			dialogg: true,
			dialogParent: false,
			loading: false,
			disabled: false,
			menu: false,
			providerView: true,
			active: [],
			loadingPurchase: false,
			open: [],
			config: {
				fullscreen: false,
			},
			loadings: {
				provider: true,
				deposit: true
			},
			datosPrincipalesValidator: true,
			e6: 1,
			form: {
				id: null,
				provider: {
					id: null,
					alias: null
				},
				deposit: {
					id: null,
					name: null
				},
				issue_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				comment: null,
				carts: null,
				surcharges: null
			},
			providers: [],
			deposits: [],
			productsCharged: [],
			paymentsCharged: {
				details: [],
				comment: null
			},
			surchargesCharged: []
		}
	},
	created: async function () {
		this.datosPrincipalesValidator = false;
		this.config.fullscreen = this.$vuetify.breakpoint.xs;

		if (this.$route.params.id){
			
			await this.$store.dispatch('Purchase/find', this.$route.params.id);

			this.form = Object.assign({}, this.getPurchaseDetail);
			this.productsCharged = this.form.carts;
			this.surchargesCharged = this.form.surcharges;
		}
		this.loadings.provider = false;
		this.loadings.deposit = false;
		this.datosPrincipalesValidator = true;
	},
	watch: {
		dialog(val) {
			val || this.close();
		}
	},
	computed: {

		...mapGetters('Provider', ['getProviders', 'getFirstProvider']),
		...mapGetters('Deposit', ['getDeposits', 'getFirstDeposit']),
		...mapGetters('Product', ['getProduct']),
		...mapGetters('Purchase', ['getPurchase']),
		...mapGetters('Purchase', ['getPurchaseDetail']),

		providerErrors() {
			const errors = [];
			if (!this.$v.form.provider.$dirty) return errors;
			!this.$v.form.provider.required &&
				errors.push("El proveedor es requerido");
			return errors;
		},

		depositErrors() {
			const errors = [];
			if (!this.$v.form.deposit.$dirty) return errors;
			!this.$v.form.deposit.required &&
				errors.push("El depósito es requerido");
			return errors;
		},

		totalToPay(){
			let total = 0;
			if(this.paymentsCharged.details.length > 0)
				this.paymentsCharged.details.forEach(element => {
						total += parseFloat(element.amount)
				});
			return total;
		},

		title() {
			return this.$route.params.id > 0 ? "Editar Compra" : "Nueva Compra";
		},

		totalPreciosCargados(){

			let total = 0;

			this.productsCharged.forEach(element => {
					total += element.unit_price * element.count;
			});

			return this.formatCurrency(total);
		},

		hasProductsAdds(){
			if(this.productsCharged)
				return this.productsCharged.length > 0;
		},

		totalProductosCargados(){
			let count = 0;
			this.productsCharged.forEach(element => {
				count += parseInt(element.count);
			});
			return count;
		},

		totalPreciosCargadosSinFormato(){

			let total = 0;

			if(this.productsCharged.length > 0)
				this.productsCharged.forEach(element => {
						total += element.unit_price * element.count;
				});

			return total;
		},

		totalSurchargesCharged(){
			let total = 0;

			if(this.surchargesCharged.length > 0)
				this.surchargesCharged.forEach(element => {
						total += element.amount_to_apply;
				});

			return total;
		},

		totalMontoSale(){
			return parseFloat(this.totalPreciosCargadosSinFormato) + parseFloat(this.totalSurchargesCharged)
		}

	},
	methods: {

		...mapActions('Purchase', ['store']),

		closeDialog(){
			this.dialogg = false;
		},

		getMainInfo(mainInfo) {
			this.form.deposit = mainInfo.deposit
			this.form.provider = mainInfo.provider
			this.form.comment = mainInfo.comment
			this.form.issue_date = mainInfo.issue_date
		},

		confirmeMainInfo(){
			this.$v.$touch()
			if (!this.$v.$invalid)
				this.e6 = 2;
		},

		updateConfig(config){
			this.config = config;
		},

		close() {
			this.$nextTick(() => {
				//this.user = Object.assign({}, null);
				// this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		chargesUpdated(charges){
			this.paymentsCharged = charges;
		},

		confirmarDatosPrincipales(){
			this.$v.$touch()
			if (!this.$v.$invalid)
				this.e6 = 2;
		},

		productAlter(products){
			this.productsCharged = products;
		},

		newDateChanged(date){
			this.form.issue_date = date;
		},

		surchargesAmountChanged(surcharges){
			this.surchargesCharged = surcharges;
		},

		beforeSave(){
			this.loadingPurchase = true;
		},

		successSave(){
			if(this.$store.state.errors.status == 200){
				this.$store.dispatch('Purchase/get');
				this.$store.dispatch('Provider/get');
				this.$store.dispatch('Product/get');
				this.$store.dispatch('DailyBox/get');
				this.$store.dispatch('Fund/get');
				this.$store.dispatch('SurchargeType/get');
				this.$store.dispatch('Surcharge/setDataChanged');
				this.$router.back();
			}
		},

		completeSave(){
			this.loadingPurchase = false;
		},

		deleteProductsObject(products){
			for (let i = 0; i < products.length; i++) {
				delete products[i].product;
			}
			return products;
		},

		setProductsToSend(){
			this.productsCharged.forEach(element => {
				delete element.attributes_non_discriminatory
			})
		},

		formatProductsToSend(){
			const productToSend = [];
			this.productsCharged.forEach(element => {

					let product = {
						product_id: element.product_id,
            category_id: element.category.id,
            name: element.name,
            cost_price: parseFloat(element.unit_price),
						attributes: [],
						stocks: []
					}

					element.attribute_values.forEach(attributeValue => {
						product.attributes.push({
							id: attributeValue.id,
							value: attributeValue.value
						})
					});

					element.attribute_detail.forEach(attributeDetail => {
						product.stocks.push({
							attribute_values: attributeDetail.attributes,
							count: parseInt(attributeDetail.count)
						})
					});

					productToSend.push(product)

			})

			return productToSend
		},

		save() {
			this.$v.$touch();
			if (this.$v.$invalid)
				return;
			const productToSend = Array.from(this.formatProductsToSend())

			this.store({
				validate: true, // !this.$v.$invalid,
				id: this.form.id,
				data: {
					provider_id: this.form.provider.id,
					order_id: null,
					deposit_id: this.form.deposit.id,
					issue_date: this.form.issue_date,
					comment: this.form.comment,
					products: productToSend,
					payment: this.paymentsCharged,
					surcharges: this.surchargesCharged
				},
				before: () => {
					this.beforeSave();
				},
				success: () => {
					this.successSave();
				},
				complete: () => {
					this.completeSave();
				},
			});
		},
	},
};
</script>
