var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        width: "400",
        transition: "dialog-bottom-transition",
        "max-width": "700px",
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5 grey lighten-2" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c("v-card-text", { staticClass: "pa-2 pl-5" }, [
            _vm._v(" " + _vm._s(_vm.subtitle) + " "),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.openCloseBox()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.textButton) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }