import axios from "axios";

export default {
	namespaced: true,
	state: {
		location: {
			provinces: [],
			cities: [],
		},
	},
	mutations: {
		SET_LOCATION(state, data) {
			state.location = data;
		},
	},
	actions: {
		getLocations: async function ({ commit }) {
			return axios.get("location").then((response) => {
				commit("SET_LOCATION", response.data);
			}).catch(() => {});
		},
	},
	getters: {
		getProvinces(state) {
			return state.location.provinces;
		},

		getFirstProvince: (state) => {
			if (state.location) return state.location.provinces[0];
			else return "";
		},

		getProvince: (state) => (locationId) => {
			return state.location.provinces.filter(
				(prop) => prop.id == locationId
			)[0];
		},

		getCity: (state) => (locationId) => {
			return state.location.cities.filter((prop) => prop.id == locationId)[0];
		},

		getCities: (state) => (province) => {
			return state.location.cities.filter((prop) => prop.parent == province);
		},
	},
};
