import AdministrationHome from "@/views/app/admin/administration-home.vue";
import AdministrationData from "@/views/app/admin/administration-data.vue";

import UserHome from "@/views/admin/user/user-home.vue";
import UserData from "@/views/admin/user/user-data.vue";
import UserForm from "@/views/admin/user/user-form.vue";

import EnterpriseHome from "@/views/admin/enterprise/enterprise-home.vue";
import EnterpriseData from "@/views/admin/enterprise/enterprise-data.vue";
import EnterpriseForm from "@/views/admin/enterprise/enterprise-form.vue";

import AccountHome from "@/views/admin/account/account-home.vue";
import AccountData from "@/views/admin/account/account-data.vue";
import AccountForm from "@/views/admin/account/account-form.vue";
import AccountChangePassword from "@/views/admin/account/account-change-password.vue";

import AuditHome from "@/views/admin/audit/audit-home.vue";
import AuditData from "@/views/admin/audit/audit-data.vue";

import BranchHome from "@/views/admin/branch/branch-home.vue";
import BranchData from "@/views/admin/branch/branch-data.vue";
import BranchForm from "@/views/admin/branch/branch-form.vue";

import SalePointHome from "@/views/admin/sale_point/sale-point-home.vue";
import SalePointForm from "@/views/admin/sale_point/sale-point-form.vue";

import RoleHome from "@/views/admin/role/role-home.vue";
import RoleData from "@/views/admin/role/role-data.vue";
import RoleForm from "@/views/admin/role/role-form.vue";

import AccessHome from "@/views/admin/access/access-home.vue";
import AccessData from "@/views/admin/access/access-data.vue";

import store from "../core/index.js";

const STR_STORE = "store";

export default {
	path: "/administration",
	name: "",
	component: AdministrationHome,
	meta: { requiresAuth: true },
	children: [
		{
			path: "",
			name: "AdministrationData",
			component: AdministrationData,
			meta: {access: true}
		},
		{
			path: "user",
			component: UserHome,
			meta: { access: 'LEC.ADM.USU' },
			children: [
				{
					path: "",
					name: "UserData",
					component: UserData,
					meta: { access: 'LEC.ADM.USU' },
				},
				{
					name: "UserCreate",
					path: STR_STORE,
					component: UserForm,
					meta: { access: 'ESC.ADM.USU' },
				},
				{
					name: "UserUpdate",
					path: STR_STORE + "/:id",
					component: UserForm,
					meta: { access: 'ESC.ADM.USU' },
					beforeEnter: async function (to, from, next) {
						if (store.state.User.users.length == 0)
							await store.dispatch("User/get");
						next();
					},
				},
			],
		},
		{
			path: "enterprise",
			component: EnterpriseHome,
			meta: { access: 'LEC.ADM.USU' },
			children: [
				{
					path: "",
					name: "EnterpriseData",
					component: EnterpriseData,
					meta: { access: 'LEC.ADM.ENT' },
				},
				{
					name: "EnterpriseUpdate",
					path: STR_STORE + "/:id",
					component: EnterpriseForm,
					meta: { access: 'ESC.ADM.ENT' },
				},
			],
		},
		{
			path: "account",
			component: AccountHome,
			meta: { access: true },
			children: [
				{
					path: "",
					name: "AccountData",
					component: AccountData,
					meta: { access: true },
				},
				{
					name: "AccountUpdate",
					path: STR_STORE,
					props: { account: true },
					component: AccountForm,
					meta: { access: true },
				},
				{
					name: "AccountChangePassword",
					path: "change-password",
					props: { account: true },
					component: AccountChangePassword,
					meta: { access: true },
				},
			],
		},
		{
			path: "audit",
			component: AuditHome,
			meta: { access: true },
			children: [
				{
					path: "",
					name: "AuditData",
					component: AuditData,
					meta: { access: true },
				},
			],
		},
		{
			path: "branch",
			component: BranchHome,
			meta: { access: 'LEC.ADM.SUC' },
			children: [
				{
					path: "",
					name: "BranchData",
					component: BranchData,
					meta: { access: 'LEC.ADM.SUC' }
				},
				{
					name: "BranchCreate",
					path: STR_STORE,
					component: BranchForm,
					meta: { access: 'ESC.ADM.SUC' },
				},
				{
					name: "BranchUpdate",
					path: STR_STORE + "/:id",
					component: BranchForm,
					meta: { access: 'ESC.ADM.SUC' },
					beforeEnter: async function (to, from, next) {
						if (store.state.Branch.branches.length == 0)
							await store.dispatch("Branch/get");
						next();
					},
				},
			],
		},
		{
			path: "sale-point",
			component: SalePointHome,
			meta: { access: 'LEC.ADM.PUN' },
			children: [
				{
					path: STR_STORE,
					name: "SalePointCreate",
					component: SalePointForm,
					meta: { access: 'ESC.ADM.PUN' },
				},
				{
					name: "SalePointUpdate",
					path: STR_STORE + "/:id",
					component: SalePointForm,
					meta: { access: 'ESC.ADM.PUN' },
				},
			],
		},
		{
			path: "role",
			component: RoleHome,
			meta: { access: 'LEC.ADM.ROL' },
			children: [
				{
					path: "",
					name: "RoleData",
					component: RoleData,
					meta: { access: 'LEC.ADM.ROL' },
				},
				{
					name: "RoleCreate",
					path: STR_STORE,
					component: RoleForm,
					meta: { access: 'ESC.ADM.ROL' },
				},
				{
					name: "RoleUpdate",
					path: STR_STORE + "/:id",
					component: RoleForm,
					meta: { access: 'ESC.ADM.ROL' },
				},
			],
		},
		{
			path: "/access",
			component: AccessHome,
			meta: { access: true },
			children: [
				{
					path: "",
					name: "AccessData",
					component: AccessData,
					meta: { access: true },
				},
			],
		},
	],
};
