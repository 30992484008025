<template>
	<div class="pl-4" :style="styles()">
		<v-icon v-if="icon" class="mr-2 mb-1">mdi-{{ icon }}</v-icon>
		<span class="d-inline text-h6 font-weight-light">{{ title }}</span>
	</div>
</template>
<script>

export default {
	props: ["title", "icon"],
	methods: {
		styles(){
			return 'border-left: 4px solid #'+this.getColor()+'; border-radius: 5px;'
		},
		getColor(){
			return process.env.NODE_ENV == 'production' ? "03A9F4" : "ff9800";
		}
	}
}
</script>
