var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("dialog-delete", {
        attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
        on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
      }),
      _c(
        "v-container",
        { staticClass: "mt-3 mb-10 flex-column" },
        [
          _c("v-row", [
            _c("div", { staticClass: "text-h3" }, [_vm._v("Mis Sucursales")]),
          ]),
          _c("v-row", [_c("v-col")], 1),
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _vm.tableLoad
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 70,
                          width: 7,
                          color: "purple",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._l(_vm.branches, function (branch) {
                    return _c(
                      "v-col",
                      {
                        key: branch.id,
                        attrs: { cols: "12", md: "4", sm: "6", lg: "4" },
                      },
                      [
                        _c(
                          "v-card",
                          { attrs: { elevation: "2" } },
                          [
                            _c(
                              "v-card-title",
                              [
                                branch.head == 1
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "yellow", left: "" } },
                                      [_vm._v(" mdi-star ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(branch.name) + " "),
                              ],
                              1
                            ),
                            _c("v-card-text", [
                              _vm._v(
                                " " + _vm._s(branch.sale_point.name) + " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getFullAddress(branch.id)) +
                                  " "
                              ),
                            ]),
                            _c(
                              "v-card-actions",
                              [
                                _vm.hasAccess("ESC.ADM.SUC")
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          rounded: "",
                                          fab: "",
                                          to: {
                                            name: "BranchUpdate",
                                            params: { id: branch.id },
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }