<template>
	<v-container class="mt-4">
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-row>
			<v-col>
				<h4 class="text-h4">Atributos</h4>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					v-if="hasAccess('ESC.INV.ATR')"
					fab
					dark
					color="primary"
					to="/inventory/attribute/store"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="4">
				<v-text-field
					v-model="search.name"
					label="Buscar atributo..."
					required
				></v-text-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="categoryAttributes"
					:search="search.name"
					height="700"
					:fixed-header="true"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.values="{ item }">
						{{ attributeNamesToStrings(item.values) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn
							class="ml-2"
							fab
							v-if="hasAccess('ESC.INV.ATR')"
							x-small
							@click="redirectWithParam('CategoryAttributeUpdate', { id: item.id })"
						>
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.INV.ATR')" class="ml-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import DialogDelete from "@/components/dialog-delete.vue";

export default {
	components: { DialogDelete },
	data: () => {
		return {
			search: {
				name: "",
			},
			loading: true,
			total: 0,
			headers: [
				{ text: "Nombre", value: "name" },
				{ text: "Descripción", value: "description" },
				{ text: "Valores", value: "values" },
				{ text: "Acciones", value: "actions" }
			],
			dialogDelete: false,
			deleteLoad: false,
		};
	},
	created: async function(){
		if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');
		this.loading = false;
	},
	computed: {
		...mapGetters('Auth', ['hasAccess']),
		...mapState('CategoryAttribute', ['categoryAttributes'])
	},
	methods: {
		...mapActions('CategoryAttribute', ['delete']),

		attributeNamesToStrings(attributeNames){
			if(attributeNames.length == 0)
				return 'Sin valores por defecto';
			const names = attributeNames.slice(0, 5).map(attribute => attribute.value);
			return names.join(", ") + ' ...';
		},

		deledit(item) {
			this.editedIndex = this.categoryAttributes.indexOf(item);
			this.form = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "CategoryAttributeUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.loading = true;
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.form.id);
			this.closeDelete();
			await this.$store.dispatch('CategoryAttribute/get');
			this.formLoad = false;
			this.deleteLoad = false;
			this.loading = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	}
};

</script>
