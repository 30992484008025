<template>
	<div>
		<Title title="Recargos"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
		<DialogDelete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></DialogDelete>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					rounded
					x-large
					color="primary"
					to="/treasury/surcharge-type"
					v-if="hasAccess('ESC.TRE.TRC')"
					><v-icon class="mr-2">mdi-cash-multiple</v-icon> Tipos de Recargo
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="surcharges"
					:search="search.name"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="tableLoad"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.surchargeable_id="{ item }">
						<div :class="getColorOrigin(item)">
							<v-icon :class="getColorOrigin(item)">{{ getIconOrigin(item) }} </v-icon> {{ getTitleOrigin(item) }} N° {{ item.surchargeable_id }}
						</div>
					</template>
					<template v-slot:item.amount="{ item }">
						<div class="red--text">
							{{ '- ' }} {{ formatCurrency(item.amount) }}
						</div>
					</template>
					<template v-slot:item.type="{ item }">
						<div>
							{{ item.type.rate }} {{ item.type.rate_type == 'FIXED' ? 'Fijo' : '%'  }}
						</div>
					</template>
					<!--<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('ESC.TRE.GAS')" class="ma-2" fab x-small @click="redirectWithParam('ExpenseUpdate', { id: item.id })">
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.TRE.REC')" class="ma-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>-->
				</v-data-table>
			</v-col>
		</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => {
		return {
			name: "Recargos",
			search: {
				name: "",
			},
			dialogDelete: false,
			deleteLoad: false,
			tableLoad: false,
			headers: [
				{ text: "Origen", value: "surchargeable_id" },
				{ text: "Monto", value: "amount" },
				{ text: "Tipo", value: "type" },
				{ text: "Fecha", value: "created_at" },
				//{ text: "Acciones", value: "actions", sortable: false },
			],
			editedIndex: -1,
			expense: null
		}
	},
	created: async function(){
		this.tableLoad = true;
		await this.$store.dispatch('Surcharge/get');
		this.tableLoad = false;
	},
	computed: {
		...mapState('Surcharge', ['surcharges']),
		...mapGetters('Auth', ['hasAccess'])
	},
	methods: {
		...mapActions("Surcharge", ["store", "delete"]),

		deledit(item) {
			this.editedIndex = this.surcharges.indexOf(item);
			this.expense = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "SurchargeUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			this.delete(this.expense);
			await this.$store.dispatch('Surcharge/get');
			this.closeDelete()
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete(){
			this.dialogDelete = false;
		},

		redirect(to) {
			this.$router.push({ name: to });
		},

		getColorOrigin(origin){
			if(origin.surchargeable_type.includes('Fund'))
				return 'teal--text';
			if(origin.surchargeable_type.includes('Payment') || origin.surchargeable_type.includes('Purchase'))
				return 'deep-orange--text';
			if(origin.surchargeable_type.includes('Charge') || origin.surchargeable_type.includes('Sale'))
				return 'red--text';
			if(origin.surchargeable_type.includes('Expense'))
				return 'green--text';
			return ''
		},

		getIconOrigin(origin){
			if(origin.surchargeable_type.includes('Fund'))
				return 'mdi-bank-outline';
			if(origin.surchargeable_type.includes('Payment') || origin.surchargeable_type.includes('Purchase'))
				return 'mdi-cart-variant';
			if(origin.surchargeable_type.includes('Charge') || origin.surchargeable_type.includes('Sale'))
				return 'mdi-basket-outline';
			if(origin.surchargeable_type.includes('Expense'))
				return 'mdi-cash-minus';
			return ''
		},

		getTitleOrigin(origin){
			if(origin.surchargeable_type.includes('Fund'))
				return 'Transferencia';
			if(origin.surchargeable_type.includes('Payment') || origin.surchargeable_type.includes('Purchase'))
				return 'Compra';
			if(origin.surchargeable_type.includes('Charge') || origin.surchargeable_type.includes('Sale'))
				return 'Venta';
			if(origin.surchargeable_type.includes('Expense'))
				return 'Gasto';
			return 'Movimiento';
		},
	}
}

</script>
