<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<p class="text-h5 blue--text">A {{ action }} {{ formatCurrency(totalCharge) }} </p>
				<v-checkbox
					v-model="allCash"
					@change="setAllCash"
					label="Cobrar todo en efectivo"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" lg="4" class="mt-3">
				<v-combobox
						:items="funds"
						dense
						item-text="title"
						item-value="id"
						:loading="loading.fund"
						v-model="charge.fund"
						:error-messages="fundErrors"
						@change="$v.charge.fund.$touch()"
						@blur="$v.charge.fund.$touch()"
						label="Fondo"
					>
					</v-combobox>
			</v-col>
			<v-col cols="12" lg="4" class="mt-3">
				<v-text-field
					v-model="charge.amount"
					dense
					prefix="$"
					:error-messages="amountErrors"
					@input="$v.charge.amount.$touch()"
					@blur="$v.charge.amount.$touch()"
					label="Monto"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="2">
				<v-btn
					class="mx-2"
					fab
					small
					dark
					color="indigo"
					@click="addCharge"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-container>
				<v-row v-for="item in charges" :key="item.name">
					<v-col cols="5" lg="4">
						{{ item.title }}
					</v-col>
					<v-col cols="5" lg="4">
						<p class="text-body-1">{{ formatCurrency(item.amount) }}</p>
					</v-col>
					<v-col cols="2" lg="4" class="pb-0 mt-0 text-center">
						<v-btn
								class="mx-2"
								fab
								dark
								dense
								x-small
								color="indigo"
								@click="deleteCharge(item)"
							>
								<v-icon dark> mdi-delete </v-icon>
							</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-row>
		<v-row>
			<v-col cols="12" lg="6">
				<p class="text-h5">Total {{ formatCurrency(total) }} </p>
			</v-col>
			<v-col cols="12" lg="6">
				<p class="text-h5">Pendiente {{ formatCurrency(totalCharge - total) }} </p>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { required, integer } from "vuelidate/lib/validators";

export default {
	props: {
		totalCharge: {
			default: 0
		},
		action: {
			default: 'cobrar'
		}
	},
	validations: {
		charge: {
			fund: { required },
			amount: { required, integer }
		},
	},
	data: () => {
		return {
			loading: {
				fund: true
			},
			allCash: false,
			charge: {
				fund: {
					id: null,
					title: null,
					fund_type: null
				},
				amount: 0
			},
			detail: '',
			charges: []
		}
	},
	created: async function(){
		if(this.$store.state.Fund.funds.length == 0)
			await this.$store.dispatch('Fund/get');
		this.charge.fund = this.getFirstFund;
		this.loading.fund = false;

		this.$emit('charges-updated', {
			details: [],
			comment: null,
			total: this.total
		})
	},
	computed: {
		...mapState('Fund', ['funds']),
		...mapGetters('Fund', ['getFirstFund']),

		fundErrors() {
			const errors = [];
			if (!this.$v.charge.fund.$dirty) return errors;
			!this.$v.charge.fund.required &&
				errors.push("El fondo es requerido");
			return errors;
		},

		amountErrors() {
			const errors = [];
			if (!this.$v.charge.amount.$dirty) return errors;
			!this.$v.charge.amount.required &&
				errors.push("La monto es requerido");
			!this.$v.charge.amount.integer &&
				errors.push("La monto debe ser numérico");
			return errors;
		},

		total(){

			let total = 0;

			if(this.charges.length > 0)
				this.charges.forEach(element => {
						total += parseFloat(element.amount);
				});

			return total;
		}
	},
	methods: {
		addCharge(){
			this.$v.$touch();
			if(this.$v.$invalid)
				return;

			if(this.charges.filter((prop) => prop.id == this.charge.fund.id).length > 0)
				return this.sweetAlertCustom('Este fondo ya fue cargado', 'warning');

			if(this.charge.amount <= 0)
				return this.sweetAlertCustom('El monto debe ser mayor a cero', 'warning')

			if(this.totalCharge == 0)
				return this.sweetAlertCustom('El monto a ' + this.action + ' es cero', 'warning')

			if((parseFloat(this.total) + parseFloat(this.charge.amount)) > this.totalCharge)
				return this.sweetAlertCustom('El monto supera el monto a ' + this.action, 'warning')

			let charge = {
				id: this.charge.fund.id,
				title: this.charge.fund.title,
				type: this.charge.fund.fund_type,
				amount: this.charge.amount
			};

			this.charges.push(Object.assign({}, charge))

			this.$emit('charges-updated', {
				details: this.charges,
				comment: this.detail,
				total: this.total
			})

		},

		getCashFund(){
			return this.funds.filter((prop) => prop.fund_type == 'CF')[0];
		},

		setAllCash(){
			this.charges = [].concat([]);

			if(!this.allCash){
				this.$emit('charges-updated', {
					details: this.charges,
					comment: this.detail,
					total: this.total
				})
				return;
			}

			const fund = this.getCashFund();
			const charge = {
				id: fund.id,
				title: fund.title,
				type: fund.fund_type,
				amount: this.totalCharge
			};

			this.charges.push(Object.assign({}, charge))

			this.$emit('charges-updated', {
				details: this.charges,
				comment: this.detail,
				total: this.total
			})
		},

		deleteCharge(item){
			let productosFiltrados = this.charges.filter((prop) => prop.id != item.id);
			this.charges = [].concat(productosFiltrados);
			this.$emit('charges-updated', {
				details: this.charges,
				comment: this.detail,
				total: this.total
			})
		}
	}
}
</script>
