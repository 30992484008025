<template>
	<v-container>
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					fab
					dark
					color="indigo"
					to="/inventory/family/store"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="8">
				<v-treeview
					rounded
					hoverable
					activatable
					:active.sync="active"
					:open.sync="open"
					:return-object="true"
					:open-all="true"
					:items="families"
				></v-treeview>
			</v-col>
			<v-col cols="12" md="4">
				<v-card v-if="selected" rounded="lg">
					<v-card-title>
						<h5>{{ selected.name }}</h5>
					</v-card-title>
					<v-card-text>
						<span>{{ selected.detail }}</span>
						<v-chip class="ml-2" v-if="selected.sale">Venta</v-chip><br />
						<small>{{ getMetric(selected.metric_ref.code) }}</small>
					</v-card-text>
					<v-card-actions>
						<v-btn
							class="mx-2"
							fab
							dark
							small
							color="indigo"
							:to="'/inventory/family/store/' + selected.id"
						>
							<v-icon dark> mdi-pencil </v-icon>
						</v-btn>
						<v-btn
							class="mx-2"
							fab
							small
							dark
							color="indigo"
							@click="deleteItem(selected.id)"
						>
							<v-icon dark> mdi-delete </v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";

export default {
	components: { DialogDelete },
	data: () => ({
		active: [],
		open: [],
		items: [
			{
				id: 1,
				name: "Applications",
				children: [
					{ id: 2, name: "Calendar" },
					{ id: 3, name: "Chrome" },
					{ id: 4, name: "Webstorm" },
				],
			},
		],
		editedIndex: -1,
		dialogDelete: false,
		deleteItemId: null,
		deleteLoad: false,
	}),
	beforeCreate: async function () {
		if (this.$store.state.Family.families.length == 0)
			this.$store.dispatch("Family/get");
	},
	computed: {
		...mapState("Family", ["families"]),

		...mapGetters("List", ["getMetric"]),

		selected() {
			if (!this.active.length) return undefined;
			return this.active[0];
		},
	},
	methods: {
		deledit(item) {
			this.editedIndex = this.branches.indexOf(item);
			this.branch = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "branchUpdate",
				params: { branch: item },
			});
		},

		deleteItem(id) {
			this.deleteItemId = id;
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.formLoad = true;
			this.delete(this.deleteItemId);
			this.closeDelete();
			await this.get();
			this.formLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	},
};
</script>
