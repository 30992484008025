<template>
  <v-combobox
    :items="deposits"
    item-text="name"
    item-value="id"
    auto-select-first
    v-model="form.deposit"
    label="Depósito"
    :loading="loading"
    :error-messages="depositErrors"
    @change="validator.form.deposit.$touch(); value()"
    @blur="validator.form.deposit.$touch(); value()"
  >
  </v-combobox>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {

  props: ['validator'],

  data: () => {
    return {
      form: {
        deposit: {
          id: null,
          name: null
        }
      },
      deposits: [],
      loading: false
    }
  },

  created: async function () {
    this.loading = true;

    if(this.$store.state.Deposit.deposits.length == 0)
      await this.get();

		this.form.deposit = this.getFirstDeposit;
    this.deposits = this.getDeposits;

    this.$emit('value', this.form.deposit);

    this.loading = false;
   
  },

  computed: {

    ...mapGetters('Deposit', ['getDeposits', 'getFirstDeposit']),

    depositErrors() {
			const errors = [];
			if (!this.validator.form.deposit.$dirty) return errors;
			!this.validator.form.deposit.required &&
				errors.push("El depósito es requerido");
			return errors;
		},

  },
  methods: {
    ...mapActions('Deposit', ['get']),

    value() {
      this.$emit('value', this.form.deposit);
    },
    
  }

};

</script>