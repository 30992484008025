var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Productos" } }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c("dialog-delete", {
            attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
            on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.hasAccess("ESC.INV.PRD")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            fab: "",
                            dark: "",
                            color: "primary",
                            to: "/inventory/product/store",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-plus "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasAccess("ESC.INV.PRD")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            rounded: "",
                            dark: "",
                            color: "primary",
                            to: "/inventory/product/stock",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-cube-outline "),
                          ]),
                          _vm._v(" Ajuste Stock "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.getProducts,
                      "single-expand": _vm.singleExpand,
                      expanded: _vm.expanded,
                      "show-expand": "",
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 50, 100],
                        itemsPerPageText: "Filas por página:",
                      },
                      loading: _vm.loading,
                      "sort-by": "created_at",
                    },
                    on: {
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" },
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            outlined: "",
                                            label: "Buscar producto...",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.filter.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.filter, "name", $$v)
                                            },
                                            expression: "filter.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "4" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.getProvidersFilter,
                                            label: "Proveedor",
                                            "item-text": "text",
                                            "item-value": "value",
                                            outlined: "",
                                          },
                                          model: {
                                            value: _vm.filter.provider,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "provider",
                                                $$v
                                              )
                                            },
                                            expression: "filter.provider",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "4" } },
                                      [
                                        _c("v-switch", {
                                          attrs: { label: "Solo con stock" },
                                          model: {
                                            value: _vm.filter.with_stock,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "with_stock",
                                                $$v
                                              )
                                            },
                                            expression: "filter.with_stock",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.stock.count",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "text-body-1 font-weight-bold",
                                attrs: {
                                  dark: "",
                                  color: _vm.getColorForState(item),
                                },
                              },
                              [_vm._v(" " + _vm._s(item.stock.count) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.provider_id",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getProvider(item.provider_id)?.alias ??
                                    "Sin proveedor asignado"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.full_name",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "pa-2" }, [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-body-1 font-weight-medium text-capitalize ma-0",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getCategory(item.category_id)?.name
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-body-2 font-weight-light text-capitalize ma-0",
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.attributes_name) +
                                      " " +
                                      _vm._s(item.name)
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.cost_price",
                        fn: function ({ item }) {
                          return [
                            _c("p", { staticClass: "text-h6" }, [
                              _vm._v(
                                _vm._s(_vm.formatCurrency(item.cost_price))
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "expanded-item",
                        fn: function ({ headers, item }) {
                          return [
                            _c(
                              "td",
                              {
                                staticClass: "elevation-0",
                                attrs: { colspan: headers.length },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "text-subtitle-1 mt-3" },
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-cube-outline "),
                                    ]),
                                    _vm._v(" Stock disponible "),
                                    _c("v-icon", [_vm._v("mdi-arrow-right")]),
                                    _vm._v(" "),
                                    _c(
                                      "strong",
                                      {
                                        staticClass:
                                          "text-subtitle-1 font-weight-bold",
                                        class: {
                                          "green--text": item.stock.count > 0,
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(item.stock.count))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.getProductStock(item)[0]?.name
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.getProductStock(item),
                                        function (productStock) {
                                          return _c(
                                            "v-chip",
                                            {
                                              key: productStock.id,
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "green",
                                                "text-color": "white",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  staticClass: "green darken-4",
                                                  attrs: { left: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        productStock.count
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(productStock.name) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _vm.hasAccess("ESC.INV.PRD")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { fab: "", "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redirectWithParam(
                                          "ProductUpdate",
                                          { id: item.id }
                                        )
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasAccess("ESC.INV.PRD")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { fab: "", "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-delete ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasAccess("ESC.INV.PRD")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-2",
                                    attrs: { fab: "", "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateStock(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-cube-outline "),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }