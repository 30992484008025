<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-users" @submit.prevent="save">
					<span>Formulario de Producto</span>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						$router.back();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-users"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	data: () => ({
		dialog: true,
		loading: false,
		disabled: false,
	}),
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		title() {
			return this.$route.params.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},
	},
	methods: {
		close() {
			this.$nextTick(() => {
				//this.user = Object.assign({}, null);
				// this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},
		save() {},
	},
};
</script>
