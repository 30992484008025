var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.$router.back()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-payment-to" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.formValid,
                    callback: function ($$v) {
                      _vm.formValid = $$v
                    },
                    expression: "formValid",
                  },
                },
                [
                  _c("v-container", [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c(
                          "v-col",
                          [
                            _c("ChargeList", {
                              attrs: {
                                action: "pagar",
                                "total-charge": _vm.total,
                              },
                              on: { "charges-updated": _vm.chargesUpdated },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                      _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.formLoad,
                    loading: _vm.formLoad,
                    form: "form-payment-to",
                    color: "blue darken-1",
                    text: "",
                  },
                },
                [_vm._v(" Pagar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }