<template>
	<div>
		<v-container style="min-height: 500px;">
			<v-row>
				<v-speed-dial
					v-model="fab"
					:top="top"
					:left="left"
					class="mr-4"
					direction="right"
					:open-on-hover="hover"
					:transition="transition"
				>
					<template v-slot:activator>
						<v-btn
							v-model="fab"
							color="primary"
							elevation="2"
							dark
							fab
						>
							<v-icon v-if="fab">
								mdi-close
							</v-icon>
							<v-icon v-else>
								mdi-plus
							</v-icon>
						</v-btn>
					</template>
					<v-btn
						rounded
						dark
						large
						v-if="hasAccess('ESC.VEN.VEN')"
						color="red"
						@click="redirect('SaleCreate')"
					>
						<v-icon>mdi-basket-outline</v-icon> Venta
					</v-btn>
					<v-btn
					rounded
						dark
						large
						v-if="hasAccess('ESC.TRE.GAS')"
						color="green"
						@click="redirect('ExpenseCreate')"
					>
						<v-icon>mdi-cash-minus</v-icon> Gasto
					</v-btn>
					<v-btn
						rounded
						dark
						large
						v-if="hasAccess('ESC.COM.COM')"
						color="deep-orange"
						@click="redirect('PurchaseCreate')"
					>
						<v-icon>mdi-cart-variant</v-icon> Compra
					</v-btn>
				</v-speed-dial>
			</v-row>
			<v-row>
				<v-col>
				<v-divider class="mt-4"></v-divider>
				</v-col>
			</v-row>
			<v-row v-if="canViewDashboard">
				<v-col cols="12" lg="9" md="8" sm="6">
					<v-chip @click="setFecha('hoy')" large active class="mr-2 px-5">Hoy</v-chip>
					<v-chip @click="setFecha('semana')" large class="mr-2 px-5">Semana</v-chip>
					<v-chip @click="setFecha('mes')" large class="px-5">Mes</v-chip>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<v-dialog
							ref="dialog"
							v-model="modal"
							:return-value.sync="filters.dates"
							persistent
							width="290px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="dateRangeText"
									label="Rango de fechas"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="filters.dates"
								range
								selected-items-text="2 seleccionados"
								locale="es-ar"
							>
								<v-spacer></v-spacer>
								<v-btn
									text
									color="primary"
									@click="modal = false"
								>
									Cancel
								</v-btn>
								<v-btn
									text
									color="primary"
									@click="$refs.dialog.save(filters.dates)
											actualizarVista()"
								>
									OK
								</v-btn>
							</v-date-picker>
					</v-dialog>
				</v-col>
			</v-row>
			<v-row v-if="loading && canViewDashboard">
				<v-col cols="12" class="text-center">
					<v-progress-circular
						:size="100"
						:width="5"
						color="purple"
						indeterminate
						class="mb-5 mt-10"
					></v-progress-circular>
					<h6 class="text-h6">Cargando métricas...</h6>
				</v-col>
			</v-row>
			<v-row v-else>
				<v-col v-if="canViewDashboard" cols="12">
					<h5 class="text-h5">{{ periodo }}</h5>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						v-if="hasAccess('LEC.VEN.VEN')"
						title="Cantidad de Ventas"
						:number="getSaleMetrics.total_sales"
						text-color="red--text text--lighten-5"
						number-size="4"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						v-if="hasAccess('LEC.TRE.FUN')"
						title="Facturado"
						:number="formatCurrency(getSaleMetrics.total_amount)"
						text-color="red--text text--lighten-5"
						number-size="4"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						v-if="hasAccess('LEC.TRE.FUN')"
						title="Ganancia"
						:number="formatCurrency(getSaleMetrics.total_gain)"
						text-color="green"
						number-size="4"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						v-if="hasAccess('LEC.COM.COM')"
						title="Total de Compras"
						:number="formatCurrency(getPurchaseMetrics.total_amount)"
						text-color="deep-orange--text text--lighten-5"
						number-size="4"
					></CardInfoNumber>
				</v-col>
				<v-col cols="12" lg="3" md="4" sm="6">
					<CardInfoNumber
						v-if="hasAccess('LEC.TRE.GAS')"
						title="Gasto Total"
						:number="formatCurrency(getExpenseMetrics.total_amount)"
						text-color="pink--text text--lighten-5"
						number-size="4"
					></CardInfoNumber>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardInfoNumber from "@/components/card-info-number.vue";

export default {
	name: "Home",
	components: { CardInfoNumber },
	data: () => ({
		fab: false,
		hover: false,
		top: true,
		left: true,
		transition: 'scale-transition',
		filters: {
			dates: [
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			]
		},
		loading: false,
		modal: false,
		periodo: 'Hoy'
	}),
	created: async function(){
		this.actualizarVista();
	},
	computed: {
		...mapGetters("Auth", ["hasAccess"]),
		...mapGetters("Dashboard", ['getSaleMetrics', 'getPurchaseMetrics', 'getExpenseMetrics']),

		canViewDashboard(){
			return this.hasAccess('LEC.VEN.VEN') || this.hasAccess('LEC.TRE.FUN') || this.hasAccess('LEC.COM.COM') || this.hasAccess('LEC.TRE.GAS');
		},

		dateRangeText () {
			return this.filters.dates.join(' ~ ')
		},
	},
	methods: {

		...mapActions('Dashboard', ['get']),

		redirect(route) {
			this.$router.push({ name: route });
		},

		actualizarVista: async function(){
			this.loading = true;
			await this.get({
				filters: {
					from: this.filters.dates[0],
					to: this.filters.dates[1]
				}
			})
			this.loading = false;
		},

		getRangoDeHoy(){
			this.periodo = 'Hoy';
			return [
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			];
		},

		getRangoDeUnaSemana(){
			this.periodo = 'Semana';
			return [
				(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			];
		},

		getRangoDeUnMes(){
			this.periodo = 'Mes';
			let today = new Date();
			return [
				(new Date(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()))).toISOString().substr(0, 10),
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			];
		},

		setFecha(type){
			switch (type) {
				case 'hoy': this.filters.dates = this.getRangoDeHoy()
					break;
				case 'semana': this.filters.dates = this.getRangoDeUnaSemana()
					break;
				case 'mes': this.filters.dates = this.getRangoDeUnMes()
					break;
			}
			this.actualizarVista()
		}
	},
};
</script>
