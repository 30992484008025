<template>
	<div>
		<Title title="Mi Caja" ></Title>
		<dialog-confirmed
			:value="openClose.dialog"
			:progress="openClose.load"
			:title="openClose.title"
			@close="openClose.dialog = false"
			@confirm="openCloseConfirm"
		></dialog-confirmed>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
			<v-row>
				<v-col>
					<v-btn
						elevation="2"
						fab
						color="primary"
						to="/daily-box/box/store"
						v-if="hasAccess('ESC.CAJ.CAJ')"
						><v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col><h6 class="text-subtitle-1">Se muestra los saldos en efectivo.</h6></v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table
						:headers="headers"
						:loading="tableLoad"
						:items="daily_boxes"
						:items-per-page="5"
						class="elevation-1"
						no-data-text="No existen registros, aún..."
					>
					<template v-slot:item.issue_date_close_custom="{ item }">
						{{ item.issue_date_close_custom ?? 'Abierto' }}
					</template>
					<template v-slot:item.diference="{ item }">
						<div :class="{'red--text': diference(item) < 0, 'green--text': diference(item) > 0}" >
							{{ formatCurrency(diference(item))  }}
						</div>
					</template>
					<template v-slot:item.amount_current="{ item }">
						{{ formatCurrency(item.amount_current) }}
					</template>
					<template v-slot:item.amount_open="{ item }">
						{{ formatCurrency(item.amount_open) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('ESC.CAJ.CAJ')" title="Abrir/Cerrar Caja" class="ma-2" fab x-small @click="openCloseBox(item)">
							<v-icon> mdi-key </v-icon>
						</v-btn>
					</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
import DialogConfirmed from "@/components/dialog-confirmed.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogConfirmed, Title },
	data: () => ({
		name: "Sucursal",
		tableLoad: false,
		branch: null,
		headers: [
			{ text: "Usuario", value: "user_open.name" },
			{ text: "Saldo Apertura", value: "amount_open" },
			{ text: "Saldo", value: "amount_current" },
			{ text: "Diferencia", value: "diference" },
			{ text: "Fecha Apertura", value: "issue_date_open_custom" },
			{ text: "Fecha Cierre", value: "issue_date_close_custom" },
			{ text: "Acciones", value: "actions", sortable: false },
		],
		editedIndex: -1,
		dialogDelete: false,
		deleteItemId: null,
		deleteLoad: false,
		boxFund: 2,
		openClose: {
			id: null,
			load: false,
			dialog: false,
			title: ''
		}
	}),

	computed: {
		...mapState('DailyBox', ['daily_boxes']),
		...mapGetters('Auth', ['hasAccess'])
	},

	created: async function () {
		this.tableLoad = true;
		if(this.$store.state.DailyBox.daily_boxes.length == 0)
			await this.$store.dispatch('DailyBox/get');
		this.tableLoad = false;
	},

	methods: {
		...mapActions("DailyBox", ["store"]),

		deledit(item) {
			this.editedIndex = this.branches.indexOf(item);
			this.branch = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "BoxUpdate",
				params: { box: item },
			});
		},

		diference(item){
			return item.amount_current - item.amount_open;
		},

		deleteItem(id) {
			this.deleteItemId = id;
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.formLoad = true;
			this.delete(this.deleteItemId);
			this.closeDelete();
			await this.get();
			this.formLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},

		openCloseConfirm: async function (){
			this.openClose.load = true;
			await this.$store.dispatch('DailyBox/openClose', this.openClose.id);
			this.$store.dispatch('DailyBox/get');
			this.openClose.dialog = false;
			this.openClose.load = false;
		},

		openCloseBox(item) {
			this.openClose.id = item.id;
			this.openClose.title = '¿Desea cerrar la caja?';
			if(item.issue_date_close)
				this.openClose.title = '¿Desea abrir la caja?';
			this.openClose.dialog = true
		},
	},
};
</script>
