<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-sale-point"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="form.code"
										required
										label="Código"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="form.name"
										required
										label="Descripción"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-sale-point"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Toast from "../../../components/toast.vue";

export default {
	components: { Toast },
	data: () => ({
		dialog: true,
		formValid: false,
		formLoad: false,
		form: {
			id: -1,
			code: "",
			name: "",
		},
	}),
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		errors: {
			get() {
				return this.$store.state.SalePoint.errors;
			},
			set(value) {
				this.$store.commit("SalePoint/SET_ERRORS", value);
			},
		},

		...mapState("List", ["dictionary"]),

		...mapGetters("Auth", ["getSalePointForId", "getEmail"]),

		title() {
			return this.form.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},
	},
	created() {
		this.init();
	},
	methods: {
		...mapActions("SalePoint", ["store"]),

		...mapActions("Auth", ["get"]),

		init() {
			if (this.$route.params.id) {
				this.form.id = this.$route.params.id;
				let data = this.getSalePointForId(this.form.id);
				this.form.name = data.name;
				this.form.code = data.code;
			}
		},

		close() {
			this.$nextTick(() => {
				this.form = Object.assign({}, null);
				this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
			this.tableLoad = true;
		},

		successSave: async function () {
			if (!this.form.id > 0) this.$refs.form.reset();
			await this.get();
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.store({
				validate: this.$refs.form.validate(),
				salePoint: this.form,
				before: () => {
					this.beforeSave();
				},
				success: () => {
					this.successSave();
				},
				complete: () => {
					this.completeSave();
				},
			});
		},
	},
};
</script>
