<template>
<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="closeDialog()"
		v-model="dialog"
		max-width="1100px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="closeDialog();"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-stock-adjustment" @submit.prevent="save">
					<v-container>
						<v-row class="ml-3 mt-2">
							<DateTimeCustom
								label="Fecha del ajuste"
								v-model="form.issue_date"
								v-on:date-changed="getIssueDate"
								:v="$v"
							/>
							<v-col cols="12" lg="4">
								<Deposit
									@value="getDeposit"
									:validator="$v"
								/>
							</v-col>
							<v-col>
								 <v-select
								 	label="Motivo"
									:items="getStockAdjustmentReasons"
									item-value="id"
									item-text="name"
									v-model="form.reason"
									:error-messages="reasonErrors"
									return-object
									@change="$v.form.reason.$touch()"
									@blur="$v.form.reason.$touch()"
                				></v-select>
							</v-col>
							<v-col cols="8">
								<v-textarea
									rows="2"
									solo
									v-model="form.comment"
									label="Detalle..."
								></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" lg="12">
								<ProductListByProvider
									@product-alter="getProductsCharged"
									:with-prices="false"
									:with-new-product-action="false"
									:with-duplicate-action="false"
									:with-stock="false"
									:with-product-count-zero="true"
									:with-button-to-add-product-count="true"
									:with-button-to-discard-products="true"
									:enable-product-count-zero="true"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="closeDialog();"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-stock-adjustment"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Deposit from '@/components/list/deposits-list';
import DateTimeCustom from "@/components/datetime-custom-1";
import ProductListByProvider from "@/components/purchasing/product-list-by-provider-v2";

export default {

	components: {ProductListByProvider, DateTimeCustom, Deposit},

	validations: {
		form: {
			issue_date: { required },
			deposit: { required },
			reason: { required }
		},
	},

	data: () => ({
		loading: false,
		dialog: true,
		disabled: false,
		form: {
			issue_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			comment: null,
			deposit: {
				id: null,
				name: null
			},
			reason: null,
			products: []
		}
	}),

	created: async function(){

		await this.$store.dispatch('Product/getStock');

		if(this.$store.state.Product.stockAdjustmentReasons.length == 0)
			await this.$store.dispatch('Product/getStockAdjustmentReason')

	},

	computed: {
		...mapGetters('Product', ['getStockAdjustmentReasons']),

		title() {
			return this.$route.params.id > 0 ? "Ajuste de Stock" : "Ajuste de Stock";
		},

		issueDateErrors() {
			const errors = [];
			if (!this.$v.form.issue_date.$dirty) return errors;
			!this.$v.form.issue_date.required &&
				errors.push("La fecha de ajuste es requerido");
			return errors;
		},

		depositErrors() {
			const errors = [];
			if (!this.$v.form.deposit.$dirty) return errors;
			!this.$v.form.deposit.required &&
				errors.push("El depósito es requerido");
			return errors;
		},

		reasonErrors() {
			const errors = [];
			if (!this.$v.form.reason.$dirty) return errors;
			!this.$v.form.reason.required &&
				errors.push("El motivo es requerido");
			return errors;
		},
	},

	methods: {

		...mapActions('Product', ['createStockAdjustment']),

		getDeposit(deposit) {
      this.form.deposit = deposit;
    },

		getIssueDate(date){
			this.form.issue_date = date;
		},

		getProductsCharged(products){
			this.form.products = products;
		},

		close() {
			this.$nextTick(() => {
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave(){
			this.loading = true;
		},

		successSave(){
			if(this.$store.state.errors.status == 200){
				//Dispatchers
				this.$store.dispatch('Product/getStock');
			 	this.$store.dispatch('Product/getStockAdjustmentReason');
				this.$store.dispatch('Product/get');

				this.$router.back();
			}
		},

		completeSave(){
			this.loading = false;
		},

		closeDialog(){
			this.dialog = !this.dialog
			this.$router.back();
		},

		formatProductsToSend(){
			const productToSend = [];
			this.form.products.forEach(element => {

					let product = {
						product_id: element.product_id,
            category_id: element.category.id,
            name: element.name,
            cost_price: parseFloat(element.unit_price),
						attributes: [],
						stocks: []
					}

					element.attribute_values.forEach(attributeValue => {
						product.attributes.push({
							id: attributeValue.id,
							value: attributeValue.value
						})
					});

					element.attribute_detail.forEach(attributeDetail => {
						product.stocks.push({
							attribute_values: attributeDetail.attributes,
							count: parseInt(attributeDetail.count)
						})
					});

					productToSend.push(product)

			})

			return productToSend
		},

		save() {

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			const productToSend = Array.from(this.formatProductsToSend())

			if(productToSend.length == 0) {
				return this.sweetAlertCustom('Debe haber al menos 1 producto cargado', 'warning');
			}

			this.createStockAdjustment({
				validate: true,
				data: {
					issue_date: this.form.issue_date,
					deposit_id: this.form.deposit.id,
					stock_adjustment_reason_id: this.form.reason?.id ?? null,
					comment: this.form.comment,
					products: productToSend
				},
				before: () => {
					this.beforeSave();
				},
				success: () => {
					this.successSave();
				},
				complete: () => {
					this.completeSave();
				},
			});
		},

	}

}

</script>
