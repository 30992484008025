<template>
	<div  style="width: 100%; height: 600px; text-align: center;">
		<v-card elevation-11 style="margin: 0 auto; margin-top: 100px; border-radius: 30px;" width="350px" elevation="2" :loading="loading">
			<v-card-title>
				<div style="width: 100%; text-align: center;">
					<v-img :src="require('./../../assets/logo.png')"  width="100" style="border-radius: 255px; margin: 0 auto;" />
					<v-divider class="ma-4"></v-divider>
					<h3>Inicio de Sesión</h3>
				</div>
			</v-card-title>
			<v-card-text>
				<v-form
					class="ma-3"
					ref="form"
					method="POST"
					@submit.prevent="login"
				>
					<v-text-field
						:counter="80"
						v-model="form.name"
						label="Usuario"
						required
					></v-text-field>
					<v-text-field
						v-model="form.password"
						label="Contraseña"
						required
						type="password"
					>
					</v-text-field>
					<!--<v-checkbox dense label="Recordarme"></v-checkbox>-->
					<v-divider class="mt-3 mb-3"></v-divider>
					<v-btn
						color="primary"
						class="mr-4"
						rounded
						type="submit"
						:disabled="loading"
					>
						Iniciar Sesión
					</v-btn>
					<!--<v-btn
						color="light"
						class="mt-2 mr-4"
						block
						@click="registerWithGoogle"
						:disabled="loading"
					>
						<v-icon left>mdi-google</v-icon>
						Iniciar con Google
					</v-btn>

					<v-divider class="mt-3 mb-3"></v-divider>

					<v-btn
						color="secondary"
						class="mt-2"
						block
						@click="register"
						:disabled="loading"
					>
						Registrarse
					</v-btn>
					-->
					<!--<v-btn
						small
						@click="forgetPassword"
						:disabled="loading"
						block
						class="mt-2"
						text
					>
						¿Olvidó su contraseña?
					</v-btn>
					-->
				</v-form>
			</v-card-text>
			</v-card>
	</div>
</template>
<script>

export default {
	data: () => ({
		form: {
			name: "",
			password: "",
		},
		loading: false,
	}),
	methods: {
		login: async function () {
			this.loading = true;
			await this.$store.dispatch("Auth/login", {
				credentials: this.form,
				session: this.$session,
			});
			if (this.$session.exists()) {
				await this.$store.dispatch("Auth/get");
				this.loading = false;
				this.$router.push("/");
			}
		},
		register: async function () {
			this.$router.push("/register");
		},
		registerWithGoogle: async function () {},
		forgetPassword: async function () {},
	},
};
</script>
