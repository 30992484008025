<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="closeDialog"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							closeDialog();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-product" @submit.prevent="save">
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-combobox
									:items="providers"
									item-text="alias"
									item-value="id"
									auto-select-first
									v-model="form.provider"
									label="Proveedor Asociado"
								>
								</v-combobox>
							</v-col>
						</v-row>
					</v-container>
					<ProductForm
						:withAddOption="false"
						:withSingleForm="false"
						:product="productToAdd"
						@add-product="productAdded"
					></ProductForm>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						closeDialog();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-product"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import ProductForm from '@/components/inventory/store-product';

export default {
	components: {ProductForm},
	props: {
		dialogOut: {
			default: true
		},
		single: {
			default: false
		},
		idOut: {
			default: null
		},
		providerIdOut: {
			default: null
		}
	},
	validations: {
		form: {
			name: { required }
		},
	},
	data: () => ({
		dialogParent: false,
		loading: false,
		disabled: false,
		active: [],
		open: [],
		productToAdd: {
			description: "",
			category: null,
			attributesValues: [],
			attributesName: ''
		},
		form: {
			id: null,
			name: "",
			provider_id: null,
			category_id: null,
			provider: {
				id: null,
				name: ''
			},
			category: {
				id: 1,
				name: ''
			},
			code: "",
			bars_code: "",
			detail: "",
			attributes: []
		},
		attributeValues: [],
		categories: [],
		attributes: [],
		providers: [],
		defaultProvider: {
			id: null,
			alias: 'Sin proveedor asociado'
		}
	}),
	created: async function () {
		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');
		if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');
		if(this.$store.state.Provider.providers.length == 0)
			await this.$store.dispatch('Provider/get');
		this.categories = this.getCategories;
		this.providers = [].concat(this.getProviders);
		this.providers.unshift(this.defaultProvider);
		this.form.category = this.getFirstCategory;
		this.form.provider = this.defaultProvider;
		this.selectCategory();
		if (this.$route.params.id){
			this.form = Object.assign({}, this.getProduct(this.$route.params.id));
			this.productToAdd.description = this.form.name;
			this.productToAdd.category = this.getCategory(this.form.category_id);
			this.productToAdd.attributesValues = this.form.attributes;
			this.form.provider = this.getProvider(this.form.provider_id) ?? this.defaultProvider;
			this.selectCategoryCharged();
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		id(val){
			this.form = Object.assign({}, this.getProduct(val));
			this.selectCategoryCharged();
		},
		providerId(val){
			this.form.provider = this.getProvider(val);
			this.form.category = null;
			this.attributeValues = []
		}
	},
	computed: {

		dialog: {
      get() {
        return this.dialogOut;
      },
      set(newValue) {
        this.$emit('update:dialog', newValue);
      },
    },

		id: {
      get() {
        return this.idOut;
      },
      set(newValue) {
        this.$emit('update:id', newValue);
      },
    },

		providerId: {
      get() {
        return this.providerIdOut;
      },
      set(newValue) {
        this.$emit('update:providerId', newValue);
      },
    },

		...mapGetters('Category', ['getCategories', 'getCategory', 'getFirstCategory']),
		...mapGetters('CategoryAttribute', ['getCategoryAttribute']),
		...mapGetters('Provider', ['getFirstProvider', 'getProviders', 'getProvider']),
		...mapGetters('Product', ['getFirstProviderLinked']),

		title() {
			return this.$route.params.id > 0 ? "Editar Producto" : "Nuevo Producto";
		},

		nameErrors() {
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
			!this.$v.form.name.required && errors.push("El nombre es requerido");
			return errors;
		},

		barsCodeErrors() {
			const errors = [];
			if (!this.$v.form.bars_code.$dirty) return errors;
			!this.$v.form.bars_code.required &&
				errors.push("El código de barras es requerido");
			return errors;
		},

		...mapGetters("Product", ["getProduct"]),
	},
	methods: {

		...mapActions('Product', ['store']),

		productAdded(product){
			this.form.category = product.category;
			this.attributeValues = product.attribute_values;
			this.form.name = product.name;
		},

		selectCategoryCharged(){
			this.attributeValues = [];
			if(this.form.attributes)
				this.form.attributes.forEach(element => {
					let attribute = this.getCategoryAttribute(element.id);
					let values = attribute.values;
					this.attributeValues.push({
						id: element.id,
						name: attribute.name,
						value: values.length > 0 ? parseInt(element.value) : element.value,
						items: values,
						isArray: Array.isArray(values) && values.length > 0
					})
				});
		},

		selectCategory(){
			this.attributeValues = [];
			let attributes = this.getCategory(this.form.category.id).attributes;
			attributes.forEach(element => {
				let value = '';
				let isArray = false;
				let items = [];
				if(element.values.length > 0){
					value = element.values[0].id;
					isArray = true;
					items = element.values
				}
				this.attributeValues.push({
					id: element.id,
					name: element.name,
					value: value,
					items: items,
					isArray: isArray
				})
			});
		},

		close() {
			this.$nextTick(() => {
				//this.user = Object.assign({}, null);
				// this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave(){
			this.loading = true;
		},

		successSave(){
			if(this.$store.state.errors.status == 200){
				this.$emit('product-created', this.form)
				this.$store.dispatch('PriceList/get');
				if(!this.single){
					this.$store.dispatch('Product/get');
					this.$router.back();
				}
				else{
					this.dialog = false;
					this.resetForm();
				}
			}
		},

		completeSave(){
			this.loading = false;
		},

		closeDialog(){
			if(!this.single)
				this.$router.back();
		},

		resetForm(){
			this.form = {
				id: null,
				name: "",
				category: {
					id: 1,
					name: ''
				},
				code: "",
				bars_code: "",
				detail: "",
				attributes: []
			}
			this.categories = this.getCategories;
			this.form.category = this.getFirstCategory;
			this.selectCategory();
			this.$v.$reset();
		},

		save() {
			this.$v.$touch();
			console.log(this.form)
			//return;
			if (!this.$v.$invalid) {
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						name: this.form.name,
						code: this.form.bars_code,
						category_id: this.form.category_id,
						provider_id: this.form.provider.id,
						detail: this.form.detail,
						attributes: this.attributeValues
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
			}
		},
	},
};
</script>
