<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<p class="text-h5 blue--text">A {{ action }} {{ formatCurrency(totalCharge - discount) }} </p>
				<v-checkbox
					v-model="allCash"
					@change="setAllCash"
					label="Cobrar todo en efectivo"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" lg="4" class="mt-3">
				<v-combobox
						:items="paymentMethods"
						outlined
						item-text="name"
						item-value="id"
						:loading="loading.paymentMethod"
						v-model="charge.paymentMethod"
						:error-messages="paymentMethodErrors"
						@change="$v.charge.paymentMethod.$touch()"
						@blur="$v.charge.paymentMethod.$touch()"
						label="Método de Pago"
					>
				</v-combobox>
			</v-col>
			<v-col cols="12" lg="4" class="mt-3">
				<v-text-field
					v-model="charge.amount"
					outlined
					prefix="$"
					:error-messages="amountErrors"
					@input="$v.charge.amount.$touch()"
					@blur="$v.charge.amount.$touch()"
					label="Monto"
				></v-text-field>
			</v-col>
			<v-col cols="12" lg="2">
				<v-btn
					class="mx-2 mt-4"
					fab
					small
					dark
					color="primary"
					@click="addCharge"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-container>
				<v-row class="text-center mb-2 rounded-sm elevation-1" v-for="item in charges" :key="item.name">
					<v-col cols="4" lg="3">
						<p class="text-h6 ma-0">{{ item.name }}</p>
					</v-col>
					<v-col cols="4" lg="3">
						<p class="text-h6 ma-0">{{ formatCurrency(item.amount) }}</p>
					</v-col>
					<v-col cols="2" lg="3" class="pb-0 mt-0 text-center">
						<v-btn
								class="mx-2"
								fab
								dark
								dense
								x-small
								color="primary"
								@click="deleteCharge(item)"
							>
								<v-icon dark> mdi-delete </v-icon>
							</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-row>
		<v-row>
			<v-col v-if="pendiente == 0">
				<v-btn class="success" rounded small @click="applyDiscount()">Aplicar descuentos</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" lg="4">
				<p class="text-h5">Total {{ formatCurrency(total - discount) }} </p>
			</v-col>
			<v-col cols="12" lg="4">
				<p class="text-h5">Pendiente {{ formatCurrency(pendiente) }} </p>
			</v-col>
			<v-col v-if="discount > 0" cols="12" lg="4">
				<p class="text-h5 success--text">Descuento {{ formatCurrency(discount) }} </p>
			</v-col>
			<v-col v-if="discount == 0 && showDiscount" cols="12" lg="4">
				<p class="text-h6 success--text">Sin descuentos</p>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { required, integer } from "vuelidate/lib/validators";

export default {
	props: {
		totalCharge: {
			default: 0
		},
		action: {
			default: 'cobrar'
		}
	},
	validations: {
		charge: {
			paymentMethod: { required },
			amount: { required, integer }
		},
	},
	data: () => {
		return {
			loading: {
				paymentMethod: true
			},
			allCash: false,
			showDiscount: false,
			charge: {
				paymentMethod: {
					id: null,
					title: null
				},
				amount: 0
			},
			detail: '',
			discount: 0,
			charges: []
		}
	},
	created: async function(){
		if(this.$store.state.PaymentMethod.paymentMethods.length == 0)
			await this.$store.dispatch('PaymentMethod/get');
		this.loading.paymentMethod = false;
		this.charge.paymentMethod = this.getFirstPaymentMethod;

		this.$emit('charges-updated', {
			details: [],
			comment: null,
			total: this.total
		})
	},
	computed: {
		...mapState('PaymentMethod', ['paymentMethods']),
		...mapGetters('PaymentMethod', ['getFirstPaymentMethod']),

		paymentMethodErrors() {
			const errors = [];
			if (!this.$v.charge.paymentMethod.$dirty) return errors;
			!this.$v.charge.paymentMethod.required &&
				errors.push("El método de pago es requerido");
			return errors;
		},

		amountErrors() {
			const errors = [];
			if (!this.$v.charge.amount.$dirty) return errors;
			!this.$v.charge.amount.required &&
				errors.push("La monto es requerido");
			!this.$v.charge.amount.integer &&
				errors.push("La monto debe ser numérico");
			return errors;
		},

		total(){

			let total = 0;

			if(this.charges.length > 0)
				this.charges.forEach(element => {
						total += parseFloat(element.amount);
				});

			return total;
		},

		pendiente(){
			return this.totalCharge - this.total
		}
	},
	watch: {
		pendiente(){
			if(this.pendiente == this.totalCharge)
				this.showDiscount = false;
		}
	},
	methods: {

		emitChargeUpdate(chargeDiscount = false){
			this.$emit('charges-updated', {
				details: this.charges,
				comment: this.detail,
				total: this.total,
				discount: this.discount,
				chargeDiscount: chargeDiscount
			})
		},

		addCharge(){
			this.$v.$touch();
			if(this.$v.$invalid)
				return;

			if(this.charges.filter((prop) => prop.id == this.charge.paymentMethod.id).length > 0)
				return this.sweetAlertCustom('Este método de pago ya fue cargado', 'warning');

			if(this.charge.amount <= 0)
				return this.sweetAlertCustom('El monto debe ser mayor a cero', 'warning')

			if(this.totalCharge == 0)
				return this.sweetAlertCustom('El monto a ' + this.action + ' es cero', 'warning')

			if((parseFloat(this.total) + parseFloat(this.charge.amount)) > this.totalCharge)
				return this.sweetAlertCustom('El monto supera el monto a ' + this.action, 'warning')

			let discount = 0;
			let amount = this.charge.amount;


			if(this.charge.paymentMethod.discount_value > 0){
				if(this.charge.paymentMethod.discount_rate == 'FIXED'){
					discount = this.roundUpToMultiple(this.charge.paymentMethod.discount_value, this.charge.paymentMethod.discount_round);
				}else{
					discount = this.roundUpToMultiple(amount / 100 * this.charge.paymentMethod.discount_value, this.charge.paymentMethod.discount_round);
				}
			}

			let charge = {
				id: this.charge.paymentMethod.id,
				name: this.charge.paymentMethod.name,
				amount: amount,
				discount: discount
			};

			this.charges.push(Object.assign({}, charge))

			this.emitChargeUpdate();

		},

		applyDiscount(){

			this.charges.forEach(element => {
					this.discount += parseFloat(element.discount)
			});

			this.showDiscount = true;

			this.emitChargeUpdate(true);
		},

		getCashMethod(){
			return this.paymentMethods.filter((prop) => prop.fund.fund_type == 'CF')[0];
		},

		setAllCash(){
			this.charges = [].concat([]);

			this.discount = 0;

			if(!this.allCash)
				return this.emitChargeUpdate();

			const method = this.getCashMethod();

			let amount = this.totalCharge;
			let discount = 0;

			if(method.discount_value > 0){
				if(method.discount_rate == 'FIXED')
					discount = this.roundUpToMultiple(method.discount_value, method.discount_round);
				else
					discount = this.roundUpToMultiple(amount / 100 * method.discount_value, method.discount_round);
			}

			const charge = {
				id: method.id,
				name: method.name,
				amount: amount,
				discount: discount
			};

			this.charges.push(Object.assign({}, charge))

			this.emitChargeUpdate();
		},

		deleteCharge(item){
			let productosFiltrados = this.charges.filter((prop) => prop.id != item.id);
			this.charges = [].concat(productosFiltrados);
			this.discount = 0;
			this.emitChargeUpdate();
		}
	}
}
</script>
