<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="9" md="9" sm="12">
        <v-combobox
          :items="getProductsToLoad"
          clearable
          filled
          dense
          :filter="productToLoadFilter"
          :item-text="productItemText"
          item-value="id"
          :hint="'Usando ' + priceList.name"
          persistent-hint
          :loading="loading.product"
          v-model="cart.product"
          label="Productos"
          @change="changeProduct()"
        >
          <template v-slot:append-outer>
            <v-chip v-if="cart.product?.id" style="margin-top: -5px;" :color="getStock <= getMinStock ? 'error' : 'success'">
              {{ getStock }}
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item>
              <div class="pa-2">
              <p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ getCategory(item.category_id)?.name }} {{ item.name }} {{ item.attributes_name }}</p>
              <!-- <p class="text-body-2 font-weight-light text-capitalize ma-0">{{ item.attributes_name }}</p> -->
            </div>
            </v-list-item>
            <v-list-item-action class="float-right">
              <v-chip :color="item.stock.count <= getMinStock ? 'error' : 'success'">
                {{ item.stock.count }}
              </v-chip>
            </v-list-item-action>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="6" lg="2" md="2" sm="4">
        <v-text-field
					v-model="cart.price"
					clearable
					filled
          dense
					label="Precio"
				></v-text-field>
      </v-col>
      <v-col cols="2" lg="1" md="1" sm="2">
				<v-btn
					class="mx-2 mt-3"
					fab
					small
					dark
					color="primary"
					@click="addProduct()"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
			</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-iterator
          :items="productsCharged"
          no-data-text="No se encontraron datos, aún..."
          hide-default-footer
        >
          <template v-slot:default="props" >
            <v-container>
              <v-row class="pb-2" v-for="item in props.items" :key="item.id">
                <v-col cols="4" lg="5" class="pb-0 d-inline">
                  <p class="text-body-2 font-weight-light text-capitalize ma-0">#{{ item.code }} </p>
                  <p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ getCategory(item.category_id)?.name }} {{ item.name }} {{ item.attributes_name }} </p>
                  <v-select
                    v-if="getStocksByProduct(item).length > 0"
                    :items="getStocksByProduct(item)"
                    item-text="name"
                    v-model="item.stock"
                    :value="getStocksByProduct(item)[0]"
                    dense
                    return-object
                    item-value="id"
                  ></v-select>
                </v-col>
                <v-col cols="4" lg="2" class="pb-0 mt-0">
                  <v-text-field
                      prefix="$"
                      outlined
                      hide-details
                      dense
                      @input="editProduct(item)"
                      v-model="item.unit_price"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" lg="1" class="pb-0 mt-0">
                  <v-text-field
                      outlined
                      dense
                      hide-details
                      @input="editProduct(item)"
                      v-model="item.count"
                    ></v-text-field>
                </v-col>
                <v-col cols="5" lg="3" class="pb-0 mt-0 text-center">
                  <p class="text-h5 mt-1">{{ formatCurrency(item.unit_price * (parseInt(item.count) ? item.count : 0))  }}</p>
                </v-col>
                <v-col cols="3" lg="1" class="pb-0 mt-0 text-center">
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      dense
                      x-small
                      color="red"
                      @click="deleteProduct(item.id)"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  props: {
    priceList: {
      default: null
    }
  },

  data () {
		return {
      loading: {
        product: false,
      },
      cart: {
				product: {
					id: null,
					name: null,
					full_name: null
				},
				count: 1,
				price: null,
			},
      productsCharged: [],
      stock: []
		}
	},

  created: async function(){
    if(this.$store.state.Product.products.length == 0)
			await this.$store.dispatch('Product/get');
    if(this.$store.state.Category.categories.length == 0)
      await this.$store.dispatch('Category/get');
    
    await this.$store.dispatch('Product/getStock');

    if(this.$store.state.PriceList.priceLists.length == 0)
			await this.$store.dispatch('PriceList/get');

		this.loading.product = false;
  },

  computed: {
		...mapGetters('Product', ['getProduct', 'getProductsWithStock']),
		...mapGetters('Category',['getCategory']),
		...mapGetters('PriceList', ['getPriceList']),
    ...mapGetters({
			getStoreStock: 'Product/getStock'
		}),

    getProductsToLoad(){
      return this.getProductsWithStock
    },
    
    getStock(){
      if(this.cart.product?.id)
				return this.cart.product.stock.count;
			return 0;
    },

    getMinStock(){
			if(this.cart.product?.id)
				return this.cart.product.stock.min;
			return 0;
		},
  },

  methods: {
    productToLoadFilter(item, queryText, itemText) {
			const searchText = queryText.toLowerCase();
			const searchWords = searchText.split(" ");
			const itemValues = [item.name, this.getCategory(item.id)?.name ?? '', item.attributes_name ?? ''];
			return searchWords.every(word => {
				return itemValues.some(value => value.toLowerCase().includes(word));
			});
		},

    getStocksByProduct(cart){
      return this.getStoreStock.filter(element => element.product_id == cart.product_id && element.attribute_values.length > 0);
    },

    changeProduct(){
      if(this.cart.product){
				if(!this.priceList)
					return this.cart.price = this.cart.product.cost_price;
				this.cart.price = this.priceList.products.filter((prop) => prop.product_id == this.cart.product.id)[0].price;
			}
    },

    productItemText(item){
      if(!item?.id){
        return ''
      }
      let categoryName = this.getCategory(item.category_id)?.name;
      return `${categoryName} ${item.full_name}`
    },

    addProduct(){

      this.cart.count = 1;

			if(!this.cart.product)
				return this.sweetAlertCustom('Debe seleccionar un producto', 'warning');

			if(!this.cart.product.id)
				return this.sweetAlertCustom('Debe seleccionar un producto', 'warning');

      if(this.cart.price == 0)
				return this.sweetAlertCustom('El precio no puede ser 0', 'warning');

			if(this.cart.count == 0)
				return this.sweetAlertCustom('La cantidad no puede ser 0', 'warning');

      const maxId = this.productsCharged.reduce((max, objeto) => (objeto.id > max ? objeto.id : max), 0);

      let productAdding = {
        id: maxId ? maxId + 1 : 1,
        code: this.cart.product.code,
        product_id: this.cart.product.id,
        category_id: this.cart.product.category_id,
        product: this.cart.product,
        name: this.cart.product.name,
        attributes_name: this.cart.product.attributes_name,
        full_name: this.getProduct(this.cart.product.id).full_name,
        unit_cost: this.cart.product.cost_price,
        unit_price: this.cart.price,
        count: this.cart.count,
        total_price: this.cart.price * this.cart.count,
        taxes_discount: 0,
        taxes_amount: 0,
        stock: null
      };

      productAdding.stock = this.getStocksByProduct(productAdding)[0] ?? null;

      this.productsCharged.push(Object.assign({}, productAdding))

      this.$emit('product-alter', this.productsCharged)
    },

    editProduct(item){
      let productos = [].concat(this.productsCharged)

			const indice = productos.findIndex((elemento, indice) => {
				if (elemento.id == item.id) {
					return true;
				}
			});

			productos[indice].unit_price = item.unit_price;
			productos[indice].count = item.count;

			this.productsCharged = [].concat(productos);
      this.$emit('product-alter', this.productsCharged);
    },

    deleteProduct(id){
      let productosFiltrados = this.productsCharged.filter((prop) => prop.id != id);
			this.productsCharged = [].concat(productosFiltrados);
      this.$emit('product-alter', this.productsCharged)
    }
  }

}

</script>