var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        persistent: "",
        transition: "dialog-bottom-transition",
        "max-width": "1100px",
        fullscreen: _vm.config.fullscreen,
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialogg,
        callback: function ($$v) {
          _vm.dialogg = $$v
        },
        expression: "dialogg",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("Toolbar", {
            attrs: { title: _vm.title },
            on: {
              "config-update": _vm.updateConfig,
              "close-dialog": _vm.closeDialog,
            },
          }),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-purchase" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.e6,
                        callback: function ($$v) {
                          _vm.e6 = $$v
                        },
                        expression: "e6",
                      },
                    },
                    [
                      _c("StepperHeader", {
                        attrs: { validator: _vm.$v, step: _vm.e6 },
                      }),
                      _c(
                        "v-stepper-items",
                        [
                          _c("StepperMainInfo", {
                            attrs: {
                              validator: _vm.$v,
                              step: _vm.e6,
                              "origin-data": _vm.form,
                            },
                            on: {
                              "main-info": _vm.getMainInfo,
                              "main-info-confirmed": _vm.confirmeMainInfo,
                            },
                          }),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              !_vm.providerView
                                ? _c("ProductList", {
                                    attrs: {
                                      withEditProductAction: true,
                                      carts: this.form.carts,
                                    },
                                    on: { "product-alter": _vm.productAlter },
                                  })
                                : _c("ProductListByProvider", {
                                    attrs: {
                                      provider: _vm.form.provider.id,
                                      carts: this.form.carts,
                                    },
                                    on: { "product-alter": _vm.productAlter },
                                  }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.hasProductsAdds,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.e6 = 3
                                    },
                                  },
                                },
                                [_vm._v(" Continuar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.e6 = 1
                                    },
                                  },
                                },
                                [_vm._v(" Atras ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _c("SurchargeList", {
                                staticClass: "mb-4",
                                attrs: {
                                  title: "Recargos",
                                  types: this.form.surcharges,
                                  amount: _vm.totalPreciosCargadosSinFormato,
                                },
                                on: {
                                  "amount-changed": _vm.surchargesAmountChanged,
                                },
                              }),
                              _c("v-divider"),
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", lg: "4" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-subtitle-2" },
                                            [_vm._v("Monto Neto")]
                                          ),
                                          _c("p", { staticClass: "text-h5" }, [
                                            _vm._v(
                                              _vm._s(_vm.totalPreciosCargados)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", lg: "4" } },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-subtitle-2" },
                                            [_vm._v("Monto Aplicado")]
                                          ),
                                          _c("p", { staticClass: "text-h5" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    _vm.totalSurchargesCharged
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "bg-blue",
                                          attrs: { cols: "12", lg: "4" },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "text-subtitle-2" },
                                            [_vm._v("Monto Total")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "text-h5 blue--text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatCurrency(
                                                    _vm.totalPreciosCargadosSinFormato +
                                                      _vm.totalSurchargesCharged
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.e6 = 4
                                    },
                                  },
                                },
                                [_vm._v(" Continuar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.e6 = 2
                                    },
                                  },
                                },
                                [_vm._v(" Atras ")]
                              ),
                            ],
                            1
                          ),
                          !this.$route.params.id
                            ? _c(
                                "v-stepper-content",
                                { attrs: { step: "4" } },
                                [
                                  _c("ChargeList", {
                                    attrs: {
                                      action: "pagar",
                                      "total-charge": _vm.totalMontoSale,
                                    },
                                    on: {
                                      "charges-updated": _vm.chargesUpdated,
                                    },
                                  }),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 5
                                        },
                                      },
                                    },
                                    [_vm._v(" Continuar ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 3
                                        },
                                      },
                                    },
                                    [_vm._v(" Atras ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: !this.$route.params.id ? 5 : 4 } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12 pa-3",
                                  attrs: { "min-height": "200px", tile: "" },
                                },
                                [
                                  _c("h5", { staticClass: "text-h5" }, [
                                    _vm._v("Detalle de la compra"),
                                  ]),
                                  _c("v-spacer"),
                                  _c("p", { staticClass: "text-body-1 mt-3" }, [
                                    _vm._v(" Compra en la fecha "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.form.issue_date)),
                                    ]),
                                    _vm._v(" a "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.form.provider?.alias)),
                                    ]),
                                    _vm._v(" que será depositada en "),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.form.deposit?.name)),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "text-body-2" }, [
                                    _c("strong", [_vm._v("Comentario: ")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.form.comment ?? "Sin datos")
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "ma-3" }),
                                  _c("p", { staticClass: "text-body-1" }, [
                                    _vm._v("Se cargaron "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.totalProductosCargados) +
                                          " productos"
                                      ),
                                    ]),
                                    _vm._v(" con un total de "),
                                    _c(
                                      "strong",
                                      { staticClass: "blue--text" },
                                      [_vm._v(_vm._s(_vm.totalPreciosCargados))]
                                    ),
                                  ]),
                                  _c("p", { staticClass: "text-body-1" }, [
                                    _vm._v("Se aplica un recargo total de "),
                                    _c(
                                      "strong",
                                      { staticClass: "blue--text" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCurrency(
                                              _vm.totalSurchargesCharged
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  !this.$route.params.id
                                    ? _c("p", { staticClass: "text-body-2" }, [
                                        _vm._v(
                                          " Del cual se pagará un total de "
                                        ),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(_vm.totalToPay)
                                            )
                                          ),
                                        ]),
                                        _vm._v(" quedando pendiente a pagar "),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatCurrency(
                                                _vm.totalMontoSale -
                                                  _vm.totalToPay
                                              )
                                            )
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    type: "submit",
                                    color: "primary",
                                    loading: _vm.loadingPurchase,
                                    disbaled: _vm.loadingPurchase,
                                    form: "form-purchase",
                                  },
                                },
                                [_vm._v(" Guardar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      !_vm.$route.params.id
                                        ? (_vm.e6 = 4)
                                        : (_vm.e6 = 3)
                                    },
                                  },
                                },
                                [_vm._v(" Atras ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }