<template>
	<v-container class="mt-4">
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="items"
					height="700"
					:fixed-header="true"
					:options.sync="options"
					:server-items-length="total"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
				></v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
	data: () => {
		return {
			search: {
				name: "",
			},
			tab: null,
			loading: false,
			totalAudits: 0,
			options: {},
			total: 0,
			headers: [
				{ text: "Depósito", value: "deposit.name" },
				{ text: "Producto", value: "product.name" },
				{ text: "Usuario", value: "user.name" },
				{ text: "Tipo", value: "movement_inventory_type_ref" },
				{ text: "Cantidad", value: "count" },
				{ text: "Fecha Operación", value: "issue_date" },
				{ text: "Fecha de Creación", value: "created_at" },
			],
			items: [],
		};
	},
	created: async function () {
		await this.get();
		this.items = this.products;
	},
	methods: {
		...mapActions("MovementInventory", ["get"]),
	},
};
</script>
