<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pt-2">
				<v-form ref="form" id="form-category" @submit.prevent="save">
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-text-field
									required
									v-model="form.name"
									label="Nombre"
									:error-messages="nameErrors"
									@change="$v.form.name.$touch()"
									@blur="$v.form.name.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="8">
								<v-textarea
									solo
									rows="2"
									v-model="form.description"
									label="Detalle..."
								></v-textarea>
							</v-col>
							<v-col cols="12" lg="12">
								<h5 class="text-h5">Atributos</h5>
								<p class="text-subtitle-1">
									Seleccione los atributos que tendrá esta categoria. Los atributos con <strong>"No discriminar stock"</strong>
									agruparan en un stock único los productos con este atributo diferente.
								</p>
								<AttributeList
									:attributes-in="form.attributes"
									v-on:change-attribute-value="changeAttributeValue"
								></AttributeList>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						$router.back();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-category"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import AttributeList from "@/components/inventory/attribute-list";

export default {
	components: { AttributeList },
	validations: {
		form: {
			name: { required }
		},
	},
	data: () => {
		return {
			active: [],
			open: [],
			dialog: true,
			dialogParent: false,
			loading: false,
			disabled: false,
			form: {
				id: null,
				name: null,
				description: null,
				attributes: []
			}
		};
	},
	created: async function () {
		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');

		if(this.$route.params.id){
			this.form = Object.assign({}, this.getCategory(this.$route.params.id));
		}
	},
	computed: {
		...mapGetters('Category', ['getCategory']),

		title() {
			return this.$route.params.id > 0 ? "Editar Categoria" : "Nueva Categoria";
		},

		nameErrors() {
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
				!this.$v.form.name.required && errors.push("El nombre es requerido");
			return errors;
		},
	},
	methods: {

		...mapActions('Category', ['store']),

		changeAttributeValue(attributeValues){
			this.form.attributes = attributeValues;
		},

		beforeSave() {
			if(this.form.attributes.length == 0)
				return this.sweetAlertCustom('Debe agregar al menos un atributo', 'warning')
			this.loading = true
		},

		successSave() {
			if(this.$store.state.errors.status == 200){
				this.$store.dispatch('Category/get');
				this.$router.back();
			}
		},

		completeSave() {
			this.loading = false;
		},

		save() {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						name: this.form.name,
						description: this.form.description,
						attributes: this.form.attributes
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
