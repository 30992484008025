var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Recargos" } }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c("DialogDelete", {
            attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
            on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.hasAccess("ESC.TRE.TRC")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            rounded: "",
                            "x-large": "",
                            color: "primary",
                            to: "/treasury/surcharge-type",
                          },
                        },
                        [
                          _c("v-icon", { staticClass: "mr-2" }, [
                            _vm._v("mdi-cash-multiple"),
                          ]),
                          _vm._v(" Tipos de Recargo "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.surcharges,
                      search: _vm.search.name,
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 50, 100],
                        itemsPerPageText: "Filas por página:",
                      },
                      loading: _vm.tableLoad,
                      "sort-by": "created_at",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.surchargeable_id",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { class: _vm.getColorOrigin(item) },
                              [
                                _c(
                                  "v-icon",
                                  { class: _vm.getColorOrigin(item) },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getIconOrigin(item)) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getTitleOrigin(item)) +
                                    " N° " +
                                    _vm._s(item.surchargeable_id) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.amount",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "red--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s("- ") +
                                  " " +
                                  _vm._s(_vm.formatCurrency(item.amount)) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.type",
                        fn: function ({ item }) {
                          return [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(item.type.rate) +
                                  " " +
                                  _vm._s(
                                    item.type.rate_type == "FIXED"
                                      ? "Fijo"
                                      : "%"
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }