<template>
  <v-stepper-header>
    <v-stepper-step
    :complete="step > 1"
    class=""
    step="1"
    :rules="[() => !validator.$invalid]"
    >
    Datos Principales
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step
    :complete="step > 2"
    step="2"
    >
    Listado de Productos
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :complete="step > 3" step="3">
      Recargos y Descuentos
    </v-stepper-step>

    <v-divider v-if="!this.$route.params.id"></v-divider>

    <v-stepper-step v-if="!this.$route.params.id" :complete="step > 4" step="4">
      Proceso de Pago
    </v-stepper-step>

    <v-divider></v-divider>

    <v-stepper-step :step="!this.$route.params.id ? 5 : 4">
      Vista General
    </v-stepper-step>
  </v-stepper-header>
</template>
<script>

export default {

  props: ['validator', 'step'],
  
  data: () => {
    return {
      
    }
  },
  methods: {

  }

};

</script>