<template>
	<div>
		<v-container class="mt-3 mb-10 flex-column">
			<v-row class="justify-center">
				<v-data-table
					:headers="headers"
					:items="$store.state.PuntoVenta.puntosVenta"
					sort-by="name"
					:loading="tableLoad"
					class="elevation-1"
				>
					<template v-slot:top>
						<v-toolbar flat>
							<v-toolbar-title>{{ name }}</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
							<v-spacer></v-spacer>
							<v-btn color="primary" to="puntos_de_venta/create" small fab>
								<v-icon dark> mdi-plus </v-icon>
							</v-btn>
							<dialog-delete
								:value="dialogDelete"
								@close="closeDelete"
								@confirm="deleteItemConfirm"
							></dialog-delete>
						</v-toolbar>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-icon small class="mr-2" @click="editItem(item)">
							mdi-pencil
						</v-icon>
						<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
					</template>
					<template v-slot:item.principal="{ item }">
						<span v-if="item.principal == 1">Sí</span>
						<span v-else>No</span>
					</template>
					<template v-slot:no-data></template>
				</v-data-table>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";

export default {
	components: { DialogDelete },
	data: () => ({
		name: "Punto de Venta",
		dialogDelete: false,
		tableLoad: false,
		headers: [
			{ text: "Descripción", value: "descripcion" },
			{ text: "Código", value: "codigo" },
			{ text: "Acciones", value: "actions", sortable: false },
		],
		editedIndex: -1,
	}),

	computed: {
		puntoVenta: {
			get() {
				return this.$store.state.PuntoVenta.puntoVenta;
			},
			set(val) {
				this.$store.commit("PuntoVenta/SET_PUNTO_VENTA", val);
			},
		},

		puntosVenta: {
			get() {
				return this.$store.state.PuntoVenta.puntosVenta;
			},
			set(val) {
				this.$store.commit("PuntoVenta/SET_PUNTOS_VENTA", val);
			},
		},
	},

	watch: {
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created() {
		this.init();
	},

	methods: {
		...mapActions("PuntoVenta", ["getPuntosVenta", "store", "delete"]),

		init: async function () {
			this.tableLoad = true;
			await this.getPuntosVenta();
			this.tableLoad = false;
		},

		deledit(item) {
			this.editedIndex = this.puntosVenta.indexOf(item);
			this.puntoVenta = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "PuntoVentaUpdate",
				params: { puntoVenta: item },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.formLoad = true;
			this.delete(this.puntoVenta);
			this.closeDelete();
			await this.getPuntosVenta();
			this.formLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	},
};
</script>
