import axios from "axios";

export default {
	namespaced: true,
	state: {
		branch: {
			id: null,
			head: null,
			name: null,
			sale_point: null,
		},
		branches: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_BRANCH(state, data) {
			state.branches = data;
		},
		SET_BRANCHES(state, data) {
			state.branches = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("branch").then((response) => {
				commit("SET_BRANCHES", response.data.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("branch", params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.data.id) {
					await dispatch("update", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("branch/" + params.data.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors ?? error.response.data,
						type: "error",
					});
				});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("branch/" + id)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors ?? error.response.data,
						type: "error",
					});
				});
		},
	},
	getters: {
		getBranch: (state) => (branchId) => {
			if (state.branches)
				return state.branches.filter((prop) => prop.id == branchId)[0];
			else return "";
		},
		getBranchs: (state) => {
			if (state.branches) return state.branches;
			else return [];
		},
		getFirstSalePoint: (state) => (branchId) => {
			if (state.branches)
				return state.branches.filter((prop) => prop.id == branchId)[0]
					.sale_point[0];
			else return "";
		},
		getFullAddress: (state) => (branchId) => {
			if (state.branches) {
				let branch = state.branches.filter((prop) => prop.id == branchId)[0];
				let address =
					branch.addresses[0].street + " " + branch.addresses[0].number;
				let city = branch.addresses[0].location.description;
				return city + ", " + address;
			} else return "";
		},
	},
};
