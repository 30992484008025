<template>
	<v-row>
		<v-col cols="12">
			<h4>Datos de Dirección</h4>
		</v-col>
		<v-col lg="3">
			<v-select
				:items="address.provinces"
				item-text="description"
				item-value="id"
				v-model="address.province"
				label="Provincia"
				:error-messages="provinceErrors"
				@change="
					v.address.province.$touch();
					changeCities();
				"
				@blur="v.address.province.$touch()"
			></v-select>
		</v-col>
		<v-col lg="4">
			<v-combobox
				:items="address.cities"
				item-text="description"
				item-value="id"
				v-model="address.city"
				label="Localidad"
				:error-messages="cityErrors"
				@change="v.address.city.$touch()"
				@blur="v.address.city.$touch()"
			></v-combobox>
		</v-col>
		<v-col lg="3">
			<v-text-field
				v-model="address.street"
				label="Calle"
				:error-messages="streetErrors"
				@input="v.address.street.$touch()"
				@blur="v.address.street.$touch()"
			></v-text-field>
		</v-col>
		<v-col lg="2">
			<v-text-field
				v-model="address.number"
				label="Número"
				:error-messages="numberErrors"
				@input="v.address.number.$touch()"
				@blur="v.address.number.$touch()"
			></v-text-field>
		</v-col>
	</v-row>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	props: ["addressable", "v"],
	data: () => {
		return {
			address: {
				provinces: [],
				cities: [],
				province: null,
				city: null,
				street: null,
				number: null,
			},
		};
	},
	created() {
		this.address.provinces = this.getProvinces;
		if (this.addressable) {
			this.address.province = this.addressable.location.parent;
			this.address.cities = this.getCities(this.address.province);
			this.address.city = this.addressable.location;
			this.address.street = this.addressable.street;
			this.address.number = this.addressable.number;
		} else {
			this.address.province = this.getFirstProvince.id;
			this.address.cities = this.getCities(this.address.province);
			this.address.city = this.address.cities[0];
		}
		this.v.address.$model = this.address;
		this.v.address.$reset();
	},
	computed: {
		...mapGetters("Location", [
			"getProvinces",
			"getCities",
			"getFirstProvince",
		]),

		provinceErrors() {
			const errors = [];
			if (!this.v.address.province.$dirty) return errors;
			!this.v.address.province.required &&
				errors.push("La provincia es requerida");
			return errors;
		},
		cityErrors() {
			const errors = [];
			if (!this.v.address.city.$dirty) return errors;
			!this.v.address.city.required && errors.push("La localidad es requerida");
			return errors;
		},
		streetErrors() {
			const errors = [];
			if (!this.v.address.street.$dirty) return errors;
			!this.v.address.street.required && errors.push("La calle es requerida");
			return errors;
		},
		numberErrors() {
			const errors = [];
			if (!this.v.address.number.$dirty) return errors;
			!this.v.address.number.required && errors.push("El número es requerido");
			!this.v.address.number.integer && errors.push("Debe ingresar un número");
			return errors;
		},
	},
	methods: {
		changeCities() {
			this.address.cities = this.getCities(this.address.province);
			this.address.city = this.address.cities[0];
		},
	},
};
</script>
