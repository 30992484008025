import axios from "axios";

export default {
	namespaced: true,
	state: {
		products: [],
		stock: [],
		stockAdjustmentReasons: []
	},
	mutations: {
		SET_PRODUCT(state, data) {
			state.product = data;
		},
		SET_PRODUCTS(state, data) {
			state.products = data;
		},
		SET_STOCK(state, data) {
			state.stock = data;
		},
		SET_STOCK_ADJUSTMENT_REASON(state, data) {
			state.stockAdjustmentReasons = data
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("product").then((response) => {
				commit("SET_PRODUCTS", response.data.data);
			}).catch(() => {});
		},
		getStock: async function ({ commit }) {
			return await axios.get("stock").then((response) => {
				commit("SET_STOCK", response.data.data);
			}).catch(() => {});
		},
		getStockAdjustmentReason: async function ({ commit }) {
			return await axios.get("stock-adjustment-reason").then((response) => {
				commit("SET_STOCK_ADJUSTMENT_REASON", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("product", params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("product/" + params.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("product/" + id)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {});
		},
		createStockAdjustment: async function ({ commit }, params) {
			params.before();
			return axios
				.post("stock-adjustments", params.data)
				.then((res) => {
					commit("SET_ERRORS", res, { root: true });
					params.success();
					params.complete();
					commit("SET_ERRORS", null, { root: true });
				})
				.catch(() => {
					params.complete();
				});
		},
	},
	getters: {
		getProducts: (state) => {
			if (state.products) return state.products;
			else return [];
		},
		getProductsByProvider: (state) => (providerId) => {
			if (state.products) 
				return state.products.filter((prop) => prop.provider_id == providerId);
			else return [];
		},
		getProduct: (state) => (productId) => {
			if (state.products)
				return state.products.filter((prop) => prop.id == productId)[0];
			else return "";
		},
		getProductsWithStock: (state) => {
			if (state.products)
				return state.products.filter((prop) => prop.stock.count > 0);
			else return "";
		},
		getFirstProviderLinked: (state) => (productId) => {
			if (state.products)
				return state.products.filter((prop) => prop.id == productId)[0]?.providers[0];
			else return "";
		},
		getStock: (state) => {
			if (state.stock) return state.stock;
			else return [];
		},
		getProductStock: (state) => (productId) => {
			if (state.stock)
				return state.stock.filter((prop) => prop.product_id == productId);
			else return [];
		},
		getStockAdjustmentReasons: (state) => {
			if (state.stockAdjustmentReasons) return state.stockAdjustmentReasons;
			else return [];
		},
	},
};
