export default [
	{
		title: 'General',
		children: [
			{
				title: "DASHBOARD",
				icon: "mdi-view-dashboard-outline",
				route: "Home",
				textColor: "pink--text",
				bgColor: "pink lighten-5 pink--text",
				permission: true,
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "MI CAJA",
				icon: "mdi-cash-register",
				route: "BoxData",
				textColor: "pink--text",
				bgColor: "pink lighten-5 pink--text",
				permission: 'LEC.CAJ.CAJ',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "MOVIMIENTOS",
				icon: "mdi-compare-vertical",
				route: "DailyBoxMovementData",
				textColor: "pink--text",
				bgColor: "pink lighten-5 pink--text",
				permission: 'LEC.CAJ.MOV',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "GASTOS",
				icon: "mdi-candy-outline",
				route: "ExpenseData",
				textColor: "pink--text",
				bgColor: "pink lighten-5 pink--text",
				permission: 'LEC.TRE.GAS',
				onlyMobile: false,
				onlyPC: false
			},
		]
	},
	{
		title: 'Venta',
		children: [
			{
				title: "VENTAS",
				icon: "mdi-basket-outline",
				route: "SaleData",
				textColor: "red--text",
				bgColor: "red lighten-5 red--text",
				permission: 'LEC.VEN.VEN',
				onlyMobile: false,
				onlyPC: false
			}
		]
	},
	{
		title: 'Compra',
		children: [
			{
				title: "COMPRAS",
				icon: "mdi-cart-variant",
				route: "PurchaseData",
				textColor: "deep-orange--text",
				bgColor: "deep-orange lighten-5 deep-orange--text",
				permission: 'LEC.COM.COM',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "PROVEEDORES",
				icon: "mdi-truck",
				route: "ProviderData",
				textColor: "deep-orange--text",
				bgColor: "deep-orange lighten-5 deep-orange--text",
				permission: 'LEC.COM.PRV',
				onlyMobile: false,
				onlyPC: true
			}
		]
	},
	{
		title: 'Inventario',
		children: [
			{
				title: "PRODUCTOS",
				icon: "mdi-tshirt-crew-outline",
				route: "ProductData",
				textColor: "purple--text",
				bgColor: "purple lighten-5 purple--text",
				permission: 'LEC.INV.PRD',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "LISTAS DE PRECIO",
				icon: "mdi-percent-box-outline",
				route: "PriceListData",
				textColor: "purple--text",
				bgColor: "purple lighten-5 purple--text",
				permission: 'LEC.INV.LPR',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "CATEGORIAS",
				icon: "mdi-shape-outline",
				route: "CategoryData",
				textColor: "purple--text",
				bgColor: "purple lighten-5 purple--text",
				permission: 'LEC.INV.CAT',
				onlyMobile: false,
				onlyPC: false
			}
		]
	},
	{
		title: 'Tesoreria',
		children: [
			{
				title: "FONDOS",
				icon: "mdi-bank-outline",
				route: "MyFundData",
				textColor: "teal--text",
				bgColor: "teal lighten-5 teal--text",
				permission: 'LEC.TRE.FUN',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "MÉTODOS DE PAGO",
				icon: "mdi-credit-card-multiple-outline",
				route: "PaymentMethodData",
				textColor: "teal--text",
				bgColor: "teal lighten-5 teal--text",
				permission: 'LEC.TRE.PME',
				onlyMobile: false,
				onlyPC: false
			},
			{
				title: "RECARGOS",
				icon: "mdi-cash-plus",
				route: "SurchargeData",
				textColor: "teal--text",
				bgColor: "teal lighten-5 teal--text",
				permission: 'LEC.TRE.REC',
				onlyMobile: false,
				onlyPC: false
			}
		]
	},
	{
		title: 'Administración',
		children: [
			{
				title: "USUARIOS",
				icon: "mdi-account-circle-outline",
				route: "UserData",
				textColor: "blue--text",
				bgColor: "blue lighten-5 blue--text",
				permission: 'LEC.ADM.USU',
				onlyMobile: false,
				onlyPC: false
			},
		]
	}
	/*{
		title: "DASHBOARD",
		icon: "mdi-view-dashboard-outline",
		route: "Home",
		textColor: "pink--text",
		bgColor: "pink lighten-5 pink--text",
		permission: true,
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "MI CAJA",
		icon: "mdi-cash-register",
		route: "BoxData",
		textColor: "blue-grey--text",
		bgColor: "blue-grey lighten-5 blue-grey--text",
		permission: 'LEC.CAJ.CAJ',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "VENTA",
		icon: "mdi-basket-outline",
		route: "SaleData",
		textColor: "red--text",
		bgColor: "red lighten-5 red--text",
		permission: 'LEC.VEN.VEN',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "GASTOS",
		icon: "mdi-cash-minus",
		route: "ExpenseData",
		textColor: "green--text",
		bgColor: "green lighten-5 green--text",
		permission: 'LEC.TRE.GAS',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "FONDOS",
		icon: "mdi-bank-outline",
		route: "MyFundData",
		textColor: "teal--text",
		bgColor: "teal lighten-5 teal--text",
		permission: 'LEC.TRE.FUN',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "RECARGOS",
		icon: "mdi-cash-plus",
		route: "SurchargeData",
		textColor: "teal--text",
		bgColor: "teal lighten-5 teal--text",
		permission: 'LEC.TRE.FUN',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "COMPRA",
		icon: "mdi-cart-variant",
		route: "PurchaseData",
		textColor: "deep-orange--text",
		bgColor: "deep-orange lighten-5 deep-orange--text",
		permission: 'LEC.COM.COM',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "PROVEEDORES",
		icon: "mdi-truck",
		route: "ProviderData",
		textColor: "deep-purple--text",
		bgColor: "deep-purple lighten-5 deep-purple--text",
		permission: 'LEC.COM.PRV',
		onlyMobile: false,
		onlyPC: true
	},
	{
		title: "INVENTARIO",
		icon: "mdi-package-variant-closed",
		route: "ProductData",
		textColor: "purple--text",
		bgColor: "purple lighten-5 purple--text",
		permission: 'LEC.INV.PRD',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "USUARIOS",
		icon: "mdi-account-circle-outline",
		route: "UserData",
		textColor: "blue--text",
		bgColor: "blue lighten-5 blue--text",
		permission: 'LEC.ADM.USU',
		onlyMobile: false,
		onlyPC: false
	},
	{
		title: "MAS",
		icon: "mdi-dots-horizontal-circle-outline",
		route: "UserData",
		textColor: "blue--text",
		bgColor: "blue lighten-5 blue--text",
		permission: 'LEC.ADM.USU',
		onlyMobile: true,
		onlyPC: false
	},
	*/
]
;
