<template>
	<v-list>
		<v-subheader>Fondos</v-subheader>
		<v-list-item-group
			v-model="selected"
			color="primary"
		>
			<v-list-item
				v-for="fund in funds"
				:key="fund.id"
				@click="selectFund(fund)"
			>
				<v-list-item-icon>
					<v-icon> {{ getIcon(fund) }} </v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ fund.title }}</v-list-item-title>
					<v-list-item-subtitle>{{ formatCurrency(fund.balance) }} </v-list-item-subtitle>
					<span class="text-caption mt-2" :class="{'orange--text': fund.movements_sum_amount}">
						<v-icon :class="{'orange--text': fund.movements_sum_amount}" x-small style="margin-top: -2px;">mdi-clock-outline</v-icon>
						{{ formatCurrency(fund.movements_sum_amount) }}
					</span>
				</v-list-item-content>
				<v-list-item-action>
					<v-btn icon v-if="hasAccess('ESC.TRE.FUN')" @click="redirectWithParam('MyFundTransferData', { id: fund.id })">
						<v-icon color="grey lighten-1">mdi-send</v-icon>
					</v-btn>
          <v-btn icon v-if="hasAccess('ESC.TRE.FUN')" @click="redirectWithParam('MyFundAdjust', { id: fund.id })">
            <v-icon color="grey lighten-1">mdi-pencil</v-icon>
          </v-btn>
        </v-list-item-action>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	props: ["funds"],
	data: () => ({
		fundTypes: [
			{id: 'CC', name: 'Cuenta Corriente'},
			{id: 'CA', name: 'Caja de Ahorro'},
			{id: 'CD', name: 'Cuenta Digital'},
			{id: 'CF', name: 'Caja en Efectivo'},
		],
		selected: 0
	}),
	computed: {

		...mapGetters('Auth', ['hasAccess']),

		getSelectedColor(){
			if(this.$vuetify.theme.dark)
				return 'teal darken-4';
			return 'teal lighten-5';
		},


	},
	methods: {

		getFundType(fund){
			return this.fundTypes.filter((element) => element.id == fund.fund_type)[0].name;
		},

		getIcon(fund){
			if(fund.fund_type == 'CC')
				return 'mdi-bank-outline';
			if(fund.fund_type == 'CF')
				return 'mdi-cash-register';
			if(fund.fund_type == 'CD')
				return 'mdi-wallet-outline'
			return 'mdi-bank-outline';
		},

		selectFund(fund){
			this.$emit('selected-fund', fund)
			this.$emit('click')
		},
		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},
	}
};
</script>
