import axios from "axios";
import http from "../../utils/http";

export default {
	namespaced: true,
	state: {
		dashboard: {}
	},
	mutations: {
		SET_DASHBOARD(state, data) {
			state.dashboard = data;
		}
	},
	actions: {
		get: async function ({ commit }, params) {
			return await axios.get("dashboard" + http.formatQueryParams(params.filters)).then((response) => {
				commit("SET_DASHBOARD", response.data);
			}).catch(() => {});
		},
	},
	getters: {
		getSaleMetrics: (state) => {
			if (state.dashboard)
				return state.dashboard.sales
			else return null;
		},
		getPurchaseMetrics: (state) => {
			if (state.dashboard)
				return state.dashboard.purchases
			else return null;
		},
		getExpenseMetrics: (state) => {
			if (state.dashboard)
				return state.dashboard.expenses
			else return null;
		},
	},
};
