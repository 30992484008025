<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="500px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-card-title dark fixed color="primary">
				Transferencia
			</v-card-title>

			<v-card-text>
				<h5 class="text-h6 mt-5"> <v-icon>mdi-bank-transfer-in</v-icon> Transferir desde <strong>{{ fund.source.title }}</strong></h5>
				<v-form
						ref="form"
						id="form-transfer"
						@submit.prevent="save"
						v-model="formValid"
					>
					<v-container>
						<v-row>
							<v-col cols="12" lg="8">
								<v-combobox
									:items="funds"
									item-text="title"
									item-value="id"
									auto-select-first
									prepend-icon="mdi-bank-transfer-out"
									v-model="fund.target"
									label="Fondo Destino"
									:loading="loading.fund.target"
									:error-messages="fundTargetErrors"
									@change="$v.fund.target.$touch()"
									@blur="$v.fund.target.$touch()"
								>
								</v-combobox>
							</v-col>
							<v-col cols="12" lg="4">
								<v-text-field
									v-model="fund.amount"
									required
									label="Monto"
									prefix="$"
									:error-messages="amountErrors"
									@change="$v.fund.amount.$touch()"
									@blur="$v.fund.amount.$touch()"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>
					</v-form>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
				</v-btn>
				<v-btn
					color="primary"
					type="submit"
					text
					:disabled="formLoad"
					:loading="formLoad"
					form="form-transfer"
				>
					<v-icon>mdi-bank-transfer</v-icon>  Transferir
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { required, integer, minValue } from 'vuelidate/lib/validators';

  export default {
		validations:{
			fund: {
				target: { required },
				amount: { required, integer, minValue: minValue(1) }
			}
		},
    data: () => {
			return {
				formValid: false,
				formLoad: false,
        dialog: true,
				fund: {
					source: null,
					target: null
				},
				loading: {
					fund: {
						target: true
					}
				},
				funds: []
      }
		},
		created: async function() {
			if(this.$store.state.Fund.funds.length == 0)
				await this.$store.dispatch('Fund/get');

			this.loading.fund.target = false;
			if (this.$route.params.id){
				this.funds = this.getFundsWithout(this.$route.params.id);
				this.fund.source = this.getFund(this.$route.params.id);
			}
		},
		computed: {
			...mapGetters('Fund', ['getFund', 'getFirstFund', 'getFundsWithout']),

			fundTargetErrors() {
				const errors = [];
				if (!this.$v.fund.target.$dirty) return errors;
				!this.$v.fund.target.required &&
					errors.push("El fondo destino es requerido");
				return errors;
			},

			amountErrors() {
				const errors = [];
				if (!this.$v.fund.amount.$dirty) return errors;
				!this.$v.fund.amount.required &&
					errors.push("El monto es requerido");
				!this.$v.fund.amount.integer &&
					errors.push("El monto debe ser numérico");
				!this.$v.fund.amount.minValue &&
					errors.push("El monto mínimo es 1");
				return errors;
			},
		},
		methods: {

			...mapActions('Fund', ['transfer']),

			beforeSave() {
				this.formLoad = true;
			},

			successSave: async function () {
				if(this.$store.state.errors.status == 200){
					this.$store.dispatch('Fund/get');
					this.$router.back();
				}
			},

			save: async function () {
				this.$v.$touch();
				if (!this.$v.$invalid)
					await this.transfer({
						validate: !this.$v.$invalid,
						data: {
							path: {
								source: this.fund.source.id,
								target: this.fund.target.id
							},
							body: {
								amount: this.fund.amount
							}
						},
						before: () => {
							this.beforeSave();
						},
						success: () => {
							this.successSave();
						}
					});
				this.formLoad = false;
			},
		}
  }
</script>
