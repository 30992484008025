var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: { value: _vm.value, color: _vm.getColor },
          scopedSlots: _vm._u(
            [
              _vm.close
                ? {
                    key: "action",
                    fn: function ({ attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { color: "pink", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.error.state = false
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          [_vm._v(" Cerrar ")]
                        ),
                      ]
                    },
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("span", { staticClass: "text-subtitle-2" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          typeof _vm.getValue === "object"
            ? _c(
                "div",
                _vm._l(_vm.getValue, function (property, index) {
                  return _c(
                    "div",
                    { key: index },
                    _vm._l(property, function (value, key) {
                      return _c("ul", { key: key }, [
                        _c("li", [_vm._v(_vm._s(value))]),
                      ])
                    }),
                    0
                  )
                }),
                0
              )
            : _c("div", [
                _c("span", { staticClass: "text-body-2" }, [
                  _vm._v(_vm._s(_vm.getValue)),
                ]),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }