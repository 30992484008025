<template>
	<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar dark fixed color="primary">
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-expense"
						@submit.prevent="save"
						v-model="formValid"
					>
					<v-container>
						<v-row>
							<v-col
								cols="12"
								sm="6"
								md="4"
							>
								<v-menu
									v-model="menu"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="form.issue_date"
											label="Fecha"
											prepend-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="form.issue_date"
										@input="menu = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" lg="4">
								<v-combobox
										:items="funds"
										prepend-icon="mdi-bank-outline"
										item-text="title"
										item-value="id"
										:loading="loading.fund"
										v-model="form.fund"
										:error-messages="fundErrors"
										@change="$v.form.fund.$touch()"
										@blur="$v.form.fund.$touch()"
										label="Fondo"
									>
									</v-combobox>
							</v-col>
							<v-col cols="12" lg="4">
								<v-combobox
										:items="expense_types"
										item-text="name"
										item-value="id"
										v-model="form.expense_type"
										:error-messages="expenseTypeErrors"
										@change="$v.form.expense_type.$touch()"
										@blur="$v.form.expense_type.$touch()"
										label="Tipo"
									>
									</v-combobox>
							</v-col>
							<v-col cols="12" lg="4">
								<v-combobox
										:items="expense_states"
										item-text="name"
										item-value="id"
										v-model="form.expense_state"
										:error-messages="expenseStateErrors"
										@change="$v.form.expense_state.$touch()"
										@blur="$v.form.expense_state.$touch()"
										label="Estado"
									>
									</v-combobox>
							</v-col>
							<v-col cols="12" lg="3">
								<v-text-field
									v-model="form.amount"
									required
									label="Monto"
									prefix="$"
									:error-messages="amountErrors"
									@change="$v.form.amount.$touch()"
									@blur="$v.form.amount.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field
									v-model="form.description"
									required
									label="Título"
									:error-messages="titleErrors"
									@change="$v.form.description.$touch()"
									@blur="$v.form.description.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<v-textarea
									rows="2"
									solo
									v-model="form.detail"
									label="Detalle..."
								></v-textarea>
							</v-col>
						</v-row>
					</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-expense"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
	</v-dialog>
</template>
<script>

import { required, integer } from 'vuelidate/lib/validators';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
	validations: {
		form: {
				expense_type: { required },
				amount: { required, integer },
				description: { required },
				issue_date: { required },
				fund: { required },
				expense_state: { required }
			}
	},
	data: () => {
		return {
			title: "Gasto",
			dialog: true,
			formValid: false,
			formLoad: false,
			menu: false,
			expense_types: [
				{id: 'ADMINISTRATIVO', name: 'Administrativo'},
				{id: 'FISCAL', name: 'Fiscal'},
				{id: 'INMUEBLE', name: 'Inmueble'},
				{id: 'MANTENIMIENTO', name: 'Mantenimiento'},
				{id: 'LIMPIEZA', name: 'Limpieza'},
				{id: 'SERVICIOS', name: 'Servicios'},
				{id: 'TRANSPORTE', name: 'Transporte'},
				{id: 'OFICINA', name: 'Oficina'},
				{id: 'IMPREVISTO', name: 'Imprevisto'},
				{id: 'MARKETING', name: 'Marketing'},
				{id: 'PERSONAL', name: 'Personal'},
				{id: 'HERRAMIENTAS', name: 'Herramientas'}
			],
			expense_states: [
				{id: 'PENDING', name: 'Pendiente'},
				{id: 'CONFIRMED', name: 'Confirmado'},
				{id: 'CANCELED', name: 'Cancelado'},
			],
			loading: {
				fund: true
			},
			form: {
				id: null,
				expense_type: null,
				amount: 0,
				description: null,
				detail: null,
				issue_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				fund: {
					id: null,
					title: null
				},
				expense_state: null
			}
		}
	},
	created: async function(){
		if(this.$store.state.Fund.funds.length == 0)
			await this.$store.dispatch('Fund/get');
		this.form.fund = this.getFirstFund;
		this.form.expense_type = this.expense_types[0];
		this.form.expense_state = this.expense_states[1];
		this.loading.fund = false;
	},
	computed:{
		...mapState('Fund', ['funds']),
		...mapGetters('Fund', ['getFirstFund']),

		fundErrors(){
			const errors = [];
			if (!this.$v.form.fund.$dirty) return errors;
			!this.$v.form.fund.required &&
				errors.push("El fondo es requerido");
			return errors;
		},

		expenseTypeErrors(){
			const errors = [];
			if (!this.$v.form.expense_type.$dirty) return errors;
			!this.$v.form.expense_type.required &&
				errors.push("El tipo de gasto es requerido");
			return errors;
		},
		expenseStateErrors(){
			const errors = [];
			if (!this.$v.form.expense_state.$dirty) return errors;
			!this.$v.form.expense_state.required &&
				errors.push("El estado del gasto es requerido");
			return errors;
		},
		amountErrors(){
			const errors = [];
			if (!this.$v.form.amount.$dirty) return errors;
			!this.$v.form.amount.required &&
				errors.push("El monto es requerido");
			!this.$v.form.amount.required &&
				errors.push("El monto debe ser un número");
			return errors;
		},
		titleErrors(){
			const errors = [];
			if (!this.$v.form.description.$dirty) return errors;
			!this.$v.form.description.required &&
				errors.push("El título es requerido");
			return errors;
		}
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	methods: {

		...mapActions('Expense', ['store']),

		close() {
			this.$nextTick(() => {
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
		},

		successSave: async function () {
			if(this.$store.state.errors?.status == 200){
				this.$store.dispatch("Expense/get");
				this.$store.dispatch("Fund/get");
				this.$store.dispatch("DailyBox/get");
				this.$router.back();
			}
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						expense_type: this.form.expense_type.id,
						amount: this.form.amount,
						description: this.form.description,
						detail: this.form.detail,
						issue_date: this.form.issue_date,
						fund_id: this.form.fund.id,
						expense_state: this.form.expense_state.id
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	}
}
</script>

