import Vue from "vue";
import VueRouter from "vue-router";
import store from "../core/index.js";

import Home from "@/views/pages/home.vue";
import Login from "@/views/auth/login.vue";
import Register from "@/views/auth/register.vue";
import VerifyEmail from "@/views/auth/verify-email.vue";
import SendVerifyEmail from "@/views/auth/send-verify-email.vue";

import NotFound from "@/views/pages/errors/404.vue";

import Administration from "./administration";
import Inventory from "./inventory";
import Treasury from "./treasury";
import DailyBox from "./daily-box";
import Purchase from "./purchase";
import Sale from "./sale";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: { requiresAuth: true },
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
		meta: { requiresAuth: false },
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
		meta: { requiresAuth: false },
	},
	{
		path: "/verify-email",
		name: "VerifyEmail",
		component: VerifyEmail,
		meta: { requiresAuth: false },
	},
	{
		path: "/send-verify-email",
		name: "SendVerifyEmail",
		component: SendVerifyEmail,
		meta: { requiresAuth: true },
	},
	{
		path: "/sale",
		name: "Sale",
		component: Sale,
		meta: { requiresAuth: true },
	},
	{
		path: "/purchase",
		name: "Purchase",
		component: Purchase,
		meta: { requiresAuth: true },
	},
	Administration,
	Inventory,
	Treasury,
	DailyBox,
	Purchase,
	Sale,
	{
		path: "*",
		name: "NOT Found / 404",
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

async function verifyUserState(store) {
	if(!store.state.Auth.user?.id)
		await store.dispatch('Auth/get')
	if(!store.state.Auth.user)
		return false;
	return true;
}

router.beforeEach(async function (to, from, next) {
	let requireAuth = to.matched.some((record) => record.meta.requiresAuth);
	let userExist = await verifyUserState(store);
	if(userExist){
		if(!store._vm.$session.exists("user")){
			store._vm.$session.start();
			store._vm.$session.set("user", store.state.Auth.user);
		}
	}

	let emailVerified = false;
	if (userExist)
		emailVerified = store.state.Auth.user?.email_verified_at !== null;

	
	let name = "";
	let hasAccess = store.getters['Auth/hasAccess'](to.meta.access);
	//Si no require autenticacion, pasamos
	if (requireAuth && to.name !== "Login" && !userExist) {
		name = "Login";
	} else if (to.name === "Login" && userExist) 
		name = "Home";
	else if (to.name !== "SendVerifyEmail" && userExist && !emailVerified)
		name = "SendVerifyEmail";
	else if(!hasAccess && to.name != 'Home' && userExist)
		name = "Home"
	else name = "";
	if (name !== "") 
		next({ name: name });
	else next();
});

export default router;
