<template>
	<div>
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="text-h6">
					{{ this.getEnterpriseAlias }}
				</v-list-item-title>
				<v-list-item-subtitle>
					{{ this.getUserFullName }}
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-divider></v-divider>
		<v-list dense rounded>
			<v-list-item
				v-for="item in items"
				:key="item.title"
				link
				:class="item.bgColor"
				style="margin: 16px 6px; padding: 4px; padding-left: 15px"
				@click="redirect(item.route)"
			>
				<v-list-item-icon>
					<v-icon :class="item.textColor">{{ item.icon }}</v-icon>
				</v-list-item-icon>

				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	props: ["mini"],
	data: () => ({
		items: [
			{
				title: "DASHBOARD",
				icon: "mdi-table",
				route: "Home",
				textColor: "red--text",
				bgColor: "red lighten-5 red--text m-3",
			},
			/*{
				title: "CAJA DIARIA",
				icon: "mdi-bank",
				route: "DailyBoxData",
				textColor: "red--text",
				bgColor: "red lighten-5 red--text m-3"
			},
			{
				title: "VENTA",
				icon: "mdi-basket-outline",
				route: "sale",
				textColor: "red--text",
				bgColor: "red lighten-5 red--text m-3"
			},*/
			{
				title: "COMPRA",
				icon: "mdi-cart-variant",
				route: "PurchaseData",
				textColor: "deep-orange--text",
				bgColor: "deep-orange lighten-5 deep-orange--text"
			},
			{
				title: "INVENTARIO",
				icon: "mdi-package-variant-closed",
				route: "ProductData",
				textColor: "purple--text",
				bgColor: "purple lighten-5 purple--text",
			},
			/*{
				title: "TESORERIA",
				icon: "mdi-bank",
				route: "MyFundData",
				textColor: "teal--text",
				bgColor: "teal lighten-5 teal--text"
			},
			{
				title: "FINANZAS",
				icon: "mdi-currency-usd",
				route: "finance",
				textColor: "green--text",
				bgColor: "green lighten-5 green--text"
			},*/
			{
				title: "USUARIOS",
				icon: "mdi-account-circle-outline",
				route: "UserData",
				textColor: "blue--text",
				bgColor: "blue lighten-5 blue--text",
			},
		],
	}),
	computed: {
		...mapGetters("Auth", [
			"getUserFullName",
			"getEnterpriseAlias",
			"getBranchs",
			"getFirstBranch",
		]),
	},
	methods: {
		redirect(route) {
			this.$router.push({ name: route });
		},
		toggle() {
			this.$emit("toggle", this.mini);
		},
	},
};
</script>
