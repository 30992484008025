var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { elevation: "1", outlined: "" } },
    [
      _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _vm.subtitle
        ? _c("v-card-subtitle", [_vm._v(" " + _vm._s(_vm.subtitle) + " ")])
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "pa-4",
          staticStyle: { overflow: "auto", "max-height": "300px" },
        },
        _vm._l(this.list, function (item) {
          return _c(
            "v-list-item",
            { key: item.id },
            [
              _c("v-list-item-title", [_vm._v(_vm._s(_vm.getName(item)))]),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { fab: "", "x-small": "" },
                  on: {
                    click: function ($event) {
                      return _vm.redirectWithParam(_vm.linkToUpdate, {
                        id: item.id,
                      })
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "v-card-actions",
        [
          _vm.linkToCreate
            ? _c(
                "v-btn",
                {
                  attrs: { outlined: "", fab: "", "x-small": "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.redirect(_vm.linkToCreate)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-plus")])],
                1
              )
            : _vm._e(),
          _vm.linkToView
            ? _c(
                "v-btn",
                {
                  attrs: { outlined: "", fab: "", "x-small": "", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.redirect(_vm.linkToView)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-view-headline")])],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            { attrs: { outlined: "", fab: "", "x-small": "", text: "" } },
            [_c("v-icon", [_vm._v("mdi-help")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }