var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-3 mb-10 flex-column" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "text-h3" }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ]),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "2" } },
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.user.people.first_name)),
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.documentType) +
                            ": " +
                            _vm._s(_vm.document) +
                            " "
                        ),
                        _c("br"),
                      ]),
                      _c(
                        "v-card-actions",
                        { staticClass: "ma-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                rounded: "",
                                text: "",
                                to: "/administration/account/change-password",
                              },
                            },
                            [_vm._v("Cambiar Contraseña")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }