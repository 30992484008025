var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Compras" } }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c("dialog-delete", {
            attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
            on: {
              close: function ($event) {
                _vm.dialogDelete = false
              },
              confirm: _vm.deleteItemConfirm,
            },
          }),
          _c("dialog-confirmed", {
            attrs: {
              value: _vm.depositPurchase.dialog,
              progress: _vm.depositPurchase.load,
              title: "¿Desea depositar los productos de esta compra?",
            },
            on: {
              close: function ($event) {
                _vm.depositPurchase.dialog = false
              },
              confirm: _vm.depositPurchaseConfirm,
            },
          }),
          _c("dialog-payment", {
            attrs: { value: _vm.payment.dialog, progress: _vm.payment.load },
            on: {
              close: function ($event) {
                _vm.payment.dialog = false
              },
              confirm: _vm.paymentConfirm,
            },
          }),
          _c("PurchaseDetail", {
            attrs: {
              dialogOut: _vm.detail.dialog,
              single: true,
              purchaseOut: _vm.detail.purchase,
            },
            on: {
              "update:dialog": this.updateDialogDetail,
              "update:purchase": this.updatePurchaseDetail,
            },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.hasAccess("ESC.COM.COM")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            fab: "",
                            dark: "",
                            color: "primary",
                            to: "/purchasing/purchase/store",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-plus "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.getPurchases,
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 50, 100],
                        itemsPerPageText: "Filas por página:",
                      },
                      loading: _vm.loading,
                      "sort-by": "created_at",
                      "no-data-text": "No hay compras...",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function () {
                          return [
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: { cols: "12", sm: "6", md: "4" },
                                      },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            ref: "menu",
                                            attrs: {
                                              "close-on-content-click": false,
                                              "return-value": _vm.filter.date,
                                              transition: "scale-transition",
                                              "offset-y": "",
                                              "min-width": "auto",
                                            },
                                            on: {
                                              "update:returnValue": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.filter,
                                                  "date",
                                                  $event
                                                )
                                              },
                                              "update:return-value": function (
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.filter,
                                                  "date",
                                                  $event
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              label:
                                                                "Rango de fechas",
                                                              "prepend-icon":
                                                                "mdi-calendar",
                                                              readonly: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.filterDateText,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.filterDateText =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "filterDateText",
                                                            },
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.menu,
                                              callback: function ($$v) {
                                                _vm.menu = $$v
                                              },
                                              expression: "menu",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-date-picker",
                                              {
                                                attrs: {
                                                  "no-title": "",
                                                  scrollable: "",
                                                  range: "",
                                                },
                                                model: {
                                                  value: _vm.filter.date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.filter,
                                                      "date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "filter.date",
                                                },
                                              },
                                              [
                                                _c("v-spacer"),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.menu = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Cancel ")]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.$refs.menu.save(
                                                          _vm.filter.date
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" OK ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "4" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.purchaseStates,
                                            label: "Estado",
                                            "item-text": "text",
                                            "item-value": "value",
                                            outlined: "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        dark: "",
                                                        color:
                                                          _vm.getColorForState(
                                                            item.value
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getDescriptionForState(
                                                              item.value
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "selection",
                                              fn: function ({ item }) {
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        dark: "",
                                                        color:
                                                          _vm.getColorForState(
                                                            item.value
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getDescriptionForState(
                                                              item.value
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.filter.state,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.filter, "state", $$v)
                                            },
                                            expression: "filter.state",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", lg: "4" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.getProvidersFilter,
                                            label: "Proveedor",
                                            "item-text": "text",
                                            "item-value": "value",
                                            outlined: "",
                                          },
                                          model: {
                                            value: _vm.filter.provider,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter,
                                                "provider",
                                                $$v
                                              )
                                            },
                                            expression: "filter.provider",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item.purchase_state",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  dark: "",
                                  color: _vm.getColorForState(
                                    item.purchase_state
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDescriptionForState(
                                        item.purchase_state
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.payment_state",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  dark: "",
                                  color: _vm.getColorForState(
                                    item.payment_state
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getDescriptionForState(
                                        item.payment_state
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.pending",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                class: {
                                  "red--text":
                                    _vm.getTotalPendingToPay(item) > 0,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(
                                        _vm.getTotalPendingToPay(item)
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.total_amount",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatCurrency(item.total_amount)) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _vm.hasAccess("LEC.COM.COM")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      title: "Ver Detalle",
                                      fab: "",
                                      "x-small": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.viewPurchase(item.id)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-eye ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasAccess("ESC.COM.COM") &&
                            _vm.showUpdateButton(item)
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: { fab: "", "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redirectWithParam(
                                          "PurchaseUpdate",
                                          { id: item.id }
                                        )
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasAccess("ESC.COM.COM")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: { fab: "", "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-delete ")])],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasAccess("ESC.INV.STK")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      title: "Depositar",
                                      fab: "",
                                      "x-small": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.depositPurchaseItem(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-cube-outline "),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.hasAccess("ESC.COM.PAG")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      title: "Pagar",
                                      fab: "",
                                      "x-small": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redirectWithParam(
                                          "PaymentCreateTo",
                                          { id: item.id, type: "purchase" }
                                        )
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-cash-sync ")])],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }