import axios from "axios";

export default {
	namespaced: true,
	state: {
		purchase: {
			id: null,
			user_id: null,
			provider_id: null,
			order_id: null,
			deposit_id: null,
			issue_date: null,
			comment: null,
			products: null
		},
		purchases: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_PURCHASE(state, data) {
			state.purchase = data;
		},
		SET_PURCHASES(state, data) {
			state.purchases = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("purchase").then((response) => {
				commit("SET_PURCHASES", response.data);
			}).catch(() => {});
		},
		find: async function ({ commit }, id) {
			return await axios.get("purchase/"+id).then((response) => {
				commit("SET_PURCHASE", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("purchase", params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
					params.complete();
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
					params.complete();
				}
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("purchase/" + params.id, params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("purchase/" + id)
				.then(() => {
				})
				.catch(() => {
				});
		},
		depositPurchase: async function ({ commit }, params) {
			return axios
				.patch("purchase/"+params.id+"/PURCHASE/state/PENDING_PAYMENT")
				.then(() => {
				})
				.catch(() => {
				});
		},
		payment: async function ({ commit }, params) {
			return axios
				.patch("purchase/"+params.id+"/PURCHASE/state/PENDING_PAYMENT")
				.then(() => {
				})
				.catch(() => {
				});
		},
	},
	getters: {
		getPurchases: (state) => {
			if (state.purchases) return state.purchases;
			else return [];
		},
		getPurchase: (state) => (purchaseId) => {
			if (state.purchases)
				return state.purchases.filter((prop) => prop.id == purchaseId)[0];
			else return "";
		},
		getPurchaseDetail: (state) => {
			if (state.purchase?.id)
				return state.purchase;
			else return "";
		},
		getTotalPendingToPay: (state) => (purchaseId) => {
			if (state.purchases){
				let purchase = state.purchases.filter((prop) => prop.id == purchaseId)[0];
				return purchase.total_amount - purchase.payments.reduce((sum, obj) => parseFloat(sum) + parseFloat(obj.total_amount), 0);
			}
		},
	},
};
