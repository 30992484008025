<template>
	<v-container></v-container>
</template>
<script>

import CardForm from "@/components/card-form-custom-1.vue";

export default {
	components: { CardForm },
	data: () => {
		return {
			tab: null
		};
	},
	created: async function () {
	},
	computed: {
	},
	methods: {
	},
};
</script>
