<template>
	<v-dialog
		hide-overlay
		persistent
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialogg"
		max-width="1100px"
		min-heigth="900px"
		:fullscreen="config.fullscreen"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="config.fullscreen = !config.fullscreen"
					>
						<v-icon>{{ config.fullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
					</v-btn>
					<v-btn
						icon
						dark
						@click="
							dialogg = !dialogg;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-sales" @submit.prevent="save">
					<v-stepper v-model="e6">
						<v-stepper-header>
							<v-stepper-step
								:complete="e6 > 1"
								class=""
								step="1"
								:rules="[() => !$v.$invalid]"
								>
								Datos Principales
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step
								:complete="e6 > 2"
								step="2"
								>
								Listado de Productos
							</v-stepper-step>

							<v-divider v-if="!this.$route.params.id"></v-divider>

							<v-stepper-step v-if="!this.$route.params.id" :complete="e6 > 3" step="3">
							Proceso de Cobro
							</v-stepper-step>

							<v-divider></v-divider>

							<v-stepper-step :step="!this.$route.params.id ? 4 : 3">
							Vista General
							</v-stepper-step>

						</v-stepper-header>
						<v-stepper-items>
							<v-stepper-content step="1">
								<v-container>
									<v-row>
										<DateTimeCustom label="Fecha de la venta" v-model="form.issue_date" v-on:date-changed="newDateChanged" :v="$v"/>
										<v-col cols="12" lg="4">
											<v-select
												:items="usersWithoutOv"
												item-value="id"
												item-text="entity.full_name"
												return-object
												v-model="form.seller"
												:loading="loadings.seller"
												label="Vendedor"
												:error-messages="sellerErrors"
												@change="$v.form.seller.$touch()"
												@blur="$v.form.seller.$touch()"
											></v-select>
										</v-col>
										<v-col cols="12" lg="4">
											<v-select
												:items="priceLists"
												item-value="id"
												item-text="name"
												v-model="form.price_list"
												:loading="loadings.price_list"
												return-object
												label="Lista de Precio"
												:error-messages="priceListErrors"
												@change="$v.form.price_list.$touch()
																$forceUpdate()"
												@blur="$v.form.price_list.$touch()"
											></v-select>
										</v-col>
										<v-col cols="8">
											<v-textarea
												rows="2"
												solo
												v-model="form.comment"
												label="Detalle..."
											></v-textarea>
										</v-col>
									</v-row>
								</v-container>
							<v-btn
								color="primary"
								:disabled="!datosPrincipalesValidator"
								@click="confirmarDatosPrincipales()"
							>
								Continuar
							</v-btn>
							<v-btn
								@click="$router.go(-1)"
								text>
								Cancelar
							</v-btn>
							</v-stepper-content>

							<v-stepper-content step="2">
								<ProductList
									:with-add-product-action="false"
									:only-products-with-stock="true"
									:show-cash-discount="true"
									v-on:product-alter="productAlter"
									:with-headers-filter="false"
									:price-list="form.price_list"
									:carts="form.carts" />
								<v-btn
									color="primary"
									:disabled="!hasProductsAdds"
									@click="e6 = 3"
								>
									Continuar
								</v-btn>
								<v-btn
									@click="e6 = 1"
									text>
									Atras
								</v-btn>
							</v-stepper-content>

							<v-stepper-content v-if="!this.$route.params.id" step="3">
							<v-card
								class="mb-12 pa-3"
								min-height="200px"
								tile
							>
							<PaymentMethodList :total-charge="totalPreciosCargadosSinFormato" v-on:charges-updated="chargesUpdated" />
							</v-card>
							<v-btn
								color="primary"
								@click="e6 = 4"
							>
								Siguiente
							</v-btn>
							<v-btn
								@click="e6 = 2" text>
								Atras
							</v-btn>
							</v-stepper-content>

							<v-stepper-content :step="!this.$route.params.id ? 4 : 3">
								<v-card
									class="mb-12 pa-3"
									min-height="200px"
									tile
								>
									<v-container fluid>
										<v-row>
											<v-col cols="12" lg="6">
												<h5 class="text-h5">Detalle de la venta</h5>
												<v-spacer></v-spacer>
												<p class="text-body-1 mt-3">Venta en la fecha <strong>{{ form.issue_date }}</strong> a <strong>Consumidor Final</strong> vendido por <strong>{{ form.seller?.entity.full_name }}</strong>.</p>
												<p class="text-body-2"><strong>Comentario: </strong> {{ form.comment ?? 'Sin datos' }}</p>
												<v-divider class="ma-3"></v-divider>
												<p class="text-body-1">Se cargaron <strong>{{ totalProductosCargados }} productos</strong> con un total de <strong class="blue--text">{{ totalPreciosCargados }}</strong></p>
												<p v-if="!this.$route.params.id" class="text-body-2">
													Del cual se pagará un total de <strong>{{ formatCurrency(totalCharge - chargesCharged.discount) }}</strong>
													quedando pendiente a pagar <strong>{{ formatCurrency(totalPreciosCargadosSinFormato - totalCharge) }}</strong>
												</p>
												<p>Descuento en aplicado: <strong class="success--text text-body-1">{{ formatCurrency(chargesCharged.discount ?? 0) }}</strong> </p>
											</v-col>
											<v-col cols="12" lg="6">
												<h5 class="text-title">Listado de Productos</h5>
												<v-list-item two-line v-for="item in productsCharged" :key="item.id">
													<v-list-item-content>
														<v-list-item-title>{{ item.full_name }}</v-list-item-title>
														<v-list-item-subtitle> {{ item.count }} por {{ formatCurrency(item.total_price * item.count) }} en total.</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							<v-btn
								color="primary"
								:loading="loadingPurchase"
								:disbaled="loadingPurchase"
								@click="save"
							>
								Guardar
							</v-btn>
							<v-btn
								@click="!$route.params.id ? e6 = 3 : e6 = 2" text>
								Atras
							</v-btn>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
				</v-form>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import DateTimeCustom from "@/components/datetime-custom-1";
import ProductList from "@/components/sale/product-list";
import PaymentMethodList from "@/components/saling/payment-methods-list";
import date from "../../../utils/date";

export default {
	components: { DateTimeCustom, ProductList, PaymentMethodList },
	validations: {
		form: {
			issue_date: { required },
			seller: { required },
			price_list: { required }
		},
	},
	data: () => {
		return {
			dialogg: true,
			dialogParent: false,
			loading: false,
			disabled: false,
			menu: false,
			active: [],
			loadingPurchase: false,
			open: [],
			config: {
				fullscreen: false,
			},
			loadings: {
				seller: true,
				deposit: true,
				price_list: true
			},
			datosPrincipalesValidator: true,
			e6: 1,
			form: {
				id: null,
				seller: {
					id: null,
					entity: {
						full_name: ''
					}
				},
				price_list: {
					id: null,
					name: null
				},
				issue_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				comment: null,
				carts: null
			},
			providers: [],
			deposits: [],
			productsCharged: [],
			chargesCharged: {
				details: [],
				comment: '',
				discount: 0,
				total: 0
			}
		}
	},
	created: async function () {
		this.datosPrincipalesValidator = false;
		this.config.fullscreen = this.$vuetify.breakpoint.xs;

		if(this.$store.state.User.users.length == 0)
			await this.$store.dispatch('User/get');

		if(this.$store.state.PriceList.priceLists.length == 0)
			await this.$store.dispatch('PriceList/get');

		this.form.seller = this.getFirstUserWithoutOv;
		this.form.deposit = this.getFirstDeposit;
		this.form.price_list = this.getFirstPriceList;

		if (this.$route.params.id){
			await this.$store.dispatch('Sale/find', this.$route.params.id);
			this.form = Object.assign({}, this.getSale(this.$route.params.id));
			this.productsCharged = this.form.carts;
		}
		this.loadings.seller = false;
		this.loadings.deposit = false;
		this.loadings.price_list = false;
		this.datosPrincipalesValidator = true;
	},
	watch: {
		dialog(val) {
			val || this.close();
		}
	},
	computed: {

		...mapState('User', ['users']),
		...mapState('PriceList', ['priceLists']),

		...mapGetters('User', ['getFirstUser']),
		...mapGetters('PriceList', ['getFirstPriceList']),
		...mapGetters('Product', ['getProduct']),
		...mapGetters('Sale', ['getSale']),

		usersWithoutOv(){
			return this.users.filter((prop) => prop.username != 'osvaldovallone');
		},

		getFirstUserWithoutOv(){
			return this.users.filter((prop) => prop.username != 'osvaldovallone')[0];
		},

		sellerErrors() {
			const errors = [];
			if (!this.$v.form.seller.$dirty) return errors;
			!this.$v.form.seller.required &&
				errors.push("El vendedor es requerido");
			return errors;
		},

		priceListErrors() {
			const errors = [];
			if (!this.$v.form.price_list.$dirty) return errors;
			!this.$v.form.price_list.required &&
				errors.push("La lista de precio es requerido");
			return errors;
		},

		depositErrors() {
			const errors = [];
			if (!this.$v.form.deposit.$dirty) return errors;
			!this.$v.form.deposit.required &&
				errors.push("El depósito es requerido");
			return errors;
		},

		title() {
			return this.$route.params.id > 0 ? "Editar Venta" : "Nueva Venta";
		},

		totalPreciosCargados(){
			return this.formatCurrency(this.totalPreciosCargadosSinFormato - (this.chargesCharged.discount ?? 0));
		},

		totalProductosCargados(){
			let count = 0;
			this.productsCharged.forEach(element => {
				count += parseInt(element.count);
			});
			return count;
		},

		totalPreciosCargadosSinFormato(){

			let total = 0;

			if(this.productsCharged.length > 0)
				this.productsCharged.forEach(element => {
						total += element.unit_price * element.count;
				});

			return total;
		},

		totalCharge(){

			let total = 0;

			if(this.chargesCharged.details.length > 0)
				this.chargesCharged.details.forEach(element => {
						total += parseFloat(element.amount);
				});

			return total;
		},

		hasProductsAdds(){
			if(this.productsCharged)
				return this.productsCharged.length > 0;
		}

	},
	methods: {

		...mapActions('Sale', ['store']),

		close() {
			this.$nextTick(() => {
				//this.user = Object.assign({}, null);
				// this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		confirmarDatosPrincipales(){
			this.$v.$touch()
			if (!this.$v.$invalid)
				this.e6 = 2;
		},

		productAlter(products){
			this.productsCharged = products;
		},

		newDateChanged(date){
			this.form.issue_date = date;
		},

		beforeSave(){
			this.loadingPurchase = true;
		},

		successSave(){
			if(this.$store.state.errors.status == 200)
				this.$router.back();
			this.$store.dispatch('Sale/get', {
				filters: {
					from: date.now(),
					to: date.now()
				}
			});
			this.$store.dispatch('DailyBox/get');
			this.$store.dispatch('Product/get');
			this.$store.dispatch('Fund/get');
		},

		completeSave(){
			this.loadingPurchase = false;
		},

		chargesUpdated(charges){
			this.chargesCharged = charges;
			/*this.chargesCharged.discount = this.chargesCharged.chargeDiscount ? this.chargesCharged.discount : 0;
			if(this.chargesCharged.discount == 0){
				for (let i = 0; i < this.chargesCharged.details.length; i++) {
  				this.chargesCharged.details[i].discount = 0;
				}
			}*/
		},

		/**
		 * Limpia los descuentos si es que no se aplicó
		 */
		setChargesToSend(){
			this.chargesCharged.discount = this.chargesCharged.chargeDiscount ? this.chargesCharged.discount : 0;
			if(this.chargesCharged.discount == 0){
				for (let i = 0; i < this.chargesCharged.details.length; i++) {
  				this.chargesCharged.details[i].discount = 0;
				}
			}
		},

		getProductsFormatted(){
			let productsToEmit = [];

			this.productsCharged.forEach(element => {
				productsToEmit.push({
					id: element.id,
					product_id: element.product_id,
					name: element.full_name,
					stock: element.stock,
					count: element.count,
					unit_cost: element.unit_cost,
					unit_price: element.unit_price,
					taxes_amount: element.taxes_amount
				})        
			})

			return productsToEmit;
		},

		save() {
			this.$v.$touch();
			if (this.$v.$invalid)
				return;

			this.setChargesToSend();
			this.store({
				validate: true, // !this.$v.$invalid,
				id: this.form.id,
				data: {
					seller_id: this.form.seller.id,
					price_list_id: this.form.price_list.id,
					issue_date: this.form.issue_date,
					comment: this.form.comment,
					products: this.getProductsFormatted(),
					charge: this.chargesCharged
				},
				before: () => {
					this.beforeSave();
				},
				success: () => {
					this.successSave();
				},
				complete: () => {
					this.completeSave();
				},
			});
		},
	},
};
</script>
