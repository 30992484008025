var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("p", { staticClass: "text-h5 blue--text" }, [
                _vm._v(
                  "A " +
                    _vm._s(_vm.action) +
                    " " +
                    _vm._s(_vm.formatCurrency(_vm.totalCharge)) +
                    " "
                ),
              ]),
              _c("v-checkbox", {
                attrs: { label: "Cobrar todo en efectivo" },
                on: { change: _vm.setAllCash },
                model: {
                  value: _vm.allCash,
                  callback: function ($$v) {
                    _vm.allCash = $$v
                  },
                  expression: "allCash",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mt-3", attrs: { cols: "12", lg: "4" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.funds,
                  dense: "",
                  "item-text": "title",
                  "item-value": "id",
                  loading: _vm.loading.fund,
                  "error-messages": _vm.fundErrors,
                  label: "Fondo",
                },
                on: {
                  change: function ($event) {
                    return _vm.$v.charge.fund.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.charge.fund.$touch()
                  },
                },
                model: {
                  value: _vm.charge.fund,
                  callback: function ($$v) {
                    _vm.$set(_vm.charge, "fund", $$v)
                  },
                  expression: "charge.fund",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mt-3", attrs: { cols: "12", lg: "4" } },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  prefix: "$",
                  "error-messages": _vm.amountErrors,
                  label: "Monto",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.charge.amount.$touch()
                  },
                  blur: function ($event) {
                    return _vm.$v.charge.amount.$touch()
                  },
                },
                model: {
                  value: _vm.charge.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.charge, "amount", $$v)
                  },
                  expression: "charge.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { fab: "", small: "", dark: "", color: "indigo" },
                  on: { click: _vm.addCharge },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v(" mdi-plus ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-container",
            _vm._l(_vm.charges, function (item) {
              return _c(
                "v-row",
                { key: item.name },
                [
                  _c("v-col", { attrs: { cols: "5", lg: "4" } }, [
                    _vm._v(" " + _vm._s(item.title) + " "),
                  ]),
                  _c("v-col", { attrs: { cols: "5", lg: "4" } }, [
                    _c("p", { staticClass: "text-body-1" }, [
                      _vm._v(_vm._s(_vm.formatCurrency(item.amount))),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-0 mt-0 text-center",
                      attrs: { cols: "2", lg: "4" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            fab: "",
                            dark: "",
                            dense: "",
                            "x-small": "",
                            color: "indigo",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCharge(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-delete "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
            _c("p", { staticClass: "text-h5" }, [
              _vm._v("Total " + _vm._s(_vm.formatCurrency(_vm.total)) + " "),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", lg: "6" } }, [
            _c("p", { staticClass: "text-h5" }, [
              _vm._v(
                "Pendiente " +
                  _vm._s(_vm.formatCurrency(_vm.totalCharge - _vm.total)) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }