var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        persistent: "",
        "max-width": "900px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.$router.back()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-price-list" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Nombre",
                                  "error-messages": _vm.nameErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.$v.form.name.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.name.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.rateValueTypes,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Tipo de Variación",
                                  "error-messages": _vm.rateTypesErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.$v.form.rate_value_type.$touch()
                                    _vm.calculatedPrices()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.rate_value_type.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.rate_value_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "rate_value_type", $$v)
                                  },
                                  expression: "form.rate_value_type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  type: "number",
                                  label: "Tasa/Valor de Variación",
                                  "error-messages": _vm.rateValueErrors,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.$v.form.rate_value.$touch()
                                    _vm.calculatedPrices()
                                  },
                                  input: function ($event) {
                                    return _vm.calculatedPrices()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.form.rate_value.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.form.rate_value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "rate_value", $$v)
                                  },
                                  expression: "form.rate_value",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { dark: "", color: "info" },
                                  on: { click: _vm.calculatedPrices },
                                },
                                [_vm._v(" Aplicar ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  solo: "",
                                  rows: "2",
                                  label: "Detalle...",
                                },
                                model: {
                                  value: _vm.form.detail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "detail", $$v)
                                  },
                                  expression: "form.detail",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("p", { staticClass: "text-h6" }, [
                            _vm._v("Productos"),
                          ]),
                          _c("v-divider", { staticClass: "ma-4" }),
                          _c("v-data-iterator", {
                            attrs: {
                              items: _vm.form.products,
                              "no-data-text":
                                "No hay productos cargados, aún...",
                              "item-key": "id",
                              search: _vm.search,
                              "sort-by": ["cost_price", "attributes_name"],
                              "sort-desc": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", lg: "7" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-7",
                                                  attrs: {
                                                    outlined: "",
                                                    dense: "",
                                                    elevation: "0",
                                                    "hide-details": "",
                                                    "prepend-inner-icon":
                                                      "mdi-magnify",
                                                    label: "Buscar",
                                                  },
                                                  model: {
                                                    value: _vm.search,
                                                    callback: function ($$v) {
                                                      _vm.search = $$v
                                                    },
                                                    expression: "search",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function ({ items }) {
                                  return _vm._l(items, function (item) {
                                    return _c(
                                      "v-row",
                                      { key: item.product_id },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "text-body-1 ml-3",
                                                class: {
                                                  "orange--text":
                                                    item.current_price !=
                                                    item.price,
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-body-1 font-weight-medium text-capitalize ma-0",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.attributes_name
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "text-body-2 font-weight-light text-capitalize ma-0",
                                                  },
                                                  [_vm._v(_vm._s(item.name))]
                                                ),
                                              ]
                                            ),
                                            item.current_price != item.price
                                              ? _c(
                                                  "v-badge",
                                                  {
                                                    attrs: {
                                                      color: "orange ml-3",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "badge",
                                                        },
                                                        slot: "badge",
                                                      },
                                                      [_vm._v("editado")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                prefix: "$",
                                                readonly: "",
                                                disabled: "",
                                                dense: "",
                                                label: "Costo",
                                              },
                                              model: {
                                                value: item.cost_price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "cost_price",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.cost_price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                prefix: "$",
                                                readonly: "",
                                                disabled: "",
                                                dense: "",
                                                label: "Precio Actual",
                                              },
                                              model: {
                                                value: item.current_price,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "current_price",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.current_price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "2" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                prefix: "$",
                                                dense: "",
                                                label: "Nuevo Precio",
                                              },
                                              model: {
                                                value: item.price,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "price", $$v)
                                                },
                                                expression: "item.price",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  })
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                      _vm.$router.back()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.loading,
                    loading: _vm.loading,
                    form: "form-price-list",
                    color: "blue darken-1",
                    text: "",
                  },
                },
                [_vm._v(" Guardar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }