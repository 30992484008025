var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Mi Caja" } }),
      _c("dialog-confirmed", {
        attrs: {
          value: _vm.openClose.dialog,
          progress: _vm.openClose.load,
          title: _vm.openClose.title,
        },
        on: {
          close: function ($event) {
            _vm.openClose.dialog = false
          },
          confirm: _vm.openCloseConfirm,
        },
      }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _vm.hasAccess("ESC.CAJ.CAJ")
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            elevation: "2",
                            fab: "",
                            color: "primary",
                            to: "/daily-box/box/store",
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("h6", { staticClass: "text-subtitle-1" }, [
                  _vm._v("Se muestra los saldos en efectivo."),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      loading: _vm.tableLoad,
                      items: _vm.daily_boxes,
                      "items-per-page": 5,
                      "no-data-text": "No existen registros, aún...",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.issue_date_close_custom",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.issue_date_close_custom ?? "Abierto"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.diference",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                class: {
                                  "red--text": _vm.diference(item) < 0,
                                  "green--text": _vm.diference(item) > 0,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCurrency(_vm.diference(item))
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.amount_current",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatCurrency(item.amount_current)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.amount_open",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatCurrency(item.amount_open)) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _vm.hasAccess("ESC.CAJ.CAJ")
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-2",
                                    attrs: {
                                      title: "Abrir/Cerrar Caja",
                                      fab: "",
                                      "x-small": "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCloseBox(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v(" mdi-key ")])],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }