var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pl-4", style: _vm.styles() },
    [
      _vm.icon
        ? _c("v-icon", { staticClass: "mr-2 mb-1" }, [
            _vm._v("mdi-" + _vm._s(_vm.icon)),
          ])
        : _vm._e(),
      _c("span", { staticClass: "d-inline text-h6 font-weight-light" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }