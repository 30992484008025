<template>
	<div>
		<Title title="Usuarios"></Title>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
			<v-row>
				<v-col>
					<v-btn
						class="mx-2"
						fab
						dark
						color="primary"
						:to="{ name: 'UserCreate' }"
						v-if="this.hasAccess('ESC.ADM.USU')"
					>
						<v-icon dark> mdi-plus </v-icon>
					</v-btn>
					<v-btn
						class="mx-2"
						rounded
						x-large
						dark
						color="primary"
						:to="{ name: 'RoleData' }"
						v-if="this.hasAccess('LEC.ADM.ROL')"
					>
						<v-icon class="mr-2" dark> mdi-account-key-outline </v-icon>
						 Roles
					</v-btn>
					<dialog-delete
						:value="dialogDelete"
						:progress="deleteLoad"
						@close="closeDelete"
						@confirm="deleteItemConfirm"
					></dialog-delete>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						:counter="10"
						v-model="search"
						label="Buscar usuario..."
						required
					></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<template>
						<v-data-table
							:headers="headers"
							:items="users"
							:items-per-page="5"
							class="elevation-1"
							:search="search"
							:loading="loadingUsers"
						>
							<template v-slot:item.email_verified_at="{ item }">
								<v-chip :color="getColorForState(item)">
									{{ getDescriptionForState(item) }}
								</v-chip>
							</template>
							<template v-slot:item.actions="{ item }">
								<v-btn v-if="hasAccess('ESC.ADM.USU')" class="ml-2" fab x-small @click=" redirectWithParam('UserUpdate', { id: item.id })">
									<v-icon> mdi-pencil </v-icon>
								</v-btn>
								<v-btn v-if="hasAccess('ESC.ADM.USU')" class="ml-2" fab x-small @click="deleteItem(item)">
									<v-icon> mdi-delete </v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</template>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import Toast from "@/components/toast";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Toast, Title },
	data: () => ({
		headers: [
			{ text: "Nombre", value: "entity.full_name" },
			{ text: "Correo", value: "email" },
			{ text: "Rol", value: "role.name" },
			{ text: "Estado", value: "email_verified_at" },
			{ text: "Acciones", value: "actions" },
		],
		search: "",
		dialogDelete: false,
		deleteLoad: false,
		loadingUsers: false,
	}),
	computed: {
		...mapState({
			users: (state) => state.User.users,
			roles: (state) => state.Role.roles,
		}),

		...mapGetters('Auth', [
			'hasAccess'
		]),

		errors: {
			get() {
				return this.$store.state.User.errors;
			},
			set(value) {
				this.$store.commit("User/SET_ERRORS", value);
			},
		},
	},
	created: async function () {
		this.loadingUsers = true;
		if(this.$store.state.User.users.length == 0)
			await this.$store.dispatch("User/get");
		this.loadingUsers = false;
	},
	methods: {
		...mapActions("User", ["delete", "get"]),

		redirect(route) {
			this.$router.push({ name: route });
		},
		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},
		getColorForState(item) {
			if (item.deleted_at) return "red";
			if (!item.email_verified_at) return "orange";
			else return "green";
		},
		getDescriptionForState(item) {
			if (item.deleted_at) return "Inactivo";
			if (!item.email_verified_at) return "No verificado";
			else return "Activo";
		},

		deledit(item) {
			this.editedIndex = this.roles.indexOf(item);
			this.user = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "RoleUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.user);
			this.closeDelete();
			this.loadingUsers = true;
			await this.get();
			this.formLoad = false;
			this.deleteLoad = false;
			this.loadingUsers = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	},
};
</script>
