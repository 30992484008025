<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<!--<v-tabs v-model="tab" background-color="transparent">
					<v-tab v-if="hasAccess('LEC.CAJ.CAJ')" to="/daily-box/box">Mi Caja</v-tab>
					<v-tab v-if="hasAccess('LEC.CAJ.MOV')" to="/daily-box/movement">Movimientos</v-tab>
				</v-tabs>-->
				<router-view></router-view>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import CardForm from "@/components/card-form-custom-1.vue";
import { mapGetters } from 'vuex';

export default {
	components: { CardForm },
	data: () => {
		return {
			search: {
				name: "",
			},
			tab: null,
			loading: true,
			options: {},
			total: 0
		};
	},
	created: async function () {

	},
	computed: {
		...mapGetters('Auth', ['hasAccess'])
	},
	methods: {
		redirect(route) {
			this.$router.push({ name: route });
		},
	},
};
</script>
