<template>
	<v-container class="mt-4">
		<DialogDelete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></DialogDelete>
		<v-row>
			<v-col>
				<h4 class="text-h4">Tipos de Recargo</h4>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					fab
					color="primary"
					to="/treasury/surcharge-type/store"
					v-if="hasAccess('ESC.TRE.TRC')"
					><v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="surchargeTypes"
					:search="search.name"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="tableLoad"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.rate="{ item }">
						{{ item.rate }} {{ item.rate_type == 'FIXED' ? 'Fijo' : '%' }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('ESC.TRE.TRC')" class="ma-2" fab x-small @click="redirectWithParam('SurchargeTypeUpdate', { id: item.id })">
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.TRE.TRC')" class="ma-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DialogDelete from "@/components/dialog-delete.vue";

export default {
	components: { DialogDelete },
	data: () => {
		return {
			name: "Recargos",
			search: {
				name: "",
			},
			dialogDelete: false,
			deleteLoad: false,
			tableLoad: false,
			headers: [
				{ text: "Código", value: "code" },
				{ text: "Nombre", value: "name" },
				{ text: "Tasa", value: "rate" },
				{ text: "Detalle", value: "detail" },
				{ text: "Acciones", value: "actions", sortable: false },
			],
			editedIndex: -1,
			surchargeType: null
		}
	},
	created: async function(){
		this.tableLoad = true;
		if(this.$store.state.SurchargeType.surchargeTypes.length == 0)
			await this.$store.dispatch('SurchargeType/get');
		this.tableLoad = false;
	},
	computed: {
		...mapState('SurchargeType', ['surchargeTypes']),
		...mapGetters('Auth', ['hasAccess'])
	},
	methods: {
		...mapActions("SurchargeType", ["store", "delete"]),

		deledit(item) {
			this.editedIndex = this.surchargeTypes.indexOf(item);
			this.surchargeType = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "SurchargeTypeUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			this.delete(this.surchargeType);
			await this.$store.dispatch('SurchargeType/get');
			this.closeDelete()
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete(){
			this.dialogDelete = false;
		},

		redirect(to) {
			this.$router.push({ name: to });
		},
	}
}

</script>
