import axios from "axios";

export default {
	namespaced: true,
	state: {
		paymentMethods: []
	},
	mutations: {
		SET_PAYMENT_METHOD(state, data) {
			state.paymentMethod = data;
		},
		SET_PAYMENT_METHODS(state, data) {
			state.paymentMethods = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("payment-method").then((response) => {
				commit("SET_PAYMENT_METHODS", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("payment-method", params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("payment-method/" + params.id, params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("payment-method/" + params.id)
				.then(() => {})
				.catch(() => {});
		},
	},
	getters: {
		getPaymentMethod: (state) => (paymentMethodId) => {
			if (state.paymentMethods)
				return state.paymentMethods.filter((prop) => prop.id == paymentMethodId)[0];
			else return "";
		},
		getPaymentMethodByFundId: (state) => (fundId) => {
			if (state.paymentMethods)
				return state.paymentMethods.filter((prop) => prop.fund.id == fundId)[0];
			else return "";
		},
		getFirstPaymentMethod: (state) => {
			if (state.paymentMethods)
				return state.paymentMethods[0];
			else return "";
		},
	},
};
