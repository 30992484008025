<template>
	<v-container>
		<v-row>
			<v-col cols="9">
				<v-container>
					<v-row
						><v-col><h2>Usuarios</h2></v-col></v-row
					>
					<v-row>
						<v-col>
							<v-btn
								class="mx-2"
								fab
								dark
								color="indigo"
								to="/administration/user/store"
							>
								<v-icon dark> mdi-plus </v-icon>
							</v-btn>
						</v-col>
						<v-col cols="12" md="4">
							<v-text-field
								:counter="10"
								label="Buscar usuario..."
								required
							></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<template>
								<v-data-table
									:headers="headers"
									:items="users"
									:items-per-page="5"
									class="elevation-1"
								></v-data-table>
							</template>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
			<v-col cols="3">
				<v-container>
					<v-row>
						<v-col class="col-12">
							<v-card elevation="4" outlined>
								<v-card-title> Roles </v-card-title>
								<v-card-text class="pa-4">
									<v-list-item v-for="role in this.roles" :key="role.id">
										<v-list-item-content>
											<v-list-item-title>{{ role.name }}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<div class="text-center">
										<v-pagination :length="1" circle></v-pagination>
									</div>
								</v-card-text>
								<v-card-actions>
									<v-btn
										outlined
										fab
										x-small
										text
										to="administration/role/store"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
									<v-btn outlined fab x-small text to="administration/role">
										<v-icon>mdi-view-headline</v-icon>
									</v-btn>
									<v-btn outlined fab x-small text>
										<v-icon>mdi-help</v-icon>
									</v-btn>
								</v-card-actions>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapState } from "vuex";

export default {
	data: () => ({
		headers: [
			{ text: "Nombre", value: "name" },
			{ text: "Correo", value: "email" },
			{ text: "Rol", value: "roles[0].name" },
		],
	}),
	computed: {
		...mapState({
			users: (state) => state.User.users,
			roles: (state) => state.Role.roles,
		}),
	},
	created: async function () {
		this.$store.dispatch("User/get");
		await this.$store.dispatch("Role/get");
	},
	methods: {},
};
</script>
