<template>
	<div>
		<Toast :type="errors.type" v-model="errors.value" />
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			scrollable
			v-model="dialog"
			:loading="loading"
			max-width="700px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar>
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-progress-linear :active="loading" indeterminate></v-progress-linear>
				<v-card-text style="min-height: 500px" class="pa-2">
					<v-form
						:disabled="loading"
						ref="form"
						id="form-punto-venta"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="provider.alias"
										required
										label="Alias"
										:error-messages="aliasErrors"
										@change="$v.provider.alias.$touch()"
										@blur="$v.provider.alias.$touch()"
									></v-text-field>
								</v-col>
								<!--<VatCondition :value="provider.vat_condition" :v="$v" />-->
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="provider.document"
										required
										label="Documento"
										:error-messages="documentErrors"
										@change="$v.provider.document.$touch()"
										@blur="$v.provider.document.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="6">
									<v-textarea
										solo
										v-model="provider.comment"
										label="Comentario..."
									></v-textarea>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-punto-venta"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

import Toast from "@/components/toast";
import VatCondition from "@/components/select-vatconditions";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from 'vuex';

export default {
	components: { Toast, VatCondition },
	validations: {
		provider: {
			alias: { required },
			document: { required },
		}
		//vat_condition: { required }
	},
	data() {
		return {
			dialog: true,
			provider: {
				id: null,
				alias: "",
				comment: "",
				document: "",
				vat_condition: {id: 'RESPONSABLE_INSCRIPTO', name: ''},
			},
			formValid: false,
			formLoad: false,
			loading: false,
		};
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {

		...mapGetters('Provider', ['getProvider']),

		errors: {
			get() {
				return this.$store.state.Provider.errors;
			},
			set(value) {
				this.$store.commit("Provider/SET_ERRORS", value);
			},
		},

		aliasErrors() {
			const errors = [];
			if (!this.$v.provider.alias.$dirty) return errors;
				!this.$v.provider.alias.required && errors.push("El alias es requerido");
			return errors;
		},

		documentErrors() {
			const errors = [];
			if (!this.$v.provider.document.$dirty) return errors;
				!this.$v.provider.document.required && errors.push("El documento es requerido");
			return errors;
		},

		title() {
			return this.provider.id > 0 ? "Editar Registro" : "Nuevo Registro";
		},
	},
	created: async function () {
		if (this.$route.params.id) {
			if(this.$store.state.Provider.providers.length == 0)
				await this.$store.dispatch('Provider/get');
			let provider = Object.assign({}, this.getProvider(this.$route.params.id));
			this.provider.id = provider.id;
			this.provider.alias = provider.alias;
			this.provider.comment = provider.comment;
			this.provider.vat_condition.id = provider.entity.vat_condition;
			this.provider.document = provider.entity.document;
		}
	},
	methods: {

		...mapActions('Provider', ['store', 'get']),

		close() {
			this.$nextTick(() => {
				this.provider = Object.assign({}, null);
				this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
			this.tableLoad = true;
		},

		successSave: async function () {
			this.$store.dispatch('Provider/get');
			if (!this.provider.id > 0)
				this.$refs.form.reset();
			this.$router.go(-1);
		},

		completeSave: async function () {
			this.formLoad = false;
		},

		save: function () {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					id: this.provider.id,
					validate: !this.$v.$invalid,
					data: {
						alias: this.provider.alias,
						document: this.provider.document,
						vat_condition: this.provider.vat_condition.id,
						comment: this.provider.comment,
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
