<template>
	<v-container></v-container>
</template>
<script>
export default {
	data: () => {
		return {

		};
	}
};
</script>
