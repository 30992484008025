export const myMixins = {
	methods: {
		nowToDateTime(){
			return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},
		verifyCuit(cuit) {
			if (cuit.length !== 11) {
				return false;
			}

			let acumulado = 0;
			let digitos = cuit.split("");
			let digito = parseInt(digitos.pop());

			for (let i = 0; i < digitos.length; i++) {
				acumulado += digitos[9 - i] * (2 + (i % 6));
			}

			let verif = 11 - (acumulado % 11);
			if (verif === 11) {
				verif = 0;
			} else if (verif === 10) {
				verif = 9;
			}

			return digito === verif;
		},
		formatCurrency(value){
			return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(value)
		},
		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},
		roundUpToMultiple(number, multiple) {
			return Math.ceil(number / multiple) * multiple;
		},
		sweetAlertCustom(message, type){
			const Toast = this.$swal.mixin({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener('mouseenter', this.$swal.stopTimer)
					toast.addEventListener('mouseleave', this.$swal.resumeTimer)
				}
			})

			return Toast.fire({
				icon: type,
				title: message
			})
		},
		capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		},
		isNumber(value){
			return typeof value != 'number';
		},
		selectAllSinceEvent(event){
			event.target.select();
		},
		getIconFund(fundType){
			if(fundType == 'CC')
				return 'mdi-bank-outline';
			if(fundType == 'CF')
				return 'mdi-cash-register';
			if(fundType == 'CD')
				return 'mdi-wallet-outline'
			return 'mdi-bank-outline';
		},

		getColorOrigin(origin){
			if(origin.movementable_type.includes('Fund') || origin.movementable_type.includes('DailyBox'))
				return 'teal--text';
			if(origin.movementable_type.includes('Payment') || origin.movementable_type.includes('Purchase'))
				return 'deep-orange--text';
			if(origin.movementable_type.includes('Charge') || origin.movementable_type.includes('Sale'))
				return 'red--text';
			if(origin.movementable_type.includes('Expense'))
				return 'green--text';
			return ''
		},

		getIconOrigin(origin){
			if(origin.movementable_type.includes('Fund') || origin.movementable_type.includes('DailyBox'))
				return 'mdi-bank-outline';
			if(origin.movementable_type.includes('Payment') || origin.movementable_type.includes('Purchase'))
				return 'mdi-cart-variant';
			if(origin.movementable_type.includes('Charge') || origin.movementable_type.includes('Sale'))
				return 'mdi-basket-outline';
			if(origin.movementable_type.includes('Expense'))
				return 'mdi-cash-minus';
			return ''
		},

		getTitleOrigin(origin){
			if(origin.movementable_type.includes('Fund') || origin.movementable_type.includes('DailyBox'))
				return 'Transferencia';
			if(origin.movementable_type.includes('Payment') || origin.movementable_type.includes('Purchase'))
				return 'Compra';
			if(origin.movementable_type.includes('Charge') || origin.movementable_type.includes('Sale'))
				return 'Venta';
			if(origin.movementable_type.includes('Expense'))
				return 'Gasto';
			return 'Movimiento';
		},

		maxId(arrayDeObjetos) {
			return arrayDeObjetos.reduce((maxId, objeto) => {
					return (objeto.id > maxId) ? objeto.id : maxId;
			}, 0)
		}
	},
};
