import { render, staticRenderFns } from "./movement-data.vue?vue&type=template&id=18869155&"
import script from "./movement-data.vue?vue&type=script&lang=js&"
export * from "./movement-data.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\osval\\vue_projects\\spa.lencerp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18869155')) {
      api.createRecord('18869155', component.options)
    } else {
      api.reload('18869155', component.options)
    }
    module.hot.accept("./movement-data.vue?vue&type=template&id=18869155&", function () {
      api.rerender('18869155', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/inventory/movement/movement-data.vue"
export default component.exports