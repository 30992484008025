import axios from "axios";

export default {
	namespaced: true,
	state: {
		customer: {
			alias: null,
			comment: null,
			document: null,
			vat_condition: null
		},
		customers: [],
	},
	mutations: {
		SET_CUSTOMER(state, data) {
			state.customer = data;
		},
		SET_CUSTOMERS(state, data) {
			state.customers = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("customer").then((response) => {
				commit("SET_CUSTOMERS", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("customer", params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {
			
				});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.succesS();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.succesS();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("customer/" + params.id, params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {
					
				});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("customer/" + params.id)
				.then(() => {
					
				})
				.catch(() => {
					
				});
		},
	},
	getters: {
		getCustomers: (state) => {
			if (state.customers) return state.customers;
			else return [];
		},
		getFirstCustomer: (state) => {
			if (state.customers) return state.customers[0];
			else return [];
		},
		getCustomer: (state) => (customerId) => {
			if (state.customers)
				return state.customers.filter((prop) => prop.id == customerId)[0];
			else return "";
		},
	},
};
