var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", lg: "12" } }, [
            _c("h6", { staticClass: "text-h5" }, [_vm._v(_vm._s(_vm.title))]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "3" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.typesList,
                  "item-text": "name",
                  hint: _vm.detailType,
                  "return-object": "",
                  "item-value": "code",
                  label: _vm.labelType,
                },
                model: {
                  value: _vm.form.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  attrs: { fab: "", small: "", color: "primary" },
                  on: { click: _vm.addItem },
                },
                [_c("v-icon", [_vm._v("mdi-plus")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pt-3 pl-3",
                  attrs: {
                    width: "100%",
                    color: _vm.$vuetify.theme.dark
                      ? "grey darken-4"
                      : "blue lighten-5",
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _vm._l(_vm.items, function (item) {
                        return _c(
                          "v-row",
                          { key: item.key },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "3" } },
                              [
                                _vm.isNumber(item.code)
                                  ? _c("p", { staticClass: "text-body-1" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ])
                                  : _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        outlined: "",
                                        autofocus: "",
                                      },
                                      on: { focus: _vm.selectAllSinceEvent },
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "3" } },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.rate_types,
                                    dense: "",
                                    outlined: "",
                                    "item-text": "name",
                                    "item-value": "id",
                                    label: "Tipo de Variación",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeRateOrType(item)
                                    },
                                  },
                                  model: {
                                    value: item.rate_type,
                                    callback: function ($$v) {
                                      _vm.$set(item, "rate_type", $$v)
                                    },
                                    expression: "item.rate_type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "2" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    prefix:
                                      item.rate_type == "FIXED" ? "Fijo" : "%",
                                    required: "",
                                    dense: "",
                                    outlined: "",
                                    label: "Tasa",
                                    type: "number",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.changeRateOrType(item)
                                    },
                                    blur: function ($event) {
                                      return _vm.changeRateOrType(item)
                                    },
                                  },
                                  model: {
                                    value: item.rate,
                                    callback: function ($$v) {
                                      _vm.$set(item, "rate", $$v)
                                    },
                                    expression: "item.rate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("v-col", { attrs: { cols: "12", lg: "2" } }, [
                              _c("h5", { staticClass: "text-h5" }, [
                                _vm._v(
                                  "+ " +
                                    _vm._s(
                                      _vm.formatCurrency(item.amount_to_apply)
                                    )
                                ),
                              ]),
                            ]),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", lg: "2" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      dark: "",
                                      color: "danger",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  },
                                  [_c("v-icon", [_vm._v("mdi-delete")])],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.items.length == 0
                        ? _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "12" } }, [
                                _vm._v(
                                  "No hay " +
                                    _vm._s(_vm.title.toLowerCase()) +
                                    " cargados."
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }