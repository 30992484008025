<template>
	<div>
		<v-snackbar :value="value" :color="getColor">
			<span class="text-subtitle-2">{{ title }}</span>
			<div v-if="typeof getValue === 'object'">
				<div v-for="(property, index) in getValue" :key="index">
					<ul v-for="(value, key) in property" :key="key">
						<li>{{ value }}</li>
					</ul>
				</div>
			</div>
			<div v-else>
				<span class="text-body-2">{{ getValue }}</span>
			</div>
			<template v-if="close" v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="error.state = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
const ERROR = "red";
const SUCCESS = "green";

export default {
	props: {
		title: {
			default: "",
		},
		type: {
			default: "success",
		},
		close: {
			default: false,
		},
		value: {
			default: false,
		},
	},
	data: () => ({
		color: "red",
	}),
	computed: {
		getValue() {
			return this.value;
		},
		getColor() {
			switch (this.type) {
				case "success":
					return SUCCESS;
				case "error":
					return ERROR;
				default:
					return SUCCESS;
			}
		},
	},
};
</script>
