<template>
	<div>
		<Title title="Movimientos"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
			<v-row>
				<v-col cols="12" lg="4">
					<v-dialog
						ref="dialog"
						v-model="modal"
						:return-value.sync="filters.dates"
						persistent
						width="290px"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								v-model="dateRangeText"
								label="Rango de fechas"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="filters.dates"
							range
							selected-items-text="2 seleccionados"
							locale="es-ar"
						>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="modal = false"
							>
								Cancel
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="$refs.dialog.save(filters.dates)
										actualizarVista()"
							>
								OK
							</v-btn>
						</v-date-picker>
					</v-dialog>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-data-table
						:headers="headers"
						:items="movements"
						:loading="tableLoad"
						:items-per-page="5"
						class="elevation-1"
						no-data-text="No existen registros, aún..."
					>
					<template v-slot:item.title="{ item }">
						<v-icon>{{ getIconFund(item.fund.fund_type) }} </v-icon> {{ item.fund.title }}
					</template>
					<template v-slot:item.origin="{ item }">
						<div :class="getColorOrigin(item)">
							<v-icon :class="getColorOrigin(item)">{{ getIconOrigin(item) }} </v-icon> {{ getTitleOrigin(item) }}
						</div>
					</template>
					<template v-slot:item.amount="{ item }">
						<div :class="{'red--text': item.debit_credit == 'D', 'green--text': item.debit_credit == 'C'}">
							{{ item.debit_credit == 'C' ? '' : '- ' }} {{ formatCurrency(item.amount) }}
						</div>
					</template>
				</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>

import { mapGetters, mapState } from 'vuex';
import Title from "@/components/title-1.vue";

export default {
	components: { Title },
	data: () => ({
		name: "Sucursal",
		tableLoad: false,
		branch: null,
		modal: false,
		headers: [
			{ text: "Fondo", value: "title" },
			{ text: "Origen", value: "origin" },
			{ text: "Monto", value: "amount" },
			{ text: "Detalle", value: "detail" },
			{ text: "Fecha de Creación", value: "issue_date_custom" }
		],
		filters: {
			dates: [
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			]
		},
		editedIndex: -1,
		dialogDelete: false,
		deleteItemId: null,
		deleteLoad: false,
		boxFund: 2,

	}),

	created: async function () {
		this.actualizarVista();
	},

	computed: {
		...mapState('MovementDailyBox', ['movements']),
		...mapGetters('Auth', ['hasAccess']),

		dateRangeText () {
			return this.filters.dates.join(' ~ ')
		},
	},

	methods: {

		actualizarVista: async function(){
			this.tableLoad = true;
			await this.$store.dispatch('MovementDailyBox/get', {
				filters: {
					from: this.filters.dates[0],
					to: this.filters.dates[1]
				}
			})
			this.tableLoad = false;
		},

		deledit(item) {
			this.editedIndex = this.branches.indexOf(item);
			this.branch = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "branchUpdate",
				params: { branch: item },
			});
		},

		deleteItem(id) {
			this.deleteItemId = id;
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.formLoad = true;
			this.delete(this.deleteItemId);
			this.closeDelete();
			await this.get();
			this.formLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	},
};
</script>
