<template>
	<v-col lg="4" cols="12" sm="6" md="4">
		<v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="value"
						v-on:input="$emit('input', $event.target.value)"
            :label="label"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
						:error-messages="issueDateErrors"
						@input="v.form.issue_date.$touch()"
						@blur="v.form.issue_date.$touch()"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="value"
          @input="menu = false
									$emit('date-changed', value)"
        ></v-date-picker>
      </v-menu>
	</v-col>
</template>
<script>

export default {
	props: {
		v: {
			default: null
		},
		value: {
			default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		},
		label: {
			default: 'Fecha'
		}
	},
	props: ["v", "value", "label"],
	data: () => {
		return {
    	menu: false,
		};
	},
	created() {
		if(this.v){
			this.v.form.issue_date.$model = this.value;
			this.v.form.issue_date.$reset();
		}
	},
	computed: {
		issueDateErrors() {
			const errors = [];
			if (!this.v.form.issue_date.$dirty) return errors;
			!this.v.form.issue_date.required &&
				errors.push("La fecha es requerida");
			return errors;
		},
	}
};
</script>
