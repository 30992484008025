var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: { required: "", label: "Valor" },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { fab: "", dark: "", color: "primary" },
                  on: { click: _vm.addValue },
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v(" mdi-plus ")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "12" } },
            _vm._l(_vm.values, function (item) {
              return _c(
                "v-chip",
                {
                  key: item,
                  staticClass: "ma-2",
                  attrs: { close: "" },
                  on: {
                    "click:close": function ($event) {
                      return _vm.deleteItem(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }