<template>
	<div>
		<Title title="Mis Fondos"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
			<v-row>
				<v-col cols="12" lg="4" sm="12" md="5">
					<v-progress-circular
						class="ma-5"
						:size="70"
						:width="7"
						v-if="fundLoad"
						color="primary"
						indeterminate
					></v-progress-circular>
					<ItemBoxFund
						v-else
						:funds="funds"
						v-on:selected-fund="selectedFund"
					></ItemBoxFund>
				</v-col>
				<v-col cols="12" lg="8" sm="12" md="7">
					<h5 class="text-h5 mb-4">Movimientos</h5>
					<v-dialog
						ref="dialog"
						v-model="modal"
						:return-value.sync="filters.dates"
						persistent
						width="290px"
						class="mb-3"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								filled
								style="width: 250px;"
								v-model="dateRangeText"
								label="Rango de fechas"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
							></v-text-field>
						</template>
						<v-date-picker
							v-model="filters.dates"
							:max="maxDate"
							:min="minDate"
							range
							selected-items-text="2 seleccionados"
							locale="es-ar"
						>
							<v-spacer></v-spacer>
							<v-btn
								text
								color="primary"
								@click="modal = false"
							>
								Cancel
							</v-btn>
							<v-btn
								text
								color="primary"
								@click="$refs.dialog.save(filters.dates)
										selectedFund(fund)"
							>
								OK
							</v-btn>
						</v-date-picker>
					</v-dialog>
					<v-data-iterator
						:items="fund_movements_local"
						loading-text="Cargando movimientos..."
						:loading.sync="tableLoad"
						:items-per-page="-1"
						hide-default-footer
						class="elevation-1"
						>
						<template v-slot:item="{ item }">
							<v-list-item three-line>
								<v-list-item-avatar :class="getColorOrigin(item)">
									<v-icon large :class="getColorOrigin(item)">{{ getIconOrigin(item) }} </v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title> {{ item.reason ?? 'Sin motivo' }}</v-list-item-title>
									<v-list-item-subtitle>{{ item.issue_date_for_humans }}</v-list-item-subtitle>
									<v-list-item-subtitle> <v-icon small>mdi-account-circle-outline</v-icon> {{ getUser(item.user_id).entity?.full_name }}</v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-list-item-title class="text-h5" :class="{'red--text': item.amount < 0, 'green--text': item.amount > 0, 'blue-grey--text text--lighten-3': item.movement_state == 'REVERSED'}"> {{ formatCurrency(item.amount) }}</v-list-item-title>
									<span class="text-caption" v-if="item.movement_state =='PENDING'" :class="getColorState(item)" >
										<v-icon :class="getColorState(item)" small style="margin-top: -2px;">
											mdi-{{ getIconState(item)  }}
										</v-icon>
										{{ item.settlement_date_for_humans }}
									</span>
									<span class="text-caption" v-else>
										<v-icon :class="getColorState(item)" small style="margin-top: -2px;">
											mdi-{{ getIconState(item)  }}
										</v-icon>
										{{ getNameState(item) }}
									</span>
								</v-list-item-action>
							</v-list-item>
							<v-divider></v-divider>
						</template>
						<template v-slot:loading>
							<div style="width: 100%; text-align: center;">
								<v-progress-circular
									class="ma-5"
									:size="70"
									:width="7"
									color="primary"
									indeterminate
								></v-progress-circular>
							</div>
						</template>
						<template v-slot:no-data>
							<div style="width: 100%; text-align: center;">
								<h5 style="padding-top: 15px;">Todo tranquilo, no hay movimientos...</h5>
								<img src="./../../../assets/svg/waiting.svg" width="100" style="margin-top: 15px; margin-bottom: 15px;">
							</div>
						</template>
					</v-data-iterator>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import ItemBoxFund from "@/components/treasury/item-box-fund";
import { mapGetters, mapState } from 'vuex';
import Title from "@/components/title-1.vue";

export default {
	components: { ItemBoxFund, Title },
	data: () => ({
		name: "Fondos",
		tableLoad: false,
		headers: [
			{ text: "Motivo", value: "reason" },
			{ text: "Monto", value: "amount" },
			{ text: "Creado por", value: "user.name" },
			{ text: "Fecha", value: "issue_date" },
			{ text: "Fecha de Liquidación", value: "settlement_date" },
			{ text: "Estado", value: "movement_state", align: 'center' }
		],
		maxDate: (new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().substr(0, 10)),
		minDate: (new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substr(0, 10)),
		editedIndex: -1,
		dialogDelete: false,
		deleteItemId: null,
		deleteLoad: false,
		fundLoad: true,
		modal: false,
		selectedItemFund: null,
		fund_movements_local: [],
		fund: {
			id: null,
			title: null
		},
		filters: {
			dates: [
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
				(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
			]
		}
	}),

	created: async function () {
		if(this.$store.state.Fund.funds.length == 0)
			await this.$store.dispatch('Fund/get');

		if(this.$store.state.User.users.length == 0)
			await this.$store.dispatch('User/get');

		this.fundLoad = false;
		this.selectedItemFund = 0;
		this.selectedFund(this.funds[0]);
	},

	computed: {
		...mapState('FundMovement', ['fund_movements']),
		...mapState('Fund', ['funds']),
		...mapGetters('User', ['getUser']),

		dateRangeText () {
			return this.filters.dates.join(' ~ ')
		},

		getFundTitle(){
			return this.fund?.title;
		}
	},

	methods: {
		deledit(item) {
			this.editedIndex = this.branches.indexOf(item);
			this.branch = Object.assign({}, item);
		},

		getIconState(item){
			if(item.movement_state == 'PENDING') return 'clock-time-nine-outline';
			if(item.movement_state == 'CONFIRMED') return 'check';
			if(item.movement_state == 'REVERSED') return 'restore';
			if(item.movement_state == 'REJECTED') return 'alert-circle-outline';
		},

		getColorState(item){
			if(item.movement_state == 'PENDING') return 'orange--text';
			if(item.movement_state == 'CONFIRMED') return 'green--text';
			if(item.movement_state == 'REVERSED') return 'teal--text';
			if(item.movement_state == 'REJECTED') return 'brown--text';
		},

		getNameState(item){
			if(item.movement_state == 'PENDING') return 'Pendiente';
			if(item.movement_state == 'CONFIRMED') return 'Completado';
			if(item.movement_state == 'REVERSED') return 'Reversado';
			if(item.movement_state == 'REJECTED') return 'Rechazado';
		},

		selectedFund: async function(fund){
			this.fund_movements_local = [];
			this.tableLoad = true;
			this.fund = fund;
			await this.$store.dispatch('FundMovement/get', {
				filters: {
					from: this.filters.dates[0],
					to: this.filters.dates[1],
					fund: this.fund.id
				}
			});
			this.fund_movements_local = Array.from(this.fund_movements);
			this.tableLoad = false;
		},

		editItem(item) {
			this.$router.push({
				name: "branchUpdate",
				params: { branch: item },
			});
		},

		deleteItem(id) {
			this.deleteItemId = id;
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.formLoad = true;
			this.delete(this.deleteItemId);
			this.closeDelete();
			await this.get();
			this.formLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	}
};
</script>
