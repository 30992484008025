<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="closeDialog()"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="closeDialog();"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pa-2">
				<v-form ref="form" id="form-product" @submit.prevent="save">
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-combobox
									:disabled="Boolean($route.params.id)"
									:items="providers"
									item-text="alias"
									item-value="id"
									auto-select-first
									v-model="provider"
									label="Proveedor Asociado"
								>
								</v-combobox>
							</v-col>
						</v-row>
					</v-container>
					<ProductForm
						:withAddOption="false"
						:withSingleForm="false"
						:product="product"
						@add-product="productAdded"
					></ProductForm>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="closeDialog();"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-product"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ProductForm from '@/components/inventory/store-product';

export default {
	components: { ProductForm },
	data: () => ({
		loading: false,
		dialog: true,
		disabled: false,
		product: {
			description: "",
			category: null,
			attributesValues: []
		},
		provider: {
			id: null,
			name: ''
		},
		form: {
			id: null,
			name: "",
			category_id: null,
			attributes: []
		},
		categories: [],
		providers: [],
		defaultProvider: {
			id: null,
			alias: 'Sin proveedor asociado'
		}
	}),
	created: async function () {
		
		await this.dispatchInitValues();

		this.setInitProvider();

		if (this.$route.params.id){
			this.form = Object.assign({}, this.getProduct(this.$route.params.id));
			this.provider = this.getProvider(this.form.provider_id) ?? this.defaultProvider;
			this.product.description = this.form.name;
			this.product.category = this.getCategory(this.form.category_id);
			this.product.attributesValues = this.form.attributes;
		}

	},
	computed: {
		...mapGetters('Provider', ['getFirstProvider', 'getProviders', 'getProvider']),
		...mapGetters('Product', ['getProduct']),
		...mapGetters('Category', ['getCategory']),
		...mapGetters('CategoryAttribute', ['getCategoryAttribute']),

		title() {
			return this.$route.params.id > 0 ? "Editar Producto" : "Nuevo Producto";
		},

	},
	methods: {
		...mapActions('Product', ['store']),

		setInitProvider(){
			this.providers = [].concat(this.getProviders);
			this.providers.unshift(this.defaultProvider);
			this.provider = this.defaultProvider;
		},

		dispatchInitValues: async function() {
			if(this.$store.state.Provider.providers.length == 0)
				await this.$store.dispatch('Provider/get');
		},

		productAdded(product){
			this.form.name = product.name;
			this.form.category_id = product.category_id;
			this.form.attributes = product.attribute_values;
		},

		close() {
			this.$nextTick(() => {
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave(){
			this.loading = true;
		},

		successSave(){
			if(this.$store.state.errors.status == 200){
				this.$store.dispatch('PriceList/get');
				this.$store.dispatch('Product/get');
				this.$router.back();
			}
		},

		completeSave(){
			this.loading = false;
		},

		closeDialog(){
			this.dialog = !this.dialog
			this.$router.back();
		},

		save() {
			this.store({
				validate: true,
				id: this.form.id,
				data: {
					name: this.form.name,
					category_id: this.form.category_id,
					provider_id: this.provider.id,
					attributes: this.form.attributes
				},
				before: () => {
					this.beforeSave();
				},
				success: () => {
					this.successSave();
				},
				complete: () => {
					this.completeSave();
				},
			});
		},
	},
};
</script>
