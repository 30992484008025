<template>
	<v-container>
		<v-row>
			<v-col cols="12">
				<!--<v-tabs v-model="tab" background-color="transparent">
					<v-tab v-if="hasAccess('LEC.TRE.FUN')" to="/treasury/fund/me">Mis Fondos</v-tab>
					<v-tab v-if="hasAccess('LEC.TRE.GAS')" to="/treasury/expense">Mis Gastos</v-tab>
					<v-tab v-if="hasAccess('LEC.TRE.PME')" to="/treasury/payment-method">Mis Métodos de Pago</v-tab>
					<v-tab v-if="hasAccess('LEC.TRE.REC')" to="/treasury/surcharge">Recargos</v-tab>
				</v-tabs>-->
				<router-view></router-view>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
	data: () => {
		return {
			tab: null
		};
	},
	computed: {
		...mapGetters('Auth', ['hasAccess'])
	}
};
</script>
