import PurchasingHome from "@/views/app/purchasing/purchasing-home.vue";
import PurchasingData from "@/views/app/purchasing/purchasing-data.vue";

import PurchaseHome from "@/views/purchasing/purchase/purchase-home.vue";
import PurchaseData from "@/views/purchasing/purchase/purchase-data.vue";
import PurchaseForm from "@/views/purchasing/purchase/purchase-form.vue";

import ProviderHome from "@/views/purchasing/provider/provider-home.vue";
import ProviderData from "@/views/purchasing/provider/provider-data.vue";
import ProviderForm from "@/views/purchasing/provider/provider-form.vue";

import PaymentHome from "@/views/purchasing/payment/payment-home.vue";
import PaymentData from "@/views/purchasing/payment/payment-data.vue";
import PaymentForm from "@/views/purchasing/payment/payment-form.vue";

const STR_STORE = "store";

export default {
	path: "/purchasing",
	name: "",
	component: PurchasingHome,
	meta: { requiresAuth: true, access: 'LEC.COM.COM' },
	children: [
		{
			path: "",
			name: "PurchasingData",
			component: PurchasingData,
			meta: { access: 'LEC.COM.COM' },
		},
		{
			path: 'purchase',
			component: PurchaseHome,
			meta: { access: 'LEC.COM.COM' },
			children: [
				{
					path: "",
					name: "PurchaseData",
					component: PurchaseData,
					meta: { access: 'LEC.COM.COM' },
				},
				{
					name: "PurchaseCreate",
					path: STR_STORE,
					component: PurchaseForm,
					meta: { access: 'ESC.COM.COM' },
				},
				{
					name: "PurchaseUpdate",
					path: STR_STORE + "/:id",
					component: PurchaseForm,
					meta: { access: 'ESC.COM.COM' },
				},
			]
		},
		{
			path: "provider",
			component: ProviderHome,
			meta: { access: 'LEC.COM.PRV' },
			children: [
				{
					path: "",
					name: "ProviderData",
					component: ProviderData,
					meta: { access: 'LEC.COM.PRV' },
				},
				{
					name: "ProviderCreate",
					path: STR_STORE,
					component: ProviderForm,
					meta: { access: 'ESC.COM.PRV' },
				},
				{
					name: "ProviderUpdate",
					path: STR_STORE + "/:id",
					component: ProviderForm,
					meta: { access: 'ESC.COM.PRV' },
				},
			],
		},
		{
			path: "payment",
			component: PaymentHome,
			meta: { access: 'LEC.COM.PAG' },
			children: [
				{
					path: "",
					name: "PaymentData",
					component: PaymentData,
					meta: { access: 'LEC.COM.PAG' },
				},
				{
					name: "PaymentCreate",
					path: STR_STORE,
					component: PaymentForm,
					meta: { access: 'ESC.COM.PAG' },
				},
				{
					name: "PaymentCreateTo",
					path: STR_STORE + '/:type/:id',
					component: PaymentForm,
					meta: { access: 'ESC.COM.PAG' },
				},
				{
					name: "PaymentUpdate",
					path: STR_STORE + "/:id",
					component: PaymentForm,
					meta: { access: 'ESC.COM.PAG' },
				},
			],
		}
	],
};
