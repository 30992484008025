var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticStyle: { "min-height": "500px" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-speed-dial",
                {
                  staticClass: "mr-4",
                  attrs: {
                    top: _vm.top,
                    left: _vm.left,
                    direction: "right",
                    "open-on-hover": _vm.hover,
                    transition: _vm.transition,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                elevation: "2",
                                dark: "",
                                fab: "",
                              },
                              model: {
                                value: _vm.fab,
                                callback: function ($$v) {
                                  _vm.fab = $$v
                                },
                                expression: "fab",
                              },
                            },
                            [
                              _vm.fab
                                ? _c("v-icon", [_vm._v(" mdi-close ")])
                                : _c("v-icon", [_vm._v(" mdi-plus ")]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.fab,
                    callback: function ($$v) {
                      _vm.fab = $$v
                    },
                    expression: "fab",
                  },
                },
                [
                  _vm.hasAccess("ESC.VEN.VEN")
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            rounded: "",
                            dark: "",
                            large: "",
                            color: "red",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.redirect("SaleCreate")
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-basket-outline")]),
                          _vm._v(" Venta "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasAccess("ESC.TRE.GAS")
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            rounded: "",
                            dark: "",
                            large: "",
                            color: "green",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.redirect("ExpenseCreate")
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-cash-minus")]),
                          _vm._v(" Gasto "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasAccess("ESC.COM.COM")
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            rounded: "",
                            dark: "",
                            large: "",
                            color: "deep-orange",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.redirect("PurchaseCreate")
                            },
                          },
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-cart-variant")]),
                          _vm._v(" Compra "),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [_c("v-col", [_c("v-divider", { staticClass: "mt-4" })], 1)],
            1
          ),
          _vm.canViewDashboard
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "9", md: "8", sm: "6" } },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-2 px-5",
                          attrs: { large: "", active: "" },
                          on: {
                            click: function ($event) {
                              return _vm.setFecha("hoy")
                            },
                          },
                        },
                        [_vm._v("Hoy")]
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "mr-2 px-5",
                          attrs: { large: "" },
                          on: {
                            click: function ($event) {
                              return _vm.setFecha("semana")
                            },
                          },
                        },
                        [_vm._v("Semana")]
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "px-5",
                          attrs: { large: "" },
                          on: {
                            click: function ($event) {
                              return _vm.setFecha("mes")
                            },
                          },
                        },
                        [_vm._v("Mes")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          ref: "dialog",
                          attrs: {
                            "return-value": _vm.filters.dates,
                            persistent: "",
                            width: "290px",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              return _vm.$set(_vm.filters, "dates", $event)
                            },
                            "update:return-value": function ($event) {
                              return _vm.$set(_vm.filters, "dates", $event)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              label: "Rango de fechas",
                                              "prepend-icon": "mdi-calendar",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm.dateRangeText,
                                              callback: function ($$v) {
                                                _vm.dateRangeText = $$v
                                              },
                                              expression: "dateRangeText",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1459921646
                          ),
                          model: {
                            value: _vm.modal,
                            callback: function ($$v) {
                              _vm.modal = $$v
                            },
                            expression: "modal",
                          },
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              attrs: {
                                range: "",
                                "selected-items-text": "2 seleccionados",
                                locale: "es-ar",
                              },
                              model: {
                                value: _vm.filters.dates,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "dates", $$v)
                                },
                                expression: "filters.dates",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.modal = false
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.dialog.save(_vm.filters.dates)
                                      _vm.actualizarVista()
                                    },
                                  },
                                },
                                [_vm._v(" OK ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.loading && _vm.canViewDashboard
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c("v-progress-circular", {
                        staticClass: "mb-5 mt-10",
                        attrs: {
                          size: 100,
                          width: 5,
                          color: "purple",
                          indeterminate: "",
                        },
                      }),
                      _c("h6", { staticClass: "text-h6" }, [
                        _vm._v("Cargando métricas..."),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-row",
                [
                  _vm.canViewDashboard
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _c("h5", { staticClass: "text-h5" }, [
                          _vm._v(_vm._s(_vm.periodo)),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                    [
                      _vm.hasAccess("LEC.VEN.VEN")
                        ? _c("CardInfoNumber", {
                            attrs: {
                              title: "Cantidad de Ventas",
                              number: _vm.getSaleMetrics.total_sales,
                              "text-color": "red--text text--lighten-5",
                              "number-size": "4",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                    [
                      _vm.hasAccess("LEC.TRE.FUN")
                        ? _c("CardInfoNumber", {
                            attrs: {
                              title: "Facturado",
                              number: _vm.formatCurrency(
                                _vm.getSaleMetrics.total_amount
                              ),
                              "text-color": "red--text text--lighten-5",
                              "number-size": "4",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                    [
                      _vm.hasAccess("LEC.TRE.FUN")
                        ? _c("CardInfoNumber", {
                            attrs: {
                              title: "Ganancia",
                              number: _vm.formatCurrency(
                                _vm.getSaleMetrics.total_gain
                              ),
                              "text-color": "green",
                              "number-size": "4",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                    [
                      _vm.hasAccess("LEC.COM.COM")
                        ? _c("CardInfoNumber", {
                            attrs: {
                              title: "Total de Compras",
                              number: _vm.formatCurrency(
                                _vm.getPurchaseMetrics.total_amount
                              ),
                              "text-color": "deep-orange--text text--lighten-5",
                              "number-size": "4",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", md: "4", sm: "6" } },
                    [
                      _vm.hasAccess("LEC.TRE.GAS")
                        ? _c("CardInfoNumber", {
                            attrs: {
                              title: "Gasto Total",
                              number: _vm.formatCurrency(
                                _vm.getExpenseMetrics.total_amount
                              ),
                              "text-color": "pink--text text--lighten-5",
                              "number-size": "4",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }