<template>
	<div>
		<toast :errors="errors"></toast>
		<v-dialog
			hide-overlay
			transition="dialog-bottom-transition"
			@input="$router.back()"
			v-model="dialog"
			max-width="900px"
			:fullscreen="$vuetify.breakpoint.xs"
		>
			<v-card>
				<v-toolbar fixed>
					<v-toolbar-title>{{ title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn
							icon
							dark
							@click="
								dialog = !dialog;
								$router.back();
							"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-card-text class="pa-2">
					<v-form
						ref="form"
						id="form-users"
						@submit.prevent="save"
						v-model="formValid"
					>
						<v-container>
							<v-row>
								<v-col cols="12">
									<h4>
										Al cambiar la contraseña, se solicitará iniciar sesión
										nuevamente.
									</h4>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="password.current"
										type="password"
										required
										label="Contraseña Actual"
										:error-messages="currentErrors"
										@input="$v.password.current.$touch()"
										@blur="$v.password.current.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="password.new"
										type="password"
										required
										label="Contraseña Nueva"
										:error-messages="newErrors"
										@input="$v.password.new.$touch()"
										@blur="$v.password.new.$touch()"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="4">
									<v-text-field
										v-model="password.confirmate"
										type="password"
										required
										label="Confirmar Contraseña"
										:error-messages="confirmateErrors"
										@input="$v.password.confirmate.$touch()"
										@blur="$v.password.confirmate.$touch()"
									></v-text-field>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						Cancelar
					</v-btn>
					<v-btn
						type="submit"
						:disabled="formLoad"
						:loading="formLoad"
						form="form-users"
						color="blue darken-1"
						text
					>
						Guardar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import toast from "@/components/toast";
import { mapState, mapActions } from "vuex";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
	components: { toast },
	validations: {
		password: {
			current: { required, minLength: minLength(8) },
			new: { required, minLength: minLength(8) },
			confirmate: {
				required,
				minLength: minLength(8),
				sameAsPassword: sameAs("new"),
			},
		},
	},
	data: () => ({
		title: "Cambiar Contraseña",
		dialog: true,
		formValid: false,
		formLoad: false,
		password: {
			current: "",
			new: "",
			confirmate: "",
		},
	}),
	watch: {
		dialog(val) {
			val || this.close();
		},
	},
	computed: {
		...mapState({
			errors: (state) => state.errors,
		}),

		currentErrors() {
			const errors = [];
			if (!this.$v.password.current.$dirty) return errors;
			!this.$v.password.current.required &&
				errors.push("La contraseña actual es requerida");
			!this.$v.password.current.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			return errors;
		},
		newErrors() {
			const errors = [];
			if (!this.$v.password.new.$dirty) return errors;
			!this.$v.password.new.required &&
				errors.push("La contraseña nueva es requerida");
			!this.$v.password.new.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			return errors;
		},
		confirmateErrors() {
			const errors = [];
			if (!this.$v.password.confirmate.$dirty) return errors;
			!this.$v.password.confirmate.required &&
				errors.push("La confirmación contraseña es requerida");
			!this.$v.password.confirmate.minLength &&
				errors.push("Debe tener mínimo 8 caracteres");
			!this.$v.password.confirmate.sameAsPassword &&
				errors.push("Las contraseñas no coinciden");
			return errors;
		},
	},
	created() {
		this.init();
	},
	methods: {
		...mapActions("Auth", ["changePassword", "logout"]),

		init() {},

		close() {
			this.$nextTick(() => {
				this.$refs.form.resetValidation();
				this.editedIndex = -1;
			});
			this.dialog = false;
		},

		beforeSave() {
			this.formLoad = true;
		},

		successSave: async function () {
			await this.logout({
				session: this.$session,
			});
			this.$router.push("/login");
		},

		completeSave() {
			this.formLoad = false;
		},

		save: async function () {
			this.beforeSave();
			this.$v.password.$touch();
			if (!this.$v.password.$invalid) {
				await this.changePassword({
					data: this.password,
					success: () => {
						this.successSave();
					},
				});
			}
			this.completeSave();
		},
	},
};
</script>
