var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.list.categories,
                  "item-text": "name",
                  "item-value": "id",
                  outlined: "",
                  "hide-details": "",
                  "auto-select-first": "",
                  label: "Categoria",
                },
                on: {
                  change: function ($event) {
                    _vm.loadAttributeValues()
                    _vm.addProduct()
                  },
                },
                model: {
                  value: _vm.form.category,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "category", $$v)
                  },
                  expression: "form.category",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-row", [_c("v-divider", { staticClass: "ma-2" })], 1),
      _c(
        "v-row",
        [
          _vm._l(_vm.form.attributesValues, function (item, index) {
            return _c("v-col", { key: index, attrs: { cols: "4" } }, [
              _vm.getCategoryAttribute(item.id)?.values.length > 0
                ? _c(
                    "div",
                    [
                      _c("v-select", {
                        attrs: {
                          items:
                            _vm.getCategoryAttribute(item.id)?.values ?? [],
                          "item-text": "value",
                          "item-value": "id",
                          outlined: "",
                          required: "",
                          "hide-details": "",
                          dense: "",
                          label: item.name,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.addProduct()
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", _vm._n($$v))
                          },
                          expression: "item.value",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-text-field", {
                        attrs: { label: item.name, outlined: "", dense: "" },
                        on: {
                          input: function ($event) {
                            return _vm.addProduct()
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                    ],
                    1
                  ),
            ])
          }),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  required: "",
                  outlined: "",
                  dense: "",
                  label: "Descripción",
                },
                on: {
                  input: function ($event) {
                    return _vm.addProduct()
                  },
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "elevation-1", attrs: { cols: "12" } }, [
            _c("h4", { staticClass: "text-subtitle-2" }, [
              _vm._v("Vista previa"),
            ]),
            _c("h6", { staticClass: "text-body-1 mt-1" }, [
              _vm._v(
                _vm._s(_vm.form.category.name) +
                  " " +
                  _vm._s(_vm.fullName) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.withAddOption
        ? _c(
            "v-row",
            { staticClass: "mt-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeForm()
                    },
                  },
                },
                [_vm._v(" Cerrar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.loadProduct()
                    },
                  },
                },
                [_vm._v(" Agregar ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }