<template>
	<v-container>
		<v-row v-if="loading">
			<v-col class="text-center" cols="12" lg="12">
				<v-progress-circular
					class="ma-5"
					:size="70"
					:width="7"
					v-if="loading"
					color="teal"
					indeterminate
				></v-progress-circular>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col v-for="item in attributes" :key="item.id" cols="12">
				<v-list-item class="elevation-1">
					<v-list-item-action>
						<v-checkbox
						@change="changeAttributeValue"
						v-model="item.selected"></v-checkbox>
					</v-list-item-action>

					<v-list-item-content>
						<v-list-item-title>{{ getCategoryAttribute(item.id)?.name }}</v-list-item-title>
						<v-list-item-subtitle>{{ attributeNamesToStrings(getCategoryAttribute(item.id)?.values) }}</v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action>
						No discriminar stock
						<v-checkbox 
						@change="changeAttributeValue"
						v-model="item.non_discriminatory"
						></v-checkbox>
					</v-list-item-action>
        </v-list-item>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
	props: {
		attributesIn: {
			default: []
		}
	},
	data: () => {
		return {
			loading: true,
			attributes: [],
			valuesSelected: []
		}
	},
	created: async function(){
		if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');
		this.formatAttributes();
		this.changeAttributeValue();
		this.loading = false;
	},
	computed: {
		...mapState('CategoryAttribute', ['categoryAttributes']),
		...mapGetters('CategoryAttribute', ['getCategoryAttribute'])
	},
	methods: {
		formatAttributes(){
			this.categoryAttributes.forEach(element => {
				let isSelected = 0;
				if(this.attributesIn.length > 0) {
					isSelected = this.attributesIn.some(attributo => attributo.id == element.id) ? element.id : 0
				}
				let isNonDiscriminatory = 0;
				if(this.attributesIn.length > 0) {
					isNonDiscriminatory = this.attributesIn.some(attributo => attributo.id == element.id && attributo.non_discriminatory) ? element.id : 0
				}
				this.attributes.push({
					id: element.id,
					selected: isSelected,
					non_discriminatory: isNonDiscriminatory
				})
			});
		},
		attributeNamesToStrings(attributeNames){
			if(attributeNames.length == 0)
				return 'Sin valores por defecto';
			const names = attributeNames.slice(0, 5).map(attribute => attribute.value);
			return names.join(", ") + ' ...';
		},
		changeAttributeValue(){
			let attributesToSend = [];

			this.attributes.filter(element => element.selected).forEach(element => {
				attributesToSend.push({
					id: element.id,
					non_discriminatory: element.non_discriminatory == 0 ? 0 : 1
				})
			})
			
			this.$emit('change-attribute-value', attributesToSend);
		}
	}
};

</script>
