var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-3 mb-10 flex-column" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.$store.state.PuntoVenta.puntosVenta,
                  "sort-by": "name",
                  loading: _vm.tableLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c("v-toolbar-title", [_vm._v(_vm._s(_vm.name))]),
                            _c("v-divider", {
                              staticClass: "mx-4",
                              attrs: { inset: "", vertical: "" },
                            }),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "primary",
                                  to: "puntos_de_venta/create",
                                  small: "",
                                  fab: "",
                                },
                              },
                              [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v(" mdi-plus "),
                                ]),
                              ],
                              1
                            ),
                            _c("dialog-delete", {
                              attrs: { value: _vm.dialogDelete },
                              on: {
                                close: _vm.closeDelete,
                                confirm: _vm.deleteItemConfirm,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-2",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [_vm._v(" mdi-pencil ")]
                        ),
                        _c(
                          "v-icon",
                          {
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [_vm._v(" mdi-delete ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.principal",
                    fn: function ({ item }) {
                      return [
                        item.principal == 1
                          ? _c("span", [_vm._v("Sí")])
                          : _c("span", [_vm._v("No")]),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }