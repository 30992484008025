<template>
	<v-dialog :value="value" :persistent="persistent" max-width="500px">
		<v-card>
			<v-progress-linear
				v-if="progress"
				indeterminate
				color="primary"
				class="mb-0"
			></v-progress-linear>
			<v-card-title class="text-h6">{{ title }}</v-card-title>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="handleClickClose"
					>Cancelar</v-btn
				>
				<v-btn
					color="blue darken-1"
					:disabled="progress"
					:loading="progress"
					text
					@click="handleClickConfirm"
					>OK</v-btn
				>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	props: {
		value: {
			default: null,
		},
		persistent: {
			default: true,
		},
		progress: {
			default: true,
		},
		title: {
			default: '¿Desea confirmar la operación?'
		}
	},
	methods: {
		handleClickClose() {
			this.$emit("close");
		},
		handleClickConfirm() {
			this.$emit("confirm");
		},
	},
};
</script>
