var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { attrs: { dark: "", fixed: "", color: "primary" } },
    [
      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.changeScreen()
                },
              },
            },
            [
              _c("v-icon", [
                _vm._v(
                  _vm._s(
                    _vm.config.fullscreen
                      ? "mdi-arrow-collapse"
                      : "mdi-arrow-expand"
                  )
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function ($event) {
                  _vm.dialog = !_vm.dialog
                  _vm.$router.back()
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }