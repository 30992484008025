var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Movimientos" } }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", lg: "4" } },
                [
                  _c(
                    "v-dialog",
                    {
                      ref: "dialog",
                      attrs: {
                        "return-value": _vm.filters.dates,
                        persistent: "",
                        width: "290px",
                      },
                      on: {
                        "update:returnValue": function ($event) {
                          return _vm.$set(_vm.filters, "dates", $event)
                        },
                        "update:return-value": function ($event) {
                          return _vm.$set(_vm.filters, "dates", $event)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        label: "Rango de fechas",
                                        "prepend-icon": "mdi-calendar",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dateRangeText,
                                        callback: function ($$v) {
                                          _vm.dateRangeText = $$v
                                        },
                                        expression: "dateRangeText",
                                      },
                                    },
                                    "v-text-field",
                                    attrs,
                                    false
                                  ),
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.modal,
                        callback: function ($$v) {
                          _vm.modal = $$v
                        },
                        expression: "modal",
                      },
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: {
                            range: "",
                            "selected-items-text": "2 seleccionados",
                            locale: "es-ar",
                          },
                          model: {
                            value: _vm.filters.dates,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "dates", $$v)
                            },
                            expression: "filters.dates",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.modal = false
                                },
                              },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.$refs.dialog.save(_vm.filters.dates)
                                  _vm.actualizarVista()
                                },
                              },
                            },
                            [_vm._v(" OK ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.movements,
                      loading: _vm.tableLoad,
                      "items-per-page": 5,
                      "no-data-text": "No existen registros, aún...",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.title",
                        fn: function ({ item }) {
                          return [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(_vm.getIconFund(item.fund.fund_type)) +
                                  " "
                              ),
                            ]),
                            _vm._v(" " + _vm._s(item.fund.title) + " "),
                          ]
                        },
                      },
                      {
                        key: "item.origin",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { class: _vm.getColorOrigin(item) },
                              [
                                _c(
                                  "v-icon",
                                  { class: _vm.getColorOrigin(item) },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getIconOrigin(item)) + " "
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.getTitleOrigin(item)) + " "
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.amount",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                class: {
                                  "red--text": item.debit_credit == "D",
                                  "green--text": item.debit_credit == "C",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.debit_credit == "C" ? "" : "- "
                                    ) +
                                    " " +
                                    _vm._s(_vm.formatCurrency(item.amount)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }