import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./core";
import { myMixins } from "./mixins";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from "moment";

import "@/assets/styles/main.css";

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.mixin(myMixins);
Vue.prototype.$moment = moment

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
