<template>
	<div>
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-container class="mt-3 mb-10 flex-column">
			<v-row>
				<div class="text-h3">Mis Sucursales</div>
			</v-row>
			<v-row>
				<v-col>
					<!--<v-btn elevation="2" fab color="primary" :to="{ name: 'BranchCreate'}"
						><v-icon>mdi-plus</v-icon>
					</v-btn>
					-->
				</v-col>
			</v-row>
			<v-row class="justify-center">
				<v-col v-if="tableLoad" cols="12" class="text-center">
					<v-progress-circular
						:size="70"
						:width="7"
						color="purple"
						indeterminate
					></v-progress-circular>
				</v-col>
				<v-col
					v-else
					cols="12"
					md="4"
					sm="6"
					lg="4"
					v-for="branch in branches"
					:key="branch.id"
				>
					<v-card elevation="2">
						<v-card-title>
							<v-icon color="yellow" v-if="branch.head == 1" left>
								mdi-star
							</v-icon>
							{{ branch.name }}
						</v-card-title>
						<v-card-text>
							{{ branch.sale_point.name }} <br />
							{{ getFullAddress(branch.id) }}
						</v-card-text>
						<v-card-actions>
							<v-btn
								v-if="hasAccess('ESC.ADM.SUC')"
								rounded
								fab
								:to="{ name: 'BranchUpdate', params: { id: branch.id } }"
							>
								<v-icon> mdi-pencil </v-icon>
							</v-btn>
							<!--<v-btn rounded fab @click="deleteItem(branch.id)">
								<v-icon color="red"> mdi-delete </v-icon>
							</v-btn>
							-->
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";

export default {
	components: { DialogDelete },
	data: () => ({
		name: "Sucursal",
		tableLoad: false,
		branch: null,
		headers: [
			{ text: "Descripción", value: "description" },
			{ text: "Principal", value: "main" },
			{ text: "Acciones", value: "actions", sortable: false },
		],
		editedIndex: -1,
		dialogDelete: false,
		deleteItemId: null,
		deleteLoad: false,
	}),

	created: async function () {
		this.tableLoad = true;
		if (this.$store.state.Branch.branches.length == 0) await this.get();
		this.tableLoad = false;
	},

	computed: {
		...mapState("Branch", ["branches"]),
		...mapGetters("Branch", ["getFullAddress"]),
		...mapGetters("Auth", ['hasAccess'])
	},

	methods: {
		...mapActions("Branch", ["get", "store", "delete"]),

		deledit(item) {
			this.editedIndex = this.branches.indexOf(item);
			this.branch = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "branchUpdate",
				params: { branch: item },
			});
		},

		deleteItem(id) {
			this.deleteItemId = id;
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.formLoad = true;
			this.delete(this.deleteItemId);
			this.closeDelete();
			await this.get();
			this.formLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},
	},
};
</script>
