var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-5" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "5", md: "4", lg: "4" } },
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "2", loading: _vm.loading } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("h2", [
                            _vm._v(
                              "Se envió la confirmación de correo electrónico"
                            ),
                          ]),
                          _c("hr", { staticClass: "ma-3" }),
                          _c("span", [
                            _vm._v(
                              "Verifique la casilla de su correo y acceda al link de confirmación"
                            ),
                          ]),
                          _c("hr", { staticClass: "ma-3" }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-2",
                              attrs: { color: "primary", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.irAlHome()
                                },
                              },
                            },
                            [_vm._v(" Ya confirmé el email ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              attrs: { color: "primary", text: "", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.sendEmail()
                                },
                              },
                            },
                            [_vm._v(" Enviar de nuevo ")]
                          ),
                          _c(
                            "v-alert",
                            {
                              staticClass: "ma-3",
                              attrs: { border: "top", color: "green" },
                              model: {
                                value: _vm.emailSent,
                                callback: function ($$v) {
                                  _vm.emailSent = $$v
                                },
                                expression: "emailSent",
                              },
                            },
                            [_vm._v(" Correo electrónico enviado ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }