<template>
	<div>
		<v-container class="mt-3 mb-10 flex-column">
			<v-row>
				<v-col cols="12">
					<div class="text-h3">{{ name }}</div>
				</v-col>
				<v-col>
					<v-card elevation="2">
						<v-card-title>{{ user.people.first_name }}</v-card-title>
						<v-card-text>
							{{ documentType }}: {{ document }} <br />
							<!-- sadas
							Condición IVA: {{ vatCondition }} <br />
							<div v-if="user.addresses.lenght > 0">
								Dirección: {{ user.addresses[0] }} <br />
							</div>
							<v-divider class="mt-3 mb-3" />
							<div class="text-h6">Contacto</div>
							Teléfono: {{ getPhone }} <br />
							Correo: {{ getEmail }} <br />
							-->
						</v-card-text>
						<v-card-actions class="ma-3">
							<!--<v-btn outlined rounded text to="/administration/account/store"
								>Editar</v-btn
							> -->
							<v-btn
								outlined
								rounded
								text
								to="/administration/account/change-password"
								>Cambiar Contraseña</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
	data: () => ({
		name: "Mi Cuenta",
		documentType: null,
		document: null,
		vatCondition: null,
		userFullName: null,
	}),

	computed: {
		...mapState("Auth", ["user"]),
		...mapGetters("Auth", ["getUserFullName", "getEmail", "getPhone"]),
	},

	created: async function () {
		this.documentType = this.user.people.entity.document_type;
		this.document = this.user.people.entity.document;
		this.vatCondition = this.user.people.entity.vat_condition;
		this.userFullName = this.getUserFullName;
	},

	methods: {},
};
</script>
