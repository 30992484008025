import axios from "axios";
import http from "../../utils/http";

export default {
	namespaced: true,
	state: {
		movement: {
			id: null,
			daily_box_id: null,
			fund_id: null,
			movementable_id: null,
			movementable_type: null,
			debit_credit: null,
			amount: null,
			detail: null,
			issue_date: null
		},
		movements: []
	},
	mutations: {
		SET_MOVEMENT(state, data) {
			state.movement = data;
		},
		SET_MOVEMENTS(state, data) {
			state.movements = data;
		},
	},
	actions: {
		get: async function ({ commit }, params) {
			return await axios.get("daily-box/movement" + http.formatQueryParams(params.filters)).then((response) => {
				commit("SET_MOVEMENTS", response.data);
			}).catch(() => {});
		}
	},
	getters: {
		getMovements: (state) => {
			if (state.movements) return state.movements;
			else return [];
		},
		getMovement: (state) => (movementId) => {
			if (state.movements)
				return state.movements.filter((prop) => prop.id == movementId)[0];
			else return "";
		},
	},
};
