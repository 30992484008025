var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: { input: _vm.closeDialog },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pa-6" }, [
            _vm.purchase
              ? _c(
                  "div",
                  { attrs: { id: "data" } },
                  [
                    _c("h4", { staticClass: "text-h4" }, [
                      _vm._v("Compra # " + _vm._s(_vm.purchase.id)),
                    ]),
                    _c(
                      "v-chip",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          color: _vm.getColorForState(
                            _vm.purchase.purchase_state
                          ),
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.getDescriptionForState(
                              _vm.purchase.purchase_state
                            )
                          )
                        ),
                      ]
                    ),
                    _c("v-divider", { staticClass: "ma-4" }),
                    _c("v-spacer"),
                    _c("p", { staticClass: "text-body-1 mt-3" }, [
                      _vm._v("Compra en la fecha "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.purchase.issue_date_custom)),
                      ]),
                      _vm._v(" a "),
                      _c("strong", [_vm._v("Consumidor Final")]),
                    ]),
                    _c("p", { staticClass: "text-body-2" }, [
                      _c("strong", [_vm._v("Comentario: ")]),
                      _vm._v(" " + _vm._s(_vm.purchase.comment ?? "Sin datos")),
                    ]),
                    _c("v-divider", { staticClass: "ma-3" }),
                    _c("p", { staticClass: "text-body-1" }, [
                      _vm._v("Se cargaron "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.totalProductosCargados) + " productos"
                        ),
                      ]),
                      _vm._v(" con un total de "),
                      _c("strong", { staticClass: "blue--text" }, [
                        _vm._v(
                          _vm._s(_vm.formatCurrency(_vm.totalPreciosCargados))
                        ),
                      ]),
                    ]),
                    _c("p", { staticClass: "text-body-2" }, [
                      _vm._v(" Del cual se pagó un total de "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.formatCurrency(_vm.totalPayment))),
                      ]),
                      _vm._v(" quedando pendiente a pagar "),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.formatCurrency(
                              _vm.totalPreciosCargados - _vm.totalPayment
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("v-divider", { staticClass: "ma-4" }),
                    _c("v-spacer"),
                    _c("h5", { staticClass: "text-h5" }, [
                      _vm._v("Listado de Productos"),
                    ]),
                    _vm._l(_vm.purchase.carts, function (item) {
                      return _c(
                        "v-list-item",
                        { key: item.id, attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "text-body-1 font-weight-medium text-capitalize ma-0",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-body-1 font-weight-bold text-capitalize ma-0",
                                    },
                                    [_vm._v("#" + _vm._s(item.product.code))]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getCategory(
                                          item.product.category_id
                                        )?.name
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-body-2 text-capitalize ma-0",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getProduct(item.product.id)
                                            ?.full_name
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.count) +
                                    " por " +
                                    _vm._s(
                                      _vm.formatCurrency(
                                        item.unit_price * item.count
                                      )
                                    ) +
                                    " en total."
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _c("div", { attrs: { id: "no-data" } }, [
                  _vm._v(" La compra no existe "),
                ]),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                      _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Salir ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }