var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-4" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        fab: "",
                        dark: "",
                        color: "indigo",
                        to: "/purchasing/provider/store",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v(" mdi-plus "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Buscar proveedor...", required: "" },
                    model: {
                      value: _vm.search.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.search, "name", $$v)
                      },
                      expression: "search.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("DialogDelete", {
            attrs: { value: _vm.dialogDelete, progress: _vm.deleteLoad },
            on: { close: _vm.closeDelete, confirm: _vm.deleteItemConfirm },
          }),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.providers,
                      search: _vm.search.name,
                      "footer-props": {
                        itemsPerPageOptions: [5, 10, 50, 100],
                        itemsPerPageText: "Filas por página:",
                      },
                      loading: _vm.tableLoad,
                      "sort-by": "created_at",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editItem(item)
                                  },
                                },
                              },
                              [_vm._v(" mdi-pencil ")]
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_vm._v(" mdi-delete ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return undefined
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }