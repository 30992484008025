<template>
	<v-col lg="5">
		<v-select
			:items="vat_conditions"
			item-text="name"
			item-value="id"
			v-model="vat_condition"
			label="Condición IVA"
			:error-messages="vatConditionErrors"
			@change="v.vat_condition.$touch();"
			@blur="v.vat_condition.$touch()"
		></v-select>
	</v-col>
</template>
<script>

export default {
	props: ["value", "v"],
	data: () => {
		return {
			vat_condition: {
				id: 'SIN_CONDICION_IVA',
				name: 'Sin condición IVA'
			},
			vat_conditions: [
				{ id: 'SIN_CONDICION_IVA', name: 'Sin condición IVA' },
				{ id: 'RESPONSABLE_INSCRIPTO', name: 'Responsable Inscripto' }
			]
		};
	},
	created() {
			this.vat_condition = this.value
			this.v.vat_condition.$model = this.vat_condition;
			this.v.vat_condition.$reset();
	},
	computed: {

		vatConditionErrors() {
			const errors = [];
			if (!this.v.vat_condition.$dirty) return errors;
			!this.v.vat_condition.required &&
				errors.push("La condición IVA es requerida");
			return errors;
		},

	},
	methods: {

	},
};
</script>
