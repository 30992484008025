var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-combobox", {
    attrs: {
      items: _vm.deposits,
      "item-text": "name",
      "item-value": "id",
      "auto-select-first": "",
      label: "Depósito",
      loading: _vm.loading,
      "error-messages": _vm.depositErrors,
    },
    on: {
      change: function ($event) {
        _vm.validator.form.deposit.$touch()
        _vm.value()
      },
      blur: function ($event) {
        _vm.validator.form.deposit.$touch()
        _vm.value()
      },
    },
    model: {
      value: _vm.form.deposit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "deposit", $$v)
      },
      expression: "form.deposit",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }