var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Toast", {
        attrs: { type: _vm.errors.type },
        model: {
          value: _vm.errors.value,
          callback: function ($$v) {
            _vm.$set(_vm.errors, "value", $$v)
          },
          expression: "errors.value",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "hide-overlay": "",
            transition: "dialog-bottom-transition",
            scrollable: "",
            loading: _vm.loading,
            "max-width": "700px",
            fullscreen: _vm.$vuetify.breakpoint.xs,
          },
          on: {
            input: function ($event) {
              return _vm.$router.back()
            },
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", fixed: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = !_vm.dialog
                              _vm.$router.back()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-progress-linear", {
                attrs: { active: _vm.loading, indeterminate: "" },
              }),
              _c(
                "v-card-text",
                { staticClass: "pa-2", staticStyle: { "min-height": "500px" } },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { disabled: _vm.loading, id: "form-punto-venta" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.save.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.formValid,
                        callback: function ($$v) {
                          _vm.formValid = $$v
                        },
                        expression: "formValid",
                      },
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Alias",
                                      "error-messages": _vm.aliasErrors,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$v.provider.alias.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.provider.alias.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.provider.alias,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.provider, "alias", $$v)
                                      },
                                      expression: "provider.alias",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Documento",
                                      "error-messages": _vm.documentErrors,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$v.provider.document.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.provider.document.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.provider.document,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.provider, "document", $$v)
                                      },
                                      expression: "provider.document",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("v-textarea", {
                                    attrs: { solo: "", label: "Comentario..." },
                                    model: {
                                      value: _vm.provider.comment,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.provider, "comment", $$v)
                                      },
                                      expression: "provider.comment",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "blue darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.$router.back()
                        },
                      },
                    },
                    [_vm._v(" Cancelar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        disabled: _vm.formLoad,
                        loading: _vm.formLoad,
                        form: "form-punto-venta",
                        color: "blue darken-1",
                        text: "",
                      },
                    },
                    [_vm._v(" Guardar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }