var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        persistent: "",
        transition: "dialog-bottom-transition",
        "max-width": "1100px",
        "min-heigth": "900px",
        fullscreen: _vm.config.fullscreen,
      },
      on: {
        input: function ($event) {
          return _vm.$router.back()
        },
      },
      model: {
        value: _vm.dialogg,
        callback: function ($$v) {
          _vm.dialogg = $$v
        },
        expression: "dialogg",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.config.fullscreen = !_vm.config.fullscreen
                        },
                      },
                    },
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(
                            _vm.config.fullscreen
                              ? "mdi-arrow-collapse"
                              : "mdi-arrow-expand"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialogg = !_vm.dialogg
                          _vm.$router.back()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-sales" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.e6,
                        callback: function ($$v) {
                          _vm.e6 = $$v
                        },
                        expression: "e6",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete: _vm.e6 > 1,
                                step: "1",
                                rules: [() => !_vm.$v.$invalid],
                              },
                            },
                            [_vm._v(" Datos Principales ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            { attrs: { complete: _vm.e6 > 2, step: "2" } },
                            [_vm._v(" Listado de Productos ")]
                          ),
                          !this.$route.params.id ? _c("v-divider") : _vm._e(),
                          !this.$route.params.id
                            ? _c(
                                "v-stepper-step",
                                { attrs: { complete: _vm.e6 > 3, step: "3" } },
                                [_vm._v(" Proceso de Cobro ")]
                              )
                            : _vm._e(),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            { attrs: { step: !this.$route.params.id ? 4 : 3 } },
                            [_vm._v(" Vista General ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _c(
                                "v-container",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("DateTimeCustom", {
                                        attrs: {
                                          label: "Fecha de la venta",
                                          v: _vm.$v,
                                        },
                                        on: {
                                          "date-changed": _vm.newDateChanged,
                                        },
                                        model: {
                                          value: _vm.form.issue_date,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "issue_date",
                                              $$v
                                            )
                                          },
                                          expression: "form.issue_date",
                                        },
                                      }),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", lg: "4" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.usersWithoutOv,
                                              "item-value": "id",
                                              "item-text": "entity.full_name",
                                              "return-object": "",
                                              loading: _vm.loadings.seller,
                                              label: "Vendedor",
                                              "error-messages":
                                                _vm.sellerErrors,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.$v.form.seller.$touch()
                                              },
                                              blur: function ($event) {
                                                return _vm.$v.form.seller.$touch()
                                              },
                                            },
                                            model: {
                                              value: _vm.form.seller,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "seller",
                                                  $$v
                                                )
                                              },
                                              expression: "form.seller",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", lg: "4" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.priceLists,
                                              "item-value": "id",
                                              "item-text": "name",
                                              loading: _vm.loadings.price_list,
                                              "return-object": "",
                                              label: "Lista de Precio",
                                              "error-messages":
                                                _vm.priceListErrors,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.$v.form.price_list.$touch()
                                                _vm.$forceUpdate()
                                              },
                                              blur: function ($event) {
                                                return _vm.$v.form.price_list.$touch()
                                              },
                                            },
                                            model: {
                                              value: _vm.form.price_list,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "price_list",
                                                  $$v
                                                )
                                              },
                                              expression: "form.price_list",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "8" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              rows: "2",
                                              solo: "",
                                              label: "Detalle...",
                                            },
                                            model: {
                                              value: _vm.form.comment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "comment",
                                                  $$v
                                                )
                                              },
                                              expression: "form.comment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.datosPrincipalesValidator,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.confirmarDatosPrincipales()
                                    },
                                  },
                                },
                                [_vm._v(" Continuar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [_vm._v(" Cancelar ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              _c("ProductList", {
                                attrs: {
                                  "with-add-product-action": false,
                                  "only-products-with-stock": true,
                                  "show-cash-discount": true,
                                  "with-headers-filter": false,
                                  "price-list": _vm.form.price_list,
                                  carts: _vm.form.carts,
                                },
                                on: { "product-alter": _vm.productAlter },
                              }),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.hasProductsAdds,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.e6 = 3
                                    },
                                  },
                                },
                                [_vm._v(" Continuar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.e6 = 1
                                    },
                                  },
                                },
                                [_vm._v(" Atras ")]
                              ),
                            ],
                            1
                          ),
                          !this.$route.params.id
                            ? _c(
                                "v-stepper-content",
                                { attrs: { step: "3" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12 pa-3",
                                      attrs: {
                                        "min-height": "200px",
                                        tile: "",
                                      },
                                    },
                                    [
                                      _c("PaymentMethodList", {
                                        attrs: {
                                          "total-charge":
                                            _vm.totalPreciosCargadosSinFormato,
                                        },
                                        on: {
                                          "charges-updated": _vm.chargesUpdated,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 4
                                        },
                                      },
                                    },
                                    [_vm._v(" Siguiente ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e6 = 2
                                        },
                                      },
                                    },
                                    [_vm._v(" Atras ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: !this.$route.params.id ? 4 : 3 } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12 pa-3",
                                  attrs: { "min-height": "200px", tile: "" },
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { fluid: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", lg: "6" } },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "text-h5" },
                                                [_vm._v("Detalle de la venta")]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-body-1 mt-3",
                                                },
                                                [
                                                  _vm._v("Venta en la fecha "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.form.issue_date
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" a "),
                                                  _c("strong", [
                                                    _vm._v("Consumidor Final"),
                                                  ]),
                                                  _vm._v(" vendido por "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.form.seller?.entity
                                                          .full_name
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v("."),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "text-body-2" },
                                                [
                                                  _c("strong", [
                                                    _vm._v("Comentario: "),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form.comment ??
                                                          "Sin datos"
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "ma-3",
                                              }),
                                              _c(
                                                "p",
                                                { staticClass: "text-body-1" },
                                                [
                                                  _vm._v("Se cargaron "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.totalProductosCargados
                                                      ) + " productos"
                                                    ),
                                                  ]),
                                                  _vm._v(" con un total de "),
                                                  _c(
                                                    "strong",
                                                    {
                                                      staticClass: "blue--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.totalPreciosCargados
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              !this.$route.params.id
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-body-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Del cual se pagará un total de "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatCurrency(
                                                              _vm.totalCharge -
                                                                _vm
                                                                  .chargesCharged
                                                                  .discount
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " quedando pendiente a pagar "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatCurrency(
                                                              _vm.totalPreciosCargadosSinFormato -
                                                                _vm.totalCharge
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("p", [
                                                _vm._v(
                                                  "Descuento en aplicado: "
                                                ),
                                                _c(
                                                  "strong",
                                                  {
                                                    staticClass:
                                                      "success--text text-body-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatCurrency(
                                                          _vm.chargesCharged
                                                            .discount ?? 0
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", lg: "6" } },
                                            [
                                              _c(
                                                "h5",
                                                { staticClass: "text-title" },
                                                [_vm._v("Listado de Productos")]
                                              ),
                                              _vm._l(
                                                _vm.productsCharged,
                                                function (item) {
                                                  return _c(
                                                    "v-list-item",
                                                    {
                                                      key: item.id,
                                                      attrs: { "two-line": "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.full_name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-list-item-subtitle",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.count
                                                                  ) +
                                                                  " por " +
                                                                  _vm._s(
                                                                    _vm.formatCurrency(
                                                                      item.total_price *
                                                                        item.count
                                                                    )
                                                                  ) +
                                                                  " en total."
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loadingPurchase,
                                    disbaled: _vm.loadingPurchase,
                                  },
                                  on: { click: _vm.save },
                                },
                                [_vm._v(" Guardar ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      !_vm.$route.params.id
                                        ? (_vm.e6 = 3)
                                        : (_vm.e6 = 2)
                                    },
                                  },
                                },
                                [_vm._v(" Atras ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }