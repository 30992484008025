import axios from "axios";

export default {
	namespaced: true,
	state: {
		movementInventory: {
			deposit_id: null,
			product_id: null,
			user_id: null,
			movement_inventory_type_ref: null,
			count: null,
			issue_date: null,
			created_at: null,
		},
		movementsInventory: [],
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_MOVEMENT_INVENTORY(state, data) {
			state.movementInventory = data;
		},
		SET_MOVEMENTS_INVENTORY(state, data) {
			state.movementsInventory = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("product/movement").then((response) => {
				commit("SET_MOVEMENTS_INVENTORY", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("product/movement", params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});
				});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.data.id) {
					await dispatch("update", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("product/movement/" + params.data.id, params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors ?? error.response.data,
						type: "error",
					});
				});
		},
		delete: async function ({ commit }, id) {
			return axios
				.delete("product/movement/" + id)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
				})
				.catch((error) => {
					commit("SET_ERRORS", {
						value: error.response.data.errors ?? error.response.data,
						type: "error",
					});
				});
		},
	},
	getters: {
		getMovementInventory: (state) => (movementInventoryId) => {
			if (state.movementsInventory)
				return state.movementsInventory.filter(
					(prop) => prop.id == movementInventoryId
				)[0];
			else return "";
		},
		getMovementsInventory: (state) => {
			if (state.movementsInventory) return state.movementsInventory;
			else return [];
		},
	},
};
