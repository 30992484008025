import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

let color = colors.orange
if(process.env.NODE_ENV == 'production'){
	color = colors.blue
}else if(process.env.NODE_ENV == 'stage') {
	color = colors.purple
}else if(process.env.NODE_ENV == 'develop') {
	color = colors.teal
}

const currentDate = new Date()

export default new Vuetify({
	theme: {
		dark: currentDate.getHours() >= 20 || currentDate.getDate <= 8,
		themes: {
			light: {
				primary: color, // #E53935
				secondary: colors.blueGrey.darken2, // #FFCDD2
				accent: colors.lightBlue.darken3, // #3F51B5,
				info: colors.teal.accent4,
			},
			dark: {
				primary: color, // #E53935
				secondary: colors.blueGrey.darken2, // #FFCDD2
				accent: colors.lightBlue.darken3, // #3F51B5,
				info: colors.teal.accent4,
			},
		},
	},
});
