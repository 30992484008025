<template>
	<div>
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-container class="mt-3 mb-10 flex-column">
			<v-row>
				<v-col cols="12">
					<div class="text-h3">Mi Empresa</div>
				</v-col>
				<v-col cols="12" md="4">
					<v-card elevation="2">
						<v-card-title>{{ alias }}</v-card-title>
						<v-card-text>
							{{ documentType }}: {{ document }} <br />
							Condición IVA: {{ vatCondition }} <br />
							<!-- Dirección: {{ address }} <br />
							<v-divider class="mt-3 mb-3" />
							<div class="text-h6">Contacto</div>
							Teléfono: {{ phone }} <br />
							Correo: {{ email }} <br />
						-->
						</v-card-text>
						<v-card-actions class="ma-3">
							<!-- <v-btn outlined rounded text :to="`enterprise/store/${getEnterpriseId}`"
								>Editar</v-btn
							>
							-->
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col cols="12" md="4" v-if="false">
					<v-card elevation="2">
						<v-card-title>Suscripción</v-card-title>
						<v-card-text>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias sit
							exercitationem eligendi nemo illo ex, optio aliquam, dignissimos
							hic officiis, nulla eius error. Voluptatem fuga aliquid non
							maxime, quos nulla.
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="4">
					<v-card elevation="2">
						<v-card-title>Puntos de Venta</v-card-title>
						<v-card-text v-if="hasAccess('LEC.ADM.PUN')">
							<v-list v-for="salePoint in salePoints" :key="salePoint.id">
								<v-btn
									left
									fab
									x-small
									v-if="hasAccess('ESC.ADM.PUN')"
									class="mr-4"
									:to="`sale-point/store/${salePoint.id}`"
								>
									<v-icon> mdi-pencil </v-icon></v-btn
								>
								<!--<v-btn
									left
									fab
									x-small
									class="mr-4"
									@click="deleteItem(salePoint.id)"
								>

									<v-icon color="red"> mdi-delete </v-icon></v-btn>-->
								{{ salePoint.name }}
							</v-list>
						</v-card-text>
						<v-card-actions>
							<!--<v-btn color="primary" rounded to="sale-point/store" fab>
								<v-icon> mdi-plus </v-icon>
							</v-btn>
							-->
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";

export default {
	components: { DialogDelete },
	data: () => ({
		alias: "Empresa",
		documentType: null,
		document: null,
		vatCondition: null,
		phone: "",
		email: "",
		address: "",
		salePoints: [],
		dialogDelete: false,
		deleteItemId: null,
		deleteLoad: false,
	}),

	computed: {
		...mapState("Auth", ["user"]),
		...mapGetters("Auth", [
			"getDocumentTypeEnterprise",
			"getVatConditionEnterprise",
			"getDocumentEnterprise",
			"getEnterpriseAlias",
			"getSalePoints",
			"getEnterpriseId",
			"getPhoneEnterprise",
			"getEmailEnterprise",
			"getFullAddressEnterprise",
			"hasAccess"
		]),
	},

	watch: {
		dialogDelete(val) {
			val || this.closeDelete();
		},
	},

	created: async function () {
		this.alias = this.getEnterpriseAlias;
		this.salePoints = this.getSalePoints;
		this.documentType = this.getDocumentTypeEnterprise;
		this.document = this.getDocumentEnterprise;
		this.vatCondition = this.getVatConditionEnterprise;
		/*this.phone = this.getPhoneEnterprise;
		this.email = this.getEmailEnterprise;
		this.address = this.getFullAddressEnterprise;*/
	},

	methods: {
		...mapActions("Auth", ["get"]),
		...mapActions("Enterprise", ["getEnterprise", "edit"]),
		...mapActions("SalePoint", ["delete"]),

		closeDelete() {
			this.dialogDelete = false;
		},

		deleteItem(id) {
			this.deleteItemId = id;
			this.dialogDelete = true;
		},

		successSave: async function () {
			await this.get();
			this.salePoints = this.getSalePoints;
			this.closeDelete();
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			await this.delete({
				id: this.deleteItemId,
				success: () => {
					this.successSave();
				},
			});
			this.deleteLoad = false;
		},
	},
};
</script>
