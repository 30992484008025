var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "mt-3 mb-10 flex-column" },
        [
          _c("v-row", [
            _c("div", { staticClass: "text-h3 ma-4" }, [_vm._v("Auditoria")]),
          ]),
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  dense: "",
                  headers: _vm.headers,
                  items: _vm.audits,
                  height: "700",
                  "fixed-header": true,
                  options: _vm.options,
                  "server-items-length": _vm.total,
                  "footer-props": {
                    itemsPerPageOptions: [5, 10, 50, 100],
                    itemsPerPageText: "Filas por página:",
                  },
                  loading: _vm.loading,
                  "sort-by": "created_at",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }