var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", { staticClass: "text-h6" }, [
                _vm._v(" " + _vm._s(this.getEnterpriseAlias) + " "),
              ]),
              _c("v-list-item-subtitle", [
                _vm._v(" " + _vm._s(this.getUserFullName) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { attrs: { dense: "", rounded: "" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-list-item",
            {
              key: item.title,
              class: item.bgColor,
              staticStyle: {
                margin: "16px 6px",
                padding: "4px",
                "padding-left": "15px",
              },
              attrs: { link: "" },
              on: {
                click: function ($event) {
                  return _vm.redirect(item.route)
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  _c("v-icon", { class: item.textColor }, [
                    _vm._v(_vm._s(item.icon)),
                  ]),
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(item.title))])],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }