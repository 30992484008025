var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.$store.state.loadingApp
        ? _c(
            "v-container",
            { staticClass: "text-center mt-10", attrs: { fluid: "" } },
            [
              _c("v-progress-circular", {
                staticClass: "mb-5 mt-10",
                attrs: {
                  size: 100,
                  width: 5,
                  color: "purple",
                  indeterminate: "",
                },
              }),
              _c("h5", { staticClass: "text-h5" }, [
                _vm._v("Cargando la app, se paciente..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      this.$session.exists() && this.user?.email_verified_at != null
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "overflow-y-hidden",
              attrs: {
                app: "",
                "mini-variant": _vm.mini,
                "expand-on-hover": "",
                permanent: "",
              },
              on: {
                "update:miniVariant": function ($event) {
                  _vm.mini = $event
                },
                "update:mini-variant": function ($event) {
                  _vm.mini = $event
                },
              },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list-item",
                { staticClass: "px-2" },
                [
                  _c(
                    "v-list-item-avatar",
                    [
                      _c("v-img", {
                        attrs: { src: require("../src/assets/logo.png") },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(this.getUserFullName)),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _vm._l(_vm.itemsWithChildren(_vm.items), function (header) {
                return _c(
                  "v-list",
                  { key: header.title, attrs: { dense: "", rounded: "" } },
                  [
                    _vm.hasChildren(header.children)
                      ? _c(
                          "div",
                          [
                            !_vm.mini
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-subtitle-2 font-weight-medium mt-2 mx-2 grey--text text--darken-1 pl-4 py-0",
                                    staticStyle: {
                                      "border-left": "3px solid #757575",
                                      "border-radius": "3px",
                                    },
                                  },
                                  [_vm._v(_vm._s(header.title))]
                                )
                              : _vm._e(),
                            _vm._l(
                              _vm.getSubItemsFiltered(header.children),
                              function (item) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: item.title,
                                    staticClass: "mb-1 mt-1",
                                    class: _vm.$vuetify.theme.dark
                                      ? _vm.getBgColorBlackTheme
                                      : item.bgColor,
                                    attrs: { link: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redirect(item.route)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            class: item.textColor,
                                            staticStyle: {
                                              "margin-left": "-8px",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.icon))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "text-capitalize text-body-2",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.title.toLowerCase())
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "overflow-hidden" },
        [
          this.$session.exists() && this.user?.email_verified_at != null
            ? _c(
                "v-app-bar",
                {
                  staticClass: "rounded-0",
                  attrs: {
                    app: "",
                    absolute: "",
                    dense: "",
                    height: "57",
                    color: "primary",
                    dark: "",
                    elevation: "3",
                    "elevate-on-scroll": "",
                    "scroll-target": "#scrolling-techniques-7",
                  },
                },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(this.getEnterpriseAlias)),
                  ]),
                  _c("v-spacer"),
                  _vm.closingSession
                    ? _c(
                        "div",
                        [
                          _c("v-progress-circular", {
                            staticClass: "mb-5 mt-5 mr-3",
                            attrs: {
                              size: 30,
                              width: 5,
                              color: "white",
                              indeterminate: "",
                            },
                          }),
                          _vm._v(" Cerrando sesión.. "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cambiarTema()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.iconTheme))])],
                    1
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { left: "", bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3221905750
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.optionsHeaderFiltered, function (item) {
                          return _c(
                            "v-list-item",
                            {
                              key: item.title,
                              on: {
                                click: function ($event) {
                                  return _vm.action(item.action)
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-sheet",
            {
              staticClass: "overflow-y-auto",
              attrs: { id: "scrolling-techniques-7", "max-height": "900" },
            },
            [
              _c(
                "v-main",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      this.$vuetify.breakpoint.smAndDown &&
      this.$session.exists() &&
      this.user?.email_verified_at != null
        ? _c(
            "v-footer",
            _vm._b(
              { attrs: { app: "", padless: true } },
              "v-footer",
              _vm.localAttrs,
              false
            ),
            [
              _c(
                "v-card",
                {
                  staticClass: "text-center",
                  attrs: { flat: "", tile: "", width: "100%" },
                },
                [
                  _c(
                    "v-card-text",
                    { attrs: { "elevation-5": "" } },
                    _vm._l(_vm.itemsMobile, function (item) {
                      return _c(
                        "v-btn",
                        {
                          key: item.icon,
                          staticClass: "mx-4",
                          class: item.bgColor,
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.redirect(item.route)
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { class: item.textColor, attrs: { size: "24px" } },
                            [_vm._v(" " + _vm._s(item.icon) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      this.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-footer",
            { attrs: { height: "30 pa-0", padless: "", app: "" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center pa-0", attrs: { cols: "12" } },
                [
                  _c("span", { staticClass: "text-caption" }, [
                    _vm._v(
                      "Desarrollado por Osvaldo Vallone - " +
                        _vm._s(new Date().getFullYear())
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }