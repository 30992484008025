import DailyBoxHome from "@/views/app/daily_box/daily-box-home.vue";
import DailyBoxData from "@/views/app/daily_box/daily-box-data.vue";

import BoxHome from "@/views/daily_box/box/daily-box-home.vue";
import BoxData from "@/views/daily_box/box/daily-box-data.vue";
import BoxForm from "@/views/daily_box/box/daily-box-form.vue";
import BoxOpenClose from "@/views/daily_box/box/daily-box-open-close.vue";

import MovementHome from "@/views/daily_box/movement/movement-home.vue";
import MovementData from "@/views/daily_box/movement/movement-data.vue";
import MovementForm from "@/views/daily_box/movement/movement-form.vue";

const STR_STORE = "store";

export default {
	path: "/daily-box",
	name: "",
	component: DailyBoxHome,
	meta: { requiresAuth: true, access: 'LEC.CAJ.CAJ' },
	children: [
		{
			path: "",
			name: "DailyBoxData",
			component: DailyBoxData,
			meta: { access: 'LEC.CAJ.CAJ' },
		},
		{
			path: 'box',
			component: BoxHome,
			meta: { access: 'LEC.CAJ.CAJ' },
			children: [
				{
					path: "",
					name: "BoxData",
					component: BoxData,
					meta: { access: 'LEC.CAJ.CAJ' },
				},
				{
					name: "BoxCreate",
					path: STR_STORE,
					component: BoxForm,
					meta: { access: 'ESC.CAJ.CAJ' },
				},
				{
					name: "BoxUpdate",
					path: STR_STORE + "/:id",
					component: BoxForm,
					meta: { access: 'ESC.CAJ.CAJ' },
				},
				{
					name: "BoxOpenClose",
					path: "open-close",
					component: BoxOpenClose,
					meta: { access: 'ESC.CAJ.CAJ' },
				},
			]
		},
		{
			path: "movement",
			name: "DailyBoxMovementHome",
			component: MovementHome,
			meta: { access: 'LEC.CAJ.MOV' },
			children: [
				{
					path: "",
					name: "DailyBoxMovementData",
					component: MovementData,
					meta: { access: 'LEC.CAJ.MOV' },
				},
				{
					path: STR_STORE,
					name: "DailyBoxMovementCreate",
					component: MovementForm,
					meta: { access: 'ESC.CAJ.MOV' },
				},
			],
		},
	],
};
