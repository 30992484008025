<template>
	<div>
		<Title title="Métodos de Pago"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
		<DialogDelete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></DialogDelete>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					fab
					color="primary"
					to="/treasury/payment-method/store"
					v-if="hasAccess('ESC.TRE.PME')"
					><v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="paymentMethods"
					:search="search.name"
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="tableLoad"
					sort-by="created_at"
					class="elevation-1"
				>
					<template v-slot:item.expense_type=" { item }">
							{{ capitalizeFirstLetter(item.expense_type.toLowerCase()) }}
					</template>
					<template v-slot:item.discount_value=" { item }">
							{{ getGeneralDiscount(item) }}
					</template>
					<template v-slot:item.amount=" { item }">
							{{ formatCurrency(item.amount) }}
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn v-if="hasAccess('ESC.TRE.PME')" class="ma-2" fab x-small @click="redirectWithParam('PaymentMethodUpdate', { id: item.id })">
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.TRE.PME')" class="ma-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => {
		return {
			name: "Métodos de Pago",
			search: {
				name: "",
			},
			dialogDelete: false,
			deleteLoad: false,
			tableLoad: false,
			headers: [
				{ text: "Nombre", value: "name" },
				{ text: "Fondo Asociado", value: "fund.title" },
				{ text: "Liquidación en Dias", value: "settlement_rate" },
				{ text: "Descuento General", value: "discount_value" },
				{ text: "Acciones", value: "actions", sortable: false },
			],
			editedIndex: -1,
			paymentMethod: null
		}
	},
	created: async function(){
		this.tableLoad = true;
		if(this.$store.state.PaymentMethod.paymentMethods.length == 0)
			await this.$store.dispatch('PaymentMethod/get');
		this.tableLoad = false;
	},
	computed: {
		...mapState('PaymentMethod', ['paymentMethods']),
		...mapGetters('Auth', ['hasAccess'])
	},
	methods: {
		...mapActions("PaymentMethod", ["store", "delete"]),

		deledit(item) {
			this.editedIndex = this.paymentMethods.indexOf(item);
			this.paymentMethod = Object.assign({}, item);
		},

		editItem(item) {
			this.$router.push({
				name: "PaymentMethodUpdate",
				params: { id: item.id },
			});
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			this.delete(this.paymentMethod);
			await this.$store.dispatch('PaymentMethod/get');
			this.closeDelete()
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete(){
			this.dialogDelete = false;
		},

		redirect(to) {
			this.$router.push({ name: to });
		},

		getGeneralDiscount(item) {
			if(item.discount_value > 0)
				return item.discount_value + ' ' + (item.discount_type == 'FIXED' ? 'Fijo' : '%') + ' ' + 'Redondeado en ' + item.discount_round
			return 'Sin descuento aplicado.'
		}

	}
}

</script>
