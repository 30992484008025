<template>
	<v-container>
		<v-row>
			<v-col>
				<!--<v-tabs color="primary" v-model="tab" background-color="transparent">
					<v-tab v-if="hasAccess('LEC.INV.PRD')" to="/inventory/product">Productos</v-tab>
					<v-tab v-if="hasAccess('LEC.INV.LPR')" to="/inventory/price-list">Listas de Precio</v-tab>
					<v-tab v-if="hasAccess('LEC.INV.CAT')" to="/inventory/category">Categorias</v-tab>
				</v-tabs>-->
				<router-view></router-view>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import CardForm from "@/components/card-form-custom-1.vue";
import ProductData from "@/views/inventory/product/product-data.vue";
import MovementData from "@/views/inventory/movement/movement-data.vue";
import FamilyData from "@/views/inventory/family/family-data.vue";
import { mapGetters, mapState } from "vuex";

export default {
	components: { CardForm, ProductData, MovementData, FamilyData },
	data: () => {
		return {
			search: {
				name: "",
			},
			tab: null,
			loading: true,
			totalAudits: 0,
			options: {},
			total: 0,
			headers: [
				{ text: "Usuario", value: "user.name" },
				{ text: "Sucursal", value: "branch.name" },
				{ text: "Módulo", value: "module" },
				{ text: "Detalle", value: "detail" },
				{ text: "Fecha y Hora", value: "created_at" },
			]
		};
	},
	computed: {

		...mapState("Deposit", ["deposits"]),
		...mapState("PriceList", ["priceLists"]),
		...mapGetters('Auth', ['hasAccess'])

	},
	watch: {
		options: {
			handler: async function () {
				this.loading = true;
				this.loading = false;
			},
			deep: true,
		},
	},
	methods: {
		redirect(route) {
			this.$router.push({ name: route });
		},
	},
};
</script>
