var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { lg: "5" } },
    [
      _c("v-select", {
        attrs: {
          items: _vm.vat_conditions,
          "item-text": "name",
          "item-value": "id",
          label: "Condición IVA",
          "error-messages": _vm.vatConditionErrors,
        },
        on: {
          change: function ($event) {
            return _vm.v.vat_condition.$touch()
          },
          blur: function ($event) {
            return _vm.v.vat_condition.$touch()
          },
        },
        model: {
          value: _vm.vat_condition,
          callback: function ($$v) {
            _vm.vat_condition = $$v
          },
          expression: "vat_condition",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }