import axios from "axios";

export default {
	namespaced: true,
	state: {
		charges: []
	},
	mutations: {
		SET_CHARGE(state, data) {
			state.charge = data;
		},
		SET_CHARGES(state, data) {
			state.charges = data;
		}
	},
	actions: {
		get: async function ({ commit }) {
			return await axios.get("charge").then((response) => {
				commit("SET_CHARGES", response.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("charge", params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {});
		},
		chargeSale: async function ({ commit }, params) {
			params.before();
			return axios
				.post("charging/charge/sale/" + params.id, params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', res, { root: true })
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			if (params.validate) {
				params.before();
				if (params.id) {
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				} else {
					await dispatch("create", {
						data: params.data,
						success: () => {
							params.success();
						},
					});
				}
				params.complete();
			}
		},
		update: async function ({ commit }, params) {
			return axios
				.put("charge/" + params.id, params.data)
				.then(() => {
					params.success();
				})
				.catch(() => {});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("charge/" + params.id)
				.then(() => {})
				.catch(() => {});
		},
	},
	getters: {
		getcharges: (state) => {
			if (state.charges) return state.charges;
			else return [];
		},
		getFirstcharge: (state) => {
			if (state.charges) return state.charges[0];
			else return [];
		},
		getcharge: (state) => (chargeId) => {
			if (state.charges)
				return state.charges.filter((prop) => prop.id == chargeId)[0];
			else return "";
		},
	},
};
