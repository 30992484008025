var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { elevation: "4", "min-height": _vm.minHeight } },
    [
      _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _c("v-card-text", [
        Array.isArray(_vm.number)
          ? _c(
              "div",
              { staticClass: "text-left", attrs: { width: "100%" } },
              _vm._l(_vm.number, function (item, index) {
                return _c("div", { key: index }, [
                  _c("p", { staticClass: "d-inline" }, [
                    _vm._v(_vm._s(item.title) + " "),
                  ]),
                  _c("p", { staticClass: "d-inline", class: _vm.getClass }, [
                    _vm._v(_vm._s(item.value)),
                  ]),
                ])
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "text-center", attrs: { width: "100%" } },
              [
                _c("p", { class: _vm.getClass }, [
                  _vm._v(" " + _vm._s(_vm.number)),
                ]),
              ]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }