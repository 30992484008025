<template>
	<div>
		<v-container class="mt-5">
			<v-row justify="center">
				<v-col cols="12" sm="5" md="4" lg="4">
					<v-card elevation="2" :loading="loading">
						<v-card-text>
							<h2>Se envió la confirmación de correo electrónico</h2>
							<hr class="ma-3" />
							<span
								>Verifique la casilla de su correo y acceda al link de
								confirmación</span
							>
							<hr class="ma-3" />
							<v-btn color="primary" class="mb-2" block @click="irAlHome()">
								Ya confirmé el email
							</v-btn>
							<v-btn
								color="primary"
								class="mt-2"
								text
								block
								@click="sendEmail()"
							>
								Enviar de nuevo
							</v-btn>
							<v-alert
								class="ma-3"
								border="top"
								color="green"
								v-model="emailSent"
							>
								Correo electrónico enviado
							</v-alert>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
	data: () => {
		return {
			loading: false,
			emailSent: false,
		};
	},
	created() {
		if (this.user.email_verified_at != null) this.$router.push("/");
	},
	computed: {
		...mapState("Auth", ["user"]),
	},
	methods: {
		...mapActions("Auth", ["sendEmailForVerifyAccount", "get"]),

		irAlHome: async function () {
			await this.get();
			this.$router.push("/");
		},

		sendEmail: async function () {
			this.loading = true;
			await this.sendEmailForVerifyAccount();
			this.loading = false;
			this.emailSent = true;
		},
	},
};
</script>
