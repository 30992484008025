import { render, staticRenderFns } from "./toolbar.vue?vue&type=template&id=c2a23fca&"
import script from "./toolbar.vue?vue&type=script&lang=js&"
export * from "./toolbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\osval\\vue_projects\\spa.lencerp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2a23fca')) {
      api.createRecord('c2a23fca', component.options)
    } else {
      api.reload('c2a23fca', component.options)
    }
    module.hot.accept("./toolbar.vue?vue&type=template&id=c2a23fca&", function () {
      api.rerender('c2a23fca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/toolbar.vue"
export default component.exports