var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Toast", {
        attrs: { type: _vm.errors.type },
        model: {
          value: _vm.errors.value,
          callback: function ($$v) {
            _vm.$set(_vm.errors, "value", $$v)
          },
          expression: "errors.value",
        },
      }),
      _c(
        "v-container",
        { staticClass: "mt-3 mb-10 flex-column" },
        [
          _c(
            "v-row",
            { staticClass: "justify-center" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.roles,
                  "sort-by": "name",
                  loading: _vm.tableLoad,
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "" } },
                          [
                            _c("v-toolbar-title", [_vm._v(_vm._s(_vm.name))]),
                            _c("v-divider", {
                              staticClass: "mx-4",
                              attrs: { inset: "", vertical: "" },
                            }),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", small: "", fab: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.redirect("RoleCreate")
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v(" mdi-plus "),
                                ]),
                              ],
                              1
                            ),
                            _c("DialogDelete", {
                              attrs: {
                                value: _vm.dialogDelete,
                                progress: _vm.deleteLoad,
                              },
                              on: {
                                close: _vm.closeDelete,
                                confirm: _vm.deleteItemConfirm,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _vm.hasAccess("ESC.ADM.ROL")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectWithParam("RoleUpdate", {
                                      id: item.id,
                                    })
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                              1
                            )
                          : _vm._e(),
                        _vm.hasAccess("ESC.ADM.ROL")
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v(" mdi-delete ")])],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return undefined
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }