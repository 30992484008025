<template>
	<v-card elevation="1" outlined>
		<v-card-title> {{ title }} </v-card-title>
		<v-card-subtitle v-if="subtitle"> {{ subtitle }} </v-card-subtitle>
		<v-card-text style="overflow: auto; max-height: 300px" class="pa-4">
			<v-list-item v-for="item in this.list" :key="item.id">
				<v-list-item-title>{{ getName(item) }}</v-list-item-title>
				<v-btn
					class="ml-2"
					fab
					x-small
					@click="redirectWithParam(linkToUpdate, { id: item.id })"
				>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>
			</v-list-item>
		</v-card-text>
		<v-card-actions>
			<v-btn v-if="linkToCreate" outlined fab x-small text @click="redirect(linkToCreate)">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
			<v-btn
				v-if="linkToView"
				outlined
				fab
				x-small
				text
				@click="redirect(linkToView)"
			>
				<v-icon>mdi-view-headline</v-icon>
			</v-btn>
			<v-btn outlined fab x-small text>
				<v-icon>mdi-help</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>
<script>
export default {
	props: [
		"title",
		"subtitle",
		"list",
		"linkToCreate",
		"linkToUpdate",
		"linkToView",
	],
	methods: {
		redirect(route) {
			this.$router.push({ name: route });
		},
		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},
		getName(item){
			if(item.alias)
				return item.alias;
			return item.name
		}
	},
};
</script>
