import axios from "axios";
import valuedation from "../../core/helpers/valuedations";

export default {
	namespaced: true,
	state: {
		users: [],
		role: {},
		roles: [],
		userTypes: [],
		auth: false,
		errors: {
			value: null,
			type: "error",
		},
	},
	mutations: {
		SET_USERS(state, data) {
			state.users = data;
		},
		SET_ROLES(state, data) {
			state.roles = data;
		},
		SET_USER_TYPES(state, data) {
			state.userTypes = data;
		},
		SET_AUTH(state, data) {
			state.auth = data;
		},
		SET_ERRORS(state, data) {
			state.errors = data;
			setTimeout(function () {
				state.errors = {
					value: null,
					type: "error",
				};
			}, 3000);
		},
	},
	actions: {
		get: async function ({ commit }) {
			return axios.get("user").then((response) => {
				commit("SET_USERS", response.data.data);
			}).catch(() => {});
		},
		create: async function ({ commit }, params) {
			return axios
				.post("user", params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		store: async function ({ dispatch }, params) {
			params.before();
			if (params.id) {
				if (params.account)
					await dispatch("updateAccount", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
				else
					await dispatch("update", {
						id: params.id,
						data: params.data,
						success: () => {
							params.success();
						},
					});
			} else {
				await dispatch("create", {
					data: params.data,
					success: () => {
						params.success();
					},
				});
			}
			params.complete();
		},
		update: async function ({ commit }, params) {
			return axios
				.put("user/" + params.id, params.data)
				.then((res) => {
					commit('SET_ERRORS', res, { root: true })
					params.success();
					commit('SET_ERRORS', null, { root: true })
				})
				.catch(() => {});
		},
		updateAccount: async function ({ commit }, params) {
			return axios
				.put("user/account", params.data)
				.then((res) => {
					commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
					/*commit("SET_ERRORS", {
						value: error.response.data.errors,
						type: "error",
					});*/
				});
		},
		delete: async function ({ commit }, params) {
			return axios
				.delete("user/" + params.id)
				.then((res) => {
					//commit("SET_ERRORS", { value: res.data, type: "success" });
					params.success();
				})
				.catch((error) => {
						//if (error.response.data)
						//commit("SET_ERRORS", { value: error.response.data, type: "error" });
				});
		},
	},
	getters: {
		getUser: (state) => (userId) => {
			if (valuedation.count(state.users) > 0)
				return state.users.filter((prop) => prop.id == userId)[0];
			else return "";
		},
		getFirstUser: (state) => {
			if (valuedation.count(state.users) > 0)
				return state.users[0];
			else return "";
		},
		getAddress: (state) => (userId) => {
			if (valuedation.count(state.users) > 0)
				return state.users.filter((prop) => prop.id == userId)[0].address;
			else return null;
		},
		getPhone: (state) => (userId) => {
			if (valuedation.count(state.users) > 0)
				return state.users.filter((prop) => prop.id == userId)[0].contacts
					.phone;
			else return "";
		},
		getEmail: (state) => (userId) => {
			if (valuedation.count(state.users) > 0)
				return state.users.filter((prop) => prop.id == userId)[0].contacts
					.email;
			else return "";
		},
		getRole: (state) => (userId) => {
			if (valuedation.count(state.users) > 0)
				return state.users.filter((prop) => prop.id == userId)[0].role[0];
			else return "";
		},
	},
};
