var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Title", { attrs: { title: "Usuarios" } }),
      _c("Toast", {
        attrs: { type: _vm.errors.type },
        model: {
          value: _vm.errors.value,
          callback: function ($$v) {
            _vm.$set(_vm.errors, "value", $$v)
          },
          expression: "errors.value",
        },
      }),
      _c(
        "v-container",
        {
          staticClass: "mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg",
          style: !_vm.$vuetify.theme.dark ? "background-color: #FAFAFA" : "",
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  this.hasAccess("ESC.ADM.USU")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            fab: "",
                            dark: "",
                            color: "primary",
                            to: { name: "UserCreate" },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v(" mdi-plus "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  this.hasAccess("LEC.ADM.ROL")
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            rounded: "",
                            "x-large": "",
                            dark: "",
                            color: "primary",
                            to: { name: "RoleData" },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { dark: "" } },
                            [_vm._v(" mdi-account-key-outline ")]
                          ),
                          _vm._v(" Roles "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("dialog-delete", {
                    attrs: {
                      value: _vm.dialogDelete,
                      progress: _vm.deleteLoad,
                    },
                    on: {
                      close: _vm.closeDelete,
                      confirm: _vm.deleteItemConfirm,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      counter: 10,
                      label: "Buscar usuario...",
                      required: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  [
                    _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.users,
                        "items-per-page": 5,
                        search: _vm.search,
                        loading: _vm.loadingUsers,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item.email_verified_at",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-chip",
                                {
                                  attrs: { color: _vm.getColorForState(item) },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getDescriptionForState(item)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "item.actions",
                          fn: function ({ item }) {
                            return [
                              _vm.hasAccess("ESC.ADM.USU")
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { fab: "", "x-small": "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.redirectWithParam(
                                            "UserUpdate",
                                            { id: item.id }
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v(" mdi-pencil ")])],
                                    1
                                  )
                                : _vm._e(),
                              _vm.hasAccess("ESC.ADM.USU")
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { fab: "", "x-small": "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(item)
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v(" mdi-delete ")])],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }