<template>
	<v-dialog
		hide-overlay
		transition="dialog-bottom-transition"
		@input="$router.back()"
		v-model="dialog"
		max-width="700px"
		:fullscreen="$vuetify.breakpoint.xs"
	>
		<v-card>
			<v-toolbar dark fixed color="primary">
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn
						icon
						dark
						@click="
							dialog = !dialog;
							$router.back();
						"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text class="pt-2">
				<v-form ref="form" id="form-category-attribute" @submit.prevent="save">
					<v-container>
						<v-row>
							<v-col cols="4">
								<v-text-field
									required
									v-model="form.name"
									label="Nombre"
									:error-messages="nameErrors"
									@change="$v.form.name.$touch()"
									@blur="$v.form.name.$touch()"
								></v-text-field>
							</v-col>
							<v-col cols="8">
								<v-textarea
									solo
									rows="2"
									v-model="form.description"
									label="Detalle..."
								></v-textarea>
							</v-col>
							<v-col cols="4">
								<v-select
									:items="types"
									item-text="name"
									item-value="id"
									v-model="type"
									label="Tipo de atributo"
								></v-select>
							</v-col>
						</v-row>
						<v-row v-if="type == 'LIST'">
							<h5 class="text-h5">Listado de valores</h5>
							<p class="text-subtitle-1">Agregue los valores que contendrá este atributo para su selección.</p>
							<AttributeValueList
								:in-values="form.values"
								v-on:list-updated="listUpdated"
							>
							</AttributeValueList>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="
						dialog = !dialog;
						$router.back();
					"
				>
					Cancelar
				</v-btn>
				<v-btn
					type="submit"
					:disabled="loading"
					:loading="loading"
					form="form-category-attribute"
					color="blue darken-1"
					text
				>
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import AttributeValueList from "@/components/inventory/attribute-value-list";

export default {
	components: { AttributeValueList },
	validations: {
		form: {
			name: { required }
		},
	},
	data: () => {
		return {
			active: [],
			open: [],
			dialog: true,
			dialogParent: false,
			loading: false,
			disabled: false,
			form: {
				id: null,
				name: null,
				description: null,
				values: []
			},
			type: 'FIXED',
			types: [
				{id: 'FIXED', name: 'Valor genérico'},
				{id: 'LIST', name: 'Lista de valores'},
			]
		};
	},
	created: async function () {
		if(this.$store.state.CategoryAttribute.categoryAttributes.length == 0)
			await this.$store.dispatch('CategoryAttribute/get');

		if(this.$route.params.id){
			this.form = Object.assign({}, this.getCategoryAttribute(this.$route.params.id));
			this.form.values = this.form.values.map(element => element.value);
			this.type = (this.form.values.length > 0) ? 'LIST' : 'FIXED';
		}
	},
	computed: {

		...mapGetters('CategoryAttribute', ['getCategoryAttribute']),

		title() {
			return this.$route.params.id > 0 ? "Editar Atributo" : "Nuevo Atributo";
		},

		nameErrors() {
			const errors = [];
			if (!this.$v.form.name.$dirty) return errors;
				!this.$v.form.name.required && errors.push("El nombre es requerido");
			return errors;
		},
	},
	methods: {

		...mapActions('CategoryAttribute', ['store']),

		listUpdated(values){
			this.form.values = values;
		},

		beforeSave() {
			this.loading = true
		},

		successSave() {
			if(this.$store.state.errors.status == 200){
				this.$store.dispatch('CategoryAttribute/get');
				this.$router.back();
			}
		},

		completeSave() {
			this.loading = false;
		},

		save() {
			this.$v.$touch();
			if (!this.$v.$invalid)
				this.store({
					validate: !this.$v.$invalid,
					id: this.form.id,
					data: {
						name: this.form.name,
						description: this.form.description,
						values: this.type == 'FIXED' ? [] : this.form.values
					},
					before: () => {
						this.beforeSave();
					},
					success: () => {
						this.successSave();
					},
					complete: () => {
						this.completeSave();
					},
				});
		},
	},
};
</script>
