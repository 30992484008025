<template>
	<div>
		<Title title="Productos"></Title>
		<v-container :style="!$vuetify.theme.dark ? 'background-color: #FAFAFA' : ''" class="mt-3 mb-10 flex-column pa-4 elevation-2 rounded-lg">
		<dialog-delete
			:value="dialogDelete"
			:progress="deleteLoad"
			@close="closeDelete"
			@confirm="deleteItemConfirm"
		></dialog-delete>
		<v-row>
			<v-col>
				<v-btn
					class="mx-2"
					fab
					v-if="hasAccess('ESC.INV.PRD')"
					dark
					color="primary"
					to="/inventory/product/store"
				>
					<v-icon dark> mdi-plus </v-icon>
				</v-btn>
				<v-btn
					class="mx-2"
					v-if="hasAccess('ESC.INV.PRD')"
					rounded
					dark
					color="primary"
					to="/inventory/product/stock"
				>
					<v-icon dark> mdi-cube-outline </v-icon> Ajuste Stock
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:headers="headers"
					:items="getProducts"
					:single-expand="singleExpand"
    			:expanded.sync="expanded"
					show-expand
					:footer-props="{
						itemsPerPageOptions: [5, 10, 50, 100],
						itemsPerPageText: 'Filas por página:',
					}"
					:loading="loading"
					sort-by="created_at"
					class="elevation-1"
				>
				<template v-slot:top>
						<v-container fluid>
							<v-row>
								<v-col
									cols="12"
									sm="6"
									md="4"
								>
								<v-text-field
									v-model="filter.name"
									outlined
									label="Buscar producto..."
									required
								></v-text-field>
								</v-col>
								<v-col cols="12" lg="4">
									<v-select
										:items="getProvidersFilter"
										label="Proveedor"
										item-text="text"
										item-value="value"
										v-model="filter.provider"
										outlined
									>
									</v-select>
								</v-col>
								<v-col cols="12" lg="4">
									<v-switch
										v-model="filter.with_stock"
										label="Solo con stock"
									></v-switch>
								</v-col>
							</v-row>
						</v-container>
					</template>
					<template class="text-center" v-slot:item.stock.count="{ item }">
						<v-chip class="text-body-1 font-weight-bold" dark :color="getColorForState(item)">
							{{ item.stock.count }}
						</v-chip>
					</template>
					<template v-slot:item.provider_id="{ item }">
						{{ getProvider(item.provider_id)?.alias ?? 'Sin proveedor asignado' }}
					</template>
					<template v-slot:item.full_name="{ item }">
						<div class="pa-2">
							<p class="text-body-1 font-weight-medium text-capitalize ma-0">{{ getCategory(item.category_id)?.name }}</p>
							<p class="text-body-2 font-weight-light text-capitalize ma-0">{{ item.attributes_name }} {{ item.name }}</p>
						</div>
					</template>
					<template class="text-center" v-slot:item.cost_price="{ item }">
							<p class="text-h6">{{ formatCurrency(item.cost_price) }}</p>
					</template>
					<template v-slot:expanded-item="{ headers, item }">
						<td class="elevation-0" :colspan="headers.length">
							<p class="text-subtitle-1 mt-3"><v-icon> mdi-cube-outline </v-icon> Stock disponible <v-icon>mdi-arrow-right</v-icon> <strong class="text-subtitle-1 font-weight-bold" :class="{'green--text': item.stock.count > 0}"> {{ item.stock.count }}</strong> </p>
							<div v-if="getProductStock(item)[0]?.name">
								<v-chip  v-for="productStock in getProductStock(item)" :key="productStock.id"
									class="ma-2"
									color="green"
									text-color="white"
								>
									<v-avatar
										left
										class="green darken-4"
									>
										{{ productStock.count }}
									</v-avatar>
									{{ productStock.name }}
								</v-chip>
							</div>
						</td>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-btn
							class="ml-2"
							fab
							v-if="hasAccess('ESC.INV.PRD')"
							x-small
							@click="redirectWithParam('ProductUpdate', { id: item.id })"
						>
							<v-icon> mdi-pencil </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.INV.PRD')" class="ml-2" fab x-small @click="deleteItem(item)">
							<v-icon> mdi-delete </v-icon>
						</v-btn>
						<v-btn v-if="hasAccess('ESC.INV.PRD')" class="ml-2" fab x-small @click="updateStock(item)">
							<v-icon> mdi-cube-outline </v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		</v-container>
	</div>
</template>
<script>

import { mapActions, mapGetters, mapState } from "vuex";
import DialogDelete from "@/components/dialog-delete.vue";
import Title from "@/components/title-1.vue";

export default {
	components: { DialogDelete, Title },
	data: () => {
		return {
			filter: {
				name: "",
				provider: "",
				with_stock: false
			},
			tab: null,
			expanded: [],
      singleExpand: false,
			loading: false,
			totalAudits: 0,
			options: {},
			total: 0,
			headers: [
				{ text: "#", value: "code" },
				{ text: "Nombre", value: "full_name" },
				{ text: "Proveedor Asignado", value: "provider_id"},
				{ text: "Precio de Costo", value: "cost_price" },
				{ text: "Stock", value: "stock.count", align: 'center' },
				{ text: "Acciones", value: "actions" },
			],
			dialogDelete: false,
			deleteLoad: false,
		};
	},
	created: async function () {
		this.loading = true;

		if(this.$store.state.Provider.providers.length == 0)
			await this.$store.dispatch('Provider/get');

		if(this.$store.state.Category.categories.length == 0)
			await this.$store.dispatch('Category/get');

		if(this.$store.state.Product.products.length == 0)
			await this.$store.dispatch('Product/get');
		this.loading = false;

		this.$store.dispatch('Product/getStock');
	},
	computed: {
		...mapState("Product", ["products", "stock"]),
		...mapState("Provider", ['providers']),
		...mapGetters("Auth", ['hasAccess']),
		...mapGetters('Provider', ['getProvider']),
		...mapGetters('Category', ['getCategory']),
		...mapGetters('Product', ['getFirstProviderLinked']),

		getProducts(){
			let products = [].concat(this.products.filter(element => element.active));

			if(this.filter.provider) {
				products = products.filter(element => {
					return element.provider_id == this.filter.provider
				})
			}

			if(this.filter.name) {
				products = products.filter(element => {
					const searchText = this.filter.name.toLowerCase();
					const searchWords = searchText.split(" ");
					const itemValues = [element.name, this.getCategory(element.category_id)?.name ?? '', element.attributes_name, element.code];
					return searchWords.every(word => {
						return itemValues.some(value => value.toLowerCase().includes(word));
					});
				})
			}

			if(this.filter.with_stock) {
				products = products.filter(element => {
					return element.stock.count > 0
				})
			}

			return products;
		},

		getProvidersFilter() {
			let providers = [];

			this.providers.forEach(element => {
				providers.push({
					value: element.id,
					text: element.alias
				})
			});

			providers = providers.sort((a, b) => a.text.localeCompare(b.text));

			providers.unshift({
				value: null,
				text: 'Todos'
			})

			return providers;
		}
	},
	methods: {

		...mapActions("Product", ['delete']),

		redirectWithParam(route, params) {
			this.$router.push({ name: route, params: params });
		},

		getProductStock(product) {

			let stock = this.stock.filter(element => {
				return element.product_id == product.id
			})

			if(stock.length == 0) {
				return []
			}

			let productStock = [];

			stock.forEach(element => {
				productStock.push({
					id: element.id,
					name: element.name,
					count: element.count
				})
			});

			return productStock;

		},

		deledit(item) {
			this.editedIndex = this.products.indexOf(item);
			this.form = Object.assign({}, item);
		},

		getColorForState(item) {
			if (item.stock.count == 0) return "red";
			if (item.stock.count <= item.stock.min) return "orange";
			else return "green";
		},

		deleteItem(item) {
			this.deledit(item);
			this.dialogDelete = true;
		},

		deleteItemConfirm: async function () {
			this.deleteLoad = true;
			this.formLoad = true;
			await this.delete(this.form.id);
			this.closeDelete();
			await this.$store.dispatch('Product/get');
			this.formLoad = false;
			this.deleteLoad = false;
		},

		closeDelete() {
			this.dialogDelete = false;
		},

		updateStock(item){

		}
	},
};
</script>
