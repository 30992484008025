var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        "max-width": "700px",
        fullscreen: _vm.$vuetify.breakpoint.xs,
      },
      on: { input: _vm.closeDialog },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", fixed: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _c("v-spacer"),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = !_vm.dialog
                          _vm.closeDialog()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-2" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { id: "form-product" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-combobox", {
                                attrs: {
                                  items: _vm.providers,
                                  "item-text": "alias",
                                  "item-value": "id",
                                  "auto-select-first": "",
                                  label: "Proveedor Asociado",
                                },
                                model: {
                                  value: _vm.form.provider,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "provider", $$v)
                                  },
                                  expression: "form.provider",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ProductForm", {
                    attrs: {
                      withAddOption: false,
                      withSingleForm: false,
                      product: _vm.productToAdd,
                    },
                    on: { "add-product": _vm.productAdded },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "blue darken-1", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = !_vm.dialog
                      _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Cancelar ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.loading,
                    loading: _vm.loading,
                    form: "form-product",
                    color: "blue darken-1",
                    text: "",
                  },
                },
                [_vm._v(" Guardar ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }